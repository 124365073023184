import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { UINavigation } from '@coreui/services/ui.navigation.service';

import { NavigationDrawerSettings } from '@coreui/classes/navigation/navigation.drawer.class'

@Component({
	selector: 		'sp-sidenav-drawer',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<slider-sheet [direction]="'right'" [size]="320">
      <div right>
        <div class="_la-navigation-drawer" surfaceContainerLow
			[style.height.px]=_containerHeight>

          <div class="header" primaryContainer>
			  <div class="user">
				<div class="circle-wrapper">
					<div class="circle" secondaryColor>
						<img [src]=avatarImg/>
					</div>
				</div>
				<div class="content">
					<div class="primary-text" onPrimaryContainer font-family>Giuliano Silvestro</div>
				</div>
			</div>

		  </div>
		  <div class="menu-container">
			<navigation-drawer-menu [data]=this._mainNavigationDrawer (clicked)=menuClicked($event)></navigation-drawer-menu>
		  </div>
		  <div class="action">
			<sbutton [label]="'Sign Out'" [fullwidth]=true
					 [large]=false [radius]=50 [tonaltype]="'primary'">
			</sbutton>
		  </div>
        </div>
      </div>
    </slider-sheet>		

		`,
})


export class SidenavDrawerComponent {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	uiNavigation: UINavigation
	_surfaceColor: string = ""

	avatarImg: string = "./assets/avatars/gs.png"

	_mainNavigationDrawer: NavigationDrawerSettings = new NavigationDrawerSettings()
	_containerHeight: number = 100

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _uiNavigation: UINavigation) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.uiNavigation = _uiNavigation


		this._mainNavigationDrawer.addDestination("home", "Home", "home", "home-outline")
		this._mainNavigationDrawer.addDestination("about", "About Us", "book-account", "book-account-outline")
		this._mainNavigationDrawer.addDestination("ecomm", "Ecommerce", "book-account", "book-account-outline")
		this._mainNavigationDrawer.addDestination("patterns", "Trash", "delete", "delete-outline")
		this._mainNavigationDrawer.addDestination("icons", "Icons", "apps", "apps")
		

		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self._surfaceColor = self.uiColorModel.surface
		});
	  
	}

	menuClicked($event: any) {
		this.uiNavigation.activeView = $event.id
		setTimeout(() => {
			this.eventsService.broadcast('onHideSliderSheet', null);
			this.ref.detectChanges();
		}, 150);
	}


	ngOnInit() {
		this._surfaceColor = this.uiColorModel.surface
		this._containerHeight = window.innerHeight
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
