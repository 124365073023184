import {Injectable} from '@angular/core'
import { HttpClient } from  '@angular/common/http'
import { Observable, Subject, ReplaySubject, from, of, range, timer, interval  } from 'rxjs'
import {takeWhile, take } from "rxjs/operators"
import { map, filter, switchMap } from 'rxjs/operators'


import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { UILayout } from '@coreui/services//ui.layout.service'
import { TypographyModel } from '@coreui/services/typography.service';

import { NavigationBarSettings } from '@coreui/classes/navigation/navigation.bar.class'
import { NavigationRailSettings } from '@coreui/classes/navigation/navigation.rail.class'
import { NavigationDrawerSettings } from '@coreui/classes/navigation/navigation.drawer.class'
import { NavigationDrawerMenu } from '@coreui/classes/navigation/navigation.drawer.menu.class'

import { UITheme } from '@coreui/classes/theme/ui.theme.class'
            
import { PersonSampleDataInfo } from '@libraries/people-ui/classes/person.sample.class'


import { DomainOpeningHoursService } from '@libraries/domainprotocols/opening.hours.service';

import { AirportsData } from '../data/airports/airports.class';


@Injectable()
export class ApplicationService  {

  private uiNavigation: UINavigation
  private uiColorModel: UIColorModel
  private uiLayout: UILayout
	private typographyModel: TypographyModel
	private httpClient: HttpClient

  private _mainNavigationRail: NavigationRailSettings = new NavigationRailSettings()
  private _mainNavigationBar: NavigationBarSettings = new NavigationBarSettings()
  private _mainNavigationDrawer: NavigationDrawerSettings = new NavigationDrawerSettings()
  private _mainNavigationDrawerMenu: NavigationDrawerMenu = new NavigationDrawerMenu()



  private samplePeople: PersonSampleDataInfo = new PersonSampleDataInfo()
  private people: any[] = []

  private _activeBackground: string = "peachy"

  constructor(_http: HttpClient,
    private _uiNavigation: UINavigation, 
    private _uiColorModel: UIColorModel,
    private _uiLayout: UILayout,
    private _typographyModel: TypographyModel) {

    this.httpClient = _http
    this.uiNavigation = _uiNavigation
    this.uiColorModel = _uiColorModel
    this.uiLayout = _uiLayout
    this.typographyModel = _typographyModel

    this.settings()

    this.people = this.samplePeople.data

    this.datamodel()
  }

  darkMode() {
    this.uiColorModel.darkScheme()
  }
  lightMode() {
    this.uiColorModel.lightScheme()
  }

  public get activeBackground(): string {
    return this._activeBackground
  }
  public set activeBackground(value: string) {
    this._activeBackground = value
  }

  public navigationRail(): NavigationRailSettings {
    return this._mainNavigationRail
  }

  public navigationDrawer(): NavigationDrawerSettings {
    return this._mainNavigationDrawer
  }

  public navigationBar(): NavigationBarSettings {
    return this._mainNavigationBar
  }

  private settings() {

    this.peachyTheme()

//    this.typographyModel.uiFontFamily = "Rubik Regular"
 //   this.typographyModel.displayFontFamily = "Alegreya Regular"

 //   this.lightColorMode()

    //this.darkColorMode()
    this._mainNavigationRail = this.defineNavigationRail()
    this._mainNavigationDrawer = this.defineNavigationDrawer()
    this._mainNavigationBar = this.defineNavigationBar()

    this.uiLayout.applicationContainerSettings().compactNavigationComponent = "both"
    this.uiLayout.applicationContainerSettings().mediumNavigationComponent = "rail"
    this.uiLayout.applicationContainerSettings().expandedNavigationComponent = "expanded"
		//this.uiLayout.showInnerHeader = true

    this.uiNavigation.activeView = "travel"
    this.uiNavigation.activeBottomNav = "home"
    this._activeBackground = "peachy"
     
  }

  peachyTheme() {
    let theme: UITheme = new UITheme()
    let amaranthRed: string = "e96625"
    let blazingYellow: string = "fee715"
    let reef: string = "017371"
    let shadedGrey: string = "605D62"
    let baroqueGrey: string = "605D66"
    theme.setColors(amaranthRed, amaranthRed, reef, shadedGrey, baroqueGrey)
    theme.uiFont = "Inter"
    theme.displayFont = "Montserrat Regular"

    this.typographyModel.uiFontFamily = theme.uiFont
    this.typographyModel.displayFontFamily = theme.displayFont
    this.uiColorModel.generateM3ColorScheme(theme.colors.primaryColor, theme.colors.secondaryColor, theme.colors.tertiaryColor , theme.colors.neutralColor, theme.colors.neutralVariantColor, theme.colors.errorColor)
    this.uiColorModel.lightScheme()
  }


  lightColorMode() {
    let amaranthRed: string = "e96625"
    this.uiColorModel.generateM3ColorScheme(amaranthRed, "017371", "fee715" , "605D62", "605D66", "b3261e")
    this.uiColorModel.lightScheme()
    this.uiColorModel.primary = this.uiColorModel.utils().hexFromHCT("e96625", 60) 
  }

  darkColorMode() {
    this.uiColorModel.generateM3ColorScheme("ffcc99", "017371", "fee715" , "605D62", "605D66", "b3261e")
    this.uiColorModel.darkScheme()
    this.uiColorModel.background = this.uiColorModel.utils().hexFromHCT(this.uiColorModel.neutralKeyColor, 10) 
    this.uiColorModel.background = "#192734"
  }


  peachyBackground(): any {
    let amaranthRed: string = "d63c68"
    let amber: string = "ffc400"
    let aquamarine: string = "7fffd4"

    let background: string = this.uiColorModel.utils().hexFromHCT(this.uiColorModel.primaryKeyColor, 98)
    let rec = {
      color1:     amaranthRed,
      color2:     amber,
      backgroundcolor: background,
      interval: 5000,
      speed: 5000,
      numclouds: 12,
      cloudopacity: 0.04
    }
    return rec
  }

  peachyNightBackground(): any {
    let amaranthRed: string = "041e21"
    let amber: string = "1b0421"
    
    //0e081f
    let background: string = "09091a"
    let rec = {
      color1:     amaranthRed,
      color2:     amber,
      backgroundcolor: background,
      interval: 5000,
      speed: 5000,
      numclouds: 2,
      cloudopacity: 0.5
    }
    return rec
  }

  mintBackground(): any {
    let amaranthRed: string = "14f793"
    let amber: string = "176644"
    
    //0e081f
    let magicMint: string = "ccf0e0"
    let background: string = this.uiColorModel.utils().hexFromHCT(magicMint, 99)
    background = background.substring(1, background.length)
    background = "e9f5ef"
    let rec = {
      color1:     amaranthRed,
      color2:     amber,
      backgroundcolor: background,
      interval: 5000,
      speed: 5000,
      numclouds: 8,
      cloudopacity: 0.05
    }
    return rec
  }




  peopleData() {
    return this.people
  }



  ///////////////////////////////////////////////////////////////////////
  // 
  ///////////////////////////////////////////////////////////////////////
  private defineNavigationRail(): NavigationRailSettings {
    let navrail: NavigationRailSettings = new NavigationRailSettings()
    navrail.showMenu = true
    navrail.addDestination("home", "Home", "home", "home-outline")
    navrail.addDestination("getstarted", "Get Started", "rocket-launch", "rocket-launch-outline")
    navrail.addDestination("layout", "Layout", "page-layout-body", "page-layout-body")

    navrail.addDestination("styles", "Style", "palette", "palette-outline")
    navrail.addDestination("styles.color", "Color", "", "")
    navrail.addDestination("styles.typography", "Typograpphy", "", "")
    navrail.addDestination("styles.icons", "Icons", "", "")
    navrail.addDestination("styles.maps", "Maps", "", "")

    navrail.addDestination("templates", "Templates", "view-dashboard", "view-dashboard-outline")
    navrail.addDestination("templates.landing", "Landing Pages", "", "")
    navrail.addDestination("templates.landing.landing01", "Landing 01", "", "")
    navrail.addDestination("templates.article", "Article", "", "")
    navrail.addDestination("templates.article.blog01", "Blog Article Page", "", "")
    navrail.addDestination("templates.article.m3", "M3 Style", "", "")
    navrail.addDestination("templates.technicaldoc", "Technical", "", "")
    navrail.addDestination("templates.technicaldoc.01", "Technical Document 01", "", "")

    navrail.addDestination("templates.saas", "SaaS Layouts", "", "")
    navrail.addDestination("templates.listdetail", "List-Detail Layouts", "", "")
    navrail.addDestination("templates.listdetail.wireframe", "List Detail Wireframe", "", "")
    navrail.addDestination("templates.listdetail.colors", "Example - Colors", "", "")
    navrail.addDestination("templates.feed", "Feed Layouts", "", "")

    navrail.addDestination("collections", "Collections", "view-grid", "view-grid-outline")
    navrail.addDestination("collections.pictures", "Images", "", "")
    navrail.addDestination("collections.pictures.grid", "Image Grid", "", "")
    navrail.addDestination("collections.pictures.manager", "Images Manager", "", "")
    navrail.addDestination("collections.pictures.carousel", "Carousel", "", "")
    navrail.addDestination("collections.pictures.upload", "Image Upload", "", "")
    navrail.addDestination("collections.colors", "Colors", "", "")
    navrail.addDestination("collections.gradients", "Gradients", "", "")

    return navrail
  }

  private defineNavigationDrawer(): NavigationDrawerSettings {
    let navDrawer: NavigationDrawerSettings = new NavigationDrawerSettings()
    navDrawer.addDestination("home", "Home", "home", "home-outline")
    navDrawer.addDestination("templates.article.m3", "Article Page", "book-account", "book-account-outline")
    navDrawer.addDestination("templates.article.m3", "Article Page", "book-account", "book-account-outline")
    navDrawer.addDestination("templates.technicaldoc.01", "Favourites", "heart",  "heart-outline")
    navDrawer.addDestination("patterns", "Trash", "delete", "delete-outline")
    navDrawer.addDestination("icons", "Icons", "apps", "apps")
    return navDrawer
  }


  private defineNavigationBar(): NavigationBarSettings {
    let navBar: NavigationBarSettings = new NavigationBarSettings()
    navBar.addDestination("home", "Home", "view-dashboard-outline", "view-dashboard")
    navBar.addDestination("discover", "Discover", "map-marker-outline", "map-marker")
    navBar.addDestination("plan", "Plan", "hexagon-multiple-outline", "hexagon-multiple")
    navBar.addDestination("activity", "Activity", "clipboard-pulse-outline", "clipboard-pulse")
    return navBar
  }



  datamodel() {
    let airportData = new AirportsData()


    airportData.addAirport("bne", "Brisbane International Airport", "bne", "YBBN", "Brisbane", "Australia", "AU", 
        "Oceania", "OC", -27.383333, 153.118333, "Australia/Brisbane", (36000 * 1000))

    airportData.addAirport("mnl", "Ninoy Aquino International Airport", "mnl", "RPLL", "Manila", "Philippines", "PH", 
    "Asia", "AS", 14.508333, 121.019722, "Asia/Manila", (28800 * 1000))

    airportData.addAirport("sin", "Changi Airport", "sin", "WSSS", "Singapore", "Singapore", "SG", 
    "Asia", "AS", 1.359167, 103.989444, "Asia/Singapore", (28800 * 1000))

    airportData.addAirportTimezone("bne", "BNBBB", 12345)

    let bne = airportData.getAirport("bne")

    console.log(JSON.stringify(bne, null, 2))


   // let json = bne.toJSON()
    //console.log(JSON.stringify(json, null, 2))
   // console.log(bne.iata) 
  }

}
