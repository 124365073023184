import {Injectable} from '@angular/core';




@Injectable()
export class ElevationsStyleService {


    constructor() {
    }


    boxShadow(level: number, hex: string) {
      let shadow = "none";
      let rgbstring = this.hexToRGBString(hex);

      if(level == 0) {
        shadow = "none";
      }
      if(level == 1) {
        shadow = "0 2px 5px 0 rgba(" + rgbstring + ", 0.16), 0 2px 10px 0 rgba(" + rgbstring + ", 0.12)";
      }
      if(level == 2) {
        shadow = "0 8px 17px 0 rgba(" + rgbstring + ", 0.2), 0 6px 20px 0 rgba(" + rgbstring + ", 0.19)";
      }
      if(level == 3) {
        shadow = "0 12px 15px 0 rgba(" + rgbstring+ ", 0.24), 0 17px 50px 0 rgba(" + rgbstring + ", 0.19)";
      }
      if(level == 4) {
        shadow = "0 16px 28px 0 rgba(" + rgbstring + ", 0.22), 0 25px 55px 0 rgba(" + rgbstring + ", 0.21)";
      }
      if(level == 5) {
        shadow = "0 27px 24px 0 rgba(" + rgbstring + ", 0.2), 0 40px 77px 0 rgba(" + rgbstring + ", 0.22)";
      }
      return shadow;
    }

    private hexToRGBString(hex: string) {
      let rgb = this.hexToRgb(hex);
      let str = "rgba(0,0,0)";
      if(rgb != null) {
        let str = "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ")";
      }
      return str;
    }

    private hexToRGBOpacityString(hex: string, opacity: number) {
      let rgb = this.hexToRgb(hex);
      let str = "rgba(0,0,0,0)";
      if(rgb != null) {
        let str = "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", " + opacity + ")";
      }
      return str;
    }


    hexToRgb(hex: string) {
       var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
       return result ? {
           r: parseInt(result[1], 16),
           g: parseInt(result[2], 16),
           b: parseInt(result[3], 16)
       } : null;
   };

    elevatedSurfaceLevel(level: number, isdarkmode: boolean) {
      let rgbValue = "rgba(255,255,255,0)";

      if(level == 0) {
        rgbValue = "rgba(255,255,255,0)";
      }
      if(level == 1) {
        rgbValue = this.surfaceColorTintL1(isdarkmode);
      }
      if(level == 2) {
        rgbValue = this.surfaceColorTintL2(isdarkmode);
      }
      if(level == 3) {
        rgbValue = this.surfaceColorTintL3(isdarkmode);
      }
      if(level == 4) {
        rgbValue = this.surfaceColorTintL4(isdarkmode);
      }
      if(level == 5) {
        rgbValue = this.surfaceColorTintL5(isdarkmode);
      }
      return rgbValue;
    }

    level1Opacity:  number  = 0.09;
    level2Opacity:  number  = 0.12;
    level3Opacity:  number  = 0.15;
    level4Opacity:  number  = 0.17;
    level5Opacity:  number  = 0.19;

    private surfaceColorTintL1(isdarkmode: boolean) {
      let val = "rgba(255,255,255,1)";
      if(isdarkmode == true) {
        val = "rgba(255,255,255," + this.level1Opacity + ")";
      }
      return val;
    }
    private surfaceColorTintL2(isdarkmode: boolean) {
      let val = "rgba(255,255,255,1)";
      if(isdarkmode == true) {
        val = "rgba(255,255,255," + this.level2Opacity + ")";
      }
      return val;
    }
    private surfaceColorTintL3(isdarkmode: boolean) {
      let val = "rgba(255,255,255,1)";
      if(isdarkmode == true) {
        val = "rgba(255,255,255," + this.level3Opacity + ")";
      }
      return val;
    }

    private surfaceColorTintL4(isdarkmode: boolean) {
      let val = "rgba(255,255,255,1)";
      if(isdarkmode == true) {
        val = "rgba(255,255,255," + this.level4Opacity + ")";
      }
      return val;
    }
    private surfaceColorTintL5(isdarkmode: boolean) {
      let val = "rgba(255,255,255,1)";
      if(isdarkmode == true) {
        val = "rgba(255,255,255," + this.level5Opacity + ")";
      }
      return val;
    }

    getElementRef(el: any, level: number, hex: string) {
      el.nativeElement.style.boxShadow = this.boxShadow(level, hex);
    //  el.nativeElement.style.background = rgbValue;
      return el;
    }

    getElevatedSurfaceElementRef(el: any, level: number, isdarkmode: boolean) {
      el.nativeElement.style.background = this.elevatedSurfaceLevel(level, isdarkmode);
      return el;
    }




    setBoxShadowElement(h_length: number, v_length: number, blur_radius: number, spread_radius: number, hex: string, opacity: number) {
      let rgb = this.hexToRGBOpacityString(hex, opacity);
      let shadow = h_length + "px " + v_length + "px " + blur_radius + "px " + spread_radius + "px " + rgb;

    //  shadow = "0 2px 5px 0 rgba(" + rgbstring + ", 0.16), 0 2px 10px 0 rgba(" + rgbstring + ", 0.12)";
      return shadow;
    }

    setBoxShadowSize(h_length: number, v_length: number, blur_radius: number, spread_radius: number) {
      let shadow_size = h_length + "px " + v_length + "px " + blur_radius + "px " + spread_radius + "px";
      return shadow_size;
    }




    defineElevationLevel(level_no: number, elem1: string, elem2: string, hex: string, opacity: number) {
      let rgba = this.hexToRGBOpacityString(hex, opacity);
      let str = elem1 + ", " + elem2;
      return str;
    }

}


/*

level1Opacity:  number  = 0.06;
level2Opacity:  number  = 0.08;
level3Opacity:  number  = 0.11;
level4Opacity:  number  = 0.13;
level5Opacity:  number  = 0.16;

*/
