
import { ColorScheme } from "./color.scheme.class"
import { ColorHCTUtils } from '../utils/color.utils.hct.class';

export class M3ColorSchemeUtility {



    constructor() { }



    public generateLightScheme(id: string, primary: string, secondary: string, tertiary: string, neutral: string, neutralVariant: string, error: string) {
        let scheme = new ColorScheme()
        let utils: ColorHCTUtils = new ColorHCTUtils

        scheme.schemeId = id
        scheme.primaryKeyColor = primary
        scheme.secondaryKeyColor = secondary
        scheme.tertiaryKeyColor = tertiary
        scheme.neutralKeyColor = neutral
        scheme.neutralVariantKeyColor = neutralVariant
        scheme.errorKeyColor = error

        scheme.primary = utils.hexFromHCT(scheme.primaryKeyColor, 40)
        scheme.primaryContainer = utils.hexFromHCT(scheme.primaryKeyColor, 90)
        scheme.onPrimary = utils.hexFromHCT(scheme.primaryKeyColor, 100)
        scheme.onPrimaryContainer = utils.hexFromHCT(scheme.primaryKeyColor, 10)
        scheme.inversePrimary = utils.hexFromHCT(scheme.primaryKeyColor, 80)

        scheme.secondary = utils.hexFromHCT(scheme.secondaryKeyColor, 40)
        scheme.secondaryContainer = utils.hexFromHCT(scheme.secondaryKeyColor, 90)
        scheme.onSecondary = utils.hexFromHCT(scheme.secondaryKeyColor, 100)
        scheme.onSecondaryContainer = utils.hexFromHCT(scheme.secondaryKeyColor, 10)

        scheme.tertiary = utils.hexFromHCT(scheme.tertiaryKeyColor, 40)
        scheme.tertiaryContainer = utils.hexFromHCT(scheme.tertiaryKeyColor, 90)
        scheme.onTertiary = utils.hexFromHCT(scheme.tertiaryKeyColor, 100)
        scheme.onTertiaryContainer = utils.hexFromHCT(scheme.tertiaryKeyColor, 10)

        scheme.surface = utils.hexFromHCT(scheme.neutralKeyColor, 98)
        scheme.surfaceDim = utils.hexFromHCT(scheme.neutralKeyColor, 87)
        scheme.surfaceBright = utils.hexFromHCT(scheme.neutralKeyColor, 98)

        scheme.surfaceLowest = utils.hexFromHCT(scheme.neutralKeyColor, 100)
        scheme.surfaceLow = utils.hexFromHCT(scheme.neutralKeyColor, 96)
        scheme.surfaceContainer = utils.hexFromHCT(scheme.neutralKeyColor, 94)
        scheme.surfaceHigh = utils.hexFromHCT(scheme.neutralKeyColor, 92)
        scheme.surfaceHighest = utils.hexFromHCT(scheme.neutralKeyColor, 90)
        scheme.surfaceVariant = utils.hexFromHCT(scheme.neutralVariantKeyColor, 90)

        scheme.onSurface = utils.hexFromHCT(scheme.neutralKeyColor, 10)
        scheme.onSurfaceVariant = utils.hexFromHCT(scheme.neutralVariantKeyColor, 30)

        scheme.inverseSurface = utils.hexFromHCT(scheme.neutralKeyColor, 20)
        scheme.inverseOnSurface = utils.hexFromHCT(scheme.neutralKeyColor, 95)

        scheme.background = utils.hexFromHCT(scheme.primaryKeyColor, 98) // was 98
       // scheme.background = utils.hexFromHCT(scheme.neutralKeyColor, 99) // was 98
        scheme.onBackground = utils.hexFromHCT(scheme.neutralKeyColor, 10)

        scheme.error = utils.hexFromHCT(scheme.errorKeyColor, 40)
        scheme.errorContainer = utils.hexFromHCT(scheme.errorKeyColor, 90)
        scheme.onError = utils.hexFromHCT(scheme.errorKeyColor, 100)
        scheme.onErrorContainer = utils.hexFromHCT(scheme.errorKeyColor, 10)

        scheme.outline = utils.hexFromHCT(scheme.neutralKeyColor, 85)
//        scheme.outline = utils.hexFromHCT(scheme.neutralKeyColor, 90)
        scheme.outlineVariant = utils.hexFromHCT(scheme.neutralVariantKeyColor, 80)

        scheme.shadow = utils.hexFromHCT(scheme.neutralKeyColor, 0)
        scheme.surfaceTint = scheme.primaryKeyColor
        scheme.scrim = utils.hexFromHCT(scheme.neutralKeyColor, 0)

        return scheme
    }


    public generateDarkScheme(id: string, primary: string, secondary: string, tertiary: string, neutral: string, neutralVariant: string, error: string) {
        let scheme = new ColorScheme()
        let utils: ColorHCTUtils = new ColorHCTUtils

        scheme.schemeId = id
        scheme.primaryKeyColor = primary
        scheme.secondaryKeyColor = secondary
        scheme.tertiaryKeyColor = tertiary
        scheme.neutralKeyColor = neutral
        scheme.neutralVariantKeyColor = neutralVariant
        scheme.errorKeyColor = error

        scheme.primary = utils.hexFromHCT(scheme.primaryKeyColor, 80)
        scheme.primaryContainer = utils.hexFromHCT(scheme.primaryKeyColor, 30)
        scheme.onPrimary = utils.hexFromHCT(scheme.primaryKeyColor, 20)
        scheme.onPrimaryContainer = utils.hexFromHCT(scheme.primaryKeyColor, 90)
        scheme.inversePrimary = utils.hexFromHCT(scheme.primaryKeyColor, 40)

        scheme.secondary = utils.hexFromHCT(scheme.secondaryKeyColor, 80)
        scheme.secondaryContainer = utils.hexFromHCT(scheme.secondaryKeyColor, 30)
        scheme.onSecondary = utils.hexFromHCT(scheme.secondaryKeyColor, 20)
        scheme.onSecondaryContainer = utils.hexFromHCT(scheme.secondaryKeyColor, 90)

        scheme.tertiary = utils.hexFromHCT(scheme.tertiaryKeyColor, 80)
        scheme.tertiaryContainer = utils.hexFromHCT(scheme.tertiaryKeyColor, 30)
        scheme.onTertiary = utils.hexFromHCT(scheme.tertiaryKeyColor, 20)
        scheme.onTertiaryContainer = utils.hexFromHCT(scheme.tertiaryKeyColor, 90)

        scheme.surface = utils.hexFromHCT(scheme.neutralKeyColor, 6)
        scheme.surfaceDim = utils.hexFromHCT(scheme.neutralKeyColor, 6)
        scheme.surfaceBright = utils.hexFromHCT(scheme.neutralKeyColor, 24)

        scheme.surfaceLowest = utils.hexFromHCT(scheme.neutralKeyColor, 4)
        scheme.surfaceLow = utils.hexFromHCT(scheme.neutralKeyColor, 10)
        scheme.surfaceContainer = utils.hexFromHCT(scheme.neutralKeyColor, 12)
        scheme.surfaceHigh = utils.hexFromHCT(scheme.neutralKeyColor, 17)
        scheme.surfaceHighest = utils.hexFromHCT(scheme.neutralKeyColor, 22)
        scheme.surfaceVariant = utils.hexFromHCT(scheme.neutralVariantKeyColor, 30)

        scheme.onSurface = utils.hexFromHCT(scheme.neutralKeyColor, 90)
        scheme.onSurfaceVariant = utils.hexFromHCT(scheme.neutralVariantKeyColor, 80)

        scheme.inverseSurface = utils.hexFromHCT(scheme.neutralKeyColor, 90)
        scheme.inverseOnSurface = utils.hexFromHCT(scheme.neutralKeyColor, 20)

        scheme.background = utils.hexFromHCT(scheme.neutralKeyColor, 6)
        scheme.onBackground = utils.hexFromHCT(scheme.neutralKeyColor, 90)

        scheme.error = utils.hexFromHCT(scheme.errorKeyColor, 80)
        scheme.errorContainer = utils.hexFromHCT(scheme.errorKeyColor, 30)
        scheme.onError = utils.hexFromHCT(scheme.errorKeyColor, 20)
        scheme.onErrorContainer = utils.hexFromHCT(scheme.errorKeyColor, 90)

        scheme.outline = utils.hexFromHCT(scheme.neutralKeyColor, 20)
//        scheme.outline = utils.hexFromHCT(scheme.neutralKeyColor, 60)
        scheme.outlineVariant = utils.hexFromHCT(scheme.neutralVariantKeyColor, 30)

        scheme.shadow = utils.hexFromHCT(scheme.neutralKeyColor, 0)
        scheme.surfaceTint = scheme.primaryKeyColor
        scheme.scrim = utils.hexFromHCT(scheme.neutralKeyColor, 0)

        return scheme
    }
}
