export class PersonInfo {

    private _id: string = ""
    private _firstName: string = ""
    private _middleNames: string = ""
    private _lastName: string = ""  
    private _avatarURL: string = ""
 

    constructor() {
    }
  
    create(id: string, fn: string, mn: string, ln: string, url: string) {
      this._id = id
      this._firstName = fn
      this._middleNames = mn
      this._lastName = ln
      this._avatarURL = url
    }

    public toJSON(): any {
      let json = {
        id: this._id,
        firstname: this._firstName,
        middlenames: this._middleNames,
        lastname: this._lastName,
        imgsrc: this._avatarURL
      }
      return json
    }
  
    public fromJSON(json: any): void {

    }
  
  
    public get id(): string {
      return this._id
    }
    public set id(value: string) {
      this._id = value
    }
    public get firstName(): string {
      return this._firstName
    }
    public set firstName(value: string) {
      this._firstName = value
    }
    public get middleNames(): string {
      return this._middleNames
    }
    public set middleNames(value: string) {
      this._middleNames = value
    }
    public get lastName(): string {
      return this._lastName
    }
    public set lastName(value: string) {
      this._lastName = value
    }
    public get avatarURL(): string {
      return this._avatarURL
    }
    public set avatarURL(value: string) {
      this._avatarURL = value
    }
   


    public newJSON(id: string, fn: string, mn: string, ln: string, url: string): any {
      let json = {
        id: id,
        firstname: fn,
        middlenames: mn,
        lastname: ln,
        imgsrc: url
      }
      return json
    }

  }


  