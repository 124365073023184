import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/////////////////////////////////////////////////////////
// Core
////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module';


import { CalendarUIMonthComponent } from './calendar-month/calendar.month.component';
import { CalendarUIMonthPickComponent } from './calendar-month/calendar.pick.component';



@NgModule({
  declarations: [
    CalendarUIMonthComponent,
    CalendarUIMonthPickComponent
  ],

  imports: [
    CommonModule,
    CoreUIModule
  ],

  providers: [
  ],

  exports: [
    CalendarUIMonthComponent,
    CalendarUIMonthPickComponent
  ]
})
export class CalendarUIModule { }
