import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '../../../services/event.service'
import { UIColorModel } from '../../../services/ui.color.model.service';


@Component({
	selector: 		'appbar-top',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
		<div class="_appbar-top {{_cssShadowClass}} {{_cssAnimationClass}}"
			[style.background]=this._surfaceColor>
			<div class="back-icon" (click)=logoClick()
				[style.opacity]=1>
				<i class="mdi mdi-menu"></i>
			</div>
			<div class="fill-remaining-space"></div>
			<div class="action-items" onSurface>
				<div class="item icon">
					<icon-button [icon]="'heart-outline'" [activeicon]="'heart'"></icon-button>				
				</div>
				<div class="item icon">
					<icon-button [icon]="'cog-outline'" [activeicon]="'cog'"></icon-button>				
				</div>
			</div>
			<div class="avatar-wrapper">
				<avatar></avatar>
			</div>
		</div> 

		`,
})


export class AppbarTopComponent {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	_surfaceColor: string = ""
	_cssShadowClass: string = ""
	_cssAnimationClass: string = ""

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self._surfaceColor = self.uiColorModel.surface
		});
		this.eventsService.on('onScrollStart', function (args: any) {
			self._surfaceColor = self.uiColorModel.surfaceContainer
			self._cssShadowClass = "shadow-2 scale-2"
			self.changeDetectorRef.detectChanges()
		});
		this.eventsService.on('onScrollEnd', function (args: any) {
			self._surfaceColor = self.uiColorModel.surface
			self._cssShadowClass = "scale-1"
			self.changeDetectorRef.detectChanges()
		});
	  
	}

	public getLogo(): string {
		return "./assets/skyai/skyai-logo-light.png"
	}
	public logoClick(): void {
		this.eventsService.broadcast("onShowModalOverlay", {id: "sample-overlay"})
	}


	public getAvatarPic(): string {
		return "./assets/avatars/happy-avatar1.jpg"
	}

	ngOnInit() {
		this._surfaceColor = this.uiColorModel.surface
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
