import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

@Component({
	selector: 		'main-routes',
	inputs: 			['route'],
	encapsulation: ViewEncapsulation.None,

	template:	`

			<div [ngSwitch]=this._route>

				<div *ngSwitchCase="'home'">
					<test-page></test-page>
				</div>

				<div *ngSwitchCase="'templates.landing.landing01'">
					<landing-page01></landing-page01>
				</div>


				<div *ngSwitchCase="'templates.article.m3'">
					<blog-page-m3></blog-page-m3>
				</div>

				<div *ngSwitchCase="'templates.article.blog01'">
					<blog-article-page>
						<article-image [imgsrc]="'./assets/sample/media/positano-night.jpg'" [caption]="'Positano at night'"></article-image>
						<paragraph>
							<article>
						Charles Hoffbauer is little known nowadays, but in the early 20th century he was very popular and highly regarded by amateurs. Hoffbauer was born and studied in Paris; in 1941 he moved from occupied France to the United States and became an American citizen. He didn't receive recognition as a painter, but instead worked as an animator at the Walt Disney Company.
			“In the restaurant” is definitely considered to be one of the best paintings by the artist.
							</article>
						</paragraph>
					</blog-article-page>
				</div>

				<div *ngSwitchCase="'templates.technicaldoc.01'">
					<technical-document-page-01></technical-document-page-01>
				</div>
				
				<!-- Style -->
				<div *ngSwitchCase="'styles.icons'">
					<icons-page></icons-page>
				</div>
				

				<!-- Layouts -->
				<div *ngSwitchCase="'templates.listdetail.wireframe'">
					<list-detail-example-page></list-detail-example-page>
				</div>
				<div *ngSwitchCase="'templates.listdetail.colors'">
					<color-list-detail-example-page></color-list-detail-example-page>
				</div>
				

				<!-- Collections -->
				<div *ngSwitchCase="'collections.pictures.upload'">
					<image-upload-page></image-upload-page>
				</div>
				
				<div *ngSwitchCase="'collections.pictures.manager'">
					<image-collection-page></image-collection-page>
				</div>
				<div *ngSwitchCase="'image-management'">
					<image-collection-page></image-collection-page>
				</div>
								

				<div *ngSwitchCase="'collections.colors'">
					<colors-collection-page></colors-collection-page>
				</div>
				<div *ngSwitchCase="'collections.gradients'">
					<gradients-collection-page></gradients-collection-page>
				</div>
				
				<!-- Maps -->
				<div *ngSwitchCase="'style.maps'">
					<apple-maps-page></apple-maps-page>
				</div>
				

				<!-- Settings -->
				<div *ngSwitchCase="'edit-profile'">
					<edit-profile-page></edit-profile-page>
				</div>
				

				<!-- Sessions -->
				<div *ngSwitchCase="'auth.sessions'">
					<sessions-list-page></sessions-list-page>
				</div>
				

			</div>

	`,

})


export class MainRoutes {
	@Input()
    set route(value: string) { this.setRoute(value); }

	_route: string = ""
	_opacity: number = 1

	private setRoute(route: string): void {
		setTimeout(() => {
			this._opacity = 0
			setTimeout(() => {
				this._route = route
				this._opacity = 1
			}, 150);
		}, 10);
	}

	constructor() {
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}


}
