import { Directive, ElementRef, Input, ChangeDetectorRef } from '@angular/core';


@Directive({
  selector: '[view-height]',
})


export class ViewHeightDirective {

  changeDetectorRef: ChangeDetectorRef

  constructor(public ref: ChangeDetectorRef, public el: ElementRef) {
    this.changeDetectorRef = ref
    this.updateElement()
  }

  updateElement() {
    let containerHeight = 300 //window.innerHeight
    this.el.nativeElement.style.height = containerHeight
  }

}
