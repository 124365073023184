import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/////////////////////////////////////////////////////////
// Core
////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module';

import { CollectionsAnimationsService } from './services/collection.animations.service';


import { AbstractAnimatedTileComponent } from './components/collections/abstract.collection.tile';
import { AbstractCollectionContainerAnimated } from './components/collections/abstract.collection.container.animated';


@NgModule({
  declarations: [
    AbstractAnimatedTileComponent,
    AbstractCollectionContainerAnimated
  ],

  imports: [
    CommonModule,
    CoreUIModule
  ],

  providers: [
    CollectionsAnimationsService
  ],

  exports: [
    AbstractAnimatedTileComponent,
    AbstractCollectionContainerAnimated
  ]
})
export class AnimatedTilesModule { }
