import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

import { EventsService } from '../../../services/event.service'
import { ColorUtils } from "../../../style/color/utils/color.utils.class"
import { UIColorModel } from '../../../services/ui.color.model.service';

@Component({
  selector: 'tile-single-text-image',
  inputs: ['label', 'imgsrc', 'secondaryicon', 'iswide'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,
 
  template: `
      <li class="single-item-list-tile-leading-image {{_isWideCSS}}" (mouseenter)=mouseoter($event)
        (mouseleave)=mouseleave($event)
        (click)=tileClicked($event)
        [style.backgroundColor]=surfaceColor>
        <div class="image-wrapper">
          <img [src]=_imgSrc/>
        </div>
        <div class="content">
          <div class="primary-text" onSurface font-family>{{_label}}</div>
        </div>
        <div *ngIf="'this._icon.length>0'" class="secondary-action">
          <tile-secondary-action-56 [icon]=_secondaryIcon></tile-secondary-action-56>
        </div>
      </li>

  `,
})
export class TileSingleItemImageComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }
  set imgsrc(value: string) {
    this.setImgSrc(value);
  }
  set secondaryicon(value: string) {
    this.setSecondaryIcon(value);
  }
  set iswide(value: boolean) {
    this.setIsWide(value);
  }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
  private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel

  _label: string = ""
  _imgSrc: string = ""
  _secondaryIcon: string = ""
  _isWideCSS: string = ""
  surfaceColor: string = ""

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
    this.surfaceColor = "rgba(0,0,0,0)"
	}

  setLabel(value: string) {
    this._label = value
  }
  setImgSrc(value: string) {
    this._imgSrc = value
  }
  setSecondaryIcon(value: string) {
    this._secondaryIcon = value
  }
  setIsWide(value: boolean) {
    if(value == true) {
      this._isWideCSS = "wide"
    } else {
      this._isWideCSS = ""
    }
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}


  tileClicked($event: any) {
    this.clicked.emit(true)
  }

  mouseoter($event: any) {
    this.surfaceColor = this.uiColorModel.surfaceDim
  }
  mouseleave($event: any) {
    this.surfaceColor = "rgba(0,0,0,0)"
  }


}
