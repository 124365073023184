import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from "@angular/core";

import { EventsService } from "../../services/event.service";
import { UIColorModel } from "../../services/ui.color.model.service";
import { UILayout } from "../../services/ui.layout.service";

@Component({
  selector: "card-action",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  inputs: ["actionlabel"],
  template: `
    
          <div class="_card-actions">
            <div class="button">
              <tonal-button
                [label]=_actionButton
                [noshadow]="true"
              ></tonal-button>
            </div>
            <div class="button">
              <outline-button [label]="'Learn More'"></outline-button>
            </div>
          </div>

  `,
})
export class CardAction {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set actionlabel(value: string) {
    this.setActionButton(value);
  }

  private eventsService: EventsService;
  private changeDetectorRef: ChangeDetectorRef;
  uiColorModel: UIColorModel;
  uiLayout: UILayout;

  _actionButton: string = "";

  constructor(
    public ref: ChangeDetectorRef,
    _eventsService: EventsService,
    private _uiLayout: UILayout,
    private _uiColorModel: UIColorModel
  ) {
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.uiLayout = _uiLayout;
    this.changeDetectorRef = ref;

    let self = this;
    this.eventsService.on("onResize", function (args: any) {
    });
  }

  setActionButton(value: string) {
    this._actionButton = value;
  }
 
  ngOnInit() {
  }

  ngOnDestroy() {}

  ngAfterViewInit() {}
}
