import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	HostListener,
	ChangeDetectorRef,
	Input,
	Output,
	EventEmitter
} from '@angular/core';

import { EventsService } from '../../services/event.service'


@Component({
	selector: 'scrim-layer',
	inputs: ['close', 'opacity'],
	outputs: ['clicked'],
	encapsulation: ViewEncapsulation.None,
	template:	`
		<div class="_scrim all-motion-animation" 
			[style.backgroundColor]=_background
			[style.opacity]=_opacity
			(click)=clickEvent($event)>
			<ng-content></ng-content>
		</div> 
	`,

})


export class ScrimLayer {
	@Output()
	clicked = new EventEmitter();
  
	@Input()
	set close(value: boolean) {
	  this.setTriggerClose(value);
	}
	set opacity(value: number) {
		this.setInitialOpacity(value);
	  }
	
	private changeDetectorRef: ChangeDetectorRef
    private eventsService: EventsService
	_opacity: number = 1
	_initialOpacity: number = 0.7
	_background: string = "rgba(0,0,0,0)"
	_triggerClose: boolean = false

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService

		let self = this;
		this.eventsService.on('onHideSliderSheet', function (args: any) {
		});
	}

	ngOnInit() {
		this._triggerClose = false
		setTimeout(() => {
			this._background = "rgba(0,0,0," + this._initialOpacity + ")"
		}, 10);
	}

	ngOnDestroy() {
	}

	setTriggerClose(value: boolean) {
		this._triggerClose = value
		if(this._triggerClose) {
			this._background = "rgba(0,0,0,0)"
		}
	}

	clickEvent($event: any) {
		let rec = {
			x:	$event.clientX,
			y:	$event.clientY
		}
		this.clicked.emit(rec);
	}

	setInitialOpacity(value: number) {
		this._initialOpacity = value
	}
}
