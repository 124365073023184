import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { UINavigation } from '@coreui/services/ui.navigation.service';


@Component({
	selector: 		'sp-appbar-compact',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
		<div class="_la-navbar-medium {{_cssShadowClass}} {{_cssAnimationClass}}"
			[style.background]=this._surfaceColor>
			<div class="logo" (click)=logoClick()
				[style.opacity]=1>
				<img [src]=getLogo() [style.opacity]=_logoOpacity/>
			</div>
			<div class="fill-remaining-space"></div>
			<div class="action-items" onSurface>
				<div class="item">
					<badge-icon-button [icon]="'bell-outline'" [activeicon]="'bell'" [smallbadge]=true [value]=3></badge-icon-button>				
				</div>
			</div>

			<div class="avatar-wrapper" (click)=hamburgerClick()>
				<avatar40 [imgsrc]="avatarImg"></avatar40>
			</div>
			<!--
			<div class="hamburger-wrapper" onSurface>
				<div class="border-circle" font-family (click)=hamburgerClick()>
					<i class="mdi mdi-menu"></i>			
				</div>
			</div>
-->
		</div> 

		`,
})


export class AppbarCompactComponent {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	uiNavigation: UINavigation
	_surfaceColor: string = ""
	_cssShadowClass: string = ""
	_cssAnimationClass: string = ""
	_logoOpacity: number = 1
	avatarImg: string = "./assets/avatars/gs.png"

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _uiNavigation: UINavigation) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.uiNavigation = _uiNavigation

		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self._surfaceColor = self.uiColorModel.surface
		});
		this.eventsService.on('onScrollStart', function (args: any) {
//			self._surfaceColor = self.uiColorModel.surfaceContainer
			self._surfaceColor = "rgba(0,0,0,0.02)"
			self._cssShadowClass = "shadow-1 scale-2"
			self.changeDetectorRef.detectChanges()
		});
		this.eventsService.on('onScrollEnd', function (args: any) {
//			self._surfaceColor = self.uiColorModel.surface
			self._surfaceColor = "rgba(0,0,0,0)"
			self._cssShadowClass = "scale-1"
			self.changeDetectorRef.detectChanges()
		});
	  
	}

	public getLogo(): string {
		let logo = "./assets/loyalty/logos/peach_marketing_icon.png"
		this._logoOpacity = 1
		if(this.uiColorModel.isDark == true) {
			logo = "./assets/loyalty/logos/peach_marketing_icon.png"
			this._logoOpacity = 0.95
		}
		return logo
	}

	public logoClick(): void {
		this.uiNavigation.activeView = "home"
	}



	public hamburgerClick() {
		this.eventsService.broadcast("onShowModalOverlay", {id: "sidenav-drawer"})
	}

	ngOnInit() {
		this._surfaceColor =  "rgba(0,0,0,0)" //this.uiColorModel.surface
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
