
export class TabsSet {

    private _id: string = ""
    private _tabs: any = []
    private _labelOnly: boolean = false

    private _activeTab: string = ""
    private _activeTabIndex: number = 0
    private _slideDirection: string = "right"

    private _cssEntrance: string = ""
    private _cssLeftEntrance: string = "fadeInLeft"
    private _cssRightEntrance: string = "fadeInRight"
    private _cssLeftEntranceBig: string = "fadeInLeftBig"
    private _cssRightEntranceBig: string = "fadeInRightBig"

    constructor() {}

    public addLabel(id: string, label: string) {
        let rec = {
            id: "tab-" + id,
            destination: id,
            label: label,
            activeIcon: "",
            inactiveIcon: "",
            isEnabled: true,
            isHovered: false,
            isActive: false
        }
        this._tabs.push(rec)
    }

    public addIconLabel(id: string, label: string, activeIcon: string,
        inactiveIcon: string) {
        let rec = {
            id: id,
            destination: id,
            label: label,
            activeIcon: activeIcon,
            inactiveIcon: inactiveIcon,
            isEnabled: true,
            isHovered: false,
            isActive: false
        }
        this._tabs.push(rec)
    }


    public setActive(id: string, isActive: boolean) {
        let i = 0
        for(i=0;i<this._tabs.length;i++) {
            if(this._tabs[i]["id"] == id) {
                this._tabs[i]["isActive"] = isActive
                if(isActive) {
                    this._activeTab = this._tabs[i]["destination"]
                    this.detectChangeDirection(i)
                    this._activeTabIndex = i
                }
            } else {
                this._tabs[i]["isActive"] = false
            }
        }
    }


    public isActive(id: string): boolean {
        let result: boolean = false
        let i = 0
        for(i=0;i<this._tabs.length;i++) {
            if(this._tabs[i]["id"] == id) {
                result = this._tabs[i]["isActive"]
            } 
        }
        return result
    }


    public setEnabled(id: string, isEnabled: boolean) {
        let i = 0
        for(i=0;i<this._tabs.length;i++) {
            if(this._tabs[i]["id"] == id) {
                this._tabs[i]["isEnabled"] = isEnabled
            } else {
                this._tabs[i]["isEnabled"] = false
            }
        }
    }
    public isEnabled(id: string): boolean {
        let result: boolean = false
        let i = 0
        for(i=0;i<this._tabs.length;i++) {
            if(this._tabs[i]["id"] == id) {
                result = this._tabs[i]["isEnabled"]
            } 
        }
        return result
    }

    public detectChangeDirection(newIndex: number) {
        this._slideDirection = "left"
        if(newIndex != this._activeTabIndex)
            this.indexChangeDifference(newIndex)
    }

    private indexChangeDifference(newIndex: number) {
        if(newIndex > this._activeTabIndex) {
            if((newIndex - this._activeTabIndex) >= 2) {
                this._slideDirection = "right"
                this._cssEntrance = this._cssRightEntranceBig
            } else {
                this._slideDirection = "right"
                this._cssEntrance = this._cssRightEntrance
            }
        } else {
            if((this._activeTabIndex - newIndex) >= 2) {
                this._slideDirection = "left"
                this._cssEntrance = this._cssLeftEntranceBig
            } else {
                this._slideDirection = "left"
                this._cssEntrance = this._cssLeftEntrance
            }
        }
    }


    public get id(): string {
        return this._id
    }
    public set id(value: string) {
        this._id = value
    }
    public get tabs(): any {
        return this._tabs
    }
    public set tabs(value: any) {
        this._tabs = value
    }

    public get labelOnly(): boolean {
        return this._labelOnly
    }
    public set labelOnly(value: boolean) {
        this._labelOnly = value
    }

    public get activeTab(): string {
        return this._activeTab
    }
    public set activeTab(value: string) {
        this._activeTab = value
    }
    public get activeTabIndex(): number {
        return this._activeTabIndex
    }
    public set activeTabIndex(value: number) {
        this._activeTabIndex = value
    }
    public get slideDirection(): string {
        return this._slideDirection
    }
    public set slideDirection(value: string) {
        this._slideDirection = value
    }
    public get cssEntrance(): string {
        return this._cssEntrance
    }
    public set cssEntrance(value: string) {
        this._cssEntrance = value
    }
    public get cssLeftEntrance(): string {
        return this._cssLeftEntrance
    }
    public set cssLeftEntrance(value: string) {
        this._cssLeftEntrance = value
    }
    public get cssRightEntrance(): string {
        return this._cssRightEntrance
    }
    public set cssRightEntrance(value: string) {
        this._cssRightEntrance = value
    }
    public get cssLeftEntranceBig(): string {
        return this._cssLeftEntranceBig
    }
    public set cssLeftEntranceBig(value: string) {
        this._cssLeftEntranceBig = value
    }
    public get cssRightEntranceBig(): string {
        return this._cssRightEntranceBig
    }
    public set cssRightEntranceBig(value: string) {
        this._cssRightEntranceBig = value
    }

}