import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/////////////////////////////////////////////////////////
// Core
////////////////////////////////////////////////////////
import { TimeZonesService } from './timezones.service'

@NgModule({
  declarations: [
  ],

  imports: [
    CommonModule
  ],

  providers: [
    TimeZonesService
  ],

  exports: [
  ]
})
export class TimezonesModule { }
