import {
	Component,
	Input
} from '@angular/core';


@Component({
	selector: 		'airline01-flight-price-card',
	inputs: ['icon', 'isdark', 'perc'],

	template: 	`
		<div class="_flight-price-tile-card" surfaceContainer 
			elevated [elevation]=1>
			<div class="flight-times" >
				<div class="origin" onSurface font-family>15:30</div>

				<div class="fillspace">
					<travel-icon-progress [noline]=true [icon]=_icon [perc]=50></travel-icon-progress>
				</div> 

				<div class="destination" onSurface font-family>18:45</div>
			</div>
		</div>

	`,

})



export class Airline01FlightPriceCard {

	@Input()
	set icon(value: string) { this.setIcon(value); }
	set perc(value: number) { this.setPerc(value); }
	set isdark(value: boolean) { this.setIsDark(value); }

	_icon: string = "airplane rotate90"
	_perc: number = 50
	_isDark:	boolean = false


	_imgsrc: string = './assets/sample/media/hongkong-aerial.jpg'
	_logoSrc: string = './assets/sample/logos/QatarAirwaysRound.jpg'

	constructor() {
	}

	setIcon(value: string) {
		this._icon = value
	}

	setPerc(value: number) {
		this._perc = value
	}

	setIsDark(value: boolean) {
		this._isDark = value
	}

	ngOnInit() {
	/*	this._perc = 1
		setTimeout(() => {
			this._perc = 100
		}, 1000); */
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
