import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { ColorUtils } from "../../style/color/utils/color.utils.class"
import { UIColorModel } from '../../services/ui.color.model.service';

@Component({
  selector: 'chip',
  inputs: ['label', 'imgsrc', 'leadingicon', 'trailingicon', 'outlined', 'capsule'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
            <li class="_chip {{_capsuleCSS}}" elevated [elevation]=_elevation
              [style.border]=_outlineString 
              [style.background]=this._highlightColor
              (mouseover)=stateHoveredActive() (mouseleave)=stateEnabledActive()
              (click)=handleClick()>
              <div *ngIf="this._imgSrc.length > 0" class="avatar">
                <img [src]=_imgSrc/>
              </div>
              <div *ngIf="_leadingIcon.length > 0" class="leading-icon">
                <i class="mdi mdi-{{_leadingIcon}}" [style.color]=this._iconColor></i>
              </div>
              <div class="label" onSurfaceVariant font-family>{{_label}}</div>
              <div *ngIf="_trailingIcon.length > 0" class="trailing-icon">
                <i class="mdi mdi-{{_trailingIcon}}" [style.color]=this._iconColor></i>
              </div>
            </li>
  `,
})
export class ChipComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }
  set imgsrc(value: string) {
    this.setImgSrc(value);
  }
  set leadingicon(value: string) {
    this.setLeadingIcon(value);
  }
  set trailingicon(value: string) {
    this.setTrailingIcon(value);
  }
  set outlined(value: boolean) {
    this.setIsOutline(value);
  }
  set capsule(value: boolean) {
    this.setIsCapsule(value);
  }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
  private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel

  _id: string = ""
  _label: string = ""
  _leadingIcon: string = ""
  _trailingIcon: string = ""
  _outlineString: string = "1px solid #000000"
	_highlightColor: string = "rgba(0,0,0,0)"
	_iconColor: string = "rgba(0,0,0,0)"
  _isActive: boolean = false
  _elevation: number = 0
  _isOutlined: boolean = false
  _imgSrc: string = ""
  _capsuleCSS: string = ""

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

    this._outlineString = "1px solid " + this.uiColorModel.outline
    this._iconColor = this.uiColorModel.onSurfaceVariant

		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self._outlineString = "1px solid " + self.uiColorModel.outline
      self._iconColor = self.uiColorModel.onSurfaceVariant
		});
	}

	handleClick() {
    this.clicked.emit(this._id)
    if(this._isActive == true) {
      this.stateDeActive()  
    } else {
      this.statePressedActive()
    }
  }



	stateEnabledActive() {
		if(this._isActive != true) {
			this._highlightColor = "rgba(0,0,0,0)"
      this._outlineString = "1px solid " + this.uiColorModel.outline
      this._iconColor = this.uiColorModel.onSurfaceVariant
		}
	}

	stateHoveredActive() {
		if(this._isActive != true) {
			this._highlightColor = this.uiColorModel.surfaceContainer
      this._iconColor = this.uiColorModel.primary
      this.changeDetectorRef.detectChanges()
		}
	}


  statePressedActive() {
		this._isActive = true
		this._highlightColor = this.uiColorModel.primaryContainer
		this._iconColor = this.uiColorModel.onPrimaryContainer
    this._outlineString = "1px solid rgba(0,0,0,0)"
	}

	stateDeActive() {
		this._isActive = false
		this.stateEnabledActive()
	}
 

  onClick() {

  }

  setImgSrc(value: string) {
    this._imgSrc = value
  }
  setLabel(value: string) {
    this._label = value
  }
  setLeadingIcon(value: string) {
    this._leadingIcon = value
  }
  setTrailingIcon(value: string) {
    this._trailingIcon = value
  }
  setIsOutline(value: boolean) {
    this._isOutlined = value
    if(value == true) {
      this._outlineString = "1px solid rgba(0,0,0,0)"
    } else {
      this._outlineString = "none"
    }
  }
  setIsCapsule(value: boolean) {
    if(value == true) {
      this._capsuleCSS = "capsule"
    } else {
      this._capsuleCSS = ""
    }
  }


  refreshColor() {
    
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
