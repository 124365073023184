import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	ChangeDetectionStrategy,
	ElementRef,
	Renderer2
} from '@angular/core';

import { DynamicCloudsBackground } from './dynamicclouds.class';
import { DynamicCloudsBackgroundControl } from './dynamicclouds.control.class';

@Component({
	selector: 		'background-dynamicclouds-presets',
	inputs: ['presets', 'active', 'cloudopacity'],
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
					<div class="_dynamicclouds-background" [style.height]=this.containerHeight
						[style.background]=this._backgroundcolor>

						<cloud-item-background-dynamicclouds *ngFor="let dat of this.shapes"
							[speed]=this._speed
							[color]=dat.hex
							[x]=dat.y
							[y]=dat.x
							[opacity]=_cloudOpacity>
						</cloud-item-background-dynamicclouds>

					</div>

								`,
})



export class BackgroundDynamicCloudsPresetsComponent {

	@Input()
	set presets(value: DynamicCloudsBackground[]) { this.setPresets(value); }
	set cloudopacity(value: number) { this.setCloudOpacity(value); }


	private changeDetectorRef: ChangeDetectorRef;

	_color1:							string = "613DFB"
	_color2:							string = "4FD1D9"
	_backgroundcolor:			string = "#6983A7"
	_cssclass:						string = ""
	_interval:						number = 15000
	shapes:								any = []
	containerHeight: 			number = 100
	update_interval: 			any;
	_numClouds:						number = 25
	_speed:								number = 5000
	_cloudOpacity:				number = 1



	constructor(public ref: ChangeDetectorRef) {
		this.changeDetectorRef = ref;
	}

	setPresets(value: DynamicCloudsBackground[]): void {
	}

	setCloudOpacity(value: number) {
		this._cloudOpacity = value
	}

	addEllipse(id: number, width: number, height: number, x: number, y: number, hex: string) {
		let rec = {
			id:					id,
			width:			420,
			height:			550,
			x:					x,
			y:					y,
			opacity:		1,
			hex:				hex,
			prev:				hex,
			class:			""
		}
		this.shapes.push(rec)
	}


	changeOut() {
		for(let dat of this.shapes) {
			dat.hex = this.randomColor()
			dat.x = this.random(100)
			dat.y = this.random(100)
		}
	}


	ngOnInit() {
		this.containerHeight = window.innerHeight

		let i = 0;
		for(i=0;i<this._numClouds;i++) {
			this.addEllipse(i, this.random(400), this.random(600), this.random(90), this.random(90), this.randomColor())
		}

		this.update_interval = setInterval(() => {
			this.changeOut()
		}, this._interval);

	}

	ngOnChanges() {
	}

	ngOnDestroy() {
		if (this.update_interval) {
			clearInterval(this.update_interval);
		}
	}


	randomColor() {
		let list = []
		list.push(this._color1)
		list.push(this._color2)
		let len = list.length
		let idx = Math.floor(Math.random() * len) + 0
		let col = "#" + list[idx]
		return col
	}


	random(max: number) {
		return Math.floor(Math.random() * max) + 1
	}

}
