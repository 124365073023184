import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,

	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';


@Component({
	selector: 		'blog-page-m3',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<content-container-scrollable>
		<optimised-content-container>
		<div class="_blog-page-m3">
			<div class="heading" onSurface>Material M3 Style Article</div>
			<div class="subheading" onSurface>A compelling description about the product or service</div>
			<div class="header-image">
				<img #imageRef [src]=_headerImage />
				<div class="caption" onPrimaryContainer>Charles Hoffbauer - In the Restaurant - 1907</div>
			</div>
			
			<ul class="_chip-set-row">
				<chip [label]="'Template'" [capsule]=true></chip>
				<chip [label]="'UI/UX'" [capsule]=true></chip>
				<chip [label]="'Blog'" [capsule]=true></chip>
				<chip [label]="'Article'" [capsule]=true></chip>
			</ul>
		</div> 
		</optimised-content-container>
	</content-container-scrollable>

		`,
})


export class BlogPageM3Template {
	@ViewChild('imageRef')
	imageRef!: HTMLImageElement;

	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel

	_headerImage: string = "./assets/artworks/Charles Hoffbauer - The Restaurant.jpg"

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.changeDetectorRef = ref
	}


	ngOnInit() {
		this.typographyModel.displayFontFamily = "Playfair Display"
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
