import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { AppAuthService } from '../app.auth.service';


@Component({
	selector: 		'signup-method',
	encapsulation: ViewEncapsulation.None,

	template:	`

        <div class="_signup-flow-container">
            <div class="body">
                <div class="header" onSurface font-family>
                    Sign Up
                </div>

                <div class="body">
                    <text-input-filled [label]="'Email'"
						(value)=getUsernameValue($event)
						[trailingicon]="'close-circle-outline'" 
						[supportingtext]=siginSupportingText
						[errormode]=siginErrorMode>
					</text-input-filled>
					<password-input-filled (value)=getPasswordValue($event)
						[supportingtext]=passwordSupportingText
						[errormode]=passwordErrorMode
					>
					</password-input-filled>

                    <sbutton [tonal]=true  [label]="'Sign Up'" [large]=true [radius]=50
                             [fullwidth]=true [preloading]=preload (click)=clicked()></sbutton>

                    <text-divider [label]="'Or'"></text-divider>

                    <ul class="list-tiles-container">
						<li class="list-tile-item"><outline-social-button [label]="'Sign up with Facebook'" [type]="'facebook'"></outline-social-button></li>
                        <li class="list-tile-item"><outline-social-button [label]="'Sign up with Google'" [type]="'google'"></outline-social-button></li>
                    </ul>
                </div>
            </div>


        </div>


	`,

})


export class SignUpMethodScreen {

    appAuthService: AppAuthService

    facebookBlue: string = "1877F2"
    xColor: string = "000000"
    envelopeRed: string = "dc3b2a"
    w: number = 100

	siginSupportingText: string = ""
	siginErrorMode: boolean = false
	passwordSupportingText: string = ""
	passwordErrorMode: boolean = false

	usernameValue: string = ""
	passwordValue: string = ""


	constructor(private _appAuthService: AppAuthService) {
        this.appAuthService = _appAuthService
	}

	ngOnInit() {
        this.w = window.innerWidth
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	getUsernameValue(value: string) {
		this.usernameValue = value
	}

	getPasswordValue(value: string) {
		this.passwordValue = value
	}



    preload: boolean = false
    clicked() {
        if(this.preload == true) {
           this.preload = false 
        } else {
            this.preload = true
        }
    }

}


/*

https://www.agoda.com/en-au/abozza-resort-boracay/hotel/boracay-island-ph.html?finalPriceView=2&isShowMobileAppPrice=false&cid=1844104&numberOfBedrooms=&familyMode=false&adults=6&children=0&rooms=1&maxRooms=0&isCalendarCallout=false&childAges=&numberOfGuest=0&missingChildAges=false&travellerType=3&showReviewSubmissionEntry=false&currencyCode=AUD&isFreeOccSearch=false&isCityHaveAsq=false&los=1&searchrequestid=4bd9722b-8a9d-4741-a142-48f309cb2c76&ds=NwetZuRn%2BPdqHHW6&checkin=2024-02-25

https://www.agoda.com/en-au/boracay-sea-view-hotel/hotel/boracay-island-ph.html?finalPriceView=2&isShowMobileAppPrice=false&cid=1844104&numberOfBedrooms=&familyMode=false&adults=6&children=0&rooms=1&maxRooms=0&checkIn=2024-02-26&isCalendarCallout=false&childAges=&numberOfGuest=0&missingChildAges=false&travellerType=3&showReviewSubmissionEntry=false&currencyCode=AUD&isFreeOccSearch=false&isCityHaveAsq=false&los=1&searchrequestid=8572263a-fd7c-40d6-a4a4-46a59044616e&ds=NwetZuRn%2BPdqHHW6

https://www.agoda.com/en-au/il-mare-sakura-resort-boracay/hotel/boracay-island-ph.html?finalPriceView=2&isShowMobileAppPrice=false&cid=1844104&numberOfBedrooms=&familyMode=false&adults=6&children=0&rooms=1&maxRooms=0&checkIn=2024-02-26&isCalendarCallout=false&childAges=&numberOfGuest=0&missingChildAges=false&travellerType=3&showReviewSubmissionEntry=false&currencyCode=AUD&isFreeOccSearch=false&isCityHaveAsq=false&tspTypes=1&los=1&searchrequestid=735484cf-af20-4585-9d26-96576822b56f&ds=NwetZuRn%2BPdqHHW6

https://www.behance.net/gallery/184728601/Travel-website-Ui-Design-using-figma

*/

/*                    <ul class="list-tiles-container">
                        <li class="list-tile-item"><std-social-button [label]="'Sign up with Instagram'" [type]="'instagram'"></std-social-button></li>
                        <li class="list-tile-item"><std-social-button [label]="'Sign up with Linkedin'" [type]="'linkedin'"></std-social-button></li>
                        <li class="list-tile-item"><std-social-button [label]="'Sign up with X'" [type]="'twitter'"></std-social-button></li>
                        <li class="list-tile-item"><std-social-button [label]="'Sign up with Google'" [type]="'google'" [preloading]=true></std-social-button></li> 
                    </ul> */
