import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  NgZone,
  Input,
} from "@angular/core";

import { EventsService } from "@coreui//services/event.service";
import { UIColorModel } from "@coreui/services/ui.color.model.service";
import { UILayout } from '@coreui//services/ui.layout.service';

@Component({
  selector: "side-sheet",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  template: `
    <div class="_side-sheet" borderLeftOutline
      [style.height.px]=_containerHeight
      surfaceColor>
        <ng-content></ng-content>
    </div>
  `,
})
export class SideSheetContainer {
  private eventsService: EventsService;
  private changeDetectorRef: ChangeDetectorRef;
  uiColorModel: UIColorModel;
	uiLayout: UILayout

  _containerHeight: number = 100

  constructor(
    public ref: ChangeDetectorRef,
    _eventsService: EventsService,
    private _uiLayout: UILayout,
    private _uiColorModel: UIColorModel
  ) {
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.changeDetectorRef = ref;
		this.uiLayout = _uiLayout

    let self = this;
    this.eventsService.on("onRefresh", function (args: any) {
      self.calculateContainerHeight()
    });
  }

  calculateContainerHeight() {
    if(this.uiLayout.showInnerHeader == true) {
      this._containerHeight = window.innerHeight - 64
    } else {
      this._containerHeight = window.innerHeight
    }
  }

  ngOnInit() {
    this.calculateContainerHeight()
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {}
}
