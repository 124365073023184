import {Injectable} from '@angular/core'


///////////////////////////////////////
// Handle animations of lists - adds,
// deletes etc
///////////////////////////////////////

@Injectable()
export class CollectionsAnimationsService {


  //animation when a neighbouring tile
  //was deleted or inserted
  addTileEffectAnimation:  string = "fadeIn"
  hideTileEffectAnimation:  string = "fade-out"
  showTileEffectAnimation:  string = "fade-in"

  neighbouringTileEffectAnimation:  string = "moved-item-left"

  constructor() {
  }


  initialiseTileCollection(records: any) {
    let i = 0;
    for(i=0;i<records.length;i++) {
      records[i]["visible"] = true
      records[i]["deleted_flag"] = false
      records[i]["array_index"] = -1
      records[i]["anim"] =	"",
      records[i]["width"] = 	220
      records[i]["height"] = 200
    }
    return records;
  }

  //////////////////////////////////////////////////////////////////////
  // ADD TILE ANIMATIONS
  /////////////////////////////////////////////////////////////////////
  addTileAnimationStart(items: any, rec_id: string, new_rec: any, tile_width: number, tile_height: number) {
    new_rec["id"] = rec_id
		new_rec["visible"] = true
    new_rec["deleted_flag"] = false
    new_rec["array_index"] = -1
		new_rec["anim"] =	this.addTileEffectAnimation
		new_rec["width"] = 	tile_width
		new_rec["height"] = tile_height
    items.unshift(new_rec)
    return items;
	}

  addTileAnimationEnd(items: any) {

    if(items.length > 1) {
      items = this.tileWobbleEffect(items, 1);
    }
    return items;
	}



  //////////////////////////////////////////////////////////////////////
  // DELETE TILE ANIMATIONS
	//////////////////////////////////////////////////////////////////////
	deletedItemIndex: number = -1;
	moveCounter: number = 3;
	deleteTileAnimationStart(items: any, id: string) {
		console.log("START....")
		let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["id"] == id) {
				items[i]["anim"] = "transitionZoomOut";
				items[i]["visible"] = true;
				items[i]["deleted_flag"] = true
				items[i]["array_index"] = i
				this.deletedItemIndex = i;
			} else {
				items[i]["anim"] = "";
			}
		}
    	return items;
	}

  deleteTileAnimationEnd(items: any) {
	let i = 0;
	for(i=0;i<items.length;i++) {
		if(items[i]["deleted_flag"] == true) {
			items[i]["anim"] = "transitionZoomOut";
			items[i]["visible"] = false;
			this.deletedItemIndex = items[i]["array_index"];
		} else {
			items[i]["anim"] = "";
		}
	}
	items.splice(this.deletedItemIndex, 1);
    if(this.deletedItemIndex < items.length) {
      	items[this.deletedItemIndex]["anim"] = this.neighbouringTileEffectAnimation;
  		items = this.tileWobbleEffect(items, this.deletedItemIndex);
  		this.moveCounter = 0;
    }
    return items;
	}

	tileWobbleEffect(items: any, idx: number) {
		setTimeout(() => {
			if(idx < items.length) {
				items[idx]["anim"] = this.neighbouringTileEffectAnimation;
				idx = (idx * 1) + 1;
				if(this.moveCounter < 5) {
					this.moveCounter = (this.moveCounter * 1) + 1;
					items = this.tileWobbleEffect(items, idx);
				}
			}
		}, 50);
    return items;
	}
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // ADD TILE ANIMATIONS
  /////////////////////////////////////////////////////////////////////
  hideOtherTiles(items: any, id: number) {
    let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["id"] != id) {
        items[i]["anim"] = this.hideTileEffectAnimation;
			}
		}
    return items;
	}

  unhideOtherTiles(items: any, id: number) {
    let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["id"] != id) {
				items[i]["anim"] = this.showTileEffectAnimation;
			}
		}
    return items;
	}

  resetAnimations(items: any) {
    let i = 0;
		for(i=0;i<items.length;i++) {
			items[i]["anim"] = "";
		}
    return items;
  }


}
