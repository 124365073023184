import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { UIColorModel } from '../../services/ui.color.model.service';
import { EventsService } from '../../services/event.service'


@Component({
  selector: 'text-button',
  inputs: ['label'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div
      class="std-button {{ btnPressEffect }}"
      [style.paddingLeft.px]=0
      [style.paddingRight.px]=0
      (click)="btnClicked()"
      font-family
	    elevated [elevation]=0>
      <span primaryForeColor font-family>{{ this.btnLabel }}</span>
    </div>
  `,
})
export class TextButtonComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }

  changeDetectorRef: ChangeDetectorRef;
	uiColorModel: UIColorModel
	private eventsService: EventsService

	constructor(public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

  }

  btnLabel: string = '';
  btnPressEffect: string = '';
  btnOpacity: number = 1;
  btnRadius: number = 18;
  _elevation: number = 1

  setLabel(value: string) {
    this.btnLabel = value;
  }


  btnClicked() {
    this.btnPressEffect = 'button-press-animation';
    this.clicked.emit(true);
    setTimeout(() => {
      this.btnPressEffect = '';
    }, 250);
  }


  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
