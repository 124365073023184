import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { UIColorModel } from '../../services/ui.color.model.service';
import { EventsService } from '../../services/event.service'

@Component({
  selector: 'sbutton',
  inputs: ['label', 'icon', 'color', 'radius', 'textcolor', 'fullwidth', 'preloading',
           'tonal', 'large', 'tonaltype'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div
      class="std-button background-color-transition-350 
        {{ btnPressEffect }}
        {{btnWidthCSS}}
        {{largeCSS}} "
      [style.background]="btnColor"
      [style.color]=txtColor
      (click)="btnClicked()"
      [style.borderRadius.px]="btnRadius"
      font-family
	    elevated [elevation]=1>
      <span *ngIf="_preloadingState==false" [style.opacity]=_txtOpacity class="opacity-motion-animation">
        <span *ngIf="btnIcon != ''" class="i mdi mdi-{{ btnIcon }} {{ spinClass }}"></span>
        <span [style.marginLeft.px]="txtMarginLeft">{{ this.btnLabel }}</span>
      </span>
      <span *ngIf="_preloadingState==true" onSecondaryContainer [style.opacity]=_spinnerOpacity class="opacity-motion-animation">
        <div class="preloader-wrapper">
          <div class="preloader-spinner"
            [style.borderTop]=_borderTop
            [style.borderRight]=_borderTop
            [style.borderBottom]=_borderTop></div>
        </div>
      </span>

    </div>
  `,
})
export class StdButtonComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }
  set color(value: string) {
    this.setColor(value);
  }
  set icon(value: string) {
    this.setIcon(value);
  }
  set textcolor(value: string) {
    this.setTextColor(value);
  }
  set radius(value: number) {
    this.setRadius(value);
  }
  set fullwidth(value: boolean) {
    this.setFullWidth(value);
  }
  set preloading(value: boolean) {
    this.setPreloading(value)
  }
  set tonal(value: boolean) {
    this.setTonal(value);
  }
  set large(value: boolean) {
    this.setLarge(value);
  }
  set tonaltype(value: string) {
    this.setColorMapType(value);
  }

  changeDetectorRef: ChangeDetectorRef;
	uiColorModel: UIColorModel
	private eventsService: EventsService

	constructor(public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

    this.setColorMap()
		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self.setColorMap()
		});

  }

  btnLabel: string = '';
  btnIcon: string = '';
  btnColor: string = 'FFEEEE';
  txtColor: string = 'FFFFFF';
  prevBtnColor: string = 'FFEEEE';
  btnPressEffect: string = '';
  btnOpacity: number = 1;
  txtMarginLeft: number = 0;
  btnWidthCSS: string = ""

  _isLarge: boolean = false
  largeCSS: string = ""

  _colorMap: string = "primary"
  _isTonal: boolean = true

  spinDirection: string = 'clock';
  spinClass: string = 'anim-spin-clockwise';
  btnRadius: number = 18;

  _preloadingState: boolean = false
  _borderTop: string = "3px solid #000000"
  _txtOpacity: number = 1
  _spinnerOpacity: number = 1

  setLabel(value: string) {
    this.btnLabel = value;
  }

  setColor(value: string) {
    if(this._isTonal == false) {
      if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')) {
        this.btnColor = value;
      } else {
        this.btnColor = '#' + value;
      }  
    }
  }

  setIcon(value: string) {
    if (this.btnIcon == 'null') {
      this.btnIcon = value;
    } else {
      this.txtMarginLeft = 8;
      this.changeIcon(value);
    }
  }

  setTextColor(value: string) {
    if(this._isTonal == false) {
      this.txtColor = value
    }
  }

  setRadius(value: number) {
    this.btnRadius = value;
  }

  setFullWidth(value: boolean) {
    if(value) {
      this.btnWidthCSS = "fullwidth";
    } else {
      this.btnWidthCSS = "";
    }
  }

  btnClicked() {
    this.btnPressEffect = 'button-press-animation-2';
    this.clicked.emit(true);
    setTimeout(() => {
      this.btnPressEffect = '';
    }, 250);
  }

  changeIcon(v: string) {
    if (this.spinDirection == 'clock') {
      this.spinClass = 'anim-spin-clockwise';
      this.ref.markForCheck();
      setTimeout(() => {
        this.btnIcon = v;
        this.spinClass = '';
      }, 150);
    } else {
      this.spinClass = 'anim-spin-counterclockwise-150';
      this.ref.markForCheck();
      setTimeout(() => {
        this.btnIcon = v;
        this.spinClass = '';
      }, 150);
    }
  }

  setPreloading(value: boolean) {
    if(value == true) {
      this._txtOpacity = 0
      this._spinnerOpacity = 1
    }
    if(value == false) {
      this._spinnerOpacity = 0
      this._txtOpacity = 1
    }
    setTimeout(() => {
      this._preloadingState = value
    }, 250);
  }

  setColorMap() {
    if(this._isTonal == true) {
     // this.btnColor = this.uiColorModel.primaryContainer
     // this.txtColor = this.uiColorModel.onPrimaryContainer
      if(this._colorMap == "surface") {
        this.btnColor = this._uiColorModel.surfaceHigh
        this.txtColor = this.uiColorModel.primary
      }
      if(this._colorMap == "primary") {
        this.btnColor = this._uiColorModel.primary
        this.txtColor = this.uiColorModel.onPrimary
      }
      if(this._colorMap == "secondary") {
        this.btnColor = this._uiColorModel.secondary
        this.txtColor = this.uiColorModel.onSecondary
      }
      if(this._colorMap == "tertiary") {
        this.btnColor = this._uiColorModel.tertiary
        this.txtColor = this.uiColorModel.onTertiary
      }
      if(this._colorMap == "primary-container") {
        this.btnColor = this._uiColorModel.primaryContainer
        this.txtColor = this.uiColorModel.onPrimaryContainer
      }
      if(this._colorMap == "secondary-container") {
        this.btnColor = this._uiColorModel.secondaryContainer
        this.txtColor = this.uiColorModel.onSecondaryContainer
      }
      if(this._colorMap == "tertiary-container") {
        this.btnColor = this._uiColorModel.tertiaryContainer
        this.txtColor = this.uiColorModel.onTertiaryContainer
      }
    }
  }
  setColorMapType(value: string) {
    this._colorMap = value
    this.setColorMap()
  }

  setTonal(value: boolean) {
    this._isTonal = value
    this.setColorMap()
  }

  setLarge(value: boolean) {
    this._isLarge = value
    if(value==true) {
      this.largeCSS = "large"
    } else {
      this.largeCSS = ""
    }
  }


  ngOnInit() {

  }

  ngOnChanges() {}

  ngOnDestroy() {}
}
