import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter
} from '@angular/core';


@Component({
	selector: 		'labelled-travel-icon-progress',
	inputs: ['origin', 'destination', 'icon', 'isdark', 'perc', 'noline'],

	template: 	`
		<div class="origin-destination-progressbar">
			<div class="origin" onSurface font-family h5>{{_origin}}</div>
			
			<div class="fillspace">
				<travel-icon-progress [noline]=_noLine [icon]=_icon [perc]=_perc></travel-icon-progress>
			</div> 

			<div class="destination" onSurface font-family>{{_dest}}</div>
		</div>

	`,

})



export class LabelledTravelIconProgressComponent {

	@Input()
	set origin(value: string) { this.setOrigin(value); }
	set destination(value: string) { this.setDestination(value); }
	set icon(value: string) { this.setIcon(value); }
	set perc(value: number) { this.setPerc(value); }
	set isdark(value: boolean) { this.setIsDark(value); }
	set noline(value: boolean) { this.setNoLine(value); }

	_origin: string = "NYC"
	_dest: string = "DBX"
	_icon: string = "airplane rotate90"
	_perc: number = 50
	_isDark:	boolean = false
	_noLine: boolean = false

	constructor() {
	}

	setOrigin(value: string) {
		this._origin = value
	}

	setDestination(value: string) {
		this._dest = value
	}

	setIcon(value: string) {
		this._icon = value
	}

	setPerc(value: number) {
		this._perc = value
	}

	setIsDark(value: boolean) {
		this._isDark = value
	}

	setNoLine(value: boolean) {
		this._noLine = value
	}

	ngOnInit() {
	/*	this._perc = 1
		setTimeout(() => {
			this._perc = 100
		}, 1000);*/
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
