import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,
	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';


@Component({
	selector: 		'blog-article-page',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<content-container-scrollable>
		<optimised-content-container>

			<div id="scroll-to-top"></div>
			<blog-page-header [heading]="'How protocols will factor into this'"></blog-page-header>

			<ng-content></ng-content>
			<scroll-top-button [scrollshowvalue]=100 (clicked)=scrollToTop()></scroll-top-button>

		</optimised-content-container>
	</content-container-scrollable>

		`,
})


export class BlogArticlePageTemplate {
	@ViewChild('imageRef')
	imageRef!: HTMLImageElement;

	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel


	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.changeDetectorRef = ref

		this.typographyModel.headingDisplayFontFamily = "League Spartan"
		this.typographyModel.displayFontFamily = "Libre Baskerville Regular"
		
	}

	scrollToTop() {
		let textElement: any = document.getElementById("scroll-to-top")
		textElement.scrollIntoView({behavior: "smooth"})
	}

	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
