import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { ColorUtils } from "../../style/color/utils/color.utils.class"
import { UIColorModel } from '../../services/ui.color.model.service';
import { TabsSet } from '../../classes/navigation/tabs.class';

@Component({
  selector: 'tabs-m3-iconlabel',
  inputs: ['data'],
  outputs: ['clicked', 'direction', 'cssclass'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
        <ul class="_tabs-container-m3-w-icon" 
            [style.borderBottom]=_borderBottom
            surfaceColor>
            <div class="item" *ngFor="let dat of this._data.tabs"
                [style.borderBottom]=this.activeBorder(dat.isActive)>
              <div class="_tab-m3-icon" 
                  (click)=tabClicked(dat.id)>
                <div class="icon">
                  <i class="mdi mdi-{{dat.activeIcon}}" onSurface></i>
                </div>
                <div class="label" onSurface>{{dat.label}}</div>
              </div>
            </div>
        </ul>

  `,
})
export class TabsM3IconLabelComponent {
  @Output()
  clicked = new EventEmitter();
  direction = new EventEmitter();
  cssclass = new EventEmitter();

  @Input()
  set data(value: TabsSet) {
    this.setData(value);
  }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
  private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel

  _data: TabsSet = new TabsSet()
  _borderBottom: string = "1px solid "

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	}

  setData(value: TabsSet) {
    this._data = value
  }

  ngOnInit() {
    this._borderBottom = this._borderBottom + this.uiColorModel.surfaceVariant
  }

  ngOnChanges() {}

  ngOnDestroy() {}


  tabClicked(id: string) {
    this._data.setActive(id, true)
  }

  activeClass(isActive: boolean): string {
    let result = ""
    if(isActive) {
      result = "active"
    } else {
      result = ""
    }
    return result
  }

  activeBorder(isActive: boolean): string {
    let result = "3px solid " + this.uiColorModel.primary
    if(isActive) {
      result = "3px solid " + this.uiColorModel.primary
    } else {
      result = "none"
    }
    return result
  }

}
