///////////////////////////////////////////////////////////////////////
// Angular modules
///////////////////////////////////////////////////////////////////////
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

///////////////////////////////////////////////////////////////////////
// 3rd Party Modules - Imports
///////////////////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module';
import { CoreUIExtensionsModule } from '@libraries/coreui-extensions/coreui.extensions.module';

import { DynamicCloudsBackgroundModule } from '@libraries/dynamic-clouds/dynamicclouds.module'


///////////////////////////////////////////////////////////////////////
// SERVICES
///////////////////////////////////////////////////////////////////////
import { ApplicationService } from './services/application.service';
import { AppAuthService } from './content/auth/app.auth.service';





///////////////////////////////////////////////////////////////////////
// Container
///////////////////////////////////////////////////////////////////////
import { Main } from './main/main';
import { MainRoutes } from './main/main.routes';

import { DashContainer } from './main/dash';
import { DashRoutes } from './main/dash.routes';

import { Modals } from './main/modals';
import { RightSliders } from './main/sliders';



import { AppbarRoutes } from './components/appbar/appbar.routes';
/* Appbar - medium size */
import { AppbarMediumComponent } from './components/appbar/appbar.medium.component';
/* Appbar - compact size */
import { AppbarCompactComponent } from './components/appbar/appbar.compact.component';

/* Sidenav Drawer */
import { SidenavDrawerComponent } from './components/sidenav/sidenav.component';
import { SidenavMenuItemComponent } from './components/sidenav/sidenav.menu.item.component';


///////////////////////////////////////////////////////////////////////
// CONTENT
///////////////////////////////////////////////////////////////////////
import { HomeScreen } from './content/home/home.screen';

// Sign Up Screen 
import { SignUpScreen } from './content/auth/signup/signup.screen';
import { SignUpMethodScreen } from './content/auth/signup/signup.method.screen';
import { SignInFieldsScreen } from './content/auth/signup/signin.fields.screen';
import { SignInScreen } from './content/auth/signup/signin.screen';

/* Ecommerce */
import { EcommerceScreen } from './content/ecommerce/ecommerce.screen';

import { SmallHotelCard } from './components/cards/horizontal.hotel.card';
import { DiscoverPlaceCard } from './components/cards/discover.card';

/* Intro Screen */
import { IntroScreen } from './content/intro/intro.screen';


@NgModule({
  declarations: [
    DashContainer,
    Main,
    MainRoutes,
    DashRoutes,
    Modals,
    RightSliders,
    AppbarRoutes,
    AppbarMediumComponent,
    AppbarCompactComponent,
    SidenavDrawerComponent,
    SidenavMenuItemComponent,

    HomeScreen,
    SignUpScreen,
    SignUpMethodScreen,
    SignInFieldsScreen,
    SignInScreen,

    EcommerceScreen,
    SmallHotelCard,
    DiscoverPlaceCard,

    //intro screen
    IntroScreen

  ],

  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    CoreUIModule,
    CoreUIExtensionsModule,
    DynamicCloudsBackgroundModule
  ],


  providers: [
    ApplicationService,
    AppAuthService
  ],

  exports: [
    DashContainer,
    Main,
    MainRoutes,
    DashRoutes,
    Modals,
    RightSliders,
    AppbarRoutes,
    AppbarMediumComponent,
    AppbarCompactComponent,
    SidenavDrawerComponent,
    SidenavMenuItemComponent,

    HomeScreen,
    SignUpScreen,
    SignUpMethodScreen,
    SignInFieldsScreen,
    SignInScreen,

    EcommerceScreen,
    SmallHotelCard,
    DiscoverPlaceCard,

    //intro screen
    IntroScreen

  ]
})
export class LoyaltyAppModule { }
