import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { ApplicationService } from '../../services/application.service';


@Component({
	selector: 		'ecommerce-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

	<content-container-scrollable>
		<optimised-content-container>

			<div class="_headline-medium" font-family onSurface>
				ECOMM!!!!!!   
			</div>
			<div font-family onSurface>
			</div>

			<ul class="_horizontal-hotel-card-row">
				<small-media-card [heading]="'Sheraton Underwater'" [imgsrc]="'./assets/sample/media/underwater-resort.jpg'"></small-media-card>
				<small-media-card [imgsrc]="'./assets/sample/media/underwater-resort.jpg'"></small-media-card>
				<small-media-card [imgsrc]="'./assets/sample/media/underwater-resort.jpg'"></small-media-card>
				<small-media-card [imgsrc]="'./assets/sample/media/underwater-resort.jpg'"></small-media-card>
				<small-media-card [imgsrc]="'./assets/sample/media/underwater-resort.jpg'"></small-media-card>
				<small-media-card [imgsrc]="'./assets/sample/media/underwater-resort.jpg'"></small-media-card>
				<small-media-card [imgsrc]="'./assets/sample/media/underwater-resort.jpg'"></small-media-card>
				<small-media-card [imgsrc]="'./assets/sample/media/underwater-resort.jpg'"></small-media-card>
			</ul>
			<ul class="_chip-set-row">
				

				<chip [label]="'Template'" [capsule]=true></chip>
				<chip [label]="'UI/UX'" [capsule]=true></chip>
				<chip [label]="'Blog'" [capsule]=true></chip>
				<chip [label]="'Article'" [capsule]=true></chip>
				<chip [label]="'Template'" [capsule]=true></chip>
				<chip [label]="'UI/UX'" [capsule]=true></chip>
				<chip [label]="'Blog'" [capsule]=true></chip>
				<chip [label]="'Article'" [capsule]=true></chip>
				<chip [label]="'Template'" [capsule]=true></chip>
				<chip [label]="'UI/UX'" [capsule]=true></chip>
				<chip [label]="'Blog'" [capsule]=true></chip>
				<chip [label]="'Article'" [capsule]=true></chip>
				<chip [label]="'Template'" [capsule]=true></chip>
				<chip [label]="'UI/UX'" [capsule]=true></chip>
				<chip [label]="'Blog'" [capsule]=true></chip>
				<chip [label]="'Article'" [capsule]=true></chip>
			</ul>


		</optimised-content-container>
		<div style="position: fixed; bottom: 32px; right: 32px;">
			<fab [icon]="'lock'" (clicked)=fabClicked()></fab>
		</div>
	</content-container-scrollable>
		
	`,

})


export class EcommerceScreen {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	applicationService: ApplicationService

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _applicationService: ApplicationService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.applicationService = _applicationService
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	fabClicked() {
		this.eventsService.broadcast("onShowModalOverlay", {id: "toast", message: "Item added"})
	}

}
