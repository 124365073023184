import {
	Component,
	Input,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	HostListener
} from '@angular/core';

import { DynamicCloudsBackgroundControl } from './dynamicclouds.control.class';
import { DynamicCloudsBackground } from './dynamicclouds.class';


@Component({
	selector: 		'background-dynamicclouds',
	inputs: ['color1', 'color2', 'backgroundcolor', 'cssclass', 'interval', 'numclouds', 'speed', 'cloudopacity', 'presetid', 'hideclouds'],
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
					<div class="_dynamicclouds-background"  [style.height]=this.containerHeight
						[style.backgroundColor]=this._backgroundcolor>

						<cloud-item-background-dynamicclouds *ngFor="let dat of this.shapes"
							[speed]=this._speed
							[color]=dat.hex
							[x]=dat.y
							[y]=dat.x
							[opacity]=dat.opacity>
						></cloud-item-background-dynamicclouds>

					</div>

								`,
})



export class BackgroundDynamicCloudsComponent {
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.containerHeight = window.innerHeight
	}

	@Input()
	set color1(value: string) { this.setColor1(value); }
	set color2(value: string) { this.setColor2(value); }
	set backgroundcolor(value: string) { this.setBackgroundColor(value); }
	set cssclass(value: string) { this.setCSSClass(value); }
	set interval(value: number) { this.setInterval(value); }
	set numclouds(value: number) { this.setNumClouds(value); }
	set speed(value: number) { this.setSpeed(value); }
	set cloudopacity(value: number) { this.setCloudOpacity(value); }
	set presetid(value: string) { this.setPreset(value); }
	set hideclouds(value: boolean) { this.hideClouds(value); }


	private changeDetectorRef: ChangeDetectorRef;

	_color1: string = "613DFB"
	_color2: string = "4FD1D9"
	_backgroundcolor: string = "#6983A7"
	_cssclass: string = ""
	_interval: number = 15000

	shapes: any = []
	containerHeight: number = 100
	update_interval: any;
	_numClouds: number = 25
	_speed: number = 5000
	_backgroundSpeed: number = 1200
	_cloudOpacity: number = 1
	_dynamicCloudsBackgroundControl: DynamicCloudsBackgroundControl

	constructor(public ref: ChangeDetectorRef) {
		this.changeDetectorRef = ref;
		this._dynamicCloudsBackgroundControl = new DynamicCloudsBackgroundControl()
	}

	setColor1(value: string): void {
		this._color1 = value
		this.changeCloudSpec()
	}
	setColor2(value: string): void {
		this._color2 = value
		this.changeCloudSpec()
	}
	setBackgroundColor(value: string): void {
		this._backgroundcolor = "#" + value
	}
	setCSSClass(value: string): void {
		this._cssclass = value
	}
	setInterval(value: number): void {
		this._interval = value
		this.changeCloudSpec()
	}

	setNumClouds(value: number) {
		this._numClouds = value
		this.changeCloudSpec()
	}
	setSpeed(value: number) {
		this._speed = value
		this.changeCloudSpec()
	}
	setCloudOpacity(value: number) {
		this._cloudOpacity = value
		this.changeCloudSpec()
	}

	hideClouds(value: boolean) {
		if(value == true) {
			this._cloudOpacity = 0
		} else {
			this._cloudOpacity = 1
		}
		this.changeCloudSpec()
	}


	setPreset(value: string) {
		if(this._dynamicCloudsBackgroundControl.presetExists(value)) {
			let preset: DynamicCloudsBackground = this._dynamicCloudsBackgroundControl.getPreset(value)
			this.backgroundcolor = preset["backgroundcolor"]
			this._color1 = preset["color1"]
			this._color2 = preset["color2"]
			this.interval = preset["interval"]
			this.numclouds = preset["numclouds"]
			this.speed = preset["speed"]
			this.generate()
		}
	}

	addEllipse(id: number, width: number, height: number, x: number, y: number, hex: string) {
		let rec = {
			id: id,
			width: 420,
			height: 550,
			x: x,
			y: y,
			opacity: this._cloudOpacity,
			hex: hex,
			prev: hex,
			class: ""
		}
		this.shapes.push(rec)
	}


	changeOut() {
		for(let dat of this.shapes) {
			dat.hex = this.randomColor()
			dat.x = this.random(100)
			dat.y = this.random(100)
		}
	}

	changeCloudOpacity() {
		for(let dat of this.shapes) {
			dat.opacity = this._cloudOpacity
		}
		this.changeDetectorRef.detectChanges()
	}

	changeCloudSpec() {
		for(let dat of this.shapes) {
			dat.speed = this._speed
			dat.opacity = this._cloudOpacity
			dat.hex = this.randomColor()
			dat.x = this.random(100)
			dat.y = this.random(100)
		}
		this.changeDetectorRef.detectChanges()
	}

	reset() {
		this.shapes = []
	}

	generate() {
		this.reset()
		let i = 0;
		for(i=0;i<this._numClouds;i++) {
			this.addEllipse(i, this.random(400), this.random(600), this.random(90), this.random(90), this.randomColor())
		}
	}


	ngOnInit() {
		this.containerHeight = window.innerHeight

		this.generate()

		this.update_interval = setInterval(() => {
			this.changeOut()
		}, this._interval)

	}

	ngOnChanges() {
	}

	ngOnDestroy() {
		if (this.update_interval) {
			clearInterval(this.update_interval);
		}
	}


	randomColor() {
		let list = []
		list.push(this._color1)
		list.push(this._color2)
		let len = list.length
		let idx = Math.floor(Math.random() * len) + 0
		let col = "#" + list[idx]
		return col
	}


	random(max: number) {
		let mv = max + 30
		let val = (Math.floor(Math.random() * mv) + 1) - 30
		return val
	}

}