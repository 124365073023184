import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '../../../services/event.service'
import { UIColorModel } from '../../../services/ui.color.model.service';
import { ColorUtils } from "../../../style/color/utils/color.utils.class"
import { NavigationDrawerMenu } from '../../../classes/navigation/navigation.drawer.menu.class';


@Component({
	selector: 		'navigation-drawer-nested-modal',
	inputs: ['data'],
	outputs: ['clicked', 'hoverenter', 'hoverexit'],
	changeDetection: ChangeDetectionStrategy.Default,
	template: 	`


	<slider-sheet [direction]="'left'" [size]="320">
      <div left>
        <div class="_navigation-drawer" surfaceContainerLow>
		  <div class="menu-control">
			<div class="icon-wrapper">
				<i class="mdi mdi-menu-open"></i>
			</div>
		  </div>

		  <navigation-drawer-submenu [data]=_activeMenu></navigation-drawer-submenu>
		 
        </div>
      </div>
    </slider-sheet>


		`,
})


export class NavigationDrawerNestedMenu {
	@Output()
	clicked = new EventEmitter();
	hoverenter = new EventEmitter();
	hoverexit = new EventEmitter();

	@Input()
	set data(value: NavigationDrawerMenu) {
	  this.setData(value);
	}
	  
	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
    private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel
	
	_data: NavigationDrawerMenu = new NavigationDrawerMenu()
	_activeMenu: NavigationDrawerMenu = new NavigationDrawerMenu()

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	  
		let self = this
		this.eventsService.on('onRefresh', function (args: any) {	
		});
	}

	setData(value: NavigationDrawerMenu) {
		this._data = value
	}

	itemClicked(id: string) {
		this.clicked.emit(id);
	  }
	


	ngOnInit() {
		this._activeMenu = this._data
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
