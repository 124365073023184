import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,

	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';


@Component({
	selector: 		'test-page',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<content-container-scrollable>


        <icon-button [icon]="'heart-outline'" [activeicon]="'heart'"></icon-button>
        <icon-button [icon]="'heart-outline'" [activeicon]="'heart'"></icon-button>
 

		<div class="row" [style.height.px]=96>
			<div class="col s2">
				<fab [icon]="'theme-light-dark'" (click)=toggleDarkLight()></fab>
			</div>
			<div class="col s2">
				<fab [icon]="'account'" (click)=toggleModal()></fab>
			</div>
			<div class="col s2">
				<fab [icon]="'edit'" (click)=toggleEdit()></fab>
			</div>
			<div class="col s3">
				<star-review [score]=4.5></star-review>
			</div>
		</div>

		<div class="row">
			<div class="col s12">

				<ul class="grid-collection-list">
						<media-card 
							[heading]="'Book tickets'"
							[subheading]="'Book a flight on our jet'"
							[mediaheight]=144
							[imgsrc]="'./assets/sample/media/jet-flying.jpg'">
						</media-card>
						<media-card 
							[heading]="'Plan your stay'"
							[subheading]="'Relax and unwind in our resort'"
							[mediaheight]=144
							[imgsrc]="'./assets/sample/media/underwater-resort.jpg'">
						</media-card>
				</ul>
			</div>
			<div class="row">
				
				<div class="col s5">
					<sessions-list-item></sessions-list-item>
				</div>
				<div class="col s1 offset-s1">
					<flag-icon [countrycode]="'sy'"></flag-icon>
				</div>
			</div>


			<div class="row">	
				<div class="col s4">
					<file-drop-component></file-drop-component>
				</div>
			</div>
			

		</div>
	<scroll-top-button></scroll-top-button>
	</content-container-scrollable>
 
		`,
})


export class TestPage {
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel
	uiColorModel: UIColorModel

	barPerc: number = 5
	indet: boolean = true

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _uiColorModel: UIColorModel,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.uiColorModel = _uiColorModel
		this.changeDetectorRef = ref
	}


	toggleModal() {
		this.eventsService.broadcast("onShowModalOverlay", {id: "sample-modal"})

	}

	toggleDarkLight() {
		if(this.uiColorModel.isDark == true) {
			this.uiColorModel.lightScheme()
		} else {
			this.uiColorModel.darkScheme()
		}
		this.eventsService.broadcast("onRefresh", {})
	}


	toggleEdit() {
		this.uiNavigation.activeView = "edit-profile"
	}

	ngOnInit() {
		this.typographyModel.displayFontFamily = "Playfair Display"

		setTimeout(() => {
			this.indet = false
			this.barPerc = 90
		}, 3000);
		setTimeout(() => {
			this.barPerc = 25
		}, 5000);

	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
