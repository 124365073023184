import { PersonInfo } from './person.class'

export class PersonSampleDataInfo {

    private _data: any[] = [];
 
    constructor() {
      this.gitsSamples()
      this.swSamples()
    //  this.loadData()
    }
  

    loadData() {
      let path = "./assets/avatars/samples1/"
      let i = 0
      for(i=1;i<=20;i++) {
        let rec = new PersonInfo()
        rec.id = "sample001_" + i
        rec.avatarURL = path + i +".png"
        this._data.push(rec)
      }

    }

  

    public get data(): any[] {
      return this._data;
    }
    public set data(value: any[]) {
      this._data = value;
    }
  
    gitsSamples() {
      let path = "./assets/avatars/samples/gits/"
      this.createSamplePerson("b0c0d6ce-4670-4d95-9425-0aae56d6c591", "Mokoto", "", "Kusunagi", path + "gits-mokoto1.png")
      this.createSamplePerson("b1ea3a34-7048-43a1-b7ca-380c24f0d198", "Batou", "", "Otu", path + "gits-batu1.png")
      this.createSamplePerson("11639c1e-11dd-4fd9-8262-6ca03d484c2c", "Daisuke", "", "Aramaki", path + "gits-chief1.png")
      this.createSamplePerson("9e342206-9ef7-4d69-bd4d-d59ad8a95223", "Koichi", "", "Togusa", path + "gits-togusa1.png")
      this.createSamplePerson("be50f7ce-e4f1-48ee-8e8f-60973db4b5ac", "Yutaka", "", "Ishikawa", path + "gits-ishikawa1.png")
    }


    
    
    
    /*
    
    
    ab4cfc8f-0652-4de8-a9d3-d83b60058a4b
    1e3f746a-63f6-45bc-8ccd-3603f2f45b9b
*/
    swSamples() {
      let path = "./assets/avatars/samples/sw/"
      this.createSamplePerson("7abfe251-209b-4df0-8903-840485d71250", "Zip", "", "Karre", path + "sw-karre.png")
      this.createSamplePerson("ecfb0311-043f-4e3d-9b8b-2c399a906de0", "Kresta", "", "F", path + "sw-f-f.png")
      this.createSamplePerson("23cce9c8-e9fd-452c-9701-99c5a2e5c1a5", "T0", "", "B1", path + "sw-t0-b1.png")
      this.createSamplePerson("69f599bc-dcf7-4495-887e-8b534ba2aa51", "Joe", "", "Ronin", path + "sw-t-ronin.png")
      this.createSamplePerson("f3a5b8d1-39b2-4f8c-a3e2-d7b4abe86733", "Yo", "", "Saiku", path + "sw-xi-saiku.png")
      this.createSamplePerson("2f41c54d-deef-4958-9e58-c71cbd3173ca", "Lah", "", "Karah", path + "sw-lahkarah.png")
    }

    

    createSamplePerson(id: string, fn: string, mn: string, ln: string, url: string) {
      let g1 = new PersonInfo()
      g1.create(id, fn, mn, ln, url)
      let json = g1.toJSON()
      this._data.push(json)
    }

  }
  