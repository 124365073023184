import { TagSet } from '../tags/tags.class'

export abstract class Thing {

    private _id: string = ""
    private _refid: string = ""
    private _typeid: string = ""
    private _tags: TagSet = new TagSet()
  
    constructor(id: string, type: string) {
        this._id = id
        this._typeid = type
    }
  
    public get id(): string {
        return this._id
    }
    public set id(value: string) {
        this._id = value
    }
    public get refid(): string {
        return this._refid
    }
    public set refid(value: string) {
        this._refid = value
    }
    public get typeid(): string {
        return this._typeid
    }
    public set typeid(value: string) {
        this._typeid = value
    }
    public get tags(): TagSet {
        return this._tags
    }
    public set tags(value: TagSet) {
        this._tags = value
    }


  }
  