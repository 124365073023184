import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '../../../services/event.service'
import { UIColorModel } from '../../../services/ui.color.model.service';
import { ColorUtils } from "../../../style/color/utils/color.utils.class"


@Component({
	selector: 		'navigation-icon',
	inputs: ['id', 'icon', 'label', 'focused', 'disabled', 'hovered', 'pressed', 'badgevalue', 'smallbadge'],
	outputs: ['clicked', 'hoverenter', 'hoverexit'],
	changeDetection: ChangeDetectionStrategy.Default,
	template: 	`
		<div class="_navigation-icon" 
			(mouseover)=handleMouseEnter() 
			(mouseleave)=handleMouseLeave()
			(click)=handleClick()>
			<div class="indicator-wrapper">
				<div class="indicator {{_touchAnimCSS}}"
					 [style.background]=_highlightColor  
					 [style.opacity]=_componentOpacity>
					<i class="mdi mdi-{{_icon}}" [style.color]=_iconColor></i>
					<div *ngIf="this._badgeValue > 0 && this._badgeSmall == false" class="badge">
						<badge [value]=_badgeValue></badge>
					</div>
					<div *ngIf="this._badgeValue > 0 && this._badgeSmall == true" class="badge small">
						<badge [small]=true></badge>
					</div>
				</div>
			</div>
			<div class="label" font-family
				[style.color]=_textColor>{{_label}}
			</div>
		</div>

		`,
})


export class NavigationIconComponent {
	@Output()
	clicked = new EventEmitter();
	hoverenter = new EventEmitter();
	hoverexit = new EventEmitter();

	@Input()
	set id(value: string) {
	  this.setId(value);
	}
	set icon(value: string) {
		this.setIcon(value);
    }
	set label(value: string) {
		this.setLabel(value);
    }
	set disabled(value: boolean) {
		this.setDisabled(value);
    }
	set hovered(value: boolean) {
		this.setHovered(value);
    }
	set pressed(value: boolean) {
		this.setPressed(value);
    }
	set badgevalue(value: number) {
		this.setBadgeValue(value);
    }
	set smallbadge(value: boolean) {
		this.setSmallBadge(value);
    }


	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
    private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel
	
	_highlightColor: string = "rgba(0,0,0,0)"
	_iconColor: string = "rgba(0,0,0,0)"
	_textColor: string = "rgba(0,0,0,0)"

	_id: string = ""
	_label: string = ""
	_icon: string = ""
	_touchAnimCSS: string = ""

	_badgeValue: number = 0
	_badgeSizeCSS: string = ""
	_badgeSmall: boolean = false


	_isDisabled: boolean = false
	_isHovered: boolean = false
	_isFocused: boolean = false
	_isPressed: boolean = false

	_componentOpacity: number = 1


	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	  
		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self.refreshColorStates()
		});

	}


	/////////////////////////////////////
	// Inputs
	////////////////////////////////////
	setId(value: string) {
		this._id = value
	}

	setIcon(value: string) {
		this._icon = value
	}

	setLabel(value: string) {
		this._label = value
	}

	setDisabled(value: boolean) {
		if(value == true) {
			this._isDisabled = true
			this.disabledState()
		} else {
			this._isDisabled = false
			this.activeState()
		}
	}	

	setHovered(value: boolean) {
		if(value == true) {
			if(this._isDisabled == false && this._isPressed == false) {	
				this.hoveredState()
			}
		} else {
			if(this._isDisabled == false && this._isPressed == false) {	
				this.activeState()
	 	    }
		}
		this.changeDetectorRef.detectChanges()
	}


	setPressed(value: boolean) {
		if(this._isDisabled == false) {
			if(value == true) {
				this._isPressed = true
				this.pressedState()
				this.pressAnimation()
			} else {
				this._isPressed = false
				this.activeState()
				this.pressAnimation()
			}
		}
	}	



	setBadgeValue(value: number) {
		this._badgeValue = value
	}

	setSmallBadge(value: boolean) {
		this._badgeSmall = value
		if(value == true) {
			this._badgeSizeCSS = "small"
		} else {
			this._badgeSizeCSS = ""
		}
	}
	



	////////////////////////////////////
	// Handlers
	////////////////////////////////////
	handleClick() {
		if(this._isDisabled == false && this._isPressed == false) {
			this.pressedState()
			this.clicked.emit(this._id)	
		//	this.pressAnimation()
		}
	}
	handleMouseEnter() {
		if(this._isDisabled == false && this._isPressed == false) {
			this.hoveredState()
		}
		this.hoverenter.emit(this._id)
	}
	handleMouseLeave() {
		if(this._isDisabled == false && this._isPressed == false) {
			this.activeState()
		}
		this.hoverexit.emit(this._id)
	}


	/*****************************************
	 * STATES
	 * 
	 *  - enabled
	 *  - disabled
	 *  - hover
	 *  - pressed
	 *  - focused
	 * 
	 * ****************************************/

	// button disabled
	disabledState() {
		this._componentOpacity = 0.4
		this._highlightColor = "rgba(0,0,0,0)"
		this._textColor = this.uiColorModel.onSecondaryContainer
		this._iconColor = this.uiColorModel.onSurface
	}

	// button active.  Normal state
	activeState() {
		this._componentOpacity = 1
		this._highlightColor = "rgba(0,0,0,0)"
		this._textColor = this.uiColorModel.onSurface
		this._iconColor = this.uiColorModel.onSurface
	}

	// button hovered on
	hoveredState() {
		this._componentOpacity = 1
		this._highlightColor = this.uiColorModel.surfaceVariant
		this._textColor = this.uiColorModel.onSurfaceVariant
		this._iconColor = this.uiColorModel.onSurfaceVariant
	}
	
	// button pressed	
	pressedState() {
		this._componentOpacity = 1
		this._highlightColor = this.uiColorModel.secondaryContainer
		this._textColor = this.uiColorModel.onSecondaryContainer
		this._iconColor = this.uiColorModel.onSecondaryContainer
	}

	focusedState() {
		this._componentOpacity = 1
		this._highlightColor = this.uiColorModel.secondaryContainer
		this._textColor = this.uiColorModel.onSurface
		this._iconColor = this.uiColorModel.onSecondaryContainer
	}

	private pressAnimation() {
		this._touchAnimCSS = "nav-icon-touch-animation"
		setTimeout(() => {
			this._touchAnimCSS = ""
		}, 150);
	}

	refreshColorStates() {
		this.activeState()
		if(this._isDisabled) {
			this.disabledState()
		} else {
			if(this._isHovered) {
				this.hoveredState()
			}
			if(this._isFocused) {
				this.focusedState()
			}
			if(this._isPressed) {
				this.pressedState()
			}
		}
	}



	ngOnInit() {
		this.refreshColorStates()
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}



}
