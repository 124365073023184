
export class ApplicationContainerSettings {



    // none, top, bottom, both
    private _compactNavigationComponent: string = "none"

    // none, rail, top
    private _mediumNavigationComponent: string = "none"

    // none, rail, full, top
    private _expandedNavigationComponent: string = "none"


    

    constructor() {}


    public get compactNavigationComponent(): string {
        return this._compactNavigationComponent
    }
    public set compactNavigationComponent(value: string) {
        this._compactNavigationComponent = value
    }

    public get mediumNavigationComponent(): string {
        return this._mediumNavigationComponent
    }
    public set mediumNavigationComponent(value: string) {
        this._mediumNavigationComponent = value
    }
    public get expandedNavigationComponent(): string {
        return this._expandedNavigationComponent
    }
    public set expandedNavigationComponent(value: string) {
        this._expandedNavigationComponent = value
    }


}