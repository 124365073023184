import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef
} from '@angular/core';

import { ApplicationService } from '../services/application.service';
import { EventsService } from '@coreui//services/event.service'
import { TypographyModel } from '@coreui/services/typography.service';
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UILayout } from '@coreui//services/ui.layout.service';


import { OpeningHours } from '@libraries/data-commons/opening-hours/openinghours.class';
import { TimeZonesService } from '@libraries/timezones/timezones.service';
import { DateUtils } from '@libraries/dateutils/date.utils.class';


@Component({
	selector: 		'peachmarketing-main',
	encapsulation: ViewEncapsulation.None,
	template:	`

		<application-container>
			<div background-layer>
				<backgrounds></backgrounds>
			</div>

			<div top>
				<pm-appbar-routes></pm-appbar-routes>
			</div>

			<div left-navigation>
				<left-navigation></left-navigation>
			</div>

			<div inner-header>
			</div>

			<div body>
				<pm-main-routes [route]=this.uiNavigation.activeView></pm-main-routes>
			</div>

			<div right-slider-sheet>
				<right-sliders></right-sliders>
			</div>

			<div bottom>
				<bottom-navigation></bottom-navigation>
			</div>

		</application-container>

		<!-- Modals -->
		<modal-contaimer></modal-contaimer>

<!--	<div style="position: fixed; bottom: 32px; right: 32px;">
			<fab [tonal]=true [colormap]="'secondary'" [icon]="'key'" [pulse]=false></fab>
		</div>	 -->
	`,

})


export class MainContainer {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	applicationService: ApplicationService
	uiNavigation: UINavigation
	uiLayout: UILayout
	private typographyModel: TypographyModel
	private timeZonesService: TimeZonesService



	constructor(public ref: ChangeDetectorRef,
			private _applicationService: ApplicationService, 
			private _eventsService: EventsService,
			private _uiNavigation: UINavigation,
			private _uiLayout: UILayout,
			private _timeZonesService: TimeZonesService,
			private _typographyModel: TypographyModel) {
		this.changeDetectorRef = ref		
		this.applicationService = _applicationService
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.uiLayout = _uiLayout
		this.typographyModel = _typographyModel
		this.timeZonesService = _timeZonesService
		document.title = "Peach Marketing"

		
	}


	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}


}





