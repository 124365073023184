import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

import { EventsService } from '../../../services/event.service'
import { ColorUtils } from "../../../style/color/utils/color.utils.class"
import { UIColorModel } from '../../../services/ui.color.model.service';

@Component({
  selector: 'tile-single-text-only',
  inputs: ['label', 'icon'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,
 
  template: `
      <li class="single-item-list-tile"
            [style.backgroundColor]=surfaceColor
            (mouseenter)=mouseoter($event)
            (mouseleave)=mouseleave($event)
            (click)=tileClicked($event)>
        <div class="primary-text" onSurface font-family>{{_label}}</div>
        <div class="inset-divider"></div>
        <div *ngIf="'this._icon.length>0'" class="secondary-action">
          <tile-secondary-action-48 [icon]=_icon></tile-secondary-action-48>
        </div>
      </li>

  `,
})
export class TileSingleItemComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }
  set icon(value: string) {
    this.setIcon(value);
  }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
  private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel

  _label: string = ""
  _icon: string = ""
  surfaceColor: string = ""

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
    this.surfaceColor = "rgba(0,0,0,0)"
	}

  setLabel(value: string) {
    this._label = value
  }
  setIcon(value: string) {
    this._icon = value
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}

  tileClicked($event: any) {
    this.clicked.emit(true)
  }
  mouseoter($event: any) {
    this.surfaceColor = this.uiColorModel.surfaceDim
  }
  mouseleave($event: any) {
    this.surfaceColor = "rgba(0,0,0,0)"
  }
}
