import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'


@Component({
	selector: 		'progress-perc-twoline-card',
	inputs: ['label', 'heading', 'perc', 'indeterminate', 'showleadingicon', 'showcontrol', 'progresscolor',
					'icon', 'iconcolor', 'iconanimated', 'iconrotate', 'iconcss',
					'controlicon', 'controliconcolor'],
	outputs: ['controlclick'],
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
					<div class="_progress-card-two-line" surfaceContainer>
						<div class="content">
							<div *ngIf="_showLeadingIcon==true" class="icon">
								<div class="icon-wrapper {{this._leadingIconCSS}}" style="transform: rotate({{_leadingIconRotate}}deg)">
									<anim-icon [icon]=this._leadingIcon [color]=this._leadingIconColor [animated]=_leadingIconAnimated></anim-icon>
								</div>
							</div>

							<div class="progress-wrapper">
								<div class="heading" onSurface font-family>{{_heading}}</div>
								<div class="label-wrapper">
									<div class="label" onSurface font-family>{{this._label}}</div>
									<div *ngIf="this._percentage>=0" class="progress-perc" onSurface font-family>{{this._percentage}}%</div>
									<div *ngIf="this._percentage==-1" class="progress-perc" onSurface font-family>???</div>
								</div>
								<div class="progress-bar">
									<linear-progress-bar [indeterminate]=this._isIndeterminate [perc]=this._percentage></linear-progress-bar>
								</div>
							</div>
							<div *ngIf="_showControl==true" class="controls-wrapper">
								<div class="icon-wrapper" (click)="controlClicked()">
									<anim-icon [icon]=this._controlIcon [color]=this._controlColor></anim-icon>
								</div>
							</div>
						</div>
					</div>

								`,
})


export class ProgressPercentTwolineCard {
	@Output()
	controlclick = new EventEmitter();

	@Input()
	set label(value: string) { this.setLabel(value); }
	set heading(value: string) { this.setHeading(value); }
	set perc(value: number) { this.setPerc(value); }
	set indeterminate(value: boolean) { this.setIndeterminate(value); }
	set showleadingicon(value: boolean) { this.setShowLeadingIcon(value); }
	set showcontrol(value: boolean) { this.setShowControl(value); }
	set progresscolor(value: string) { this.setProgressColor(value); }
	set icon(value: string) { this.setIcon(value); }
	set iconcolor(value: string) { this.setIconColor(value); }
	set iconanimated(value: boolean) { this.setIconAnimated(value); }
	set iconrotate(value: number) { this.setIconRotate(value); }
	set iconcss(value: string) { this.setIconCSS(value); }


	set controlicon(value: string) { this.setControlIcon(value); }
	set controliconcolor(value: string) { this.setControlIconColor(value); }



	private changeDetectorRef: ChangeDetectorRef;
	private eventsService: EventsService

	_label:							string = ""
	_heading:						string = "&nbsp;"
	_percentage: 				number = 0

	_isIndeterminate:		boolean = false
	_barColor: 							string = "75e900"

	_showLeadingIcon:			boolean = false
	_leadingIconColor:  	string = "000000"
	_leadingIcon: 				string = "cloud-upload-outline"
	_leadingIconAnimated: boolean = true
	_leadingIconRotate:		number = 0
	_leadingIconCSS:			string = ""


	_showControl:				boolean = false
	_controlIcon: 			string = "close-circle-outline"
	_controlColor: 			string = "rgba(0,0,0,0.65)"



	_controlState:			string = "active"		//active, paused, done


	_totalFileSize: 		number = 0;
	_uploaded: 					number = 0;
	_displayUploaded: 	string = "";
	_displayTotal: 			string = "";


	_darkmode:					boolean = true

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService) {
		this.eventsService = _eventsService;
		this.changeDetectorRef = ref;
	}


	setLabel(value: string): void {
		this._label = value
	}

	setHeading(value: string) {
		this._heading = value
	}

	setPerc(value: number): void {
		this._percentage = value
		if(value >= 100) {
			this._percentage = 100
		}
	}

	setIndeterminate(value: boolean) {
		this._isIndeterminate = value;
	}

	setShowLeadingIcon(value: boolean) {
		this._showLeadingIcon = value
	}

	setShowControl(value: boolean) {
		this._showControl = value
	}

	setProgressColor(value: string) {
		this._barColor = value
	}

	setIcon(value: string) {
		this._leadingIcon = value
	}

	setIconColor(value: string) {
		this._leadingIconColor = value
	}

	setIconAnimated(value: boolean) {
		this._leadingIconAnimated = value
	}

	setIconRotate(value: number) {
		this._leadingIconRotate = value
	}

	setIconCSS(value: string) {
		this._leadingIconCSS = value
	}

	setControlIcon(value: string) {
		this._controlIcon = value
	}

	setControlIconColor(value: string) {
		this._controlColor = value
	}



	fileUploadUpdate() {

	}


	controlClicked() {
		this.controlclick.emit(true);
	}

	ngOnInit() {

		this._totalFileSize = 1024000000
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}


	bytesDemo() {
		this._displayUploaded = this.formatBytes(this._uploaded, 2)
		this._displayTotal = this.formatBytes(this._totalFileSize, 2)
		this._uploaded = this._uploaded + 4500
	}


	formatBytes(bytes: number, decimals = 2) {
	    if (bytes === 0) return '0';
	    const k = 1024;
	    const dm = decimals < 0 ? 0 : decimals;
	    const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
	    const i = Math.floor(Math.log(bytes) / Math.log(k));
	    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
}
