
import { UIBrandColor } from '../brand/ui.brand.color.class'

export class UITheme {

    private _colors: UIBrandColor = new UIBrandColor();
    private _uiFont: string = "Roboto";
    private _displayFont: string = "Roboto";

    constructor() {
    }


    setColors(primary: string, secondary: string, tertiary: string, neutral: string, neutralVariant: string) {
        this._colors.primaryColor = primary
        this._colors.secondaryColor = secondary
        this._colors.tertiaryColor = tertiary
        this._colors.neutralColor = neutral
        this._colors.neutralVariantColor = neutralVariant
    }

    toJSON(): any {
        let colorRec = this.colors.toJSON()

        let rec = {
            colors: colorRec,
            uiFont: this._uiFont,
            displayFont: this._displayFont
        }
        return rec
    }

    fromJSON(json: any) {
        this._colors.fromJSON(json["colors"])
        this._uiFont = json["uiFont"]
        this._displayFont = json["displayFont"]
    }

    public get colors(): UIBrandColor {
        return this._colors;
    }
    public set colors(value: UIBrandColor) {
        this._colors = value;
    }
    public get uiFont(): string {
        return this._uiFont;
    }
    public set uiFont(value: string) {
        this._uiFont = value;
    }
    public get displayFont(): string {
        return this._displayFont;
    }
    public set displayFont(value: string) {
        this._displayFont = value;
    }
    


}
    