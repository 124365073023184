import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';



@Component({
	selector: 		'whoweare-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

	<content-container-scrollable>
		<optimised-content-container>

			<div id="scroll-to-top"></div>
			<heading2>About Us</heading2>

			<scroll-top-button [scrollshowvalue]=100 (clicked)=scrollToTop()></scroll-top-button>
			<article-image [imgsrc]="'./assets/spiritans/photos/IMG_20190609_104448.jpg'" [caption]="'Positano at night'"></article-image>
				<paragraph>
					<article>
					2.helping those oppressed and most disadvantaged, as a group or as individuals 

3.helping the Church where it has difficulty finding workers. 

 

The Spiritans are committed to the poor, and we do this by promoting justice for the individual.   

 

Through our work in our individual parishes, schools, aged care facilities, hospitals, and the broader community we: 

 

•are committed to the care of, support for, and evangelisation of all people but particularly the poor and oppressed. 

•provide an opportunity for people of all ethnic groups, walks of life and ages to grow in faith and discipleship.  

•Foster Christian communities and the education and training of a committed and responsible laity. 

•provide vocations, ministry, and training for ministries, missionary, and religious life. 

•engage in social and educational work in line with the Spiritan calling. 

Our Foundation Story
					</article>
				</paragraph>
				
				<heading3>Our Foundation Story</heading3>
				<paragraph>
					<article>
					The Congregation of the Holy Spirit (Spiritans) is an international Apostolic Missionary Order founded in 1703 by Claude Poullart des Places. In 1848 it amalgamated with the Congregation of the Immaculate Heart of Mary, founded by Francis Libermann, to become the Congregation it is today.  The Spiritans have a presence in 60 countries on all continents. The group in Oceania has Spiritan priests from Ghana, Ireland, Kenya, Madagascar, Malawi, Nigeria, Papua New Guinea, Tanzania, Uganda and Vietnam. The Congregation in Australia requires at least sixteen members to carry out its mission effectively.   Currently, the group has ten members.  The Congregation in Papua New Guinea currently has six members and would need three additional members to meet the parishes’ needs fully. 
					</article>
				</paragraph>

				<paragraph>
					<article>
					Preaching the Good News, the practice of evangelical counsels, and life in a fraternal and praying community are the three essential elements that give our apostolic life its basis and unity. 
					</article>
				</paragraph>

				<paragraph>
					<article>
					Preaching the Good News, the practice of evangelical counsels, and life in a fraternal and praying community are the three essential elements that give our apostolic life its basis and unity. 
					</article>
				</paragraph>

			</optimised-content-container>
	</content-container-scrollable>

	`,

})


export class WhoWeAreScreen {


	constructor() {
	}

	
	scrollToTop() {
		let textElement: any = document.getElementById("scroll-to-top")
		textElement.scrollIntoView({behavior: "smooth"})
	}


	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}


}
