import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	HostListener,
	ChangeDetectorRef,
	Input
} from '@angular/core';

import { EventsService } from '../../services/event.service'


@Component({
	selector: 		'row',
	inputs: ['label'],
	encapsulation: ViewEncapsulation.None,
	template:	`
		<div class="row" 
			[style.marginBottom.px]=0>
			<ng-content></ng-content>
		</div>
	`,

})


export class RowComponent {
	@Input()
	set label(value: string) {
	  this.setLabel(value);
	}
  

	private changeDetectorRef: ChangeDetectorRef
    private eventsService: EventsService

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
	}

	setLabel(value: string) {}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
	}


}
