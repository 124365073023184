import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';


@Component({
	selector: 		'saas-toolbar',
	changeDetection: ChangeDetectionStrategy.Default,
	outputs: ['searchvalue', 'clicked'],
	template: 	`
		<div class="_collection-management-toolbar">
			<div class="fill-remaining-space"></div>
			<div class="search-wrapper">
				<search-bar (value)=searchValue($event)></search-bar>
			</div>
			<div class="action-items" onSurface>
				<div class="item icon">
					<icon-button [icon]="'select'" [activeicon]="'select-off'" (clicked)=selectClicked()></icon-button>				
				</div>
			</div>
			<div class="action-button-wrapper" onSurface>
				<fab [icon]="'plus'" (clicked)=actionClicked()></fab>
			</div>
		</div> 

		`,
})


export class SassToolbarComponent {
	@Output()
	clicked = new EventEmitter();
	searchvalue = new EventEmitter();
  
	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel


	_isSelected: boolean = false
	_toolbarState: any = {}

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	
	}

	searchValue($event: any) {
		this.searchvalue.emit($event)
	}

	selectClicked() {
		if(this._isSelected) {
			this._isSelected = false
			this.clicked.emit("select-off")
		} else {
			this._isSelected = true
			this.clicked.emit("select-on")
		}
	}

	actionClicked() {
		this.clicked.emit("action-clicked")
	}

	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
