import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { AppAuthService } from '../app.auth.service';


@Component({
	selector: 		'signup-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

        <content-container-scrollable>

                <mobile-screen-card [width]=340>

                    <div [ngSwitch]=this.appAuthService.activeView>

                        <div *ngSwitchCase="'signin'">
							<signin-screen></signin-screen>
                        </div>

                        <div *ngSwitchCase="'method'">
                            <signup-method></signup-method>
                        </div>
                        

                    </div>
                
</mobile-screen-card>
        </content-container-scrollable>

		
	`,

})


export class SignUpScreen {

    appAuthService: AppAuthService

    constructor(private _appAuthService: AppAuthService) {
        this.appAuthService = _appAuthService
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}


}
