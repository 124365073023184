import { PaletteObject } from './color.palette.object.class';
import { GradientCollection } from './gradient.collection.class';

export class PaletteDataSample {

  constructor() {
  }


  pantone2023() {
    let palette = new PaletteObject("pantone2023")
    palette.setName("Pantone Colors 2023")
    palette.addColor("BE3455", "Viva Magenta")
    palette.addColor("EDCDC2", "Pale Dogwood")
    palette.addColor("E5CCB0", "Gray Sand")
    palette.addColor("D4CACD", "Gray Lilac")
    palette.addColor("BFB092", "Pale Khaki")
    palette.addColor("B7A990", "Fields of Rye")
    palette.addColor("B4B1A1", "Agate Gray")
    palette.addColor("BFCAD6", "Plein Air")
    return palette
  }


  peachMarketingColors() {
    let palette = new PaletteObject("pm_colors")
    palette.setName("Peach Marketing Colors")
    palette.addColor("acd8c6", "#acd8c6")
    palette.addColor("6cbdb8", "#6cbdb8")
    palette.addColor("f4c266", "#f4c266")
    palette.addColor("f17e27", "#f17e27")
    palette.addColor("345c72", "#345c72")
    palette.addColor("d4edf4", "#d4edf4")

    return palette
  }


  materialColors(): PaletteObject {
    let palette = new PaletteObject("material_design_colors")
    palette.setName("Material Design Colors")

    palette.addColor("FFEBEE", "Red 50")
    palette.addColor("FFCDD2", "Red 100")
    palette.addColor("EF9A9A", "Red 200")
    palette.addColor("E57373", "Red 300")
    palette.addColor("EF5350", "Red 400")
    palette.addColor("F44336", "Red 500")
    palette.addColor("E53935", "Red 600")
    palette.addColor("D32F2F", "Red 700")
    palette.addColor("C62828", "Red 800")
    palette.addColor("B71C1C", "Red 900")
    palette.addColor("FF8A80", "Red A100")
    palette.addColor("FF5252", "Red A200")
    palette.addColor("FF1744", "Red A400")
    palette.addColor("D50000", "Red A700")
    palette.addColor("FCE4EC", "Pink 50")
    palette.addColor("F8BBD0", "Pink 100")
    palette.addColor("F48FB1", "Pink 200")
    palette.addColor("F06292", "Pink 300")
    palette.addColor("EC407A", "Pink 400")
    palette.addColor("E91E63", "Pink 500")
    palette.addColor("D81B60", "Pink 600")
    palette.addColor("C2185B", "Pink 700")
    palette.addColor("AD1457", "Pink 800")
    palette.addColor("880E4F", "Pink 900")
    palette.addColor("FF80AB", "Pink A100")
    palette.addColor("FF4081", "Pink A200")
    palette.addColor("F50057", "Pink A400")
    palette.addColor("C51162", "Pink A700")
    palette.addColor("F3E5F5", "Purple 50")
    palette.addColor("E1BEE7", "Purple 100")
    palette.addColor("CE93D8", "Purple 200")
    palette.addColor("BA68C8", "Purple 300")
    palette.addColor("AB47BC", "Purple 400")
    palette.addColor("9C27B0", "Purple 500")
    palette.addColor("8E24AA", "Purple 600")
    palette.addColor("7B1FA2", "Purple 700")
    palette.addColor("6A1B9A", "Purple 800")
    palette.addColor("4A148C", "Purple 900")
    palette.addColor("EA80FC", "Purple A100")
    palette.addColor("E040FB", "Purple A200")
    palette.addColor("D500F9", "Purple A400")
    palette.addColor("AA00FF", "Purple A700")
    palette.addColor("EDE7F6", "Deep Purple 50")
    palette.addColor("D1C4E9", "Deep Purple 100")
    palette.addColor("B39DDB", "Deep Purple 200")
    palette.addColor("9575CD", "Deep Purple 300")
    palette.addColor("7E57C2", "Deep Purple 400")
    palette.addColor("673AB7", "Deep Purple 500")
    palette.addColor("5E35B1", "Deep Purple 600")
    palette.addColor("512DA8", "Deep Purple 700")
    palette.addColor("4527A0", "Deep Purple 800")
    palette.addColor("311B92", "Deep Purple 900")
    palette.addColor("B388FF", "Deep Purple A100")
    palette.addColor("7C4DFF", "Deep Purple A200")
    palette.addColor("651FFF", "Deep Purple A400")
    palette.addColor("6200EA", "Deep Purple A700")
    palette.addColor("E8EAF6", "Indigo 50")
    palette.addColor("C5CAE9", "Indigo 100")
    palette.addColor("9FA8DA", "Indigo 200")
    palette.addColor("7986CB", "Indigo 300")
    palette.addColor("5C6BC0", "Indigo 400")
    palette.addColor("3F51B5", "Indigo 500")
    palette.addColor("3949AB", "Indigo 600")
    palette.addColor("303F9F", "Indigo 700")
    palette.addColor("283593", "Indigo 800")
    palette.addColor("1A237E", "Indigo 900")
    palette.addColor("8C9EFF", "Indigo A100")
    palette.addColor("536DFE", "Indigo A200")
    palette.addColor("3D5AFE", "Indigo A400")
    palette.addColor("304FFE", "Indigo A700")
    palette.addColor("E3F2FD", "Blue 50")
    palette.addColor("BBDEFB", "Blue 100")
    palette.addColor("90CAF9", "Blue 200")
    palette.addColor("64B5F6", "Blue 300")
    palette.addColor("42A5F5", "Blue 400")
    palette.addColor("2196F3", "Blue 500")
    palette.addColor("1E88E5", "Blue 600")
    palette.addColor("1976D2", "Blue 700")
    palette.addColor("1565C0", "Blue 800")
    palette.addColor("0D47A1", "Blue 900")
    palette.addColor("82B1FF", "Blue A100")
    palette.addColor("448AFF", "Blue A200")
    palette.addColor("2979FF", "Blue A400")
    palette.addColor("2962FF", "Blue A700")
    palette.addColor("E1F5FE", "Light Blue 50")
    palette.addColor("B3E5FC", "Light Blue 100")
    palette.addColor("81D4FA", "Light Blue 200")
    palette.addColor("4FC3F7", "Light Blue 300")
    palette.addColor("29B6F6", "Light Blue 400")
    palette.addColor("03A9F4", "Light Blue 500")
    palette.addColor("039BE5", "Light Blue 600")
    palette.addColor("0288D1", "Light Blue 700")
    palette.addColor("0277BD", "Light Blue 800")
    palette.addColor("01579B", "Light Blue 900")
    palette.addColor("80D8FF", "Light Blue A100")
    palette.addColor("40C4FF", "Light Blue A200")
    palette.addColor("00B0FF", "Light Blue A400")
    palette.addColor("0091EA", "Light Blue A700")
    palette.addColor("E0F7FA", "Cyan 50")
    palette.addColor("B2EBF2", "Cyan 100")
    palette.addColor("80DEEA", "Cyan 200")
    palette.addColor("4DD0E1", "Cyan 300")
    palette.addColor("26C6DA", "Cyan 400")
    palette.addColor("00BCD4", "Cyan 500")
    palette.addColor("00ACC1", "Cyan 600")
    palette.addColor("0097A7", "Cyan 700")
    palette.addColor("00838F", "Cyan 800")
    palette.addColor("006064", "Cyan 900")
    palette.addColor("84FFFF", "Cyan A100")
    palette.addColor("18FFFF", "Cyan A200")
    palette.addColor("00E5FF", "Cyan A400")
    palette.addColor("00B8D4", "Cyan A700")
    palette.addColor("E0F2F1", "Teal 50")
    palette.addColor("B2DFDB", "Teal 100")
    palette.addColor("80CBC4", "Teal 200")
    palette.addColor("4DB6AC", "Teal 300")
    palette.addColor("26A69A", "Teal 400")
    palette.addColor("009688", "Teal 500")
    palette.addColor("00897B", "Teal 600")
    palette.addColor("00796B", "Teal 700")
    palette.addColor("00695C", "Teal 800")
    palette.addColor("004D40", "Teal 900")
    palette.addColor("A7FFEB", "Teal A100")
    palette.addColor("64FFDA", "Teal A200")
    palette.addColor("1DE9B6", "Teal A400")
    palette.addColor("00BFA5", "Teal A700")
    palette.addColor("E8F5E9", "Green 50")
    palette.addColor("C8E6C9", "Green 100")
    palette.addColor("A5D6A7", "Green 200")
    palette.addColor("81C784", "Green 300")
    palette.addColor("66BB6A", "Green 400")
    palette.addColor("4CAF50", "Green 500")
    palette.addColor("43A047", "Green 600")
    palette.addColor("388E3C", "Green 700")
    palette.addColor("2E7D32", "Green 800")
    palette.addColor("1B5E20", "Green 900")
    palette.addColor("B9F6CA", "Green A100")
    palette.addColor("69F0AE", "Green A200")
    palette.addColor("00E676", "Green A400")
    palette.addColor("00C853", "Green A700")
    palette.addColor("F1F8E9", "Light Green 50")
    palette.addColor("DCEDC8", "Light Green 100")
    palette.addColor("C5E1A5", "Light Green 200")
    palette.addColor("AED581", "Light Green 300")
    palette.addColor("9CCC65", "Light Green 400")
    palette.addColor("8BC34A", "Light Green 500")
    palette.addColor("7CB342", "Light Green 600")
    palette.addColor("689F38", "Light Green 700")
    palette.addColor("558B2F", "Light Green 800")
    palette.addColor("33691E", "Light Green 900")
    palette.addColor("CCFF90", "Light Green A100")
    palette.addColor("B2FF59", "Light Green A200")
    palette.addColor("76FF03", "Light Green A400")
    palette.addColor("64DD17", "Light Green A700")
    palette.addColor("F9FBE7", "Lime 50")
    palette.addColor("F0F4C3", "Lime 100")
    palette.addColor("E6EE9C", "Lime 200")
    palette.addColor("DCE775", "Lime 300")
    palette.addColor("D4E157", "Lime 400")
    palette.addColor("CDDC39", "Lime 500")
    palette.addColor("C0CA33", "Lime 600")
    palette.addColor("AFB42B", "Lime 700")
    palette.addColor("9E9D24", "Lime 800")
    palette.addColor("827717", "Lime 900")
    palette.addColor("F4FF81", "Lime A100")
    palette.addColor("EEFF41", "Lime A200")
    palette.addColor("C6FF00", "Lime A400")
    palette.addColor("AEEA00", "Lime A700")
    palette.addColor("FFFDE7", "Yellow 50")
    palette.addColor("FFF9C4", "Yellow 100")
    palette.addColor("FFF59D", "Yellow 200")
    palette.addColor("FFF176", "Yellow 300")
    palette.addColor("FFEE58", "Yellow 400")
    palette.addColor("FFEB3B", "Yellow 500")
    palette.addColor("FDD835", "Yellow 600")
    palette.addColor("FBC02D", "Yellow 700")
    palette.addColor("F9A825", "Yellow 800")
    palette.addColor("F57F17", "Yellow 900")
    palette.addColor("FFFF8D", "Yellow A100")
    palette.addColor("FFFF00", "Yellow A200")
    palette.addColor("FFEA00", "Yellow A400")
    palette.addColor("FFD600", "Yellow A700")
    palette.addColor("FFF8E1", "Amber 50")
    palette.addColor("FFECB3", "Amber 100")
    palette.addColor("FFE082", "Amber 200")
    palette.addColor("FFD54F", "Amber 300")
    palette.addColor("FFCA28", "Amber 400")
    palette.addColor("FFC107", "Amber 500")
    palette.addColor("FFB300", "Amber 600")
    palette.addColor("FFA000", "Amber 700")
    palette.addColor("FF8F00", "Amber 800")
    palette.addColor("FF6F00", "Amber 900")
    palette.addColor("FFE57F", "Amber A100")
    palette.addColor("FFD740", "Amber A200")
    palette.addColor("FFC400", "Amber A400")
    palette.addColor("FFAB00", "Amber A700")
    palette.addColor("FFF3E0", "Orange 50")
    palette.addColor("FFE0B2", "Orange 100")
    palette.addColor("FFCC80", "Orange 200")
    palette.addColor("FFB74D", "Orange 300")
    palette.addColor("FFA726", "Orange 400")
    palette.addColor("FF9800", "Orange 500")
    palette.addColor("FB8C00", "Orange 600")
    palette.addColor("F57C00", "Orange 700")
    palette.addColor("EF6C00", "Orange 800")
    palette.addColor("E65100", "Orange 900")
    palette.addColor("FFD180", "Orange A100")
    palette.addColor("FFAB40", "Orange A200")
    palette.addColor("FF9100", "Orange A400")
    palette.addColor("FF6D00", "Orange A700")
    palette.addColor("FBE9E7", "Deep Orange 50")
    palette.addColor("FFCCBC", "Deep Orange 100")
    palette.addColor("FFAB91", "Deep Orange 200")
    palette.addColor("FF8A65", "Deep Orange 300")
    palette.addColor("FF7043", "Deep Orange 400")
    palette.addColor("FF5722", "Deep Orange 500")
    palette.addColor("F4511E", "Deep Orange 600")
    palette.addColor("E64A19", "Deep Orange 700")
    palette.addColor("D84315", "Deep Orange 800")
    palette.addColor("BF360C", "Deep Orange 900")
    palette.addColor("FF9E80", "Deep Orange A100")
    palette.addColor("FF6E40", "Deep Orange A200")
    palette.addColor("FF3D00", "Deep Orange A400")
    palette.addColor("DD2C00", "Deep Orange A700")
    palette.addColor("EFEBE9", "Brown 50")
    palette.addColor("D7CCC8", "Brown 100")
    palette.addColor("BCAAA4", "Brown 200")
    palette.addColor("A1887F", "Brown 300")
    palette.addColor("8D6E63", "Brown 400")
    palette.addColor("795548", "Brown 500")
    palette.addColor("6D4C41", "Brown 600")
    palette.addColor("5D4037", "Brown 700")
    palette.addColor("4E342E", "Brown 800")
    palette.addColor("3E2723", "Brown 900")
    palette.addColor("FAFAFA", "Grey 50")
    palette.addColor("F5F5F5", "Grey 100")
    palette.addColor("EEEEEE", "Grey 200")
    palette.addColor("E0E0E0", "Grey 300")
    palette.addColor("BDBDBD", "Grey 400")
    palette.addColor("9E9E9E", "Grey 500")
    palette.addColor("757575", "Grey 600")
    palette.addColor("616161", "Grey 700")
    palette.addColor("424242", "Grey 800")
    palette.addColor("212121", "Grey 900")
    palette.addColor("ECEFF1", "Blue Grey 50")
    palette.addColor("CFD8DC", "Blue Grey 100")
    palette.addColor("B0BEC5", "Blue Grey 200")
    palette.addColor("90A4AE", "Blue Grey 300")
    palette.addColor("78909C", "Blue Grey 400")
    palette.addColor("607D8B", "Blue Grey 500")
    palette.addColor("546E7A", "Blue Grey 600")
    palette.addColor("455A64", "Blue Grey 700")
    palette.addColor("37474F", "Blue Grey 800")
    palette.addColor("263238", "Blue Grey 900")
    palette.addColor("000000", "Black")
    palette.addColor("FFFFFF", "White")
    return palette
  }

  flatColors() {
    let palette = new PaletteObject("flat_ui_colors")
    palette.setName("Flat UI Colors")
    palette.addColor("1ABC9C", "Turquoise")
    palette.addColor("2ECC71", "Emerald")
    palette.addColor("3498DB", "Peter River")
    palette.addColor("9B59B6", "Amethyst")
    palette.addColor("34495E", "Wet Ashphalt")
    palette.addColor("16A085", "Green Sea")
    palette.addColor("27AE60", "Nephritis")
    palette.addColor("2980B9", "Belize Hole")
    palette.addColor("2C3E50", "Midnight Blue")
    palette.addColor("F1C40F", "Sunflower")
    palette.addColor("F1C40F", "Sunflower")
    palette.addColor("E67E22", "Carrot")
    palette.addColor("E74C3C", "Alizarin")
    palette.addColor("ECF0F1", "Clouds")
    palette.addColor("95A5A6", "Concrete")
    palette.addColor("F39C12", "Orange")
    palette.addColor("D35400", "Pumpkin")
    palette.addColor("C0392B", "Pomegranate")
    palette.addColor("BDC3C7", "Silver")
    palette.addColor("7F8C8D", "Asbestos")
    return palette
  }


  gradients(): GradientCollection {
    let collection = new GradientCollection("Common")
    collection.addGradient("Alive", "-webkit-linear-gradient(90deg, #BD3F32 0%, #CB356B 100%)");
    collection.addGradient("Amethyst", "-webkit-linear-gradient(270deg, #9D50BB 0%, #6E48AA 100%)");
    collection.addGradient("Aqualicious", "-webkit-linear-gradient(270deg, #50C9C3 0%, #96DEDA 100%)");
    collection.addGradient("Aqua Splash", "-webkit-linear-gradient(15deg, #13547a 0%, #80d0c7 100%)");
    collection.addGradient("Aqua Zone", "-webkit-linear-gradient(-50deg, #007adf 40%, #00ecbc 100%)");
    collection.addGradient("Ash", "-webkit-linear-gradient(90deg, #606c88 0%, #3f4c6b 100%)");
    collection.addGradient("Atlas", "-webkit-linear-gradient(270deg, #4BC0C8 0%, #C779D0 50%, #FEAC5E 100%)");
    collection.addGradient("Aubergine", "-webkit-linear-gradient(90deg, #AA076B 0%, #61045F 100%)");
    collection.addGradient("Azure Pop", "-webkit-linear-gradient(270deg, #89fffd 0%, #ef32d9 100%)");
    collection.addGradient("Back To Earth", "-webkit-linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%)");
    collection.addGradient("Beachy", "-webkit-linear-gradient(120deg, #7b54ed 5%, #45cebc 50%, #fddc8a 90%)");
    collection.addGradient("Between the Clouds", "-webkit-linear-gradient(90deg, #73C8A9 0%, #373B44 100%)");
    collection.addGradient("Between Night and Day", "-webkit-linear-gradient(90deg, #2c3e50 0%, #3498db 100%)");
    collection.addGradient("Bloody Mary", "-webkit-linear-gradient(270deg, #FF512F 0%, #DD2476 100%)");
    collection.addGradient("Blush", "-webkit-linear-gradient(270deg, #B24592 0%, #F15F79 100%)");
    collection.addGradient("Bora Bora", "-webkit-linear-gradient(270deg, #2BC0E4 0%, #EAECC6 100%)");
    collection.addGradient("Calm Dayra", "-webkit-linear-gradient(270deg, #5f2c82 0%, #49a09d 100%)");
    collection.addGradient("Cherry", "-webkit-linear-gradient(270deg, #EB3349 0%, #F45C43 100%)");
    collection.addGradient("Cherry Blossoms", "-webkit-linear-gradient(100deg, #FBD3E9 0%, #BB377D 100%)");
    collection.addGradient("Crystal River", "-webkit-linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%)");
    collection.addGradient("Clear Sky", "-webkit-linear-gradient(270deg, #005C97 0%, #363795 100%)");
    collection.addGradient("Clean Mirror", "-webkit-linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%)");
    collection.addGradient("Clouds", "-webkit-linear-gradient(270deg, #ECE9E6 0%, #FFFFFF 100%)");
    collection.addGradient("Cochiti Lake", "-webkit-linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%)");
    collection.addGradient("Cold Evening", "-webkit-linear-gradient(0deg, #0c3483 0%, #a2b6df 100%, #6b8cce 100%, #a2b6df 100%)");
    collection.addGradient("Color of Sky", "-webkit-linear-gradient(270deg, #E0EAFC 0%, #CFDEF3 100%)");
    collection.addGradient("Cosmic Fusion", "-webkit-linear-gradient(90deg, #333399 0%, #ff00cc 100%)");
    collection.addGradient("Crazy Orange", "-webkit-linear-gradient(270deg, #D38312 0%, #A83279 100%)");
    collection.addGradient("Crystal River", "-webkit-linear-gradient(-30deg, #22E1FF 0%, #1D8FE1 20%, #625EB1 100%)");
    collection.addGradient("Dance To Forget", "-webkit-linear-gradient(270deg, #FF4E50 0%, #F9D423 100%)");
    collection.addGradient("Day Tripper", "-webkit-linear-gradient(270deg, #f857a6 0%, #ff5858 100%)");
    collection.addGradient("Decent", "-webkit-linear-gradient(270deg, #4CA1AF 0%, #C4E0E5 100%)");
    collection.addGradient("Deep Relief", "-webkit-linear-gradient(-225deg, #7085B6 0%, #87A7D9 50%, #DEF3F8 100%)");
    collection.addGradient("Deep Space", "-webkit-linear-gradient(270deg, #000000 0%, #434343 100%)");
    collection.addGradient("Dense Water", "-webkit-linear-gradient(90deg, #3ab5b0 0%, #3d99be 31%, #56317a 100%)");
    collection.addGradient("Digital Lagoon", "-webkit-linear-gradient(110deg, #396afc 40%, #221144 100%)");
    collection.addGradient("Digital Water", "-webkit-linear-gradient(30deg, #ACB6E5 0%, #74ebd5 100%)");
    collection.addGradient("Dirty Fog", "-webkit-linear-gradient(270deg, #B993D6 0%, #8CA6DB 100%)");
    collection.addGradient("Earthly", "-webkit-linear-gradient(270deg, #649173 0%, #DBD5A4 100%)");
    collection.addGradient("Electric Violet", "-webkit-linear-gradient(270deg, #4776E6 0%, #8E54E9 100%)");
    collection.addGradient("Emerald Water", "-webkit-linear-gradient(270deg, #348F50 0%, #56B4D3 100%)");
    collection.addGradient("Endless River", "-webkit-linear-gradient(270deg, #43cea2 0%, #185a9d 100%)");
    collection.addGradient("Evening Night", "-webkit-linear-gradient(90deg, #FFFDE4 0%, #005AA7 100%)");
    collection.addGradient("Fabled Sunset", "-webkit-linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 67%, #FFF800 100%)");
    collection.addGradient("Frost", "-webkit-linear-gradient(90deg, #004e92 0%, #000428 100%)");
    collection.addGradient("Fly High", "-webkit-linear-gradient(-30deg, #48c6ef 0%, #6f86d6 100%)");
    collection.addGradient("Frozen Berry", "-webkit-linear-gradient(0deg, #e8198b 0%, #c7eafd 100%)");
    collection.addGradient("Frozen Heat", "-webkit-linear-gradient(-225deg, #FF057C 0%, #7C64D5 48%, #4CC3FF 100%)");
    collection.addGradient("Glass of Water", "-webkit-linear-gradient(-30deg, #dfe9f3 0%, #FFFFFF 100%)");
    collection.addGradient("Green Beach", "-webkit-linear-gradient(270deg, #02AAB0 0%, #00CDAC 100%)");
    collection.addGradient("Haikus", "-webkit-linear-gradient(270deg, #fd746c 0%, #ff9068 100%)");
    collection.addGradient("Heavy Rain", "-webkit-linear-gradient(0deg, #cfd9df 0%, #e2ebf0 100%)");
    collection.addGradient("High Flyer", "-webkit-linear-gradient(-30deg, #0acffe 0%, #495aff 100%)");
    collection.addGradient("Hip to Pink", "-webkit-linear-gradient(270deg, #00bdb3 0%, #f857a6 50%, #FF4C8D 100%)");
    collection.addGradient("Ibiza Sunset", "-webkit-linear-gradient(270deg, #ee0979 0%, #ff6a00 100%)");
    collection.addGradient("Iced Tropicana", "-webkit-linear-gradient(270deg, #c6ffdd 0%, #fbd786 50%, #f7797d 100%)");
    collection.addGradient("Intuitive Purple", "-webkit-linear-gradient(270deg, #DA22FF 0%, #9733EE 100%)");
    collection.addGradient("Jonquil", "-webkit-linear-gradient(270deg, #FFEEEE 0%, #DDEFBB 100%)");
    collection.addGradient("Juicy Orange", "-webkit-linear-gradient(270deg, #FF8008 0%, #FFC837 100%)");
    collection.addGradient("Jungle Day", "-webkit-linear-gradient(-30deg, #8baaaa 0%, #ae8b9c 100%)");
    collection.addGradient("Jupiter Skies", "-webkit-linear-gradient(-225deg, #69EACB 0%, #EACCF8 48%, #6654F1 100%)");
    collection.addGradient("Kashmir", "-webkit-linear-gradient(270deg, #614385 0%, #516395 100%)");
    collection.addGradient("Koko Caramel", "-webkit-linear-gradient(270deg, #D1913C 0%, #FFD194 100%)");
    collection.addGradient("Kimboy New Blue", "-webkit-linear-gradient(-30deg, #396afc 0%, #2948ff 100%)");
    collection.addGradient("Landing Aircraft", "-webkit-linear-gradient(-225deg, #5D9FFF 0%, #B8DCFF 48%, #6BBBFF 100%)");
    collection.addGradient("Lawrencium", "-webkit-linear-gradient(90deg, #24243e 0%, #302b63 50%, #0f0c29 100%)");
    collection.addGradient("Lemon Skyline", "-webkit-linear-gradient(90deg, #ffff1c 0%, #00c3ff 100%)");
    collection.addGradient("Lemon Twist", "-webkit-linear-gradient(90deg, #3CA55C 0%, #B5AC49 100%)");
    collection.addGradient("Limade", "-webkit-linear-gradient(90deg, #FAFFD1 0%, #A1FFCE 100%)");
    collection.addGradient("Martini", "-webkit-linear-gradient(270deg, #24FE41 0%, #FDFC47 100%)");
    collection.addGradient("Metallic Toad", "-webkit-linear-gradient(270deg, #abbaab 0%, #ffffff 100%)");
    collection.addGradient("Midnight Bloom", "-webkit-linear-gradient(-30deg, #2b5876 0%, #4e4376 100%)");
    collection.addGradient("Mojito", "-webkit-linear-gradient(270deg, #1D976C 0%, #93F9B9 100%)");
    collection.addGradient("Moor", "-webkit-linear-gradient(270deg, #616161 0%, #9bc5c3 100%)");
    collection.addGradient("Neon Life", "-webkit-linear-gradient(270deg, #B3FFAB 0%, #12FFF7 100%)");
    collection.addGradient("Near Moon", "-webkit-linear-gradient(0deg, #5ee7df 0%, #b490ca 100%)");
    collection.addGradient("Near Space", "-webkit-linear-gradient(0deg, #002690 0%, #0B0E12 50%, #0C0F0F 100%)");
    collection.addGradient("New York", "-webkit-linear-gradient(0deg, #fff1eb 0%, #ace0f9 100%)");
    collection.addGradient("Night Call", "-webkit-linear-gradient(-225deg, #AC32E4 0%, #7918F2 48%, #4801FF 100%)");
    collection.addGradient("Nighthawk", "-webkit-linear-gradient(270deg, #2980b9 0%, #2c3e50 100%)");
    collection.addGradient("Night Sky", "-webkit-linear-gradient(-10deg, #1e3c72 0%, #1e3c72 1%, #2a5298 100%)");
    collection.addGradient("Passion", "-webkit-linear-gradient(270deg, #e53935 0%, #e35d5b 100%)");
    collection.addGradient("Peach", "-webkit-linear-gradient(270deg, #ED4264 0%, #FFEDBC 100%)");
    collection.addGradient("Pinot Noir", "-webkit-linear-gradient(270deg, #4b6cb7 0%, #182848 100%)");
    collection.addGradient("Pink Tequila", "-webkit-linear-gradient(-230deg, #f7ff00 0%, #ee506a 40%)");
    collection.addGradient("Playing with Reds", "-webkit-linear-gradient(90deg, #d31027 0%, #ea384d 100%)");
    collection.addGradient("Plum Bath", "-webkit-linear-gradient(0deg, #cc208e 0%, #6713d2 100%)");
    collection.addGradient("Poncho", "-webkit-linear-gradient(270deg, #403A3E 0%, #BE5869 100%)");
    collection.addGradient("Portrait", "-webkit-linear-gradient(90deg, #eef2f3 0%, #8e9eab 100%)");
    collection.addGradient("Purple Bliss", "-webkit-linear-gradient(270deg, #360033 0%, #0b8793 100%)");
    collection.addGradient("Purple Sky", "-webkit-linear-gradient(-30deg, #ED65B8 0%, #6C40B2 100%)");
    collection.addGradient("Rainbow Blue", "-webkit-linear-gradient(30deg, #0575E6 0%, #00F260 100%)");
    collection.addGradient("Rare Wind", "-webkit-linear-gradient(0deg, #a8edea 0%, #fed6e3 100%)");
    collection.addGradient("Reef", "-webkit-linear-gradient(270deg, #00d2ff 0%, #3a7bd5 100%)");
    collection.addGradient("Red Skyline", "-webkit-linear-gradient(0deg, #dbdcd7 0%, #dddcd7 24%, #e2c9cc 30%, #e7627d 46%, #b8235a 59%, #801357 71%, #3d1635 84%, #1c1a27 100%)");
    collection.addGradient("Royal", "-webkit-linear-gradient(90deg, #243B55 0%, #141E30 100%)");
    collection.addGradient("Salt Mountain", "-webkit-linear-gradient(-60deg, #FFFEFF 50%, #D7FFFE 100%)");
    collection.addGradient("Sea Blitz", "-webkit-linear-gradient(270deg, #1CD8D2 0%, #93EDC7 100%)");
    collection.addGradient("Sea Strike", "-webkit-linear-gradient(-225deg, #77FFD2 0%, #6297DB 48%, #1EECFF 100%)");
    collection.addGradient("Sea Weed", "-webkit-linear-gradient(270deg, #4CB8C4 0%, #3CD3AD 100%)");
    collection.addGradient("servquik", "-webkit-linear-gradient(270deg, #485563 0%, #29323c 100%)");
    collection.addGradient("Shadow Night", "-webkit-linear-gradient(270deg, #000000 0%, #53346D 100%)");
    collection.addGradient("Sharp Blue", "-webkit-linear-gradient(0deg, #00c6fb 0%, #005bea 100%)");
    collection.addGradient("Shore", "-webkit-linear-gradient(270deg, #70e1f5 0%, #ffd194 100%)");
    collection.addGradient("Sky Gliding", "-webkit-linear-gradient(0deg, #88d3ce 0%, #6e45e2 100%)");
    collection.addGradient("Skyline", "-webkit-linear-gradient(90deg, #1488cc 0%, #2b32b2 100%)");
    collection.addGradient("Sirius Tamed", "-webkit-linear-gradient(270deg, #EFEFBB 0%, #D4D3DD 100%)");
    collection.addGradient("Shrimpy", "-webkit-linear-gradient(270deg, #e43a15 0%, #e65245 100%)");
    collection.addGradient("Slight Ocean View", "-webkit-linear-gradient(90deg, #3f2b96 0%, #a8c0ff 100%)");
    collection.addGradient("Space Shift", "-webkit-linear-gradient(60deg, #3d3393 0%, #2b76b9 37%, #2cacd1 65%, #35eb93 100%)");
    collection.addGradient("Starfall", "-webkit-linear-gradient(270deg, #F0C27B 0%, #4B1248 100%)");
    collection.addGradient("Steel Grey", "-webkit-linear-gradient(270deg, #1F1C2C 0%, #928DAB 100%)");
    collection.addGradient("Sugar Lollipop", "-webkit-linear-gradient(-225deg, #A445B2 0%, #D41872 52%, #FF0066 100%)");
    collection.addGradient("Sunrise", "-webkit-linear-gradient(270deg, #FF512F 0%, #F09819 100%)");
    collection.addGradient("Sweet Morning", "-webkit-linear-gradient(270deg, #FF5F6D 0%, #FFC371 100%)");
    collection.addGradient("Sweet Sunset", "-webkit-linear-gradient(0deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%)");
    collection.addGradient("Timber", "-webkit-linear-gradient(270deg, #fc00ff 0%, #00dbde 100%)");
    collection.addGradient("The Blue Lagoon", "-webkit-linear-gradient(270deg, #191654 0%, #43C6AC 100%)");
    collection.addGradient("Titanium", "-webkit-linear-gradient(270deg, #283048 0%, #859398 100%)");
    collection.addGradient("Tranquil", "-webkit-linear-gradient(270deg, #EECDA3 0%, #EF629F 100%)");
    collection.addGradient("Turquoise Flow", "-webkit-linear-gradient(270deg, #136a8a 0%, #267871 100%)");
    collection.addGradient("Venice Blue", "-webkit-linear-gradient(270deg, #085078 0%, #85D8CE 100%)");
    collection.addGradient("Vicious Stance", "-webkit-linear-gradient(-30deg, #29323c 0%, #485563 100%)");
    collection.addGradient("Virgin", "-webkit-linear-gradient(270deg, #C9FFBF 0%, #FFAFBD 100%)");
    collection.addGradient("Virgin America", "-webkit-linear-gradient(270deg, #7b4397 0%, #dc2430 100%)");
    collection.addGradient("Vivid Sublime", "-webkit-linear-gradient(30deg, #3F5EFB 0%, #FC466B 100%)");
    collection.addGradient("Gentle Pinks", "-webkit-linear-gradient(0deg, #cbcaea 50%, #fab3d6 100%)");
    collection.addGradient("Stained Paper", "-webkit-linear-gradient(30deg, #FFFFFF 70%, #EECDA3 100%)");
    return collection
  }

}
