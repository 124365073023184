import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
import { DomainOpeningHoursService } from './opening.hours.service';

@NgModule({
  declarations: [
  ],

  imports: [
    CommonModule
  ],

  providers: [
    DomainOpeningHoursService
  ],

  exports: [
  ]
})
export class DomainProtocolsModule { }
