import { TravelServicePoint } from './travel.service.point.class'


export class Airport extends TravelServicePoint {

    private _iata: string = ""
    private _icao: string = ""


    constructor(id: string, iata: string, name: string) {
        super(id, "airport")
        this._iata = iata
        this.name = name
    }

    public toJSON(): any {
        let location = this.location.toJSON()
        let json = {
            id: this.id,
            name: this.name,
            serviceType: this.serviceType,
            iata: this.iata,
            icao: this.icao,
            location: location
        }
        return json
    }
    
    public fromJSON(json: any): Airport {
        let id = json["id"]
        let iata = json["iata"]
        let icao = json["icao"]
        let name = json["name"]

        let airport = new Airport(id, iata, name)
        airport.iata = iata
        airport.icao = icao
        airport.name = json["name"]
        airport.serviceType = json["serviceType"]
        airport.location.fromJSON(json["location"])
        return airport
    }
    

    public get iata(): string {
        return this._iata
    }
    public set iata(value: string) {
        this._iata = value
    }
    public get icao(): string {
        return this._icao
    }
    public set icao(value: string) {
        this._icao = value
    }

}
  