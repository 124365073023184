import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'avatar40',
  inputs: ['imgsrc'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div class="_avatar-40" surfaceContainerHigh>
      <span *ngIf="this._imgsrc.length == 0"><i class="mdi mdi-account-outline" onSurface></i></span>
      <span *ngIf="this._imgsrc.length > 0">
        <img [src]=this._imgsrc>/>
      </span>
    </div>
  `,
})
export class Avatar40Component {

  @Input()
  set imgsrc(value: string) {
    this.setImgSrc(value);
  }

  _imgsrc: string = ""
  changeDetectorRef: ChangeDetectorRef;

  constructor(public ref: ChangeDetectorRef) {
    this.changeDetectorRef = ref;
  }

  setImgSrc(value: string) {
    this._imgsrc = value
  }


  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
