import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";

import { EventsService } from "../../../services/event.service";
import { UIColorModel } from "../../../services/ui.color.model.service";
import { UILayout } from "../../../services/ui.layout.service";

@Component({
  selector: "media-card",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  inputs: ["heading", "subheading", "imgsrc", "mediaheight"],
  template: `
    <transformable-card [width]=360 [marginright]=8>
      <div compact>
          <div class="two-item-list-tile-leading-image wide">
            <div class="image-wrapper">
              <img [src]="_imgsrc" />
            </div>
            <div class="content">
              <div class="top-line" font-family onSurface>{{ _heading }}</div>
              <div class="bottom-line" font-family onSurface>
                {{ _subheading }}
              </div>
            </div>
          </div>
      </div>

      <div medium>
        <div class="_card-media">
          <div class="media" [style.height.px]="_mediaHeight">
            <img [src]="_imgsrc" />
          </div>
          <div class="_card-content">
            <div class="headline" font-family onSurface>{{ _heading }}</div>
            <div class="subheading" font-family onSurface>
              {{ _subheading }}
            </div>
          </div>
          <div class="_card-actions">
            <div class="button">
              <tonal-button
                [label]="'Book Ticket'"
                [noshadow]="true"
              ></tonal-button>
            </div>
            <div class="button">
              <outline-button [label]="'Learn More'"></outline-button>
            </div>
          </div>
        </div>
      </div>
    </transformable-card>

  `,
})
export class MediaCardContainer {
  @Input()
  set heading(value: string) {
    this.setHeading(value);
  }
  set subheading(value: string) {
    this.setSubheading(value);
  }
  set imgsrc(value: string) {
    this.setImgSrc(value);
  }
  set mediaheight(value: number) {
    this.setMediaHeight(value);
  }

  private eventsService: EventsService;
  private changeDetectorRef: ChangeDetectorRef;
  uiColorModel: UIColorModel;
  uiLayout: UILayout;

  _imgsrc: string = "";
  _mediaHeight: number = 1;
  _heading: string = "Book Tickets";
  _subheading: string = "Travel to your destination";
  displayMode: string = "list";
  _tileWidth: number = 0;
  _tileWidthPerc: number = 0;
  _widthStyle: string = "100%";

  constructor(
    public ref: ChangeDetectorRef,
    _eventsService: EventsService,
    private _uiLayout: UILayout,
    private _uiColorModel: UIColorModel
  ) {
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.uiLayout = _uiLayout;
    this.changeDetectorRef = ref;

    let self = this;
    this.eventsService.on("onResize", function (args: any) {
      self.detectMode();
    });
  }

  setHeading(value: string) {
    this._heading = value;
  }
  setSubheading(value: string) {
    this._subheading = value;
  }

  setImgSrc(value: string) {
    this._imgsrc = value;
  }

  setMediaHeight(value: number) {
    this._mediaHeight = value;
  }

  detectMode() {
    if (this.uiLayout.windowClass == "compact") {
      this.displayMode = "list";
      this._tileWidth = 0;
      this._widthStyle = "100%";
    } else {
      this.displayMode = "card";
      this._tileWidth = 360;
      this._widthStyle = "360px";
    }
  }

  ngOnInit() {
    this.detectMode();
  }

  ngOnDestroy() {}

  ngAfterViewInit() {}
}

/*
<li class="grid-element" style="width: {{_widthStyle}}"
    [style.marginRight.px]=16>
    <div class="_card" surfaceContainerLow 
        elevated [elevation]=1>

        <div [ngSwitch]=this.displayMode>

          <div *ngSwitchCase="'list'">
            <div class="two-item-list-tile-leading-image wide">
              <div class="image-wrapper">
                <img [src]=_imgsrc />
              </div>
              <div class="content">
                <div class="top-line" font-family onSurface>{{_heading}}</div>
                <div class="bottom-line" font-family onSurface>{{_subheading}}</div>
              </div>
            </div>
          </div>


          <div *ngSwitchCase="'card'">
            <div class="_card-media">
              <div class="media"
                [style.height.px]=_mediaHeight>
                <img [src]=_imgsrc />
              </div>
              <div class="_card-content">
                <div class="headline" font-family onSurface>{{_heading}}</div>
                <div class="subheading" font-family onSurface>{{_subheading}}</div>
                
              </div>
              <div class="_card-actions">
                <div class="button">
                  <tonal-button
                    [label]="'Book Ticket'"
                    [noshadow]="true"
                  ></tonal-button>
                </div>
                <div class="button">
                  <outline-button [label]="'Learn More'"></outline-button>
                </div>
              </div>
            </div>
          </div>

        </div>
</div>
</li>
*/
