import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { ApplicationService } from '../../../services/application.service';
import { EventsService } from '@coreui/services/event.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';

import { IconCollection } from '@libraries/material-icons/classes/icon.collection.class';

@Component({ 
	selector: 		'icons-page',
	encapsulation: ViewEncapsulation.None,
	template:	`
            <content-container-scrollable>
				
				<appui-page-headung [title]="'Icons'" (searchvalue)=searchTerm($event)></appui-page-headung>

				<div class="row">
					<div class="col s12 m8 offset-m2">
						<search-bar [trailingicon]="'close'" (value)=searchTerm($event)></search-bar>
					</div>
				</div>
				<ul class="grid-collection-list">
				
					<li class="_icon-tile" *ngFor="let dat of this._resultSet">
						<div class="icon-wrapper">
							<i class="mdi mdi-{{dat}}" onSurface></i>
						</div>
						<div class="label" onSurface font-family>{{dat}}</div>
					</li>

				</ul>
			</content-container-scrollable>

	`,

})


export class IconsPage {

	private eventsService: EventsService;
	private changeDetectorRef: ChangeDetectorRef;
	containerHeight: number = 100
	uiColorModel: UIColorModel
	applicationService: ApplicationService

	iconList: any = []
	_searchTerm: string = ""
	_resultSet: any = []

	update_interval: any;
	icons: IconCollection = new IconCollection()

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService, 
			private _applicationService: ApplicationService,
			private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.applicationService = _applicationService
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	}


	searchTerm($event: string) {
		this._searchTerm = $event
		this._searchTerm = this._searchTerm.toLowerCase()
		this._resultSet = this.icons.search(this._searchTerm)
	}




	ngOnInit() { 

	}


	ngOnDestroy() {
		if (this.update_interval) {
			clearInterval(this.update_interval);
		}
	}

	ngAfterViewInit() {
	}
  

}
