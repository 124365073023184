import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,

	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';

import { PaletteDataSample } from '@libraries/color-swatches/classes/palette.data.class';


@Component({
	selector: 		'colors-collection-page',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<content-container-scrollable>
		<saas-toolbar (searchvalue)=search($event) (clicked)=toolbarAction($event)></saas-toolbar>
		<colors-collection-anim [data]=_searchResults></colors-collection-anim>

	</content-container-scrollable>

		`,
})


export class ColorsCollectionPage {
  
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel


	_colorData: any = {}
	_searchResults: any = {}
	

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.changeDetectorRef = ref
	}



	toolbarAction($event: any) {
		//alert($event)
		if($event == "select-on")
			this.eventsService.broadcast("selectModeOn", {})	

		if($event == "select-off")
			this.eventsService.broadcast("selectModeOff", {})	
	}



	search($event: any) {
		console.log($event)
		this._searchResults = this.searchResults($event)
	}



    searchResults(term: string): any {
        let results: any = []
        let i = 0
        for(i=0;i<this._colorData.length;i++) {
			let name = this._colorData[i]["name"].toLowerCase()
            if(name.includes(term.toLowerCase())) {
                results.push(this._colorData[i])
            }
        }
        return results
    }



	ngOnInit() {
		let pp = new PaletteDataSample()
		this._colorData = pp.materialColors().getColors()
		this._searchResults = this._colorData
		this.changeDetectorRef.detectChanges()
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
