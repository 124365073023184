import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	NgZone,
	Input
} from '@angular/core';

import { EventsService } from '@coreui//services/event.service'
import { AbstractCollectionContainerAnimated } from '@libraries/animated-tiles/components/collections/abstract.collection.container.animated';
import { PaletteDataSample } from '@libraries/color-swatches/classes/palette.data.class';


@Component({
	selector: 		'colors-collection-anim',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
	inputs: ['mode'],
	template:	`  
		<ul class="grid-list"  [style.opacity]=this.entryOpacity>

			<abstract-collection-tile [id]=dat.id [width]=150 [height]=168 [radius]=1 [marginright]=16 [marginbottom]=16
				[cssclass]=dat.anim [selectmode]=dat.select_mode [selected]=dat.selected_flag (clicked)=onClick($event)
				*ngFor="let dat of this.items">

				<color-swatch-card [hex]=dat.hex [label]=dat.name></color-swatch-card>
			</abstract-collection-tile>

		</ul>

	`,

})


export class ColorsAnimCollection extends AbstractCollectionContainerAnimated {

	palette: any = []
	_isSelectMode: boolean = false
	_isSelected: boolean = false

	constructor(public ref: ChangeDetectorRef, _eventsService: EventsService) {
		super(ref, _eventsService)
	}


	onClick($event: any) {
		if(this.getMode() == "select") {
			this.selectItem($event)
		}
	}

	override ngOnInit() {
		let pp = new PaletteDataSample()
		this.palette = pp.materialColors()
		this.initData(this.palette["colors"])
		this.changeDetectorRef.detectChanges()
		this.shakeAll()
	}

	override ngOnDestroy() {
	}

	override ngAfterViewInit() {

	}

}
