import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '../../../services/event.service'
import { ColorUtils } from "../../../style/color/utils/color.utils.class"
import { UIColorModel } from '../../../services/ui.color.model.service';

@Component({
  selector: 'tile-secondary-action-56',
  inputs: ['icon'],
  changeDetection: ChangeDetectionStrategy.Default,
 
  template: `
      <div class="list-tile-secondary-action-56">
        <i class="mdi mdi-{{_icon}}" onSurface font-family></i>
      </div>

  `,
})
export class TileSecondaryAction56Component {

  @Input()
  set icon(value: string) {
    this.setIcon(value);
  }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
  private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel

  _icon: string = ""

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	}

  setIcon(value: string) {
    this._icon = value
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}


}
