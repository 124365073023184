import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef
} from '@angular/core';

import { ApplicationService } from '../services/application.service';
import { EventsService } from '@coreui//services/event.service'
import { TypographyModel } from '@coreui/services/typography.service';
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UILayout } from '@coreui//services/ui.layout.service';


@Component({
	selector: 		'spiritans-web-main',
	encapsulation: ViewEncapsulation.None,
	template:	`

		<application-container>

			<div top>
				<appbar-routes></appbar-routes>
			</div>

			<div left-navigation>

			</div>

			<div inner-header>
			</div>

			<div body>
				<main-routes [route]=this.uiNavigation.activeView></main-routes>
			</div>

			<div right-slider-sheet>
				<right-sliders></right-sliders>
			</div>

			<div bottom>
			</div>

		</application-container>

		<!-- Modals -->
		<modal-contaimer></modal-contaimer>
	`,

})


export class Main {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	applicationService: ApplicationService
	uiNavigation: UINavigation
	uiLayout: UILayout
	private typographyModel: TypographyModel

	_route: string = "home"

	constructor(public ref: ChangeDetectorRef,
			private _applicationService: ApplicationService, 
			private _eventsService: EventsService,
			private _uiNavigation: UINavigation,
			private _uiLayout: UILayout,
			private _typographyModel: TypographyModel) {
		this.changeDetectorRef = ref		
		this.applicationService = _applicationService
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.uiLayout = _uiLayout
		this.typographyModel = _typographyModel
		this.uiNavigation.activeView = "home"
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

}


