import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	HostListener,
	ChangeDetectorRef,
	Input
} from '@angular/core';

import { EventsService } from '../../services/event.service'


@Component({
	selector: 		'dashboard-screen-container',
	encapsulation: ViewEncapsulation.None,
	template:	`

		

		<div class="scrollable-div-container" [style.height.px]=this.containerHeight view-height
				scrollEventDetect>
			<ng-content></ng-content>
		</div>
	`,

})


export class DashboardScreenContainer {

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.recalculate()
	}


	private changeDetectorRef: ChangeDetectorRef 
    private eventsService: EventsService
	containerHeight: number | undefined = window.innerHeight
	_scrollTimer: any = {}

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService

		let self = this;
		this.eventsService.on('onRefresh', function (args: any) {
			self.recalculate()
		});		
	}


	recalculate() {
		this.containerHeight = window.innerHeight
		this.changeDetectorRef.detectChanges()
	}

	ngAfterViewInit() {
		this.recalculate()
	}

	ngOnDestroy() {
	}


}
