import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	NgZone,
	Input,
	Output,
	EventEmitter
} from '@angular/core';



@Component({
	selector: 		'horizontal-cards-collection-container',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
	template:	`
		<ul class="_horizontal-tiles-container">
			<ng-content></ng-content>
		</ul>
	`,  
})


export class HorizontalCardCollectionContainer {
	
	constructor() {
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}

}
