import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { UIColorModel } from '../../services/ui.color.model.service';
import { EventsService } from '../../services/event.service'


@Component({
  selector: 'tonal-button',
  inputs: ['label', 'noshadow', 'fullwidth', 'preloading'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div
      class="std-button {{ btnPressEffect }} {{btnWidthCSS}}"
      secondaryContainer
      (click)="btnClicked()"
      [style.borderRadius.px]="btnRadius"
      font-family
	    elevated [elevation]=_elevation >
        <span *ngIf="_preloadingState==false" onSecondaryContainer>{{ this.btnLabel }}</span> 
        <span *ngIf="_preloadingState==true" onSecondaryContainer>
        <div class="preloader-wrapper">
          <div class="preloader-spinner"
            [style.borderTop]=_borderTop
            [style.borderRight]=_borderTop
            [style.borderBottom]=_borderTop></div>
        </div>
      </span>
    </div>
  `,
})
export class TonalButtonComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }
  set noshadow(value: boolean) {
    this.isNoShadow(value);
  }
  set fullwidth(value: boolean) {
    this.setFullWidth(value)
  }
  set preloading(value: boolean) {
    this.setPreloading(value)
  }

  changeDetectorRef: ChangeDetectorRef;
	uiColorModel: UIColorModel
	private eventsService: EventsService

	constructor(public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

  }

  btnLabel: string = '';
  btnPressEffect: string = '';
  btnOpacity: number = 1;
  btnRadius: number = 18;
  _elevation: number = 1
  btnWidthCSS: string = ""
  _preloadingState: boolean = false
  _borderTop: string = "3px solid #000000"

  setLabel(value: string) {
    this.btnLabel = value;
  }

  isNoShadow(value: boolean) {
    if(value == true) {
      this._elevation = 0
    } else {
      this._elevation = 1
    }
  }

  setFullWidth(value: boolean) {
    if(value) {
      this.btnWidthCSS = "fullwidth";
    } else {
      this.btnWidthCSS = "";
    }
  }

  btnClicked() {
    this.btnPressEffect = 'button-press-animation';
    this.clicked.emit(true);
    setTimeout(() => {
      this.btnPressEffect = '';
    }, 250);
  }


  setPreloading(value: boolean) {
    this._preloadingState = value
  }

  ngOnInit() {
    this._borderTop = "3px solid " + this.uiColorModel.primary
  }

  ngOnChanges() {}

  ngOnDestroy() {}
}
