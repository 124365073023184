import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


/////////////////////////////////////////////////////////
// 3rd Party Libraries
////////////////////////////////////////////////////////
import { MarkdownModule } from 'ngx-markdown';


/////////////////////////////////////////////////////////
// Common Libraries
////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module'
import { CoreUIExtensionsModule } from '@libraries/coreui-extensions/coreui.extensions.module';


/////////////////////////////////////////////////////////
// Components
////////////////////////////////////////////////////////
import { BlogPageHeaderComponent } from './components/header/blog.page.header'
import { BlogArticlePageTemplate } from './article/article.page'

import { BlogArticleImageComponent } from './components/images/blog.article.image'

import { TypographyParagraphComponent } from './components/typography/typography.paragraph';
import { TypographyArticleComponent } from './components/typography/typography.article';
import { TypographyHeading1Component } from './components/typography/typography.heading1';
import { TypographyHeading2Component } from './components/typography/typography.heading2';
import { TypographyHeading3Component } from './components/typography/typography.heading3';
import { TypographyHeading4Component } from './components/typography/typography.heading4';

import { HeadingDisplayFontFamilyDirective } from './directives/heading.display.font.family.directive';
import { DisplayFontFamilyDirective } from './directives/display.font.family.directive';


@NgModule({
  declarations: [
    HeadingDisplayFontFamilyDirective,
    DisplayFontFamilyDirective,

    BlogPageHeaderComponent,
    BlogArticlePageTemplate,
    BlogArticleImageComponent,
    TypographyParagraphComponent,
    TypographyArticleComponent,
    TypographyHeading1Component,
    TypographyHeading2Component,
    TypographyHeading3Component,
    TypographyHeading4Component
  ],

  imports: [
    CommonModule,
    CoreUIModule,
    CoreUIExtensionsModule
  ],

  providers: [
        
  ],

  exports: [
    HeadingDisplayFontFamilyDirective,
    DisplayFontFamilyDirective,

    BlogPageHeaderComponent,
    BlogArticlePageTemplate,
    BlogArticleImageComponent,
    TypographyParagraphComponent,
    TypographyArticleComponent,
    TypographyHeading1Component,
    TypographyHeading2Component,
    TypographyHeading3Component,
    TypographyHeading4Component
  ]
})
export class BlogThemeModule { }
