import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { UILayout } from "@coreui/services/ui.layout.service";

@Component({
  selector: "place-rating-card",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  inputs: ["heading", "imgsrc", "mediaheight"],
  template: `
   
        <div class="_place-rating-card" (click)=clicked()>
          <div class="media" [style.height.px]="_mediaHeight"
            elevated [elevation]=_elevation>
            <img [src]="_imgsrc" [draggable]=false/>
          </div>
          <div class="_card-content">
            <div class="_review-ratings">
              <div class="score" onSecondaryContainer surfaceColorDim font-family>4.9</div>              
              <div class="stars" [style.opacity]=0.6>
                <star-review [score]=5 [small]=true [activecolor]=_starColor></star-review>
              </div>

            </div> 
            <div class="title" font-family onSurface>{{ _heading }}</div>
          </div>
          
        </div>

  `,
})
export class PlaceRatingCard {
  @Input()
  set heading(value: string) {
    this.setHeading(value);
  }

  set imgsrc(value: string) {
    this.setImgSrc(value);
  }
  set mediaheight(value: number) {
    this.setMediaHeight(value);
  }

  private eventsService: EventsService;
  private changeDetectorRef: ChangeDetectorRef;
  uiColorModel: UIColorModel;
  uiLayout: UILayout;

  _imgsrc: string = "";
  _mediaHeight: number = 88;
  _starColor: string = ""
  _elevation: number = 2


  _heading: string = "Book Tickets";

  constructor(
    public ref: ChangeDetectorRef,
    _eventsService: EventsService,
    private _uiLayout: UILayout,
    private _uiColorModel: UIColorModel
  ) {
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.uiLayout = _uiLayout;
    this.changeDetectorRef = ref;

    this._starColor = this.uiColorModel.onBackground

    let self = this;
    this.eventsService.on("onResize", function (args: any) {
    });
  }

  setHeading(value: string) {
    this._heading = value;
  }


  setImgSrc(value: string) {
    this._imgsrc = value;
  }

  setMediaHeight(value: number) {
    this._mediaHeight = value;
  }

  clicked() {
   // alert("boom")

  }





  ngOnInit() {
  }

  ngOnDestroy() {}

  ngAfterViewInit() {}
}

/*
<li class="grid-element" style="width: {{_widthStyle}}"
    [style.marginRight.px]=16>
    <div class="_card" surfaceContainerLow 
        elevated [elevation]=1>

        <div [ngSwitch]=this.displayMode>

          <div *ngSwitchCase="'list'">
            <div class="two-item-list-tile-leading-image wide">
              <div class="image-wrapper">
                <img [src]=_imgsrc />
              </div>
              <div class="content">
                <div class="top-line" font-family onSurface>{{_heading}}</div>
                <div class="bottom-line" font-family onSurface>{{_subheading}}</div>
              </div>
            </div>
          </div>


          <div *ngSwitchCase="'card'">
            <div class="_card-media">
              <div class="media"
                [style.height.px]=_mediaHeight>
                <img [src]=_imgsrc />
              </div>
              <div class="_card-content">
                <div class="headline" font-family onSurface>{{_heading}}</div>
                <div class="subheading" font-family onSurface>{{_subheading}}</div>
                
              </div>
              <div class="_card-actions">
                <div class="button">
                  <tonal-button
                    [label]="'Book Ticket'"
                    [noshadow]="true"
                  ></tonal-button>
                </div>
                <div class="button">
                  <outline-button [label]="'Learn More'"></outline-button>
                </div>
              </div>
            </div>
          </div>

        </div>
</div>
</li>
*/
