import { Thing } from '../thing/thing.class'
import { LocationStreetAddress } from '../location/location.streetaddress.class'

export abstract class Place extends Thing {

    private _name: string = ""
    private _location: LocationStreetAddress = new LocationStreetAddress()

  
    constructor(id: string) {
      super(id, "place")
    }
  
    public get location(): LocationStreetAddress {
      return this._location
    }
    public set location(value: LocationStreetAddress) {
      this._location = value
    }
    public get name(): string {
      return this._name
    }
    public set name(value: string) {
      this._name = value
    }
  
  }
  