import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';


@Directive({
  selector: '[bk-cubic-bezier-elem]',
  inputs: ['timing', 'item'],
})


export class TransitionCubicBezierDirective {
    @Input()
    set timing(value: number) { this.setTiming(value); }
    set item(value: string) { this.setItem(value); }


    itemRef:     string = "all";
    timingMS:    number = 350;
    elem:        ElementRef;
    renderer:    Renderer2;

    constructor(el: ElementRef, _renderer: Renderer2) {
      this.elem = el;
      this.renderer = _renderer;
      this.updateElement();
  }


  setTiming(value: number) {
    this.timingMS = value;
    this.updateElement();
  }

  setItem(value: string) {
    this.itemRef = value;
    this.updateElement();
  }

  updateElement() {
    this.renderer.setStyle(this.elem.nativeElement, 'transition', this.itemRef + " " + this.timingMS + "ms cubic-bezier(0.53, 0.01, 0.36, 1.63)");
  }

}
