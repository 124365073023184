import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { ColorUtils } from "../../style/color/utils/color.utils.class"
import { UIColorModel } from '../../services/ui.color.model.service';

@Component({
  selector: 'text-divider',
  inputs: ['label'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
        <div class="text-divider" font-family onSurface>{{_label}}</div>

  `,
})
export class TextDividerComponent {

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
  private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel

  _label: string = ""

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	}

  setLabel(value: string) {
    this._label = value
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}


}
