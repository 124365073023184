import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { UIColorModel } from '../../services/ui.color.model.service';
import { ColorUtils } from "../../style/color/utils/color.utils.class"


@Component({
	selector: 		'menu-item-collapsible',
	inputs: ['id', 'label', 'focus', 'disabled', 'active','hovered', 'submenu'],
	outputs: ['clicked', 'hoverenter', 'hoverexit'],
	changeDetection: ChangeDetectionStrategy.Default,
	template: 	`

		<div class="_collapsible-header">
			<div class="_menu-item-no-icon-collapsible" 
				[style.background]=_highlightColor
				(mouseenter)=stateHoveredActive()
				(mouseleave)=stateEnabledActive()
				(click)=handleHeaderClick()>
				<div class="label" onSurface>{{_label}}</div>
				<div class="secondary-action">
					<i class="mdi mdi-menu-{{_caretIcon}}" [style.fontSize.px]=22 onSurface></i>
				</div>
		 	</div>
		</div>

		<div class="_collapsible-body {{_toggleSubmenuCSS}}">
			<ul class="menu-list-container">
				<menu-item-no-icon *ngFor="let dat of this._subbMenu"
					[id]=dat.id
					[label]=dat.label
					[active]=dat.isPressed
					(clicked)=subMenuClicked(dat.id)>
				</menu-item-no-icon>

			</ul>
		</div>


		`,
})


export class MenuItemNoIconCollapsibleComponent {
	@Output()
	clicked = new EventEmitter();
	hoverenter = new EventEmitter();
	hoverexit = new EventEmitter();

	@Input()
	set id(value: string) {
	  this.setId(value);
	}
	set icon(value: string) {
		this.setIcon(value);
    }
	set label(value: string) {
		this.setLabel(value);
    }
	set active(value: boolean) {
		this.setActive(value);
    }
	set hovered(value: boolean) {
		this.setHovered(value);
    }	
	set submenu(value: any) {
		this.setSubMenu(value);
    }
	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
    private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel
	
	_highlightColor: string = "rgba(0,0,0,0)"
	_iconColor: string = "rgba(0,0,0,0)"
	_textColor: string = "rgba(0,0,0,0)"

	_id: string = ""
	_label: string = ""
	_icon: string = ""
	_touchAnimCSS: string = ""

	_fontWeight: number = 500
	_isActive: boolean = false

	_toggleSubmenuCSS: string = "hide"
	_subbMenu: any = []
	_hasSubmenu: boolean = false
	_caretIcon: string = "up"


	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	  
		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			if(self._isActive) {
				self._highlightColor = self.uiColorModel.secondaryContainer
				self._textColor = self.uiColorModel.onSurface
				self._iconColor = self.uiColorModel.onSecondaryContainer
				self._fontWeight = 600
			} else {
				self._highlightColor = "rgba(0,0,0,0)"
				self._iconColor = self.uiColorModel.onSecondaryContainer
				self._textColor = self.uiColorModel.onSurface
			}
	
		});


		this.eventsService.on('onHoverMenuItem', function (args: any) {
			if(args.id == self._id) {
				self._toggleSubmenuCSS = "show"
				self._caretIcon = "down"
			}
		});
		this.eventsService.on('onUnHoverMenuItem', function (args: any) {
			if(args.id == self._id) {
				self._toggleSubmenuCSS = "hide"
				self._caretIcon = "up"
			}
		});

	}

	setId(value: string) {
		this._id = value
	}

	setIcon(value: string) {
		this._icon = value
	}

	setLabel(value: string) {
		this._label = value
	}

	setActive(value: boolean) {
		if(value == true) {
			this.statePressedActive()
		} else {
			this.stateDeActive()
		}
	}

	setHovered(value: boolean) {
		if(value == true) {
			if(this._isActive != true) {
				this._highlightColor = this.uiColorModel.surfaceVariant
				this._textColor = this.uiColorModel.onSurfaceVariant
				this._iconColor = this.uiColorModel.onSurfaceVariant
				this._fontWeight = 600
				this._toggleSubmenuCSS = "show"
				this._caretIcon = "down"	
			}
		} else {
			if(this._isActive != true) {
				this._highlightColor = "rgba(0,0,0,0)"
				this._textColor = this.uiColorModel.onSecondaryContainer
				this._iconColor = this.uiColorModel.onSurface
				this._fontWeight = 500
				this._caretIcon = "up"
				this._toggleSubmenuCSS = "hide"
			}
		}
	}

	setSubMenu(value: any) {
		this._subbMenu = value
		if(this._subbMenu.length > 0) {
			this._hasSubmenu = true
		} else {
			this._hasSubmenu = false			
		}
	}

	handleHeaderClick() {
		if(this._toggleSubmenuCSS == "show") {
			this._toggleSubmenuCSS = "hide"
			this._caretIcon = "up"
		} else {
			this._toggleSubmenuCSS = "show"
			this._caretIcon = "down"
		}
	}

	subMenuClicked($event: any) {
		this.clicked.emit($event)
	}

	stateEnabledActive() {
		if(this._isActive != true) {
			this._highlightColor = "rgba(0,0,0,0)"
			this._textColor = this.uiColorModel.onSecondaryContainer
			this._iconColor = this.uiColorModel.onSurface
			this._fontWeight = 500
			this._caretIcon = "up"
			this.hoverexit.emit(this._id)
		}
	}

	stateHoveredActive() {
		if(this._isActive != true) {
			this._highlightColor = this.uiColorModel.surfaceVariant
			this._textColor = this.uiColorModel.onSurfaceVariant
			this._iconColor = this.uiColorModel.onSurfaceVariant
			this._fontWeight = 600
			this.hoverenter.emit(this._id)
		}
	}

	stateFocusedActive() {
		this._highlightColor = this.uiColorModel.surface
		this._textColor = this.uiColorModel.onSurface
		this._iconColor = this.uiColorModel.onSecondaryContainer
	}

	statePressedActive() {
		this._touchAnimCSS = "nav-rail-icon-touch-animation"
		this._isActive = true
		this._highlightColor = this.uiColorModel.surfaceVariant
		this._textColor = this.uiColorModel.onSurface
		this._iconColor = this.uiColorModel.onSecondaryContainer
		this._fontWeight = 600

		setTimeout(() => {
			this._touchAnimCSS = ""
	    }, 500);

	}

	stateDeActive() {
		this._isActive = false
		this.stateEnabledActive()
	}


	stateEnabledInActive() {
	}


	hoverState() {

	}



	onHoverOn() {
		this._highlightColor = this.uiColorModel.surfaceVariant
		this._textColor = this.uiColorModel.onSurface
		this._iconColor = this.uiColorModel.onSecondaryContainer
	}

	onHoverOff() {
		this._highlightColor = "rgba(0,0,0,0"
		this._textColor = this.uiColorModel.onSecondaryContainer
		this._iconColor = this.uiColorModel.onSurface
	}

	ngOnInit() {
		this._highlightColor = "rgba(0,0,0,0"
		this._textColor = this.uiColorModel.onSecondaryContainer
		this._iconColor = this.uiColorModel.onSurface
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
