import { Injectable } from '@angular/core';


@Injectable()
export class TypographyModel {

    private _uiFontFamily: string = "Arial, Helvetica, sans-serif";
    private _displayFontFamily: string = "Arial, Helvetica, sans-serif";
    private _headingDisplayFontFamily: string = "Arial, Helvetica, sans-serif";
    private _scales: any = [];


    constructor() {
      this.defaultTypeModel()
    }


    public defaultTypeModel() {
      let fontFamily = "Roboto"
      this.addScale("display-large", fontFamily, 76, 85, 400, -0.25)
      this.addScale("display-medium", fontFamily, 60, 69, 400, 0)
      this.addScale("display-small", fontFamily, 48, 59, 400, 0)

      this.addScale("headline-large", fontFamily, 43, 53, 400, 0)
      this.addScale("headline-medium", fontFamily, 37, 48, 400, 0)
      this.addScale("headline-small", fontFamily, 32, 43, 400, 0)

      this.addScale("title-large", fontFamily, 29, 37, 400, 0)
      this.addScale("title-medium", fontFamily, 21, 32, 400, 0.15)
      this.addScale("title-small", fontFamily, 19, 27, 400, 0.1)

      this.addScale("label-large", fontFamily, 19, 27, 500, 0.1)
      this.addScale("label-medium", fontFamily, 16, 21, 500, 0.5)
      this.addScale("label-small", fontFamily, 15, 21, 500, 0.5)

      this.addScale("body-large", fontFamily, 21, 32, 400, 0.5)
      this.addScale("body-medium", fontFamily, 19, 27, 400, 0.25)
      this.addScale("body-small", fontFamily, 16, 21, 400, 0.4)

    }


    public addScale(id: string, font: string, size: number, lineheight: number, weight: number, letterspacing: number) {
        let rec = {
            id:                     id,
            font:                   font,
            size:                   size,
            lineheight:             lineheight,
            weight:                 weight,
            letterspacing:          letterspacing
        }
        this._scales.push(rec)
    }

    public getScale(id: string) {
        let scale = {}
        let i = 0;
        for(i=0;i<this._scales.length;i++) {
            if(this._scales[i]["id"] == id) {
                scale = this._scales[i]
            }
        }
        return scale
    }


    public get scales(): any {
        return this._scales;
    }
    public set scales(value: any) {
        this._scales = value;
    }


    public get uiFontFamily(): string {
      return this._uiFontFamily;
    }
    public set uiFontFamily(value: string) {
      this._uiFontFamily = value;
    }
    public get displayFontFamily(): string {
      return this._displayFontFamily;
    }
    public set displayFontFamily(value: string) {
      this._displayFontFamily = value;
    }
    public get headingDisplayFontFamily(): string {
      return this._headingDisplayFontFamily;
    }
    public set headingDisplayFontFamily(value: string) {
      this._headingDisplayFontFamily = value;
    }
  
}
