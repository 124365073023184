export class TagSet {

    private _tags: any[] = []
  
    constructor() {
    }

    public addTag(id: string, label: string) {
      let rec = {
        id:   id,
        label: label
      }
      this._tags.push(rec)
    }
  
    public removeTag(id: string) {
      let idx = this.tagIndex(id)
      if(idx != -1) {
        this._tags.splice(idx, 1)
      }
    }
  
    public hasTag(id: string): boolean {
      let hasTag: boolean = false
      for(let i=0;i<this._tags.length;i++) {
        if(this._tags[i]["id"] == id) {
          hasTag = true
        }
      } 
      return hasTag
    }

    private tagIndex(id: string): number {
      let idx: number = -1
      for(let i=0;i<this._tags.length;i++) {
        if(this._tags[i]["id"] == id) {
          idx = i
        }
      } 
      return idx
    }

    public toJSON(): any {
      let json: any[] = []
      for(let i=0;i<this._tags.length;i++) {
        //let rec = [this._tags[i]["id"], this._tags[i]["label"]]
        let rec = {
          id: this._tags[i]["id"],
          label: this._tags[i]["label"]
        }
        json.push(rec)
      }       
      return json
    }
  
    public fromJSON(json: any): void {
      for(let i=0;i<json.length;i++) {
        let rec = {
          id: json[i]["id"],
          label: json[i]["label"]
        }
        this._tags.push(rec)
      }       
    }
  

  }
  