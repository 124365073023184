import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';
import { HttpClient } from  '@angular/common/http'

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { FileUploadService } from './file.upload.service';
import { ApplicationService } from '../../services/application.service';
					  

@Component({
	selector: 		'uicore-file-upload',
	encapsulation: ViewEncapsulation.None,

	template:	`

		<div class="_file-upload-container">
			<div class="drop-target">

			<div class="_file-drop-target" surfaceContainer type="file"
					[style.border]=_border>

					<div class="info-block">
						<i class="mdi mdi-cloud-upload-outline" onSurface></i>
						<div class="heading" onSurface font-family>Upload files here</div>
					</div>
					<input type="file" class="_file-input" multiple (change)="this.fileUploadService.fileBrowseHandler($event)" />
				</div>				
			</div>

			<div class="file-list-container" surfaceColor>
				<ul class="list-tiles-container">
					<file-upload-progress-card  *ngFor="let dat of this.fileUploadService.filesToUpload"
						[heading]=dat.filename	
						[indeterminate]=false	
						[filesize]=dat.size
						[uploaded]=dat.progress
						[showleadingicon]=dat.isUploading
						>
					</file-upload-progress-card>
				</ul>
			</div>
		</div>


	`,

})


export class FileUploadContainer {

	

	private eventsService: EventsService
	uiColorModel: UIColorModel
	fileUploadService: FileUploadService
	applicationService: ApplicationService

	_border: string = "2px dashed "

	constructor(_eventsService: EventsService, private _uiColorModel: UIColorModel, 
		private _applicationService: ApplicationService,
		private _fileUploadService: FileUploadService) {
		this.eventsService = _eventsService
		this.applicationService = _applicationService
		this.uiColorModel = _uiColorModel
		this.fileUploadService = _fileUploadService
		this._border = "3px dashed " + this.uiColorModel.outline
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}





}
