import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/////////////////////////////////////////////////////////
// Core
////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module';
import { CoreUIExtensionsModule } from '@libraries/coreui-extensions/coreui.extensions.module';


import { PlaceRatingCard } from './places/place.rating.card';

// Travel - Airlines
import { TravelIconProgressComponent } from './travel/airline/depart-arrive-progressbar/travel.progress.component';
import { LabelledTravelIconProgressComponent } from './travel/airline/depart-arrive-progressbar/labelled.travel.progress.component';

// Airline01 Set
import { Airline01TripDetailsCard } from './travel/airline/trip-details-card/airline01.trip.details.card';
import { Airline01FlightPriceCard } from './travel/airline/flight-price-tile/airline01.flight.price.card';


@NgModule({
  declarations: [
    PlaceRatingCard,
    TravelIconProgressComponent,
    LabelledTravelIconProgressComponent,
    Airline01TripDetailsCard,
    Airline01FlightPriceCard

  ],

  imports: [
    CommonModule,
    CoreUIModule,
    CoreUIExtensionsModule
  ],

  providers: [
  ],

  exports: [
    PlaceRatingCard,
    TravelIconProgressComponent,
    LabelledTravelIconProgressComponent,
    Airline01TripDetailsCard,
    Airline01FlightPriceCard
  ]
})

export class CardExamplesModule { }
