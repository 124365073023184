
export class UISpacingUtils {

 

    public detectWindowClass(width: number): string {
        let screenClass = "expanded"
        if(width < 600) {
            screenClass = "compact"
        }
        if(width > 600 && width <= 1082) {
            screenClass = "medium"
        }
        if(width > 1083) {
            screenClass = "expanded"
        }

        return screenClass
    }


    public selectWindowMargin(screenClass: string): number {
        let margin: number = 24
         
        if(screenClass == "compact") {
            margin = 16
        }
        if(screenClass == "medium") {
            margin = 24
        }
        if(screenClass == "expanded") {
            margin = 24
        }
        return margin
    }

}