import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	NgZone,
	Input
} from '@angular/core';

import { EventsService } from '@coreui//services/event.service'
import { AbstractCollectionContainerAnimated } from '@libraries/animated-tiles/components/collections/abstract.collection.container.animated';


@Component({
	selector: 		'gradient-collection-anim',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
	template:	`    
		<ul class="grid-collection-list" [style.opacity]=this.entryOpacity>
			<abstract-collection-tile [id]=dat.id [width]=200 [height]=232 [radius]=16 [marginright]=16 [marginbottom]=16
				[cssclass]=dat.anim [selectmode]=dat.select_mode [selected]=dat.selected_flag (clicked)=onClick($event)
				*ngFor="let dat of this.items">
				<gradient-swatch [label]=dat.name [gradient]=dat.hexstring [gradientdata]=dat.colors [radius]=16></gradient-swatch>
			</abstract-collection-tile>

		</ul>
	`,

})


export class GradientAnimCollection extends AbstractCollectionContainerAnimated {
  
	gradients: any = []
	_isSelectMode: boolean = false
	_isSelected: boolean = false

	constructor(public ref: ChangeDetectorRef, _eventsService: EventsService) {
		super(ref, _eventsService)
	}


	onClick($event: any) {
		if(this.getMode() == "select") {
			this.selectItem($event)
		}
	}


	override setData(value: any): void {
	//	console.log(JSON.stringify(value, null, 4))
		this.initData(value)
		this.changeDetectorRef.detectChanges()
		this.shakeAll()
	}

	override ngOnInit() {
	}

	override ngOnDestroy() {
	}

	override ngAfterViewInit() {

	}

}
