export class DynamicCloudsBackground {


  private id:                 string
  private backgroundcolor:    string
  private color1:             string
  private color2:             string
  private interval:           number  = 1500
  private numclouds:          number  = 15
  private speed:              number  = 5000
  private opacity:            number  = 1

  constructor(_id: string, _backgroundcolor: string, _color1: string, _color2: string, _interval: number,
              _numclouds: number, _speed: number, _opacity: number) {
    this.id = _id
    this.backgroundcolor = _backgroundcolor
    this.color1 = _color1
    this.color2 = _color2
    this.interval = _interval
    this.numclouds = _numclouds
    this.speed = _speed
    this.opacity = _opacity
  }

  public getId(): string { return this.id }
  public getBackgroundColor(): string { return this.backgroundcolor }
  public getColor1(): string { return this.color1 }
  public getColor2(): string { return this.color2 }
  public getInterval(): number { return this.interval }
  public getNumClouds(): number { return this.numclouds }
  public getSpeed(): number { return this.speed }

  public getRecord(): any {
    let rec = {
      id:                 this.id,
      backgroundcolor:    this.backgroundcolor,
      color1:             this.color1,
      color2:             this.color2,
      interval:           this.interval,
      numclouds:          this.numclouds,
      speed:              this.speed,
      opacity:            this.opacity
    }
    return rec
  }
}
