import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';
import { HttpClient } from  '@angular/common/http'


import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';

@Component({
	selector: 		'file-drop-target',
	encapsulation: ViewEncapsulation.None,

	template:	`

		<div class="_file-drop-target" surfaceContainer type="file"
			[style.border]=_border>

			<div class="info-block">
				<i class="mdi mdi-cloud-upload-outline" onSurface></i>
				<div class="heading" onSurface font-family>Upload files here</div>
			</div>
			<input type="file" class="_file-input" />
		</div>


	`,

})


export class FileDropTargetComponent {

	
	private eventsService: EventsService
	uiColorModel: UIColorModel
	_border: string = "2px dashed "
	files: any[] = []

	constructor(_eventsService: EventsService, private _uiColorModel: UIColorModel) {
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this._border = "3px dashed " + this.uiColorModel.outline
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}



}
