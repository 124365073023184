import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { ApplicationService } from '../../services/application.service';


@Component({
	selector: 		'home-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

	<content-container-scrollable>
		<optimised-content-container>

			<div class="_headline-medium" font-family onSurface>
				HOME!!!!!!   
			</div>
			<div font-family onSurface>
			</div>

			<switch [value]=true></switch>
			<ul class="_horizontal-hotel-card-row">
				<discover-place-card [imgsrc]=samplePic1></discover-place-card>
				<discover-place-card [imgsrc]=samplePic2></discover-place-card>
				<discover-place-card [imgsrc]=samplePic1></discover-place-card>
				<discover-place-card [imgsrc]=samplePic1></discover-place-card>
			</ul>

		</optimised-content-container>
		<div style="position: fixed; bottom: 32px; right: 32px;">
			<fab [icon]="'lock'" (clicked)=fabClicked()></fab>
		</div>
	</content-container-scrollable>
		
	`,

})


export class HomeScreen {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	applicationService: ApplicationService


	samplePic1: string = "/assets/sample/media/indigo-restaurant.jpg"
	samplePic2: string = "/assets/sample/media/coco-mama.png"

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _applicationService: ApplicationService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.applicationService = _applicationService
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	fabClicked() {
		this.eventsService.broadcast("onShowModalOverlay", {id: "toast", message: "Item added"})
	/*	if(this.uiColorModel.isDark) {
			this.applicationService.lightColorMode()
		} else {
			this.applicationService.darkColorMode()
		} 
		this.eventsService.onRefresh()*/
	}

}
