import {
	Component,
	ChangeDetectorRef,
	ViewEncapsulation,
	Input
} from '@angular/core';

import { EventsService } from '../../../services/event.service'
import { UIColorModel } from '../../../services/ui.color.model.service';


@Component({
	selector: 		'linear-progress-bar',
	encapsulation: ViewEncapsulation.None,
	inputs: ['perc', 'indeterminate'],
	styles: 		[],
	template:	`
		<div class="_linear-progress-bar" surfaceContainerHighest>
			<div class="{{indeterminateClass}}" [style.width.%]=percValue primaryColor></div>
		</div>

	`,

})


export class ProgressBarComponent {
	@Input()
	set indeterminate(value: boolean) { this.setIndeterminate(value); }
	set perc(value: number) { this.setPerc(value); }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	indeterminateClass: string = "determinate width-motion-animation";
	percValue:	number = 55;

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	}


	setIndeterminate(value: boolean) {
		if(value == true) {
			this.indeterminateClass = "indeterminate";
		} else {
			this.indeterminateClass = "determinate width-motion-animation";
		}
	}

	setPerc(val: number) {
		this.percValue = val;
	}

	ngOnInit() {
	}


}