import { Directive, ElementRef, Input, ChangeDetectorRef, NgZone } from '@angular/core';

import { EventsService } from '../../services/event.service';


@Directive({
  selector: '[scrollEventDetect]',
})


export class DetectScrollDirective {

  changeDetectorRef: ChangeDetectorRef
  eventsService: EventsService
  private eventOptions: boolean|{capture?: boolean, passive?: boolean} = {};
	_scrollTimer: any = {}
  lastKnownScrollPosition: number = 0
  isScrolling: boolean = false

  constructor(public ref: ChangeDetectorRef, private ngZone: NgZone, 
      private _eventsService: EventsService) {
    this.changeDetectorRef = ref
    this.eventsService = _eventsService
  }

  ngOnInit() {            
    this.eventOptions = true;
    this.ngZone.runOutsideAngular(() => {
        window.addEventListener('scroll', this.scroll, <any>this.eventOptions);
        window.addEventListener('scrollend', this.scrollend, <any>this.eventOptions);
    });
}

  ngOnDestroy() {
      window.removeEventListener('scroll', this.scroll, <any>this.eventOptions);
      window.removeEventListener('scrollend', this.scrollend, <any>this.eventOptions);
      //unfortunately the compiler doesn't know yet about this object, so cast to any
  }

  scroll = ($event: any): void => {
    let scrollDirection: string = ""
    let scrollY: number = 0
    
    if(this.lastKnownScrollPosition < $event.target.scrollTop) {
      scrollDirection = "down"
    } else {
      scrollDirection = "up"
    }

    scrollY = $event.target.scrollTop

    if(this.throttle(200)) {
      this.eventsService.broadcast("onScrollStart", {direction: scrollDirection, y: scrollY})
      this.isScrolling = false
    }


    this.lastKnownScrollPosition = $event.target.scrollTop

  } 

  scrollend = ($event: any): void => {  
      scrollY = $event.target.scrollTop
      this.isScrolling = false
      this.eventsService.broadcast("onScrollEnd", {direction: "", y: scrollY})
  }


  lastTS: number = 0
  throttle(delay: number): boolean {
    let canFire: boolean = false
    let now = Date.now()
    if(now > this.lastTS + delay) {
     this.lastTS = now 
     canFire = true
    }
    return canFire
  }


}





/*
scroll = ($event: any): void => {
  let scrollDirection: string = ""
  let scrollY: number = 0
  
  if(this.lastKnownScrollPosition < $event.target.scrollTop) {
    scrollDirection = "down"
  } else {
    scrollDirection = "up"
  }
  scrollY = $event.target.scrollTop

  if(this._scrollTimer !== null) {
    this.eventsService.broadcast("onScrollStart", {direction: scrollDirection, y: scrollY})
    clearTimeout(this._scrollTimer);         
  }

  let self = this
  this._scrollTimer = setTimeout(function() {
    self.eventsService.broadcast("onScrollEnd", {})
  }, 75);
  this.lastKnownScrollPosition = $event.target.scrollTop


*/