import {
    Component,
    Input,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
  } from '@angular/core';
  
  import { ColorUtils } from '@coreui/style/color/utils/color.utils.class';
  import { ColorHCTUtils } from '@coreui//style/color/utils/color.utils.hct.class';
  
  import { M3ColorSchemeUtility } from '@coreui/style/color/schemes/color.schene.utility.class';
  import { UIColorModel } from '@coreui//services/ui.color.model.service';
  
  
  
  @Component({
    selector: 'color-tomal-list',
    inputs: ['hex'],
    changeDetection: ChangeDetectionStrategy.Default,
  
    template: `
                      
            <div class="color-tonal-box"
                [style.color]=dat.text
                [style.background]=dat.color
                *ngFor="let dat of this.colorData">{{dat.tone}}</div>
  `,
  })
  export class TonalColorListComponent {
  
    @Input()
    set hex(value: string) {
      this.setHex(value);
    }
  
  
    private colorUtils: ColorUtils = new ColorUtils()
    changeDetectorRef: ChangeDetectorRef;
    uiColorModel: UIColorModel
  
    constructor(public ref: ChangeDetectorRef, private _uiColorModel: UIColorModel) {
      this.changeDetectorRef = ref;
      this.uiColorModel = _uiColorModel;
    }
  
    _hexColor: string = '376A20';
    colorData: any = []
  
    setHex(value: string) {
      this._hexColor = value;
    }
  
    ngOnInit() { 
      this.addColorTone2(this._hexColor, 0)
      this.addColorTone2(this._hexColor, 2)
      this.addColorTone2(this._hexColor, 6)
      this.addColorTone2(this._hexColor, 10)
      this.addColorTone2(this._hexColor, 20)
      this.addColorTone2(this._hexColor, 30)
      this.addColorTone2(this._hexColor, 40)
      this.addColorTone2(this._hexColor, 50)
      this.addColorTone2(this._hexColor, 60)
      this.addColorTone2(this._hexColor, 70)
      this.addColorTone2(this._hexColor, 80)
      this.addColorTone2(this._hexColor, 90)
      this.addColorTone2(this._hexColor, 95)
      this.addColorTone2(this._hexColor, 98)
      this.addColorTone2(this._hexColor, 100)
  
      let util = new M3ColorSchemeUtility()
      let primary = "386a1f"
      let secondary = "55624c"
      let tertiary = "19686a"
      let error = "b3261e"
  
      let neutral = "5d5f5a"
      let neutralVariant = "5b6056"
      util.generateLightScheme("light", primary, secondary, tertiary, neutral, neutralVariant, error)
  
      
  
  }
  
  
    ngOnChanges() {}
  
    ngOnDestroy() {}
  
  
    addColorTone2(hex: string, scale: number) {
      let whiteText: string = "#FFFFFF"
      let blackText: string = "#000000"
      let textCol: string = ""
  
      let util: ColorHCTUtils = new ColorHCTUtils()
      let newHex = util.hexFromHCT(hex, scale)
  
      if(this.colorUtils.isDark(newHex)) {
        textCol = whiteText
      } else {
        textCol = blackText
      }
  
      let rec = {
        color: newHex,
        tone: scale,
        text: textCol
      }
      this.colorData.push(rec)
  
    }
  
  
  
  }
  