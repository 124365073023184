import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/////////////////////////////////////////////////////////
// Core
////////////////////////////////////////////////////////
import { BackgroundDynamicCloudsComponent } from './background.dynamicclouds.component';
import { BackgroundDynamicCloudsPresetsComponent } from './background.dynamicclouds.dynamic.component';
import { DynamicCloudComponent } from './cloud.dynamic.component';

import { TransitionCubicBezierDirective } from './transition.cubicbezier.directive';

@NgModule({
  declarations: [
    BackgroundDynamicCloudsComponent,
    BackgroundDynamicCloudsPresetsComponent,
    DynamicCloudComponent,
    TransitionCubicBezierDirective
  ],

  imports: [
    CommonModule
  ],

  providers: [
  ],

  exports: [
    BackgroundDynamicCloudsComponent,
    BackgroundDynamicCloudsPresetsComponent,
    DynamicCloudComponent,
    TransitionCubicBezierDirective
  ]
})
export class DynamicCloudsBackgroundModule { }
