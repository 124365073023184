export class LocationStreetAddress {

    private _refid: string = ""
    private _country: string = ""
    private _countryCode2: string = ""
    private _continent: string = ""
    private _continentCode: string = ""
    private _timezone: string = ""
    private _timezoneUTCOffset: number = 0
    private _streetAddress: string = ""
    private _locale: string = ""
    private _city: string = ""
    private _stateprovince: string = ""
    private _postcode: string = ""
    private _latitude: number = 0
    private _longitude: number = 0
    private _isValidated: boolean = false
  
    constructor() {
    }
  
    public toJSON(): any {
      let json = {
        id: this._refid,
        country: this._country,
        countryCode2: this._countryCode2,
        continent: this._continent,
        continentCode: this._continentCode,
        timezone: this._timezone,
        timezoneUTCOffset: this._timezoneUTCOffset,
        streetAddress: this._streetAddress,
        locale: this._locale,
        city: this._city,
        state_province: this._stateprovince,
        postcode: this._postcode,
        latitude: this._latitude,
        longitude: this._longitude
      }
      return json
    }
  
    public fromJSON(json: any): void {
      this._refid = json["refid"]
      this._country = json["country"]
      this._countryCode2 = json["countryCode2"]
      this._continent = json["continent"]
      this.continentCode = json["continentCode"]
      this._timezone = json["timezone"]
      this._timezoneUTCOffset = json["timezoneUTCOffset"]
      this._streetAddress = json["streetAddress"]
      this._locale = json["locale"]
      this._city = json["city"]
      this._stateprovince = json["state_province"]
      this._postcode = json["postcode"]
      this._latitude = json["latitude"]
      this._longitude = json["longitude"]
    }
  
    public get refid(): string {
      return this._refid
    }
    public set refid(value: string) {
      this._refid = value
    }  
    public get country(): string {
      return this._country
    }
    public set country(value: string) {
      this._country = value
    }
    public get countryCode2(): string {
      return this._countryCode2
    }
    public set countryCode2(value: string) {
      this._countryCode2 = value
    }
    public get continent(): string {
      return this._continent
    }
    public set continent(value: string) {
      this._continent = value
    }
    public get continentCode(): string {
      return this._continentCode
    }
    public set continentCode(value: string) {
      this._continentCode = value
    }
    public get timezone(): string {
      return this._timezone
    }
    public set timezone(value: string) {
      this._timezone = value
    }
    public get timezoneUTCOffset(): number {
      return this._timezoneUTCOffset
    }
    public set timezoneUTCOffset(value: number) {
      this._timezoneUTCOffset = value
    }
  
    public get streetAddress(): string {
      return this._streetAddress
    }
    public set streetAddress(value: string) {
      this._streetAddress = value
    }
    public get locale(): string {
      return this._locale
    }
    public set locale(value: string) {
      this._locale = value
    }
    public get city(): string {
      return this._city
    }
    public set city(value: string) {
      this._city = value
    }
    public get postcode(): string {
      return this._postcode
    }
    public set postcode(value: string) {
      this._postcode = value
    }
    public get stateprovince(): string {
      return this._stateprovince
    }
    public set stateprovince(value: string) {
      this._stateprovince = value
    }
    public get latitude(): number {
      return this._latitude
    }
    public set latitude(value: number) {
      this._latitude = value
    }
    public get longitude(): number {
      return this._longitude
    }
    public set longitude(value: number) {
      this._longitude = value
    }
    public get isValidated(): boolean {
      return this._isValidated
    }
    public set isValidated(value: boolean) {
      this._isValidated = value
    }
  
  }