import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/////////////////////////////////////////////////////////
// Core
////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module'

import { FlagIconComponent } from './floagicon.component'

@NgModule({
  declarations: [
    FlagIconComponent
  ],

  imports: [
    CommonModule,
    CoreUIModule
  ],

  providers: [
  ],

  exports: [
    FlagIconComponent
  ]
})
export class FlagIconsModule { }
