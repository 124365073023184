import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';
import { HttpClient } from  '@angular/common/http'


import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { SkyAISessionService } from '@libraries/skyai-api/api/skyai.session.class'

@Component({
	selector: 		'uicore-signin-modal',
	encapsulation: ViewEncapsulation.None,

	template:	`

        <modal [percentage]=false [width]=400 [height]=480 [differentcompact]=true
				[compactwidth]=100 [compactheight]=100 [compactpercentage]=true>
        
			<div class="_signin-flow-container">
				<div class="header">
					<div class="_title-medium" font-family onSurface>Sign In</div>
				</div>
				<div class="body">
					<text-input-filled [label]="'Email'"
						(value)=getUsernameValue($event)
						[trailingicon]="'close-circle-outline'" 
						[supportingtext]=siginSupportingText
						[errormode]=siginErrorMode>
					</text-input-filled>
					<password-input-filled (value)=getPasswordValue($event)
						[supportingtext]=passwordSupportingText
						[errormode]=passwordErrorMode
					>
					</password-input-filled>
					<text-button [label]="'Forgot Password?'"></text-button>
				</div>
				<div class="action-wrapper">
					<tonal-button [label]="'Sign In'" [fullwidth]=true (clicked)=signInClick() [preloading]=isSigningIn></tonal-button>
				</div>
			</div>
        </modal>

	`,

})


export class SignInModalFlow {

	
	private eventsService: EventsService
	uiNavigation: UINavigation

	siginSupportingText: string = ""
	siginErrorMode: boolean = false
	passwordSupportingText: string = ""
	passwordErrorMode: boolean = false
	isSigningIn: boolean = false

	usernameValue: string = ""
	passwordValue: string = ""
	private skyAISessionService: SkyAISessionService

	constructor(_eventsService: EventsService, _http: HttpClient,
			private _uiNavigation: UINavigation) {
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.skyAISessionService = new SkyAISessionService(_http)
		this.skyAISessionService.init("https://pg8.in/pm/v1", "skyai_root.FVsaDg1rQEIAcRpqQEukPkqxjeOmPb8NeJgn9Sef")
		//FVsaDg1rQEIAcRpqQEukPkqxjeOmPb8NeJgn9Sef
	
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}

	getUsernameValue(value: string) {
		this.usernameValue = value
	}

	getPasswordValue(value: string) {
		this.passwordValue = value
	}


	closeSequence() {
		setTimeout(() => {
			this.eventsService.broadcast("onHideModalOverlay", {})
			this.eventsService.broadcast("onSignIn", {data: this.skyAISessionService})
			this.uiNavigation.activeView = ""
		}, 450);		
	}

	signInClick() {
		this.isSigningIn = true


		this.skyAISessionService.signin(this.usernameValue, this.passwordValue).subscribe(
			value => {
				console.log(JSON.stringify(value.body.data, null, 4))
				if(value.body.data.status == true) {
					this.passwordSupportingText = ""
					this.passwordErrorMode = false
					this.isSigningIn = false
					this.skyAISessionService.sessionRegisterSuccessful(value.body.data.session)
					this.closeSequence()
				} else {
					this.passwordSupportingText = "Invalid username or password"
					this.passwordErrorMode = true
					this.isSigningIn = false
				}
		
			}, 
			err => {
				console.log(err)
				console.log("Error contacting server")
				this.isSigningIn = false
		})		
	}



}
