import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	NgZone,
	Input
} from '@angular/core';

import { EventsService } from '@coreui//services/event.service'


@Component({
	selector: 		'sessions-list-item',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
	template:	`    

		<li class="two-item-list-tile-icon-text">
			<div class="circle-wrapper">
				<div class="circle" tertiaryContainer></div>
			</div>
			<div class="content">
				<div class="top-line" font-family onSurface>Peach Morato</div>
				<div class="bottom-line" font-family onSurface>136.236.7.8</div>
			</div>
			<div class="secondary-action">
				<div class="text" font-family onSurface>
				<span [style.width.px]=16><flag-icon [countrycode]="'sy'"></flag-icon></span> 37 minutes
				</div>
			</div>
		</li>
	`,

})


export class SessionsListItemComponent {
  
	private eventsService: EventsService;
	private changeDetectorRef: ChangeDetectorRef;

	constructor(public ref: ChangeDetectorRef, _eventsService: EventsService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
	}


	ngOnInit() { 
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}
	

}
