import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { UIColorModel } from '../../services/ui.color.model.service';
import { EventsService } from '../../services/event.service'


@Component({
  selector: 'fab',
  inputs: ['icon', 'size', 'color', 'colormap', 'textcolor', 'round', 'tonal', 'pulse'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    
    <div class="_fab-button round {{ btnPressEffect }} {{_fabSizeCSS}} {{_pulseCSS}}"
       elevated [elevation]=_elevation
       [style.background-color]=this._containerColor
      (click)=btnClicked()>
      <i class="mdi mdi-{{_btnIcon}}" 
        [style.color]=_textColor></i>
      <div class="touch-overlay {{touchEffectCSS}}"></div>
		</div>


  `,
})
export class FabButtonComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set size(value: string) {
    this.setSize(value);
  }
  set colormap(value: string) {
    this.setColorMapType(value);
  }
  set color(value: string) {
    this.setColor(value);
  }
  set icon(value: string) {
    this.setIcon(value);
  }
  set textcolor(value: string) {
    this.setTextColor(value);
  }
  set tonal(value: boolean) {
    this.setTonal(value);
  }
  set pulse(value: boolean) {
    this.setPulse(value);
  }
  

  changeDetectorRef: ChangeDetectorRef;
	uiColorModel: UIColorModel
	private eventsService: EventsService



  _btnIcon: string = '';
  prevBtnColor: string = 'FFEEEE';
  btnPressEffect: string = '';
  btnOpacity: number = 1;
  touchEffectCSS: string = ""

  _colorMap: string = "none"
  _isTonal: boolean = true

  // Settings
  _fabSize: string = ""
  _fabSizeCSS: string = ""

  _containerColor: string = ""
  _elevation: number = 2
  _textColor: string = ""

  _isRound: boolean = false

  _pulseCSS: string = ""

  // States
  _fabState: string = "eneabled"

  _enabledContainerColor: string = ""
  _enabledElevation: number = 3
  _enabledElevationLowered: number = 1
  _enabledTextColor: string = ""


	constructor(public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

    this.setColorMapType(this._colorMap)
		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self.setColorMapType(self._colorMap)
		});

  }


  enabledState() {
    this._enabledContainerColor = this._uiColorModel.primaryContainer
    this._enabledTextColor = this.uiColorModel.onPrimaryContainer
    this._enabledElevation = 3
    this._enabledElevationLowered = 1
  }

  hoverState() {
    this._enabledContainerColor = this._uiColorModel.primaryContainer
    this._enabledTextColor = this.uiColorModel.onPrimaryContainer
    this._enabledElevation = 4
    this._enabledElevationLowered = 2
  }

  focusedState() {
    this._enabledContainerColor = this._uiColorModel.primaryContainer
    this._enabledTextColor = this.uiColorModel.onPrimaryContainer
    this._enabledElevation = 4
    this._enabledElevationLowered = 2
  }

  disabledState() {
    this._enabledContainerColor = this._uiColorModel.primaryContainer
    this._enabledTextColor = this.uiColorModel.onPrimaryContainer
    this._enabledElevation = 4
    this._enabledElevationLowered = 2
  }





  setSize(value: string) {
    this._fabSize = "";
    if(value == "small") {
      this._fabSize = value
      this._fabSizeCSS = "small"
    }
    if(value == "large") {
      this._fabSize = value
      this._fabSizeCSS = "large"
    }
  }

  setColorMap() {
    if(this._isTonal == true) {
      if(this._colorMap == "surface") {
        this._containerColor = "#" + this._uiColorModel.surfaceHigh
        this._textColor = this.uiColorModel.primary
      }
      if(this._colorMap == "primary") {
        this._containerColor = "#" + this._uiColorModel.primaryKeyColor
        this._textColor = this.uiColorModel.onPrimaryContainer
      }
      if(this._colorMap == "secondary") {
        this._containerColor = "#" + this._uiColorModel.secondaryKeyColor
        this._textColor = this.uiColorModel.onSecondaryContainer
      }
      if(this._colorMap == "tertiary") {
        this._containerColor = "#" + this.uiColorModel.tertiaryKeyColor
        this._textColor = this.uiColorModel.onTertiaryContainer
      }
      if(this._colorMap == "primary-container") {
        this._containerColor = this.uiColorModel.primaryContainer
        this._textColor = this.uiColorModel.onPrimaryContainer
      }
      if(this._colorMap == "secondary-container") {
        this._containerColor = this.uiColorModel.secondaryContainer
        this._textColor = this.uiColorModel.onSecondaryContainer
      }
      if(this._colorMap == "tertiary-container") {
        this._containerColor = this.uiColorModel.tertiaryContainer
        this._textColor = this.uiColorModel.onTertiaryContainer
      }
    }
  }
  setColorMapType(value: string) {
    this._colorMap = value
    this.setColorMap()
  }


  setColor(value: string) {
    if(this._isTonal == false) {
      if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')) {
        this._containerColor = value;
      } else {
        this._containerColor = '#' + value;
      }
    }
  }

  setIcon(value: string) {
    if (this._btnIcon == 'null') {
      this._btnIcon = value;
    } else {
      this.changeIcon(value);
    }
  }

  setTextColor(value: string) {
    this._textColor = value
  }

  setTonal(value: boolean) {
    this._isTonal = value
  }
  setPulse(value: boolean) {
    if(value == true) {
      this._pulseCSS = "ppulse"
    } else {
      this._pulseCSS = ""
    }
    
  }

  btnClicked() {
    if(this._containerColor == this._uiColorModel.surfaceHigh) {
      this.btnClickedIfSurface()
    } else {
      this.btnClickedEffect()
    }
  }

  btnClickedIfSurface() {
    this._containerColor = this._uiColorModel.surfaceLow
    this._elevation = 1
    this.clicked.emit(true);
    setTimeout(() => {
      this.btnPressEffect = '';
      this.touchEffectCSS = "";
      this._containerColor = this._uiColorModel.surfaceHigh
      this._elevation = 2
    }, 100);
  }

  btnClickedEffect() {
    this.btnPressEffect = 'fab-touch-animation';
    this.touchEffectCSS = "fab-ripple-animation";
    this._elevation = 2
    this.clicked.emit(true);
    setTimeout(() => {
      this.btnPressEffect = '';
      this.touchEffectCSS = "";
    }, 250);
  }



  changeIcon(v: string) {
    this._btnIcon = v;
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
