import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { ApplicationService } from '../../services/application.service';
import { TimeZonesService } from '@libraries/timezones/timezones.service';


@Component({
	selector: 		'home-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

	<content-container-scrollable>
		<optimised-content-container>

			<div class="_morning-sunrise-goodmorning-wrapper" >
				<div class="introduction-text {{goodmorningTextState}}" [style.opacity]=goodmorningOpacity>
					<div font-family>Good morning</div>
				</div>

				<div class="good-morning-label">
					<div class="text">
						<div heading5>What would you like to do?</div>
					</div>
				</div>
            </div>
			<div class="_headline-medium" onSurface>{{w}}</div>
			<sbutton [label]="'Pick'" (click)=pick()></sbutton>


		</optimised-content-container>
	</content-container-scrollable>
		
	`,

})


export class HomeScreen {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	applicationService: ApplicationService
	private timeZonesService: TimeZonesService

	goodmorningTextState: string = ""
	goodmorningOpacity: number = 1

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _applicationService: ApplicationService,
		private _timeZonesService: TimeZonesService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.applicationService = _applicationService
		this.timeZonesService = _timeZonesService

	}


	w: number = 0
	ngOnInit() {
	//	this.eventsService.broadcast("onShowModalOverlay", {id: "radar-popup"})
	//	this.applicationService.activeBackground = "sunrise"
		this.w = window.innerWidth
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}


	pick() {
		this.eventsService.broadcast("onShowModalOverlay", {id: "people"})
	}
}
