import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	NgZone,
	Input
} from '@angular/core';

import { EventsService } from '@coreui//services/event.service'


@Component({
	selector: 		'sessions-list',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
	template:	`    
		<table>
			<th class="_title-medium" font-family onSurface>Session Id</th>
			<th class="_title-medium" font-family onSurface>Start TS</th>
			<th class="_title-medium" font-family onSurface>IP Address</th>
			<th class="_title-medium" font-family onSurface>Country</th>
		</table>
	`,

})


export class SessionsList {
  
	private eventsService: EventsService;
	private changeDetectorRef: ChangeDetectorRef;

	constructor(public ref: ChangeDetectorRef, _eventsService: EventsService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
	}


	ngOnInit() { 
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}
	

}
