import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  Input,
} from "@angular/core";

import { EventsService } from "@coreui/services/event.service";

@Component({
  selector: "saas-page-container",
  encapsulation: ViewEncapsulation.None,
  template: `
    <div
      class="scrollable-div-container"
      [style.height.px]="this.containerHeight"
	  [style.paddingLeft.%]=8.125
	  [style.paddingRight.%]=8.125
      view-height
      scrollEventDetect
    >
      <ng-content></ng-content>
    </div>
  `,
})
export class SaaSPageContentContainer {
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.recalculate();
  }
  private changeDetectorRef: ChangeDetectorRef;
  private eventsService: EventsService;
  containerHeight: number | undefined = window.innerHeight;
  _scrollTimer: any = {};

  constructor(
    private ref: ChangeDetectorRef,
    private _eventsService: EventsService
  ) {
    this.changeDetectorRef = ref;
    this.eventsService = _eventsService;

    let self = this;
    this.eventsService.on("onRefresh", function (args: any) {
      self.recalculate();
    });
  }

  recalculate() {
    this.containerHeight = window.innerHeight;
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    this.recalculate();
  }

  ngOnDestroy() {}
}
