import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,

	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';


@Component({
	selector: 		'edit-profile-page',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<saas-page-container>
		<div id="scroll-to-top" class="_saas-page-header">
			<div class="heading" onSurface>Edit Profile</div>
		</div>

		<div class="row">
			<div class="col s12 m4">
				<div class="_title-medium" onSurface>Basics</div>
			</div>
			<div class="col s12 m8">
				<text-input-filled [label]="'First name'"></text-input-filled>
				<text-input-filled [label]="'Last name'"></text-input-filled>
			</div>
		</div>

		<scroll-top-button [scrollshowvalue]=144 (clicked)=scrollToTop()></scroll-top-button>

	</saas-page-container>

		`,
})


export class EditProfilePage {
  
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel
	

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.changeDetectorRef = ref
	}

	scrollToTop() {
		let textElement: any = document.getElementById("scroll-to-top")
		textElement.scrollIntoView({behavior: "smooth"})
	}



	ngOnInit() {
		this.changeDetectorRef.detectChanges()
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
