import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,
	Input,
	ViewChild,
	ChangeDetectionStrategy,
	Output,
	EventEmitter
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';


@Component({
	selector: 		'appui-page-headung',
	changeDetection: ChangeDetectionStrategy.Default,
	inputs: ['title'],
	template: 	`
		<div class="_appui-page-header">
			
			<div class="guide-heading">
				<div class="heading" onSurface font-family>{{_pageTitle}}</div>
				<div class="subheading" onSurface font-family>Search over 6000 icons</div>
			</div>		

		<!--	<div class="heading-wrapper">
				<div class="page-heading" onSurface font-family>{{_pageTitle}}</div>
			</div>	-->		
		</div>

		`,
})


export class AppUIPageHeading {
	@Output()
	searchvalue = new EventEmitter();

	@Input()
	set title(value: string) {
	  this.setPageTitle(value);
	}

	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel

	_pageTitle: string = ""
	
	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.changeDetectorRef = ref
	}


	setPageTitle(value: string) {
		this._pageTitle = value
	}

	searchValue($event: any) {
		this.searchvalue.emit($event)
	}


	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}


/*
				<div class="search-wrapper">
					<search-bar (value)=searchValue($event) [trailingicon]="'close'"></search-bar>
				</div>

*/