import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	ChangeDetectionStrategy,
	ViewChild,
	ElementRef
} from '@angular/core';


@Component({
	selector: 		'cloud-item-background-dynamicclouds',
	inputs: ['width', 'height', 'x', 'y', 'color', 'cssclass', 'speed', 'opacity'],
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
					<div class="magical-ellipse  {{this._cssclass}}" bk-cubic-bezier-elem [timing]=this._speed [item]="'top left'"
						[style.width.px]=this._width
						[style.height.px]=this._height
						[style.top.%]=this._y
						[style.left.%]=this._x
						[style.backgroundColor]=this._color
						[style.opacity]=this._opacity>
					</div>

								`,
})



export class DynamicCloudComponent {

	@Input()
	set width(value: number) { this.setWidth(value); }
	set height(value: number) { this.setHeight(value); }
	set x(value: number) { this.setX(value); }
	set y(value: number) { this.setY(value); }

	set color(value: string) { this.setColor(value); }
	set cssclass(value: string) { this.setCSSClass(value); }
	set speed(value: number) { this.setSpeed(value); }
	set opacity(value: number) { this.setOpacity(value); }

	private changeDetectorRef: ChangeDetectorRef;

	_width: number = 420
	_height: number = 550
	_x: number = 420
	_y: number = 550
	_color: string = "613DFB"
	_cssclass: string = ""
	_speed: number = 5000
	_opacity: number = 1


	constructor(public ref: ChangeDetectorRef) {
		this.changeDetectorRef = ref;
	}
	setWidth(value: number): void {
		this._width = value
	}
	setHeight(value: number): void {
		this._height = value
	}
	setX(value: number): void {
		this._x = value
	}
	setY(value: number): void {
		this._y = value
	}

	setColor(value: string): void {
		this._color = value
	}
	setCSSClass(value: string): void {
		this._cssclass = value
	}
	setSpeed(value: number) {
		this._speed = value
	}
	setOpacity(value: number) {
		this._opacity = value
	}


	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
