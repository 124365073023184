import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/////////////////////////////////////////////////////////
// Core
////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module';


import { AbstractTile } from './tile/abstract.tile';
import { HorizontalCardCollectionContainer } from './containers/horizontal/horizontal.card.row';


@NgModule({
  declarations: [
    AbstractTile,
    HorizontalCardCollectionContainer
  ],

  imports: [
    CommonModule,
    CoreUIModule
  ],

  providers: [
  ],

  exports: [
    AbstractTile,
    HorizontalCardCollectionContainer
  ]
})
export class TilesCollectionsModule { }
