import { Directive, ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { EventsService } from '@coreui/services/event.service';
import { TypographyModel } from '@coreui/services/typography.service';

@Directive({
  selector: '[heading-display-font-family]',
})

export class HeadingDisplayFontFamilyDirective {
  typographyModel: TypographyModel
  eventsService: EventsService
  changeDetectorRef: ChangeDetectorRef

  constructor(private _eventsService: EventsService, public el: ElementRef,
              public ref: ChangeDetectorRef,
              private _typographyModel: TypographyModel) {

    this.eventsService = _eventsService;
    this.typographyModel = _typographyModel;
    this.changeDetectorRef = ref

    this.updateElement()
    let self = this;
    this.eventsService.on('onRefresh', function (args: any) {
      self.updateElement()
    });
  }

  updateElement() {
    this.el.nativeElement.style.fontFamily = this.typographyModel.headingDisplayFontFamily
    this.ref.markForCheck();
  }
}
