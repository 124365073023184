import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { UIColorModel } from '../../services/ui.color.model.service';
import { EventsService } from '../../services/event.service'

@Component({
  selector: 'outline-button',
  inputs: ['label'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div
      class="std-button {{ btnPressEffect }}"
      [style.border]=_border
      (click)="btnClicked()"
      [style.borderRadius.px]="btnRadius"
      font-family onSecondaryContainer>{{ this.btnLabel }}
    </div>
  `,
})
export class OutlineButtonComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }

  changeDetectorRef: ChangeDetectorRef;
	uiColorModel: UIColorModel
	private eventsService: EventsService

	constructor(public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

    let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self.setBorderColor()
		});

  }

  btnLabel: string = '';
  btnPressEffect: string = '';
  btnOpacity: number = 1;
  btnRadius: number = 18;
  _border: string = "1px solid rgba(0,0,0,1)"

  setLabel(value: string) {
    this.btnLabel = value;
  }

  setBorderColor() {
    this._border = "1px solid " + this.uiColorModel.onSecondaryContainer 
  }

  btnClicked() {
    this.btnPressEffect = 'button-press-animation';
    this.clicked.emit(true);
    setTimeout(() => {
      this.btnPressEffect = '';
    }, 250);
  }

  ngOnInit() {
    this.setBorderColor()
  }

  ngOnChanges() {}

  ngOnDestroy() {}
}
