import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { ApplicationService } from '../services/application.service';
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UILayout } from '@coreui//services/ui.layout.service';

@Component({
	selector: 		'right-sliders',
	encapsulation: ViewEncapsulation.None,

	template:	`

			<div [ngSwitch]=this._slider>

				<div *ngSwitchCase="'add-gradient'">
					<add-gradient-slider></add-gradient-slider>
				</div>
				<div *ngSwitchCase="'file-upload'">
					<file-upload-slider></file-upload-slider>
				</div>

			</div>

	`,

})


export class RightSliders {

	_slider: string = ""
	private eventsService: EventsService
	applicationService: ApplicationService
	uiNavigation: UINavigation
	uiLayout: UILayout


	constructor(_eventsService: EventsService, 
			private _applicationService: ApplicationService,
			private _uiLayout: UILayout,
			private _uiNavigation: UINavigation) {
		this.eventsService = _eventsService
		this.applicationService = _applicationService
		this.uiNavigation = _uiNavigation
		this.uiLayout = _uiLayout

		let self = this;
		self.eventsService.on('onShowRightSlider', function (args: any) {
			self.showSlider(args.id)
		});

		self.eventsService.on('onHideRightSlider', function (args: any) {
			self.hideSlider()
		});

	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}


	showSlider(id: string) {
		this._slider = id
		this.uiLayout.showRightSliderSheet = true
	}
	hideSlider() {
		this.uiLayout.showRightSliderSheet = false
		setTimeout(() => {
			this._slider = ""
		}, 350);	  
	}


}
