
export class IconCollection {

    private icons: any = []
    
    constructor() {
        this.loadData()
    }


    public search(term: string): any {
        let results: any = []
        let i = 0
        for(i=0;i<this.icons.length;i++) {
            if(this.icons[i].includes(term )) {
                results.push(this.icons[i])
            }
        }
        return results
    }


    private addIcon(icon: string) {
		let iconStr = this.parseString(icon)
		this.icons.push(iconStr)
	}

	private parseString(str: string): string {
		let newStr: string[] = str.split(":")
		return newStr[0]
	}

    private loadData() {
		this.addIcon("ab-testing:F01C9")
		this.addIcon("abacus:F16E0")
		this.addIcon("abjad-arabic:F1328")
		this.addIcon("abjad-hebrew:F1329")
		this.addIcon("abugida-devanagari:F132A")
		this.addIcon("abugida-thai:F132B")
		this.addIcon("access-point:F0003")
		this.addIcon("access-point-check:F1538")
		this.addIcon("access-point-minus:F1539")
		this.addIcon("access-point-network:F0002")
		this.addIcon("access-point-network-off:F0BE1")
		this.addIcon("access-point-off:F1511")
		this.addIcon("access-point-plus:F153A")
		this.addIcon("access-point-remove:F153B")
		this.addIcon("account:F0004")
		this.addIcon("account-alert:F0005")
		this.addIcon("account-alert-outline:F0B50")
		this.addIcon("account-arrow-left:F0B51")
		this.addIcon("account-arrow-left-outline:F0B52")
		this.addIcon("account-arrow-right:F0B53")
		this.addIcon("account-arrow-right-outline:F0B54")
		this.addIcon("account-box:F0006")
		this.addIcon("account-box-multiple:F0934")
		this.addIcon("account-box-multiple-outline:F100A")
		this.addIcon("account-box-outline:F0007")
		this.addIcon("account-cancel:F12DF")
		this.addIcon("account-cancel-outline:F12E0")
		this.addIcon("account-cash:F1097")
		this.addIcon("account-cash-outline:F1098")
		this.addIcon("account-check:F0008")
		this.addIcon("account-check-outline:F0BE2")
		this.addIcon("account-child:F0A89")
		this.addIcon("account-child-circle:F0A8A")
		this.addIcon("account-child-outline:F10C8")
		this.addIcon("account-circle:F0009")
		this.addIcon("account-circle-outline:F0B55")
		this.addIcon("account-clock:F0B56")
		this.addIcon("account-clock-outline:F0B57")
		this.addIcon("account-cog:F1370")
		this.addIcon("account-cog-outline:F1371")
		this.addIcon("account-convert:F000A")
		this.addIcon("account-convert-outline:F1301")
		this.addIcon("account-cowboy-hat:F0E9B")
		this.addIcon("account-cowboy-hat-outline:F17F3")
		this.addIcon("account-details:F0631")
		this.addIcon("account-details-outline:F1372")
		this.addIcon("account-edit:F06BC")
		this.addIcon("account-edit-outline:F0FFB")
		this.addIcon("account-eye:F0420")
		this.addIcon("account-eye-outline:F127B")
		this.addIcon("account-filter:F0936")
		this.addIcon("account-filter-outline:F0F9D")
		this.addIcon("account-group:F0849")
		this.addIcon("account-group-outline:F0B58")
		this.addIcon("account-hard-hat:F05B5")
		this.addIcon("account-heart:F0899")
		this.addIcon("account-heart-outline:F0BE3")
		this.addIcon("account-injury:F1815")
		this.addIcon("account-injury-outline:F1816")
		this.addIcon("account-key:F000B")
		this.addIcon("account-key-outline:F0BE4")
		this.addIcon("account-lock:F115E")
		this.addIcon("account-lock-outline:F115F")
		this.addIcon("account-minus:F000D")
		this.addIcon("account-minus-outline:F0AEC")
		this.addIcon("account-multiple:F000E")
		this.addIcon("account-multiple-check:F08C5")
		this.addIcon("account-multiple-check-outline:F11FE")
		this.addIcon("account-multiple-minus:F05D3")
		this.addIcon("account-multiple-minus-outline:F0BE5")
		this.addIcon("account-multiple-outline:F000F")
		this.addIcon("account-multiple-plus:F0010")
		this.addIcon("account-multiple-plus-outline:F0800")
		this.addIcon("account-multiple-remove:F120A")
		this.addIcon("account-multiple-remove-outline:F120B")
		this.addIcon("account-music:F0803")
		this.addIcon("account-music-outline:F0CE9")
		this.addIcon("account-network:F0011")
		this.addIcon("account-network-outline:F0BE6")
		this.addIcon("account-off:F0012")
		this.addIcon("account-off-outline:F0BE7")
		this.addIcon("account-outline:F0013")
		this.addIcon("account-plus:F0014")
		this.addIcon("account-plus-outline:F0801")
		this.addIcon("account-question:F0B59")
		this.addIcon("account-question-outline:F0B5A")
		this.addIcon("account-reactivate:F152B")
		this.addIcon("account-reactivate-outline:F152C")
		this.addIcon("account-remove:F0015")
		this.addIcon("account-remove-outline:F0AED")
		this.addIcon("account-search:F0016")
		this.addIcon("account-search-outline:F0935")
		this.addIcon("account-settings:F0630")
		this.addIcon("account-settings-outline:F10C9")
		this.addIcon("account-star:F0017")
		this.addIcon("account-star-outline:F0BE8")
		this.addIcon("account-supervisor:F0A8B")
		this.addIcon("account-supervisor-circle:F0A8C")
		this.addIcon("account-supervisor-circle-outline:F14EC")
		this.addIcon("account-supervisor-outline:F112D")
		this.addIcon("account-switch:F0019")
		this.addIcon("account-switch-outline:F04CB")
		this.addIcon("account-tie:F0CE3")
		this.addIcon("account-tie-outline:F10CA")
		this.addIcon("account-tie-voice:F1308")
		this.addIcon("account-tie-voice-off:F130A")
		this.addIcon("account-tie-voice-off-outline:F130B")
		this.addIcon("account-tie-voice-outline:F1309")
		this.addIcon("account-voice:F05CB")
		this.addIcon("account-voice-off:F0ED4")
		this.addIcon("adjust:F001A")
		this.addIcon("air-conditioner:F001B")
		this.addIcon("air-filter:F0D43")
		this.addIcon("air-horn:F0DAC")
		this.addIcon("air-humidifier:F1099")
		this.addIcon("air-humidifier-off:F1466")
		this.addIcon("air-purifier:F0D44")
		this.addIcon("airbag:F0BE9")
		this.addIcon("airballoon:F001C")
		this.addIcon("airballoon-outline:F100B")
		this.addIcon("airplane:F001D")
		this.addIcon("airplane-landing:F05D4")
		this.addIcon("airplane-off:F001E")
		this.addIcon("airplane-takeoff:F05D5")
		this.addIcon("airport:F084B")
		this.addIcon("alarm:F0020")
		this.addIcon("alarm-bell:F078E")
		this.addIcon("alarm-check:F0021")
		this.addIcon("alarm-light:F078F")
		this.addIcon("alarm-light-off:F171E")
		this.addIcon("alarm-light-off-outline:F171F")
		this.addIcon("alarm-light-outline:F0BEA")
		this.addIcon("alarm-multiple:F0022")
		this.addIcon("alarm-note:F0E71")
		this.addIcon("alarm-note-off:F0E72")
		this.addIcon("alarm-off:F0023")
		this.addIcon("alarm-panel:F15C4")
		this.addIcon("alarm-panel-outline:F15C5")
		this.addIcon("alarm-plus:F0024")
		this.addIcon("alarm-snooze:F068E")
		this.addIcon("album:F0025")
		this.addIcon("alert:F0026")
		this.addIcon("alert-box:F0027")
		this.addIcon("alert-box-outline:F0CE4")
		this.addIcon("alert-circle:F0028")
		this.addIcon("alert-circle-check:F11ED")
		this.addIcon("alert-circle-check-outline:F11EE")
		this.addIcon("alert-circle-outline:F05D6")
		this.addIcon("alert-decagram:F06BD")
		this.addIcon("alert-decagram-outline:F0CE5")
		this.addIcon("alert-minus:F14BB")
		this.addIcon("alert-minus-outline:F14BE")
		this.addIcon("alert-octagon:F0029")
		this.addIcon("alert-octagon-outline:F0CE6")
		this.addIcon("alert-octagram:F0767")
		this.addIcon("alert-octagram-outline:F0CE7")
		this.addIcon("alert-outline:F002A")
		this.addIcon("alert-plus:F14BA")
		this.addIcon("alert-plus-outline:F14BD")
		this.addIcon("alert-remove:F14BC")
		this.addIcon("alert-remove-outline:F14BF")
		this.addIcon("alert-rhombus:F11CE")
		this.addIcon("alert-rhombus-outline:F11CF")
		this.addIcon("alien:F089A")
		this.addIcon("alien-outline:F10CB")
		this.addIcon("align-horizontal-center:F11C3")
		this.addIcon("align-horizontal-left:F11C2")
		this.addIcon("align-horizontal-right:F11C4")
		this.addIcon("align-vertical-bottom:F11C5")
		this.addIcon("align-vertical-center:F11C6")
		this.addIcon("align-vertical-top:F11C7")
		this.addIcon("all-inclusive:F06BE")
		this.addIcon("allergy:F1258")
		this.addIcon("alpha:F002B")
		this.addIcon("alpha-a:F0AEE")
		this.addIcon("alpha-a-box:F0B08")
		this.addIcon("alpha-a-box-outline:F0BEB")
		this.addIcon("alpha-a-circle:F0BEC")
		this.addIcon("alpha-a-circle-outline:F0BED")
		this.addIcon("alpha-b:F0AEF")
		this.addIcon("alpha-b-box:F0B09")
		this.addIcon("alpha-b-box-outline:F0BEE")
		this.addIcon("alpha-b-circle:F0BEF")
		this.addIcon("alpha-b-circle-outline:F0BF0")
		this.addIcon("alpha-c:F0AF0")
		this.addIcon("alpha-c-box:F0B0A")
		this.addIcon("alpha-c-box-outline:F0BF1")
		this.addIcon("alpha-c-circle:F0BF2")
		this.addIcon("alpha-c-circle-outline:F0BF3")
		this.addIcon("alpha-d:F0AF1")
		this.addIcon("alpha-d-box:F0B0B")
		this.addIcon("alpha-d-box-outline:F0BF4")
		this.addIcon("alpha-d-circle:F0BF5")
		this.addIcon("alpha-d-circle-outline:F0BF6")
		this.addIcon("alpha-e:F0AF2")
		this.addIcon("alpha-e-box:F0B0C")
		this.addIcon("alpha-e-box-outline:F0BF7")
		this.addIcon("alpha-e-circle:F0BF8")
		this.addIcon("alpha-e-circle-outline:F0BF9")
		this.addIcon("alpha-f:F0AF3")
		this.addIcon("alpha-f-box:F0B0D")
		this.addIcon("alpha-f-box-outline:F0BFA")
		this.addIcon("alpha-f-circle:F0BFB")
		this.addIcon("alpha-f-circle-outline:F0BFC")
		this.addIcon("alpha-g:F0AF4")
		this.addIcon("alpha-g-box:F0B0E")
		this.addIcon("alpha-g-box-outline:F0BFD")
		this.addIcon("alpha-g-circle:F0BFE")
		this.addIcon("alpha-g-circle-outline:F0BFF")
		this.addIcon("alpha-h:F0AF5")
		this.addIcon("alpha-h-box:F0B0F")
		this.addIcon("alpha-h-box-outline:F0C00")
		this.addIcon("alpha-h-circle:F0C01")
		this.addIcon("alpha-h-circle-outline:F0C02")
		this.addIcon("alpha-i:F0AF6")
		this.addIcon("alpha-i-box:F0B10")
		this.addIcon("alpha-i-box-outline:F0C03")
		this.addIcon("alpha-i-circle:F0C04")
		this.addIcon("alpha-i-circle-outline:F0C05")
		this.addIcon("alpha-j:F0AF7")
		this.addIcon("alpha-j-box:F0B11")
		this.addIcon("alpha-j-box-outline:F0C06")
		this.addIcon("alpha-j-circle:F0C07")
		this.addIcon("alpha-j-circle-outline:F0C08")
		this.addIcon("alpha-k:F0AF8")
		this.addIcon("alpha-k-box:F0B12")
		this.addIcon("alpha-k-box-outline:F0C09")
		this.addIcon("alpha-k-circle:F0C0A")
		this.addIcon("alpha-k-circle-outline:F0C0B")
		this.addIcon("alpha-l:F0AF9")
		this.addIcon("alpha-l-box:F0B13")
		this.addIcon("alpha-l-box-outline:F0C0C")
		this.addIcon("alpha-l-circle:F0C0D")
		this.addIcon("alpha-l-circle-outline:F0C0E")
		this.addIcon("alpha-m:F0AFA")
		this.addIcon("alpha-m-box:F0B14")
		this.addIcon("alpha-m-box-outline:F0C0F")
		this.addIcon("alpha-m-circle:F0C10")
		this.addIcon("alpha-m-circle-outline:F0C11")
		this.addIcon("alpha-n:F0AFB")
		this.addIcon("alpha-n-box:F0B15")
		this.addIcon("alpha-n-box-outline:F0C12")
		this.addIcon("alpha-n-circle:F0C13")
		this.addIcon("alpha-n-circle-outline:F0C14")
		this.addIcon("alpha-o:F0AFC")
		this.addIcon("alpha-o-box:F0B16")
		this.addIcon("alpha-o-box-outline:F0C15")
		this.addIcon("alpha-o-circle:F0C16")
		this.addIcon("alpha-o-circle-outline:F0C17")
		this.addIcon("alpha-p:F0AFD")
		this.addIcon("alpha-p-box:F0B17")
		this.addIcon("alpha-p-box-outline:F0C18")
		this.addIcon("alpha-p-circle:F0C19")
		this.addIcon("alpha-p-circle-outline:F0C1A")
		this.addIcon("alpha-q:F0AFE")
		this.addIcon("alpha-q-box:F0B18")
		this.addIcon("alpha-q-box-outline:F0C1B")
		this.addIcon("alpha-q-circle:F0C1C")
		this.addIcon("alpha-q-circle-outline:F0C1D")
		this.addIcon("alpha-r:F0AFF")
		this.addIcon("alpha-r-box:F0B19")
		this.addIcon("alpha-r-box-outline:F0C1E")
		this.addIcon("alpha-r-circle:F0C1F")
		this.addIcon("alpha-r-circle-outline:F0C20")
		this.addIcon("alpha-s:F0B00")
		this.addIcon("alpha-s-box:F0B1A")
		this.addIcon("alpha-s-box-outline:F0C21")
		this.addIcon("alpha-s-circle:F0C22")
		this.addIcon("alpha-s-circle-outline:F0C23")
		this.addIcon("alpha-t:F0B01")
		this.addIcon("alpha-t-box:F0B1B")
		this.addIcon("alpha-t-box-outline:F0C24")
		this.addIcon("alpha-t-circle:F0C25")
		this.addIcon("alpha-t-circle-outline:F0C26")
		this.addIcon("alpha-u:F0B02")
		this.addIcon("alpha-u-box:F0B1C")
		this.addIcon("alpha-u-box-outline:F0C27")
		this.addIcon("alpha-u-circle:F0C28")
		this.addIcon("alpha-u-circle-outline:F0C29")
		this.addIcon("alpha-v:F0B03")
		this.addIcon("alpha-v-box:F0B1D")
		this.addIcon("alpha-v-box-outline:F0C2A")
		this.addIcon("alpha-v-circle:F0C2B")
		this.addIcon("alpha-v-circle-outline:F0C2C")
		this.addIcon("alpha-w:F0B04")
		this.addIcon("alpha-w-box:F0B1E")
		this.addIcon("alpha-w-box-outline:F0C2D")
		this.addIcon("alpha-w-circle:F0C2E")
		this.addIcon("alpha-w-circle-outline:F0C2F")
		this.addIcon("alpha-x:F0B05")
		this.addIcon("alpha-x-box:F0B1F")
		this.addIcon("alpha-x-box-outline:F0C30")
		this.addIcon("alpha-x-circle:F0C31")
		this.addIcon("alpha-x-circle-outline:F0C32")
		this.addIcon("alpha-y:F0B06")
		this.addIcon("alpha-y-box:F0B20")
		this.addIcon("alpha-y-box-outline:F0C33")
		this.addIcon("alpha-y-circle:F0C34")
		this.addIcon("alpha-y-circle-outline:F0C35")
		this.addIcon("alpha-z:F0B07")
		this.addIcon("alpha-z-box:F0B21")
		this.addIcon("alpha-z-box-outline:F0C36")
		this.addIcon("alpha-z-circle:F0C37")
		this.addIcon("alpha-z-circle-outline:F0C38")
		this.addIcon("alphabet-aurebesh:F132C")
		this.addIcon("alphabet-cyrillic:F132D")
		this.addIcon("alphabet-greek:F132E")
		this.addIcon("alphabet-latin:F132F")
		this.addIcon("alphabet-piqad:F1330")
		this.addIcon("alphabet-tengwar:F1337")
		this.addIcon("alphabetical:F002C")
		this.addIcon("alphabetical-off:F100C")
		this.addIcon("alphabetical-variant:F100D")
		this.addIcon("alphabetical-variant-off:F100E")
		this.addIcon("altimeter:F05D7")
		this.addIcon("ambulance:F002F")
		this.addIcon("ammunition:F0CE8")
		this.addIcon("ampersand:F0A8D")
		this.addIcon("amplifier:F0030")
		this.addIcon("amplifier-off:F11B5")
		this.addIcon("anchor:F0031")
		this.addIcon("android:F0032")
		this.addIcon("android-messages:F0D45")
		this.addIcon("android-studio:F0034")
		this.addIcon("angle-acute:F0937")
		this.addIcon("angle-obtuse:F0938")
		this.addIcon("angle-right:F0939")
		this.addIcon("angular:F06B2")
		this.addIcon("angularjs:F06BF")
		this.addIcon("animation:F05D8")
		this.addIcon("animation-outline:F0A8F")
		this.addIcon("animation-play:F093A")
		this.addIcon("animation-play-outline:F0A90")
		this.addIcon("ansible:F109A")
		this.addIcon("antenna:F1119")
		this.addIcon("anvil:F089B")
		this.addIcon("apache-kafka:F100F")
		this.addIcon("api:F109B")
		this.addIcon("api-off:F1257")
		this.addIcon("apple:F0035")
		this.addIcon("apple-finder:F0036")
		this.addIcon("apple-icloud:F0038")
		this.addIcon("apple-ios:F0037")
		this.addIcon("apple-keyboard-caps:F0632")
		this.addIcon("apple-keyboard-command:F0633")
		this.addIcon("apple-keyboard-control:F0634")
		this.addIcon("apple-keyboard-option:F0635")
		this.addIcon("apple-keyboard-shift:F0636")
		this.addIcon("apple-safari:F0039")
		this.addIcon("application:F08C6")
		this.addIcon("application-array:F10F5")
		this.addIcon("application-array-outline:F10F6")
		this.addIcon("application-braces:F10F7")
		this.addIcon("application-braces-outline:F10F8")
		this.addIcon("application-brackets:F0C8B")
		this.addIcon("application-brackets-outline:F0C8C")
		this.addIcon("application-cog:F0675")
		this.addIcon("application-cog-outline:F1577")
		this.addIcon("application-edit:F00AE")
		this.addIcon("application-edit-outline:F0619")
		this.addIcon("application-export:F0DAD")
		this.addIcon("application-import:F0DAE")
		this.addIcon("application-outline:F0614")
		this.addIcon("application-parentheses:F10F9")
		this.addIcon("application-parentheses-outline:F10FA")
		this.addIcon("application-settings:F0B60")
		this.addIcon("application-settings-outline:F1555")
		this.addIcon("application-variable:F10FB")
		this.addIcon("application-variable-outline:F10FC")
		this.addIcon("approximately-equal:F0F9E")
		this.addIcon("approximately-equal-box:F0F9F")
		this.addIcon("apps:F003B")
		this.addIcon("apps-box:F0D46")
		this.addIcon("arch:F08C7")
		this.addIcon("archive:F003C")
		this.addIcon("archive-alert:F14FD")
		this.addIcon("archive-alert-outline:F14FE")
		this.addIcon("archive-arrow-down:F1259")
		this.addIcon("archive-arrow-down-outline:F125A")
		this.addIcon("archive-arrow-up:F125B")
		this.addIcon("archive-arrow-up-outline:F125C")
		this.addIcon("archive-cancel:F174B")
		this.addIcon("archive-cancel-outline:F174C")
		this.addIcon("archive-check:F174D")
		this.addIcon("archive-check-outline:F174E")
		this.addIcon("archive-clock:F174F")
		this.addIcon("archive-clock-outline:F1750")
		this.addIcon("archive-cog:F1751")
		this.addIcon("archive-cog-outline:F1752")
		this.addIcon("archive-edit:F1753")
		this.addIcon("archive-edit-outline:F1754")
		this.addIcon("archive-eye:F1755")
		this.addIcon("archive-eye-outline:F1756")
		this.addIcon("archive-lock:F1757")
		this.addIcon("archive-lock-open:F1758")
		this.addIcon("archive-lock-open-outline:F1759")
		this.addIcon("archive-lock-outline:F175A")
		this.addIcon("archive-marker:F175B")
		this.addIcon("archive-marker-outline:F175C")
		this.addIcon("archive-minus:F175D")
		this.addIcon("archive-minus-outline:F175E")
		this.addIcon("archive-music:F175F")
		this.addIcon("archive-music-outline:F1760")
		this.addIcon("archive-off:F1761")
		this.addIcon("archive-off-outline:F1762")
		this.addIcon("archive-outline:F120E")
		this.addIcon("archive-plus:F1763")
		this.addIcon("archive-plus-outline:F1764")
		this.addIcon("archive-refresh:F1765")
		this.addIcon("archive-refresh-outline:F1766")
		this.addIcon("archive-remove:F1767")
		this.addIcon("archive-remove-outline:F1768")
		this.addIcon("archive-search:F1769")
		this.addIcon("archive-search-outline:F176A")
		this.addIcon("archive-settings:F176B")
		this.addIcon("archive-settings-outline:F176C")
		this.addIcon("archive-star:F176D")
		this.addIcon("archive-star-outline:F176E")
		this.addIcon("archive-sync:F176F")
		this.addIcon("archive-sync-outline:F1770")
		this.addIcon("arm-flex:F0FD7")
		this.addIcon("arm-flex-outline:F0FD6")
		this.addIcon("arrange-bring-forward:F003D")
		this.addIcon("arrange-bring-to-front:F003E")
		this.addIcon("arrange-send-backward:F003F")
		this.addIcon("arrange-send-to-back:F0040")
		this.addIcon("arrow-all:F0041")
		this.addIcon("arrow-bottom-left:F0042")
		this.addIcon("arrow-bottom-left-bold-outline:F09B7")
		this.addIcon("arrow-bottom-left-thick:F09B8")
		this.addIcon("arrow-bottom-left-thin-circle-outline:F1596")
		this.addIcon("arrow-bottom-right:F0043")
		this.addIcon("arrow-bottom-right-bold-outline:F09B9")
		this.addIcon("arrow-bottom-right-thick:F09BA")
		this.addIcon("arrow-bottom-right-thin-circle-outline:F1595")
		this.addIcon("arrow-collapse:F0615")
		this.addIcon("arrow-collapse-all:F0044")
		this.addIcon("arrow-collapse-down:F0792")
		this.addIcon("arrow-collapse-horizontal:F084C")
		this.addIcon("arrow-collapse-left:F0793")
		this.addIcon("arrow-collapse-right:F0794")
		this.addIcon("arrow-collapse-up:F0795")
		this.addIcon("arrow-collapse-vertical:F084D")
		this.addIcon("arrow-decision:F09BB")
		this.addIcon("arrow-decision-auto:F09BC")
		this.addIcon("arrow-decision-auto-outline:F09BD")
		this.addIcon("arrow-decision-outline:F09BE")
		this.addIcon("arrow-down:F0045")
		this.addIcon("arrow-down-bold:F072E")
		this.addIcon("arrow-down-bold-box:F072F")
		this.addIcon("arrow-down-bold-box-outline:F0730")
		this.addIcon("arrow-down-bold-circle:F0047")
		this.addIcon("arrow-down-bold-circle-outline:F0048")
		this.addIcon("arrow-down-bold-hexagon-outline:F0049")
		this.addIcon("arrow-down-bold-outline:F09BF")
		this.addIcon("arrow-down-box:F06C0")
		this.addIcon("arrow-down-circle:F0CDB")
		this.addIcon("arrow-down-circle-outline:F0CDC")
		this.addIcon("arrow-down-drop-circle:F004A")
		this.addIcon("arrow-down-drop-circle-outline:F004B")
		this.addIcon("arrow-down-left:F17A1")
		this.addIcon("arrow-down-left-bold:F17A2")
		this.addIcon("arrow-down-right:F17A3")
		this.addIcon("arrow-down-right-bold:F17A4")
		this.addIcon("arrow-down-thick:F0046")
		this.addIcon("arrow-down-thin-circle-outline:F1599")
		this.addIcon("arrow-expand:F0616")
		this.addIcon("arrow-expand-all:F004C")
		this.addIcon("arrow-expand-down:F0796")
		this.addIcon("arrow-expand-horizontal:F084E")
		this.addIcon("arrow-expand-left:F0797")
		this.addIcon("arrow-expand-right:F0798")
		this.addIcon("arrow-expand-up:F0799")
		this.addIcon("arrow-expand-vertical:F084F")
		this.addIcon("arrow-horizontal-lock:F115B")
		this.addIcon("arrow-left:F004D")
		this.addIcon("arrow-left-bold:F0731")
		this.addIcon("arrow-left-bold-box:F0732")
		this.addIcon("arrow-left-bold-box-outline:F0733")
		this.addIcon("arrow-left-bold-circle:F004F")
		this.addIcon("arrow-left-bold-circle-outline:F0050")
		this.addIcon("arrow-left-bold-hexagon-outline:F0051")
		this.addIcon("arrow-left-bold-outline:F09C0")
		this.addIcon("arrow-left-bottom:F17A5")
		this.addIcon("arrow-left-bottom-bold:F17A6")
		this.addIcon("arrow-left-box:F06C1")
		this.addIcon("arrow-left-circle:F0CDD")
		this.addIcon("arrow-left-circle-outline:F0CDE")
		this.addIcon("arrow-left-drop-circle:F0052")
		this.addIcon("arrow-left-drop-circle-outline:F0053")
		this.addIcon("arrow-left-right:F0E73")
		this.addIcon("arrow-left-right-bold:F0E74")
		this.addIcon("arrow-left-right-bold-outline:F09C1")
		this.addIcon("arrow-left-thick:F004E")
		this.addIcon("arrow-left-thin-circle-outline:F159A")
		this.addIcon("arrow-left-top:F17A7")
		this.addIcon("arrow-left-top-bold:F17A8")
		this.addIcon("arrow-right:F0054")
		this.addIcon("arrow-right-bold:F0734")
		this.addIcon("arrow-right-bold-box:F0735")
		this.addIcon("arrow-right-bold-box-outline:F0736")
		this.addIcon("arrow-right-bold-circle:F0056")
		this.addIcon("arrow-right-bold-circle-outline:F0057")
		this.addIcon("arrow-right-bold-hexagon-outline:F0058")
		this.addIcon("arrow-right-bold-outline:F09C2")
		this.addIcon("arrow-right-bottom:F17A9")
		this.addIcon("arrow-right-bottom-bold:F17AA")
		this.addIcon("arrow-right-box:F06C2")
		this.addIcon("arrow-right-circle:F0CDF")
		this.addIcon("arrow-right-circle-outline:F0CE0")
		this.addIcon("arrow-right-drop-circle:F0059")
		this.addIcon("arrow-right-drop-circle-outline:F005A")
		this.addIcon("arrow-right-thick:F0055")
		this.addIcon("arrow-right-thin-circle-outline:F1598")
		this.addIcon("arrow-right-top:F17AB")
		this.addIcon("arrow-right-top-bold:F17AC")
		this.addIcon("arrow-split-horizontal:F093B")
		this.addIcon("arrow-split-vertical:F093C")
		this.addIcon("arrow-top-left:F005B")
		this.addIcon("arrow-top-left-bold-outline:F09C3")
		this.addIcon("arrow-top-left-bottom-right:F0E75")
		this.addIcon("arrow-top-left-bottom-right-bold:F0E76")
		this.addIcon("arrow-top-left-thick:F09C4")
		this.addIcon("arrow-top-left-thin-circle-outline:F1593")
		this.addIcon("arrow-top-right:F005C")
		this.addIcon("arrow-top-right-bold-outline:F09C5")
		this.addIcon("arrow-top-right-bottom-left:F0E77")
		this.addIcon("arrow-top-right-bottom-left-bold:F0E78")
		this.addIcon("arrow-top-right-thick:F09C6")
		this.addIcon("arrow-top-right-thin-circle-outline:F1594")
		this.addIcon("arrow-u-down-left:F17AD")
		this.addIcon("arrow-u-down-left-bold:F17AE")
		this.addIcon("arrow-u-down-right:F17AF")
		this.addIcon("arrow-u-down-right-bold:F17B0")
		this.addIcon("arrow-u-left-bottom:F17B1")
		this.addIcon("arrow-u-left-bottom-bold:F17B2")
		this.addIcon("arrow-u-left-top:F17B3")
		this.addIcon("arrow-u-left-top-bold:F17B4")
		this.addIcon("arrow-u-right-bottom:F17B5")
		this.addIcon("arrow-u-right-bottom-bold:F17B6")
		this.addIcon("arrow-u-right-top:F17B7")
		this.addIcon("arrow-u-right-top-bold:F17B8")
		this.addIcon("arrow-u-up-left:F17B9")
		this.addIcon("arrow-u-up-left-bold:F17BA")
		this.addIcon("arrow-u-up-right:F17BB")
		this.addIcon("arrow-u-up-right-bold:F17BC")
		this.addIcon("arrow-up:F005D")
		this.addIcon("arrow-up-bold:F0737")
		this.addIcon("arrow-up-bold-box:F0738")
		this.addIcon("arrow-up-bold-box-outline:F0739")
		this.addIcon("arrow-up-bold-circle:F005F")
		this.addIcon("arrow-up-bold-circle-outline:F0060")
		this.addIcon("arrow-up-bold-hexagon-outline:F0061")
		this.addIcon("arrow-up-bold-outline:F09C7")
		this.addIcon("arrow-up-box:F06C3")
		this.addIcon("arrow-up-circle:F0CE1")
		this.addIcon("arrow-up-circle-outline:F0CE2")
		this.addIcon("arrow-up-down:F0E79")
		this.addIcon("arrow-up-down-bold:F0E7A")
		this.addIcon("arrow-up-down-bold-outline:F09C8")
		this.addIcon("arrow-up-drop-circle:F0062")
		this.addIcon("arrow-up-drop-circle-outline:F0063")
		this.addIcon("arrow-up-left:F17BD")
		this.addIcon("arrow-up-left-bold:F17BE")
		this.addIcon("arrow-up-right:F17BF")
		this.addIcon("arrow-up-right-bold:F17C0")
		this.addIcon("arrow-up-thick:F005E")
		this.addIcon("arrow-up-thin-circle-outline:F1597")
		this.addIcon("arrow-vertical-lock:F115C")
		this.addIcon("artstation:F0B5B")
		this.addIcon("aspect-ratio:F0A24")
		this.addIcon("assistant:F0064")
		this.addIcon("asterisk:F06C4")
		this.addIcon("at:F0065")
		this.addIcon("atlassian:F0804")
		this.addIcon("atm:F0D47")
		this.addIcon("atom:F0768")
		this.addIcon("atom-variant:F0E7B")
		this.addIcon("attachment:F0066")
		this.addIcon("audio-video:F093D")
		this.addIcon("audio-video-off:F11B6")
		this.addIcon("augmented-reality:F0850")
		this.addIcon("auto-download:F137E")
		this.addIcon("auto-fix:F0068")
		this.addIcon("auto-upload:F0069")
		this.addIcon("autorenew:F006A")
		this.addIcon("av-timer:F006B")
		this.addIcon("aws:F0E0F")
		this.addIcon("axe:F08C8")
		this.addIcon("axis:F0D48")
		this.addIcon("axis-arrow:F0D49")
		this.addIcon("axis-arrow-info:F140E")
		this.addIcon("axis-arrow-lock:F0D4A")
		this.addIcon("axis-lock:F0D4B")
		this.addIcon("axis-x-arrow:F0D4C")
		this.addIcon("axis-x-arrow-lock:F0D4D")
		this.addIcon("axis-x-rotate-clockwise:F0D4E")
		this.addIcon("axis-x-rotate-counterclockwise:F0D4F")
		this.addIcon("axis-x-y-arrow-lock:F0D50")
		this.addIcon("axis-y-arrow:F0D51")
		this.addIcon("axis-y-arrow-lock:F0D52")
		this.addIcon("axis-y-rotate-clockwise:F0D53")
		this.addIcon("axis-y-rotate-counterclockwise:F0D54")
		this.addIcon("axis-z-arrow:F0D55")
		this.addIcon("axis-z-arrow-lock:F0D56")
		this.addIcon("axis-z-rotate-clockwise:F0D57")
		this.addIcon("axis-z-rotate-counterclockwise:F0D58")
		this.addIcon("babel:F0A25")
		this.addIcon("baby:F006C")
		this.addIcon("baby-bottle:F0F39")
		this.addIcon("baby-bottle-outline:F0F3A")
		this.addIcon("baby-buggy:F13E0")
		this.addIcon("baby-carriage:F068F")
		this.addIcon("baby-carriage-off:F0FA0")
		this.addIcon("baby-face:F0E7C")
		this.addIcon("baby-face-outline:F0E7D")
		this.addIcon("backburger:F006D")
		this.addIcon("backspace:F006E")
		this.addIcon("backspace-outline:F0B5C")
		this.addIcon("backspace-reverse:F0E7E")
		this.addIcon("backspace-reverse-outline:F0E7F")
		this.addIcon("backup-restore:F006F")
		this.addIcon("bacteria:F0ED5")
		this.addIcon("bacteria-outline:F0ED6")
		this.addIcon("badge-account:F0DA7")
		this.addIcon("badge-account-alert:F0DA8")
		this.addIcon("badge-account-alert-outline:F0DA9")
		this.addIcon("badge-account-horizontal:F0E0D")
		this.addIcon("badge-account-horizontal-outline:F0E0E")
		this.addIcon("badge-account-outline:F0DAA")
		this.addIcon("badminton:F0851")
		this.addIcon("bag-carry-on:F0F3B")
		this.addIcon("bag-carry-on-check:F0D65")
		this.addIcon("bag-carry-on-off:F0F3C")
		this.addIcon("bag-checked:F0F3D")
		this.addIcon("bag-personal:F0E10")
		this.addIcon("bag-personal-off:F0E11")
		this.addIcon("bag-personal-off-outline:F0E12")
		this.addIcon("bag-personal-outline:F0E13")
		this.addIcon("bag-suitcase:F158B")
		this.addIcon("bag-suitcase-off:F158D")
		this.addIcon("bag-suitcase-off-outline:F158E")
		this.addIcon("bag-suitcase-outline:F158C")
		this.addIcon("baguette:F0F3E")
		this.addIcon("balcony:F1817")
		this.addIcon("balloon:F0A26")
		this.addIcon("ballot:F09C9")
		this.addIcon("ballot-outline:F09CA")
		this.addIcon("ballot-recount:F0C39")
		this.addIcon("ballot-recount-outline:F0C3A")
		this.addIcon("bandage:F0DAF")
		this.addIcon("bank:F0070")
		this.addIcon("bank-check:F1655")
		this.addIcon("bank-minus:F0DB0")
		this.addIcon("bank-off:F1656")
		this.addIcon("bank-off-outline:F1657")
		this.addIcon("bank-outline:F0E80")
		this.addIcon("bank-plus:F0DB1")
		this.addIcon("bank-remove:F0DB2")
		this.addIcon("bank-transfer:F0A27")
		this.addIcon("bank-transfer-in:F0A28")
		this.addIcon("bank-transfer-out:F0A29")
		this.addIcon("barcode:F0071")
		this.addIcon("barcode-off:F1236")
		this.addIcon("barcode-scan:F0072")
		this.addIcon("barley:F0073")
		this.addIcon("barley-off:F0B5D")
		this.addIcon("barn:F0B5E")
		this.addIcon("barrel:F0074")
		this.addIcon("baseball:F0852")
		this.addIcon("baseball-bat:F0853")
		this.addIcon("baseball-diamond:F15EC")
		this.addIcon("baseball-diamond-outline:F15ED")
		this.addIcon("bash:F1183")
		this.addIcon("basket:F0076")
		this.addIcon("basket-fill:F0077")
		this.addIcon("basket-minus:F1523")
		this.addIcon("basket-minus-outline:F1524")
		this.addIcon("basket-off:F1525")
		this.addIcon("basket-off-outline:F1526")
		this.addIcon("basket-outline:F1181")
		this.addIcon("basket-plus:F1527")
		this.addIcon("basket-plus-outline:F1528")
		this.addIcon("basket-remove:F1529")
		this.addIcon("basket-remove-outline:F152A")
		this.addIcon("basket-unfill:F0078")
		this.addIcon("basketball:F0806")
		this.addIcon("basketball-hoop:F0C3B")
		this.addIcon("basketball-hoop-outline:F0C3C")
		this.addIcon("bat:F0B5F")
		this.addIcon("bathtub:F1818")
		this.addIcon("bathtub-outline:F1819")
		this.addIcon("battery:F0079")
		this.addIcon("battery-10:F007A")
		this.addIcon("battery-10-bluetooth:F093E")
		this.addIcon("battery-20:F007B")
		this.addIcon("battery-20-bluetooth:F093F")
		this.addIcon("battery-30:F007C")
		this.addIcon("battery-30-bluetooth:F0940")
		this.addIcon("battery-40:F007D")
		this.addIcon("battery-40-bluetooth:F0941")
		this.addIcon("battery-50:F007E")
		this.addIcon("battery-50-bluetooth:F0942")
		this.addIcon("battery-60:F007F")
		this.addIcon("battery-60-bluetooth:F0943")
		this.addIcon("battery-70:F0080")
		this.addIcon("battery-70-bluetooth:F0944")
		this.addIcon("battery-80:F0081")
		this.addIcon("battery-80-bluetooth:F0945")
		this.addIcon("battery-90:F0082")
		this.addIcon("battery-90-bluetooth:F0946")
		this.addIcon("battery-alert:F0083")
		this.addIcon("battery-alert-bluetooth:F0947")
		this.addIcon("battery-alert-variant:F10CC")
		this.addIcon("battery-alert-variant-outline:F10CD")
		this.addIcon("battery-arrow-down:F17DE")
		this.addIcon("battery-arrow-down-outline:F17DF")
		this.addIcon("battery-arrow-up:F17E0")
		this.addIcon("battery-arrow-up-outline:F17E1")
		this.addIcon("battery-bluetooth:F0948")
		this.addIcon("battery-bluetooth-variant:F0949")
		this.addIcon("battery-charging:F0084")
		this.addIcon("battery-charging-10:F089C")
		this.addIcon("battery-charging-100:F0085")
		this.addIcon("battery-charging-20:F0086")
		this.addIcon("battery-charging-30:F0087")
		this.addIcon("battery-charging-40:F0088")
		this.addIcon("battery-charging-50:F089D")
		this.addIcon("battery-charging-60:F0089")
		this.addIcon("battery-charging-70:F089E")
		this.addIcon("battery-charging-80:F008A")
		this.addIcon("battery-charging-90:F008B")
		this.addIcon("battery-charging-high:F12A6")
		this.addIcon("battery-charging-low:F12A4")
		this.addIcon("battery-charging-medium:F12A5")
		this.addIcon("battery-charging-outline:F089F")
		this.addIcon("battery-charging-wireless:F0807")
		this.addIcon("battery-charging-wireless-10:F0808")
		this.addIcon("battery-charging-wireless-20:F0809")
		this.addIcon("battery-charging-wireless-30:F080A")
		this.addIcon("battery-charging-wireless-40:F080B")
		this.addIcon("battery-charging-wireless-50:F080C")
		this.addIcon("battery-charging-wireless-60:F080D")
		this.addIcon("battery-charging-wireless-70:F080E")
		this.addIcon("battery-charging-wireless-80:F080F")
		this.addIcon("battery-charging-wireless-90:F0810")
		this.addIcon("battery-charging-wireless-alert:F0811")
		this.addIcon("battery-charging-wireless-outline:F0812")
		this.addIcon("battery-check:F17E2")
		this.addIcon("battery-check-outline:F17E3")
		this.addIcon("battery-heart:F120F")
		this.addIcon("battery-heart-outline:F1210")
		this.addIcon("battery-heart-variant:F1211")
		this.addIcon("battery-high:F12A3")
		this.addIcon("battery-lock:F179C")
		this.addIcon("battery-lock-open:F179D")
		this.addIcon("battery-low:F12A1")
		this.addIcon("battery-medium:F12A2")
		this.addIcon("battery-minus:F17E4")
		this.addIcon("battery-minus-outline:F17E5")
		this.addIcon("battery-minus-variant:F008C")
		this.addIcon("battery-negative:F008D")
		this.addIcon("battery-off:F125D")
		this.addIcon("battery-off-outline:F125E")
		this.addIcon("battery-outline:F008E")
		this.addIcon("battery-plus:F17E6")
		this.addIcon("battery-plus-outline:F17E7")
		this.addIcon("battery-plus-variant:F008F")
		this.addIcon("battery-positive:F0090")
		this.addIcon("battery-remove:F17E8")
		this.addIcon("battery-remove-outline:F17E9")
		this.addIcon("battery-unknown:F0091")
		this.addIcon("battery-unknown-bluetooth:F094A")
		this.addIcon("beach:F0092")
		this.addIcon("beaker:F0CEA")
		this.addIcon("beaker-alert:F1229")
		this.addIcon("beaker-alert-outline:F122A")
		this.addIcon("beaker-check:F122B")
		this.addIcon("beaker-check-outline:F122C")
		this.addIcon("beaker-minus:F122D")
		this.addIcon("beaker-minus-outline:F122E")
		this.addIcon("beaker-outline:F0690")
		this.addIcon("beaker-plus:F122F")
		this.addIcon("beaker-plus-outline:F1230")
		this.addIcon("beaker-question:F1231")
		this.addIcon("beaker-question-outline:F1232")
		this.addIcon("beaker-remove:F1233")
		this.addIcon("beaker-remove-outline:F1234")
		this.addIcon("bed:F02E3")
		this.addIcon("bed-double:F0FD4")
		this.addIcon("bed-double-outline:F0FD3")
		this.addIcon("bed-empty:F08A0")
		this.addIcon("bed-king:F0FD2")
		this.addIcon("bed-king-outline:F0FD1")
		this.addIcon("bed-outline:F0099")
		this.addIcon("bed-queen:F0FD0")
		this.addIcon("bed-queen-outline:F0FDB")
		this.addIcon("bed-single:F106D")
		this.addIcon("bed-single-outline:F106E")
		this.addIcon("bee:F0FA1")
		this.addIcon("bee-flower:F0FA2")
		this.addIcon("beehive-off-outline:F13ED")
		this.addIcon("beehive-outline:F10CE")
		this.addIcon("beekeeper:F14E2")
		this.addIcon("beer:F0098")
		this.addIcon("beer-outline:F130C")
		this.addIcon("bell:F009A")
		this.addIcon("bell-alert:F0D59")
		this.addIcon("bell-alert-outline:F0E81")
		this.addIcon("bell-badge:F116B")
		this.addIcon("bell-badge-outline:F0178")
		this.addIcon("bell-cancel:F13E7")
		this.addIcon("bell-cancel-outline:F13E8")
		this.addIcon("bell-check:F11E5")
		this.addIcon("bell-check-outline:F11E6")
		this.addIcon("bell-circle:F0D5A")
		this.addIcon("bell-circle-outline:F0D5B")
		this.addIcon("bell-minus:F13E9")
		this.addIcon("bell-minus-outline:F13EA")
		this.addIcon("bell-off:F009B")
		this.addIcon("bell-off-outline:F0A91")
		this.addIcon("bell-outline:F009C")
		this.addIcon("bell-plus:F009D")
		this.addIcon("bell-plus-outline:F0A92")
		this.addIcon("bell-remove:F13EB")
		this.addIcon("bell-remove-outline:F13EC")
		this.addIcon("bell-ring:F009E")
		this.addIcon("bell-ring-outline:F009F")
		this.addIcon("bell-sleep:F00A0")
		this.addIcon("bell-sleep-outline:F0A93")
		this.addIcon("beta:F00A1")
		this.addIcon("betamax:F09CB")
		this.addIcon("biathlon:F0E14")
		this.addIcon("bicycle:F109C")
		this.addIcon("bicycle-basket:F1235")
		this.addIcon("bicycle-electric:F15B4")
		this.addIcon("bicycle-penny-farthing:F15E9")
		this.addIcon("bike:F00A3")
		this.addIcon("bike-fast:F111F")
		this.addIcon("billboard:F1010")
		this.addIcon("billiards:F0B61")
		this.addIcon("billiards-rack:F0B62")
		this.addIcon("binoculars:F00A5")
		this.addIcon("bio:F00A6")
		this.addIcon("biohazard:F00A7")
		this.addIcon("bird:F15C6")
		this.addIcon("bitbucket:F00A8")
		this.addIcon("bitcoin:F0813")
		this.addIcon("black-mesa:F00A9")
		this.addIcon("blender:F0CEB")
		this.addIcon("blender-outline:F181A")
		this.addIcon("blender-software:F00AB")
		this.addIcon("blinds:F00AC")
		this.addIcon("blinds-open:F1011")
		this.addIcon("block-helper:F00AD")
		this.addIcon("blood-bag:F0CEC")
		this.addIcon("bluetooth:F00AF")
		this.addIcon("bluetooth-audio:F00B0")
		this.addIcon("bluetooth-connect:F00B1")
		this.addIcon("bluetooth-off:F00B2")
		this.addIcon("bluetooth-settings:F00B3")
		this.addIcon("bluetooth-transfer:F00B4")
		this.addIcon("blur:F00B5")
		this.addIcon("blur-linear:F00B6")
		this.addIcon("blur-off:F00B7")
		this.addIcon("blur-radial:F00B8")
		this.addIcon("bolt:F0DB3")
		this.addIcon("bomb:F0691")
		this.addIcon("bomb-off:F06C5")
		this.addIcon("bone:F00B9")
		this.addIcon("book:F00BA")
		this.addIcon("book-account:F13AD")
		this.addIcon("book-account-outline:F13AE")
		this.addIcon("book-alert:F167C")
		this.addIcon("book-alert-outline:F167D")
		this.addIcon("book-alphabet:F061D")
		this.addIcon("book-arrow-down:F167E")
		this.addIcon("book-arrow-down-outline:F167F")
		this.addIcon("book-arrow-left:F1680")
		this.addIcon("book-arrow-left-outline:F1681")
		this.addIcon("book-arrow-right:F1682")
		this.addIcon("book-arrow-right-outline:F1683")
		this.addIcon("book-arrow-up:F1684")
		this.addIcon("book-arrow-up-outline:F1685")
		this.addIcon("book-cancel:F1686")
		this.addIcon("book-cancel-outline:F1687")
		this.addIcon("book-check:F14F3")
		this.addIcon("book-check-outline:F14F4")
		this.addIcon("book-clock:F1688")
		this.addIcon("book-clock-outline:F1689")
		this.addIcon("book-cog:F168A")
		this.addIcon("book-cog-outline:F168B")
		this.addIcon("book-cross:F00A2")
		this.addIcon("book-edit:F168C")
		this.addIcon("book-edit-outline:F168D")
		this.addIcon("book-education:F16C9")
		this.addIcon("book-education-outline:F16CA")
		this.addIcon("book-information-variant:F106F")
		this.addIcon("book-lock:F079A")
		this.addIcon("book-lock-open:F079B")
		this.addIcon("book-lock-open-outline:F168E")
		this.addIcon("book-lock-outline:F168F")
		this.addIcon("book-marker:F1690")
		this.addIcon("book-marker-outline:F1691")
		this.addIcon("book-minus:F05D9")
		this.addIcon("book-minus-multiple:F0A94")
		this.addIcon("book-minus-multiple-outline:F090B")
		this.addIcon("book-minus-outline:F1692")
		this.addIcon("book-multiple:F00BB")
		this.addIcon("book-multiple-outline:F0436")
		this.addIcon("book-music:F0067")
		this.addIcon("book-music-outline:F1693")
		this.addIcon("book-off:F1694")
		this.addIcon("book-off-outline:F1695")
		this.addIcon("book-open:F00BD")
		this.addIcon("book-open-blank-variant:F00BE")
		this.addIcon("book-open-outline:F0B63")
		this.addIcon("book-open-page-variant:F05DA")
		this.addIcon("book-open-page-variant-outline:F15D6")
		this.addIcon("book-open-variant:F14F7")
		this.addIcon("book-outline:F0B64")
		this.addIcon("book-play:F0E82")
		this.addIcon("book-play-outline:F0E83")
		this.addIcon("book-plus:F05DB")
		this.addIcon("book-plus-multiple:F0A95")
		this.addIcon("book-plus-multiple-outline:F0ADE")
		this.addIcon("book-plus-outline:F1696")
		this.addIcon("book-refresh:F1697")
		this.addIcon("book-refresh-outline:F1698")
		this.addIcon("book-remove:F0A97")
		this.addIcon("book-remove-multiple:F0A96")
		this.addIcon("book-remove-multiple-outline:F04CA")
		this.addIcon("book-remove-outline:F1699")
		this.addIcon("book-search:F0E84")
		this.addIcon("book-search-outline:F0E85")
		this.addIcon("book-settings:F169A")
		this.addIcon("book-settings-outline:F169B")
		this.addIcon("book-sync:F169C")
		this.addIcon("book-sync-outline:F16C8")
		this.addIcon("book-variant:F00BF")
		this.addIcon("book-variant-multiple:F00BC")
		this.addIcon("bookmark:F00C0")
		this.addIcon("bookmark-check:F00C1")
		this.addIcon("bookmark-check-outline:F137B")
		this.addIcon("bookmark-minus:F09CC")
		this.addIcon("bookmark-minus-outline:F09CD")
		this.addIcon("bookmark-multiple:F0E15")
		this.addIcon("bookmark-multiple-outline:F0E16")
		this.addIcon("bookmark-music:F00C2")
		this.addIcon("bookmark-music-outline:F1379")
		this.addIcon("bookmark-off:F09CE")
		this.addIcon("bookmark-off-outline:F09CF")
		this.addIcon("bookmark-outline:F00C3")
		this.addIcon("bookmark-plus:F00C5")
		this.addIcon("bookmark-plus-outline:F00C4")
		this.addIcon("bookmark-remove:F00C6")
		this.addIcon("bookmark-remove-outline:F137A")
		this.addIcon("bookshelf:F125F")
		this.addIcon("boom-gate:F0E86")
		this.addIcon("boom-gate-alert:F0E87")
		this.addIcon("boom-gate-alert-outline:F0E88")
		this.addIcon("boom-gate-arrow-down:F0E89")
		this.addIcon("boom-gate-arrow-down-outline:F0E8A")
		this.addIcon("boom-gate-arrow-up:F0E8C")
		this.addIcon("boom-gate-arrow-up-outline:F0E8D")
		this.addIcon("boom-gate-outline:F0E8B")
		this.addIcon("boom-gate-up:F17F9")
		this.addIcon("boom-gate-up-outline:F17FA")
		this.addIcon("boombox:F05DC")
		this.addIcon("boomerang:F10CF")
		this.addIcon("bootstrap:F06C6")
		this.addIcon("border-all:F00C7")
		this.addIcon("border-all-variant:F08A1")
		this.addIcon("border-bottom:F00C8")
		this.addIcon("border-bottom-variant:F08A2")
		this.addIcon("border-color:F00C9")
		this.addIcon("border-horizontal:F00CA")
		this.addIcon("border-inside:F00CB")
		this.addIcon("border-left:F00CC")
		this.addIcon("border-left-variant:F08A3")
		this.addIcon("border-none:F00CD")
		this.addIcon("border-none-variant:F08A4")
		this.addIcon("border-outside:F00CE")
		this.addIcon("border-right:F00CF")
		this.addIcon("border-right-variant:F08A5")
		this.addIcon("border-style:F00D0")
		this.addIcon("border-top:F00D1")
		this.addIcon("border-top-variant:F08A6")
		this.addIcon("border-vertical:F00D2")
		this.addIcon("bottle-soda:F1070")
		this.addIcon("bottle-soda-classic:F1071")
		this.addIcon("bottle-soda-classic-outline:F1363")
		this.addIcon("bottle-soda-outline:F1072")
		this.addIcon("bottle-tonic:F112E")
		this.addIcon("bottle-tonic-outline:F112F")
		this.addIcon("bottle-tonic-plus:F1130")
		this.addIcon("bottle-tonic-plus-outline:F1131")
		this.addIcon("bottle-tonic-skull:F1132")
		this.addIcon("bottle-tonic-skull-outline:F1133")
		this.addIcon("bottle-wine:F0854")
		this.addIcon("bottle-wine-outline:F1310")
		this.addIcon("bow-tie:F0678")
		this.addIcon("bowl:F028E")
		this.addIcon("bowl-mix:F0617")
		this.addIcon("bowl-mix-outline:F02E4")
		this.addIcon("bowl-outline:F02A9")
		this.addIcon("bowling:F00D3")
		this.addIcon("box:F00D4")
		this.addIcon("box-cutter:F00D5")
		this.addIcon("box-cutter-off:F0B4A")
		this.addIcon("box-shadow:F0637")
		this.addIcon("boxing-glove:F0B65")
		this.addIcon("braille:F09D0")
		this.addIcon("brain:F09D1")
		this.addIcon("bread-slice:F0CEE")
		this.addIcon("bread-slice-outline:F0CEF")
		this.addIcon("bridge:F0618")
		this.addIcon("briefcase:F00D6")
		this.addIcon("briefcase-account:F0CF0")
		this.addIcon("briefcase-account-outline:F0CF1")
		this.addIcon("briefcase-check:F00D7")
		this.addIcon("briefcase-check-outline:F131E")
		this.addIcon("briefcase-clock:F10D0")
		this.addIcon("briefcase-clock-outline:F10D1")
		this.addIcon("briefcase-download:F00D8")
		this.addIcon("briefcase-download-outline:F0C3D")
		this.addIcon("briefcase-edit:F0A98")
		this.addIcon("briefcase-edit-outline:F0C3E")
		this.addIcon("briefcase-eye:F17D9")
		this.addIcon("briefcase-eye-outline:F17DA")
		this.addIcon("briefcase-minus:F0A2A")
		this.addIcon("briefcase-minus-outline:F0C3F")
		this.addIcon("briefcase-off:F1658")
		this.addIcon("briefcase-off-outline:F1659")
		this.addIcon("briefcase-outline:F0814")
		this.addIcon("briefcase-plus:F0A2B")
		this.addIcon("briefcase-plus-outline:F0C40")
		this.addIcon("briefcase-remove:F0A2C")
		this.addIcon("briefcase-remove-outline:F0C41")
		this.addIcon("briefcase-search:F0A2D")
		this.addIcon("briefcase-search-outline:F0C42")
		this.addIcon("briefcase-upload:F00D9")
		this.addIcon("briefcase-upload-outline:F0C43")
		this.addIcon("briefcase-variant:F1494")
		this.addIcon("briefcase-variant-off:F165A")
		this.addIcon("briefcase-variant-off-outline:F165B")
		this.addIcon("briefcase-variant-outline:F1495")
		this.addIcon("brightness-1:F00DA")
		this.addIcon("brightness-2:F00DB")
		this.addIcon("brightness-3:F00DC")
		this.addIcon("brightness-4:F00DD")
		this.addIcon("brightness-5:F00DE")
		this.addIcon("brightness-6:F00DF")
		this.addIcon("brightness-7:F00E0")
		this.addIcon("brightness-auto:F00E1")
		this.addIcon("brightness-percent:F0CF2")
		this.addIcon("broadcast:F1720")
		this.addIcon("broadcast-off:F1721")
		this.addIcon("broom:F00E2")
		this.addIcon("brush:F00E3")
		this.addIcon("brush-off:F1771")
		this.addIcon("brush-variant:F1813")
		this.addIcon("bucket:F1415")
		this.addIcon("bucket-outline:F1416")
		this.addIcon("buffet:F0578")
		this.addIcon("bug:F00E4")
		this.addIcon("bug-check:F0A2E")
		this.addIcon("bug-check-outline:F0A2F")
		this.addIcon("bug-outline:F0A30")
		this.addIcon("bugle:F0DB4")
		this.addIcon("bulldozer:F0B22")
		this.addIcon("bullet:F0CF3")
		this.addIcon("bulletin-board:F00E5")
		this.addIcon("bullhorn:F00E6")
		this.addIcon("bullhorn-outline:F0B23")
		this.addIcon("bullseye:F05DD")
		this.addIcon("bullseye-arrow:F08C9")
		this.addIcon("bulma:F12E7")
		this.addIcon("bunk-bed:F1302")
		this.addIcon("bunk-bed-outline:F0097")
		this.addIcon("bus:F00E7")
		this.addIcon("bus-alert:F0A99")
		this.addIcon("bus-articulated-end:F079C")
		this.addIcon("bus-articulated-front:F079D")
		this.addIcon("bus-clock:F08CA")
		this.addIcon("bus-double-decker:F079E")
		this.addIcon("bus-marker:F1212")
		this.addIcon("bus-multiple:F0F3F")
		this.addIcon("bus-school:F079F")
		this.addIcon("bus-side:F07A0")
		this.addIcon("bus-stop:F1012")
		this.addIcon("bus-stop-covered:F1013")
		this.addIcon("bus-stop-uncovered:F1014")
		this.addIcon("butterfly:F1589")
		this.addIcon("butterfly-outline:F158A")
		this.addIcon("cable-data:F1394")
		this.addIcon("cached:F00E8")
		this.addIcon("cactus:F0DB5")
		this.addIcon("cake:F00E9")
		this.addIcon("cake-layered:F00EA")
		this.addIcon("cake-variant:F00EB")
		this.addIcon("cake-variant-outline:F17F0")
		this.addIcon("calculator:F00EC")
		this.addIcon("calculator-variant:F0A9A")
		this.addIcon("calculator-variant-outline:F15A6")
		this.addIcon("calendar:F00ED")
		this.addIcon("calendar-account:F0ED7")
		this.addIcon("calendar-account-outline:F0ED8")
		this.addIcon("calendar-alert:F0A31")
		this.addIcon("calendar-arrow-left:F1134")
		this.addIcon("calendar-arrow-right:F1135")
		this.addIcon("calendar-blank:F00EE")
		this.addIcon("calendar-blank-multiple:F1073")
		this.addIcon("calendar-blank-outline:F0B66")
		this.addIcon("calendar-check:F00EF")
		this.addIcon("calendar-check-outline:F0C44")
		this.addIcon("calendar-clock:F00F0")
		this.addIcon("calendar-clock-outline:F16E1")
		this.addIcon("calendar-cursor:F157B")
		this.addIcon("calendar-edit:F08A7")
		this.addIcon("calendar-end:F166C")
		this.addIcon("calendar-export:F0B24")
		this.addIcon("calendar-heart:F09D2")
		this.addIcon("calendar-import:F0B25")
		this.addIcon("calendar-lock:F1641")
		this.addIcon("calendar-lock-outline:F1642")
		this.addIcon("calendar-minus:F0D5C")
		this.addIcon("calendar-month:F0E17")
		this.addIcon("calendar-month-outline:F0E18")
		this.addIcon("calendar-multiple:F00F1")
		this.addIcon("calendar-multiple-check:F00F2")
		this.addIcon("calendar-multiselect:F0A32")
		this.addIcon("calendar-outline:F0B67")
		this.addIcon("calendar-plus:F00F3")
		this.addIcon("calendar-question:F0692")
		this.addIcon("calendar-range:F0679")
		this.addIcon("calendar-range-outline:F0B68")
		this.addIcon("calendar-refresh:F01E1")
		this.addIcon("calendar-refresh-outline:F0203")
		this.addIcon("calendar-remove:F00F4")
		this.addIcon("calendar-remove-outline:F0C45")
		this.addIcon("calendar-search:F094C")
		this.addIcon("calendar-star:F09D3")
		this.addIcon("calendar-start:F166D")
		this.addIcon("calendar-sync:F0E8E")
		this.addIcon("calendar-sync-outline:F0E8F")
		this.addIcon("calendar-text:F00F5")
		this.addIcon("calendar-text-outline:F0C46")
		this.addIcon("calendar-today:F00F6")
		this.addIcon("calendar-week:F0A33")
		this.addIcon("calendar-week-begin:F0A34")
		this.addIcon("calendar-weekend:F0ED9")
		this.addIcon("calendar-weekend-outline:F0EDA")
		this.addIcon("call-made:F00F7")
		this.addIcon("call-merge:F00F8")
		this.addIcon("call-missed:F00F9")
		this.addIcon("call-received:F00FA")
		this.addIcon("call-split:F00FB")
		this.addIcon("camcorder:F00FC")
		this.addIcon("camcorder-off:F00FF")
		this.addIcon("camera:F0100")
		this.addIcon("camera-account:F08CB")
		this.addIcon("camera-burst:F0693")
		this.addIcon("camera-control:F0B69")
		this.addIcon("camera-enhance:F0101")
		this.addIcon("camera-enhance-outline:F0B6A")
		this.addIcon("camera-flip:F15D9")
		this.addIcon("camera-flip-outline:F15DA")
		this.addIcon("camera-front:F0102")
		this.addIcon("camera-front-variant:F0103")
		this.addIcon("camera-gopro:F07A1")
		this.addIcon("camera-image:F08CC")
		this.addIcon("camera-iris:F0104")
		this.addIcon("camera-metering-center:F07A2")
		this.addIcon("camera-metering-matrix:F07A3")
		this.addIcon("camera-metering-partial:F07A4")
		this.addIcon("camera-metering-spot:F07A5")
		this.addIcon("camera-off:F05DF")
		this.addIcon("camera-outline:F0D5D")
		this.addIcon("camera-party-mode:F0105")
		this.addIcon("camera-plus:F0EDB")
		this.addIcon("camera-plus-outline:F0EDC")
		this.addIcon("camera-rear:F0106")
		this.addIcon("camera-rear-variant:F0107")
		this.addIcon("camera-retake:F0E19")
		this.addIcon("camera-retake-outline:F0E1A")
		this.addIcon("camera-switch:F0108")
		this.addIcon("camera-switch-outline:F084A")
		this.addIcon("camera-timer:F0109")
		this.addIcon("camera-wireless:F0DB6")
		this.addIcon("camera-wireless-outline:F0DB7")
		this.addIcon("campfire:F0EDD")
		this.addIcon("cancel:F073A")
		this.addIcon("candelabra:F17D2")
		this.addIcon("candelabra-fire:F17D3")
		this.addIcon("candle:F05E2")
		this.addIcon("candycane:F010A")
		this.addIcon("cannabis:F07A6")
		this.addIcon("cannabis-off:F166E")
		this.addIcon("caps-lock:F0A9B")
		this.addIcon("car:F010B")
		this.addIcon("car-2-plus:F1015")
		this.addIcon("car-3-plus:F1016")
		this.addIcon("car-arrow-left:F13B2")
		this.addIcon("car-arrow-right:F13B3")
		this.addIcon("car-back:F0E1B")
		this.addIcon("car-battery:F010C")
		this.addIcon("car-brake-abs:F0C47")
		this.addIcon("car-brake-alert:F0C48")
		this.addIcon("car-brake-hold:F0D5E")
		this.addIcon("car-brake-parking:F0D5F")
		this.addIcon("car-brake-retarder:F1017")
		this.addIcon("car-child-seat:F0FA3")
		this.addIcon("car-clutch:F1018")
		this.addIcon("car-cog:F13CC")
		this.addIcon("car-connected:F010D")
		this.addIcon("car-convertible:F07A7")
		this.addIcon("car-coolant-level:F1019")
		this.addIcon("car-cruise-control:F0D60")
		this.addIcon("car-defrost-front:F0D61")
		this.addIcon("car-defrost-rear:F0D62")
		this.addIcon("car-door:F0B6B")
		this.addIcon("car-door-lock:F109D")
		this.addIcon("car-electric:F0B6C")
		this.addIcon("car-electric-outline:F15B5")
		this.addIcon("car-emergency:F160F")
		this.addIcon("car-esp:F0C49")
		this.addIcon("car-estate:F07A8")
		this.addIcon("car-hatchback:F07A9")
		this.addIcon("car-info:F11BE")
		this.addIcon("car-key:F0B6D")
		this.addIcon("car-lifted-pickup:F152D")
		this.addIcon("car-light-dimmed:F0C4A")
		this.addIcon("car-light-fog:F0C4B")
		this.addIcon("car-light-high:F0C4C")
		this.addIcon("car-limousine:F08CD")
		this.addIcon("car-multiple:F0B6E")
		this.addIcon("car-off:F0E1C")
		this.addIcon("car-outline:F14ED")
		this.addIcon("car-parking-lights:F0D63")
		this.addIcon("car-pickup:F07AA")
		this.addIcon("car-seat:F0FA4")
		this.addIcon("car-seat-cooler:F0FA5")
		this.addIcon("car-seat-heater:F0FA6")
		this.addIcon("car-settings:F13CD")
		this.addIcon("car-shift-pattern:F0F40")
		this.addIcon("car-side:F07AB")
		this.addIcon("car-sports:F07AC")
		this.addIcon("car-tire-alert:F0C4D")
		this.addIcon("car-traction-control:F0D64")
		this.addIcon("car-turbocharger:F101A")
		this.addIcon("car-wash:F010E")
		this.addIcon("car-windshield:F101B")
		this.addIcon("car-windshield-outline:F101C")
		this.addIcon("car-wrench:F1814")
		this.addIcon("carabiner:F14C0")
		this.addIcon("caravan:F07AD")
		this.addIcon("card:F0B6F")
		this.addIcon("card-account-details:F05D2")
		this.addIcon("card-account-details-outline:F0DAB")
		this.addIcon("card-account-details-star:F02A3")
		this.addIcon("card-account-details-star-outline:F06DB")
		this.addIcon("card-account-mail:F018E")
		this.addIcon("card-account-mail-outline:F0E98")
		this.addIcon("card-account-phone:F0E99")
		this.addIcon("card-account-phone-outline:F0E9A")
		this.addIcon("card-bulleted:F0B70")
		this.addIcon("card-bulleted-off:F0B71")
		this.addIcon("card-bulleted-off-outline:F0B72")
		this.addIcon("card-bulleted-outline:F0B73")
		this.addIcon("card-bulleted-settings:F0B74")
		this.addIcon("card-bulleted-settings-outline:F0B75")
		this.addIcon("card-minus:F1600")
		this.addIcon("card-minus-outline:F1601")
		this.addIcon("card-multiple:F17F1")
		this.addIcon("card-multiple-outline:F17F2")
		this.addIcon("card-off:F1602")
		this.addIcon("card-off-outline:F1603")
		this.addIcon("card-outline:F0B76")
		this.addIcon("card-plus:F11FF")
		this.addIcon("card-plus-outline:F1200")
		this.addIcon("card-remove:F1604")
		this.addIcon("card-remove-outline:F1605")
		this.addIcon("card-search:F1074")
		this.addIcon("card-search-outline:F1075")
		this.addIcon("card-text:F0B77")
		this.addIcon("card-text-outline:F0B78")
		this.addIcon("cards:F0638")
		this.addIcon("cards-club:F08CE")
		this.addIcon("cards-diamond:F08CF")
		this.addIcon("cards-diamond-outline:F101D")
		this.addIcon("cards-heart:F08D0")
		this.addIcon("cards-outline:F0639")
		this.addIcon("cards-playing-outline:F063A")
		this.addIcon("cards-spade:F08D1")
		this.addIcon("cards-variant:F06C7")
		this.addIcon("carrot:F010F")
		this.addIcon("cart:F0110")
		this.addIcon("cart-arrow-down:F0D66")
		this.addIcon("cart-arrow-right:F0C4E")
		this.addIcon("cart-arrow-up:F0D67")
		this.addIcon("cart-check:F15EA")
		this.addIcon("cart-minus:F0D68")
		this.addIcon("cart-off:F066B")
		this.addIcon("cart-outline:F0111")
		this.addIcon("cart-plus:F0112")
		this.addIcon("cart-remove:F0D69")
		this.addIcon("cart-variant:F15EB")
		this.addIcon("case-sensitive-alt:F0113")
		this.addIcon("cash:F0114")
		this.addIcon("cash-100:F0115")
		this.addIcon("cash-check:F14EE")
		this.addIcon("cash-lock:F14EA")
		this.addIcon("cash-lock-open:F14EB")
		this.addIcon("cash-marker:F0DB8")
		this.addIcon("cash-minus:F1260")
		this.addIcon("cash-multiple:F0116")
		this.addIcon("cash-plus:F1261")
		this.addIcon("cash-refund:F0A9C")
		this.addIcon("cash-register:F0CF4")
		this.addIcon("cash-remove:F1262")
		this.addIcon("cassette:F09D4")
		this.addIcon("cast:F0118")
		this.addIcon("cast-audio:F101E")
		this.addIcon("cast-audio-variant:F1749")
		this.addIcon("cast-connected:F0119")
		this.addIcon("cast-education:F0E1D")
		this.addIcon("cast-off:F078A")
		this.addIcon("cast-variant:F001F")
		this.addIcon("castle:F011A")
		this.addIcon("cat:F011B")
		this.addIcon("cctv:F07AE")
		this.addIcon("ceiling-fan:F1797")
		this.addIcon("ceiling-fan-light:F1798")
		this.addIcon("ceiling-light:F0769")
		this.addIcon("ceiling-light-outline:F17C7")
		this.addIcon("cellphone:F011C")
		this.addIcon("cellphone-arrow-down:F09D5")
		this.addIcon("cellphone-basic:F011E")
		this.addIcon("cellphone-charging:F1397")
		this.addIcon("cellphone-check:F17FD")
		this.addIcon("cellphone-cog:F0951")
		this.addIcon("cellphone-dock:F011F")
		this.addIcon("cellphone-information:F0F41")
		this.addIcon("cellphone-key:F094E")
		this.addIcon("cellphone-link:F0121")
		this.addIcon("cellphone-link-off:F0122")
		this.addIcon("cellphone-lock:F094F")
		this.addIcon("cellphone-message:F08D3")
		this.addIcon("cellphone-message-off:F10D2")
		this.addIcon("cellphone-nfc:F0E90")
		this.addIcon("cellphone-nfc-off:F12D8")
		this.addIcon("cellphone-off:F0950")
		this.addIcon("cellphone-play:F101F")
		this.addIcon("cellphone-remove:F094D")
		this.addIcon("cellphone-screenshot:F0A35")
		this.addIcon("cellphone-settings:F0123")
		this.addIcon("cellphone-sound:F0952")
		this.addIcon("cellphone-text:F08D2")
		this.addIcon("cellphone-wireless:F0815")
		this.addIcon("centos:F111A")
		this.addIcon("certificate:F0124")
		this.addIcon("certificate-outline:F1188")
		this.addIcon("chair-rolling:F0F48")
		this.addIcon("chair-school:F0125")
		this.addIcon("chandelier:F1793")
		this.addIcon("charity:F0C4F")
		this.addIcon("chart-arc:F0126")
		this.addIcon("chart-areaspline:F0127")
		this.addIcon("chart-areaspline-variant:F0E91")
		this.addIcon("chart-bar:F0128")
		this.addIcon("chart-bar-stacked:F076A")
		this.addIcon("chart-bell-curve:F0C50")
		this.addIcon("chart-bell-curve-cumulative:F0FA7")
		this.addIcon("chart-box:F154D")
		this.addIcon("chart-box-outline:F154E")
		this.addIcon("chart-box-plus-outline:F154F")
		this.addIcon("chart-bubble:F05E3")
		this.addIcon("chart-donut:F07AF")
		this.addIcon("chart-donut-variant:F07B0")
		this.addIcon("chart-gantt:F066C")
		this.addIcon("chart-histogram:F0129")
		this.addIcon("chart-line:F012A")
		this.addIcon("chart-line-stacked:F076B")
		this.addIcon("chart-line-variant:F07B1")
		this.addIcon("chart-multiline:F08D4")
		this.addIcon("chart-multiple:F1213")
		this.addIcon("chart-pie:F012B")
		this.addIcon("chart-ppf:F1380")
		this.addIcon("chart-sankey:F11DF")
		this.addIcon("chart-sankey-variant:F11E0")
		this.addIcon("chart-scatter-plot:F0E92")
		this.addIcon("chart-scatter-plot-hexbin:F066D")
		this.addIcon("chart-timeline:F066E")
		this.addIcon("chart-timeline-variant:F0E93")
		this.addIcon("chart-timeline-variant-shimmer:F15B6")
		this.addIcon("chart-tree:F0E94")
		this.addIcon("chat:F0B79")
		this.addIcon("chat-alert:F0B7A")
		this.addIcon("chat-alert-outline:F12C9")
		this.addIcon("chat-minus:F1410")
		this.addIcon("chat-minus-outline:F1413")
		this.addIcon("chat-outline:F0EDE")
		this.addIcon("chat-plus:F140F")
		this.addIcon("chat-plus-outline:F1412")
		this.addIcon("chat-processing:F0B7B")
		this.addIcon("chat-processing-outline:F12CA")
		this.addIcon("chat-question:F1738")
		this.addIcon("chat-question-outline:F1739")
		this.addIcon("chat-remove:F1411")
		this.addIcon("chat-remove-outline:F1414")
		this.addIcon("chat-sleep:F12D1")
		this.addIcon("chat-sleep-outline:F12D2")
		this.addIcon("check:F012C")
		this.addIcon("check-all:F012D")
		this.addIcon("check-bold:F0E1E")
		this.addIcon("check-circle:F05E0")
		this.addIcon("check-circle-outline:F05E1")
		this.addIcon("check-decagram:F0791")
		this.addIcon("check-decagram-outline:F1740")
		this.addIcon("check-network:F0C53")
		this.addIcon("check-network-outline:F0C54")
		this.addIcon("check-outline:F0855")
		this.addIcon("check-underline:F0E1F")
		this.addIcon("check-underline-circle:F0E20")
		this.addIcon("check-underline-circle-outline:F0E21")
		this.addIcon("checkbook:F0A9D")
		this.addIcon("checkbox-blank:F012E")
		this.addIcon("checkbox-blank-badge:F1176")
		this.addIcon("checkbox-blank-badge-outline:F0117")
		this.addIcon("checkbox-blank-circle:F012F")
		this.addIcon("checkbox-blank-circle-outline:F0130")
		this.addIcon("checkbox-blank-off:F12EC")
		this.addIcon("checkbox-blank-off-outline:F12ED")
		this.addIcon("checkbox-blank-outline:F0131")
		this.addIcon("checkbox-intermediate:F0856")
		this.addIcon("checkbox-marked:F0132")
		this.addIcon("checkbox-marked-circle:F0133")
		this.addIcon("checkbox-marked-circle-outline:F0134")
		this.addIcon("checkbox-marked-outline:F0135")
		this.addIcon("checkbox-multiple-blank:F0136")
		this.addIcon("checkbox-multiple-blank-circle:F063B")
		this.addIcon("checkbox-multiple-blank-circle-outline:F063C")
		this.addIcon("checkbox-multiple-blank-outline:F0137")
		this.addIcon("checkbox-multiple-marked:F0138")
		this.addIcon("checkbox-multiple-marked-circle:F063D")
		this.addIcon("checkbox-multiple-marked-circle-outline:F063E")
		this.addIcon("checkbox-multiple-marked-outline:F0139")
		this.addIcon("checkbox-multiple-outline:F0C51")
		this.addIcon("checkbox-outline:F0C52")
		this.addIcon("checkerboard:F013A")
		this.addIcon("checkerboard-minus:F1202")
		this.addIcon("checkerboard-plus:F1201")
		this.addIcon("checkerboard-remove:F1203")
		this.addIcon("cheese:F12B9")
		this.addIcon("cheese-off:F13EE")
		this.addIcon("chef-hat:F0B7C")
		this.addIcon("chemical-weapon:F013B")
		this.addIcon("chess-bishop:F085C")
		this.addIcon("chess-king:F0857")
		this.addIcon("chess-knight:F0858")
		this.addIcon("chess-pawn:F0859")
		this.addIcon("chess-queen:F085A")
		this.addIcon("chess-rook:F085B")
		this.addIcon("chevron-double-down:F013C")
		this.addIcon("chevron-double-left:F013D")
		this.addIcon("chevron-double-right:F013E")
		this.addIcon("chevron-double-up:F013F")
		this.addIcon("chevron-down:F0140")
		this.addIcon("chevron-down-box:F09D6")
		this.addIcon("chevron-down-box-outline:F09D7")
		this.addIcon("chevron-down-circle:F0B26")
		this.addIcon("chevron-down-circle-outline:F0B27")
		this.addIcon("chevron-left:F0141")
		this.addIcon("chevron-left-box:F09D8")
		this.addIcon("chevron-left-box-outline:F09D9")
		this.addIcon("chevron-left-circle:F0B28")
		this.addIcon("chevron-left-circle-outline:F0B29")
		this.addIcon("chevron-right:F0142")
		this.addIcon("chevron-right-box:F09DA")
		this.addIcon("chevron-right-box-outline:F09DB")
		this.addIcon("chevron-right-circle:F0B2A")
		this.addIcon("chevron-right-circle-outline:F0B2B")
		this.addIcon("chevron-triple-down:F0DB9")
		this.addIcon("chevron-triple-left:F0DBA")
		this.addIcon("chevron-triple-right:F0DBB")
		this.addIcon("chevron-triple-up:F0DBC")
		this.addIcon("chevron-up:F0143")
		this.addIcon("chevron-up-box:F09DC")
		this.addIcon("chevron-up-box-outline:F09DD")
		this.addIcon("chevron-up-circle:F0B2C")
		this.addIcon("chevron-up-circle-outline:F0B2D")
		this.addIcon("chili-alert:F17EA")
		this.addIcon("chili-alert-outline:F17EB")
		this.addIcon("chili-hot:F07B2")
		this.addIcon("chili-hot-outline:F17EC")
		this.addIcon("chili-medium:F07B3")
		this.addIcon("chili-medium-outline:F17ED")
		this.addIcon("chili-mild:F07B4")
		this.addIcon("chili-mild-outline:F17EE")
		this.addIcon("chili-off:F1467")
		this.addIcon("chili-off-outline:F17EF")
		this.addIcon("chip:F061A")
		this.addIcon("church:F0144")
		this.addIcon("cigar:F1189")
		this.addIcon("cigar-off:F141B")
		this.addIcon("circle:F0765")
		this.addIcon("circle-box:F15DC")
		this.addIcon("circle-box-outline:F15DD")
		this.addIcon("circle-double:F0E95")
		this.addIcon("circle-edit-outline:F08D5")
		this.addIcon("circle-expand:F0E96")
		this.addIcon("circle-half:F1395")
		this.addIcon("circle-half-full:F1396")
		this.addIcon("circle-medium:F09DE")
		this.addIcon("circle-multiple:F0B38")
		this.addIcon("circle-multiple-outline:F0695")
		this.addIcon("circle-off-outline:F10D3")
		this.addIcon("circle-outline:F0766")
		this.addIcon("circle-slice-1:F0A9E")
		this.addIcon("circle-slice-2:F0A9F")
		this.addIcon("circle-slice-3:F0AA0")
		this.addIcon("circle-slice-4:F0AA1")
		this.addIcon("circle-slice-5:F0AA2")
		this.addIcon("circle-slice-6:F0AA3")
		this.addIcon("circle-slice-7:F0AA4")
		this.addIcon("circle-slice-8:F0AA5")
		this.addIcon("circle-small:F09DF")
		this.addIcon("circular-saw:F0E22")
		this.addIcon("city:F0146")
		this.addIcon("city-variant:F0A36")
		this.addIcon("city-variant-outline:F0A37")
		this.addIcon("clipboard:F0147")
		this.addIcon("clipboard-account:F0148")
		this.addIcon("clipboard-account-outline:F0C55")
		this.addIcon("clipboard-alert:F0149")
		this.addIcon("clipboard-alert-outline:F0CF7")
		this.addIcon("clipboard-arrow-down:F014A")
		this.addIcon("clipboard-arrow-down-outline:F0C56")
		this.addIcon("clipboard-arrow-left:F014B")
		this.addIcon("clipboard-arrow-left-outline:F0CF8")
		this.addIcon("clipboard-arrow-right:F0CF9")
		this.addIcon("clipboard-arrow-right-outline:F0CFA")
		this.addIcon("clipboard-arrow-up:F0C57")
		this.addIcon("clipboard-arrow-up-outline:F0C58")
		this.addIcon("clipboard-check:F014E")
		this.addIcon("clipboard-check-multiple:F1263")
		this.addIcon("clipboard-check-multiple-outline:F1264")
		this.addIcon("clipboard-check-outline:F08A8")
		this.addIcon("clipboard-clock:F16E2")
		this.addIcon("clipboard-clock-outline:F16E3")
		this.addIcon("clipboard-edit:F14E5")
		this.addIcon("clipboard-edit-outline:F14E6")
		this.addIcon("clipboard-file:F1265")
		this.addIcon("clipboard-file-outline:F1266")
		this.addIcon("clipboard-flow:F06C8")
		this.addIcon("clipboard-flow-outline:F1117")
		this.addIcon("clipboard-list:F10D4")
		this.addIcon("clipboard-list-outline:F10D5")
		this.addIcon("clipboard-minus:F1618")
		this.addIcon("clipboard-minus-outline:F1619")
		this.addIcon("clipboard-multiple:F1267")
		this.addIcon("clipboard-multiple-outline:F1268")
		this.addIcon("clipboard-off:F161A")
		this.addIcon("clipboard-off-outline:F161B")
		this.addIcon("clipboard-outline:F014C")
		this.addIcon("clipboard-play:F0C59")
		this.addIcon("clipboard-play-multiple:F1269")
		this.addIcon("clipboard-play-multiple-outline:F126A")
		this.addIcon("clipboard-play-outline:F0C5A")
		this.addIcon("clipboard-plus:F0751")
		this.addIcon("clipboard-plus-outline:F131F")
		this.addIcon("clipboard-pulse:F085D")
		this.addIcon("clipboard-pulse-outline:F085E")
		this.addIcon("clipboard-remove:F161C")
		this.addIcon("clipboard-remove-outline:F161D")
		this.addIcon("clipboard-search:F161E")
		this.addIcon("clipboard-search-outline:F161F")
		this.addIcon("clipboard-text:F014D")
		this.addIcon("clipboard-text-multiple:F126B")
		this.addIcon("clipboard-text-multiple-outline:F126C")
		this.addIcon("clipboard-text-off:F1620")
		this.addIcon("clipboard-text-off-outline:F1621")
		this.addIcon("clipboard-text-outline:F0A38")
		this.addIcon("clipboard-text-play:F0C5B")
		this.addIcon("clipboard-text-play-outline:F0C5C")
		this.addIcon("clipboard-text-search:F1622")
		this.addIcon("clipboard-text-search-outline:F1623")
		this.addIcon("clippy:F014F")
		this.addIcon("clock:F0954")
		this.addIcon("clock-alert:F0955")
		this.addIcon("clock-alert-outline:F05CE")
		this.addIcon("clock-check:F0FA8")
		this.addIcon("clock-check-outline:F0FA9")
		this.addIcon("clock-digital:F0E97")
		this.addIcon("clock-end:F0151")
		this.addIcon("clock-fast:F0152")
		this.addIcon("clock-in:F0153")
		this.addIcon("clock-out:F0154")
		this.addIcon("clock-outline:F0150")
		this.addIcon("clock-start:F0155")
		this.addIcon("clock-time-eight:F1446")
		this.addIcon("clock-time-eight-outline:F1452")
		this.addIcon("clock-time-eleven:F1449")
		this.addIcon("clock-time-eleven-outline:F1455")
		this.addIcon("clock-time-five:F1443")
		this.addIcon("clock-time-five-outline:F144F")
		this.addIcon("clock-time-four:F1442")
		this.addIcon("clock-time-four-outline:F144E")
		this.addIcon("clock-time-nine:F1447")
		this.addIcon("clock-time-nine-outline:F1453")
		this.addIcon("clock-time-one:F143F")
		this.addIcon("clock-time-one-outline:F144B")
		this.addIcon("clock-time-seven:F1445")
		this.addIcon("clock-time-seven-outline:F1451")
		this.addIcon("clock-time-six:F1444")
		this.addIcon("clock-time-six-outline:F1450")
		this.addIcon("clock-time-ten:F1448")
		this.addIcon("clock-time-ten-outline:F1454")
		this.addIcon("clock-time-three:F1441")
		this.addIcon("clock-time-three-outline:F144D")
		this.addIcon("clock-time-twelve:F144A")
		this.addIcon("clock-time-twelve-outline:F1456")
		this.addIcon("clock-time-two:F1440")
		this.addIcon("clock-time-two-outline:F144C")
		this.addIcon("close:F0156")
		this.addIcon("close-box:F0157")
		this.addIcon("close-box-multiple:F0C5D")
		this.addIcon("close-box-multiple-outline:F0C5E")
		this.addIcon("close-box-outline:F0158")
		this.addIcon("close-circle:F0159")
		this.addIcon("close-circle-multiple:F062A")
		this.addIcon("close-circle-multiple-outline:F0883")
		this.addIcon("close-circle-outline:F015A")
		this.addIcon("close-network:F015B")
		this.addIcon("close-network-outline:F0C5F")
		this.addIcon("close-octagon:F015C")
		this.addIcon("close-octagon-outline:F015D")
		this.addIcon("close-outline:F06C9")
		this.addIcon("close-thick:F1398")
		this.addIcon("closed-caption:F015E")
		this.addIcon("closed-caption-outline:F0DBD")
		this.addIcon("cloud:F015F")
		this.addIcon("cloud-alert:F09E0")
		this.addIcon("cloud-braces:F07B5")
		this.addIcon("cloud-check:F0160")
		this.addIcon("cloud-check-outline:F12CC")
		this.addIcon("cloud-circle:F0161")
		this.addIcon("cloud-download:F0162")
		this.addIcon("cloud-download-outline:F0B7D")
		this.addIcon("cloud-lock:F11F1")
		this.addIcon("cloud-lock-outline:F11F2")
		this.addIcon("cloud-off-outline:F0164")
		this.addIcon("cloud-outline:F0163")
		this.addIcon("cloud-print:F0165")
		this.addIcon("cloud-print-outline:F0166")
		this.addIcon("cloud-question:F0A39")
		this.addIcon("cloud-refresh:F052A")
		this.addIcon("cloud-search:F0956")
		this.addIcon("cloud-search-outline:F0957")
		this.addIcon("cloud-sync:F063F")
		this.addIcon("cloud-sync-outline:F12D6")
		this.addIcon("cloud-tags:F07B6")
		this.addIcon("cloud-upload:F0167")
		this.addIcon("cloud-upload-outline:F0B7E")
		this.addIcon("clover:F0816")
		this.addIcon("coach-lamp:F1020")
		this.addIcon("coat-rack:F109E")
		this.addIcon("code-array:F0168")
		this.addIcon("code-braces:F0169")
		this.addIcon("code-braces-box:F10D6")
		this.addIcon("code-brackets:F016A")
		this.addIcon("code-equal:F016B")
		this.addIcon("code-greater-than:F016C")
		this.addIcon("code-greater-than-or-equal:F016D")
		this.addIcon("code-json:F0626")
		this.addIcon("code-less-than:F016E")
		this.addIcon("code-less-than-or-equal:F016F")
		this.addIcon("code-not-equal:F0170")
		this.addIcon("code-not-equal-variant:F0171")
		this.addIcon("code-parentheses:F0172")
		this.addIcon("code-parentheses-box:F10D7")
		this.addIcon("code-string:F0173")
		this.addIcon("code-tags:F0174")
		this.addIcon("code-tags-check:F0694")
		this.addIcon("codepen:F0175")
		this.addIcon("coffee:F0176")
		this.addIcon("coffee-maker:F109F")
		this.addIcon("coffee-maker-outline:F181B")
		this.addIcon("coffee-off:F0FAA")
		this.addIcon("coffee-off-outline:F0FAB")
		this.addIcon("coffee-outline:F06CA")
		this.addIcon("coffee-to-go:F0177")
		this.addIcon("coffee-to-go-outline:F130E")
		this.addIcon("coffin:F0B7F")
		this.addIcon("cog:F0493")
		this.addIcon("cog-box:F0494")
		this.addIcon("cog-clockwise:F11DD")
		this.addIcon("cog-counterclockwise:F11DE")
		this.addIcon("cog-off:F13CE")
		this.addIcon("cog-off-outline:F13CF")
		this.addIcon("cog-outline:F08BB")
		this.addIcon("cog-refresh:F145E")
		this.addIcon("cog-refresh-outline:F145F")
		this.addIcon("cog-sync:F1460")
		this.addIcon("cog-sync-outline:F1461")
		this.addIcon("cog-transfer:F105B")
		this.addIcon("cog-transfer-outline:F105C")
		this.addIcon("cogs:F08D6")
		this.addIcon("collage:F0640")
		this.addIcon("collapse-all:F0AA6")
		this.addIcon("collapse-all-outline:F0AA7")
		this.addIcon("color-helper:F0179")
		this.addIcon("comma:F0E23")
		this.addIcon("comma-box:F0E2B")
		this.addIcon("comma-box-outline:F0E24")
		this.addIcon("comma-circle:F0E25")
		this.addIcon("comma-circle-outline:F0E26")
		this.addIcon("comment:F017A")
		this.addIcon("comment-account:F017B")
		this.addIcon("comment-account-outline:F017C")
		this.addIcon("comment-alert:F017D")
		this.addIcon("comment-alert-outline:F017E")
		this.addIcon("comment-arrow-left:F09E1")
		this.addIcon("comment-arrow-left-outline:F09E2")
		this.addIcon("comment-arrow-right:F09E3")
		this.addIcon("comment-arrow-right-outline:F09E4")
		this.addIcon("comment-bookmark:F15AE")
		this.addIcon("comment-bookmark-outline:F15AF")
		this.addIcon("comment-check:F017F")
		this.addIcon("comment-check-outline:F0180")
		this.addIcon("comment-edit:F11BF")
		this.addIcon("comment-edit-outline:F12C4")
		this.addIcon("comment-eye:F0A3A")
		this.addIcon("comment-eye-outline:F0A3B")
		this.addIcon("comment-flash:F15B0")
		this.addIcon("comment-flash-outline:F15B1")
		this.addIcon("comment-minus:F15DF")
		this.addIcon("comment-minus-outline:F15E0")
		this.addIcon("comment-multiple:F085F")
		this.addIcon("comment-multiple-outline:F0181")
		this.addIcon("comment-off:F15E1")
		this.addIcon("comment-off-outline:F15E2")
		this.addIcon("comment-outline:F0182")
		this.addIcon("comment-plus:F09E5")
		this.addIcon("comment-plus-outline:F0183")
		this.addIcon("comment-processing:F0184")
		this.addIcon("comment-processing-outline:F0185")
		this.addIcon("comment-question:F0817")
		this.addIcon("comment-question-outline:F0186")
		this.addIcon("comment-quote:F1021")
		this.addIcon("comment-quote-outline:F1022")
		this.addIcon("comment-remove:F05DE")
		this.addIcon("comment-remove-outline:F0187")
		this.addIcon("comment-search:F0A3C")
		this.addIcon("comment-search-outline:F0A3D")
		this.addIcon("comment-text:F0188")
		this.addIcon("comment-text-multiple:F0860")
		this.addIcon("comment-text-multiple-outline:F0861")
		this.addIcon("comment-text-outline:F0189")
		this.addIcon("compare:F018A")
		this.addIcon("compare-horizontal:F1492")
		this.addIcon("compare-vertical:F1493")
		this.addIcon("compass:F018B")
		this.addIcon("compass-off:F0B80")
		this.addIcon("compass-off-outline:F0B81")
		this.addIcon("compass-outline:F018C")
		this.addIcon("compass-rose:F1382")
		this.addIcon("connection:F1616")
		this.addIcon("console:F018D")
		this.addIcon("console-line:F07B7")
		this.addIcon("console-network:F08A9")
		this.addIcon("console-network-outline:F0C60")
		this.addIcon("consolidate:F10D8")
		this.addIcon("contactless-payment:F0D6A")
		this.addIcon("contactless-payment-circle:F0321")
		this.addIcon("contactless-payment-circle-outline:F0408")
		this.addIcon("contacts:F06CB")
		this.addIcon("contacts-outline:F05B8")
		this.addIcon("contain:F0A3E")
		this.addIcon("contain-end:F0A3F")
		this.addIcon("contain-start:F0A40")
		this.addIcon("content-copy:F018F")
		this.addIcon("content-cut:F0190")
		this.addIcon("content-duplicate:F0191")
		this.addIcon("content-paste:F0192")
		this.addIcon("content-save:F0193")
		this.addIcon("content-save-alert:F0F42")
		this.addIcon("content-save-alert-outline:F0F43")
		this.addIcon("content-save-all:F0194")
		this.addIcon("content-save-all-outline:F0F44")
		this.addIcon("content-save-cog:F145B")
		this.addIcon("content-save-cog-outline:F145C")
		this.addIcon("content-save-edit:F0CFB")
		this.addIcon("content-save-edit-outline:F0CFC")
		this.addIcon("content-save-move:F0E27")
		this.addIcon("content-save-move-outline:F0E28")
		this.addIcon("content-save-off:F1643")
		this.addIcon("content-save-off-outline:F1644")
		this.addIcon("content-save-outline:F0818")
		this.addIcon("content-save-settings:F061B")
		this.addIcon("content-save-settings-outline:F0B2E")
		this.addIcon("contrast:F0195")
		this.addIcon("contrast-box:F0196")
		this.addIcon("contrast-circle:F0197")
		this.addIcon("controller-classic:F0B82")
		this.addIcon("controller-classic-outline:F0B83")
		this.addIcon("cookie:F0198")
		this.addIcon("cookie-alert:F16D0")
		this.addIcon("cookie-alert-outline:F16D1")
		this.addIcon("cookie-check:F16D2")
		this.addIcon("cookie-check-outline:F16D3")
		this.addIcon("cookie-clock:F16E4")
		this.addIcon("cookie-clock-outline:F16E5")
		this.addIcon("cookie-cog:F16D4")
		this.addIcon("cookie-cog-outline:F16D5")
		this.addIcon("cookie-edit:F16E6")
		this.addIcon("cookie-edit-outline:F16E7")
		this.addIcon("cookie-lock:F16E8")
		this.addIcon("cookie-lock-outline:F16E9")
		this.addIcon("cookie-minus:F16DA")
		this.addIcon("cookie-minus-outline:F16DB")
		this.addIcon("cookie-off:F16EA")
		this.addIcon("cookie-off-outline:F16EB")
		this.addIcon("cookie-outline:F16DE")
		this.addIcon("cookie-plus:F16D6")
		this.addIcon("cookie-plus-outline:F16D7")
		this.addIcon("cookie-refresh:F16EC")
		this.addIcon("cookie-refresh-outline:F16ED")
		this.addIcon("cookie-remove:F16D8")
		this.addIcon("cookie-remove-outline:F16D9")
		this.addIcon("cookie-settings:F16DC")
		this.addIcon("cookie-settings-outline:F16DD")
		this.addIcon("coolant-temperature:F03C8")
		this.addIcon("copyright:F05E6")
		this.addIcon("cordova:F0958")
		this.addIcon("corn:F07B8")
		this.addIcon("corn-off:F13EF")
		this.addIcon("cosine-wave:F1479")
		this.addIcon("counter:F0199")
		this.addIcon("countertop:F181C")
		this.addIcon("countertop-outline:F181D")
		this.addIcon("cow:F019A")
		this.addIcon("cpu-32-bit:F0EDF")
		this.addIcon("cpu-64-bit:F0EE0")
		this.addIcon("crane:F0862")
		this.addIcon("creation:F0674")
		this.addIcon("creative-commons:F0D6B")
		this.addIcon("credit-card:F0FEF")
		this.addIcon("credit-card-check:F13D0")
		this.addIcon("credit-card-check-outline:F13D1")
		this.addIcon("credit-card-clock:F0EE1")
		this.addIcon("credit-card-clock-outline:F0EE2")
		this.addIcon("credit-card-edit:F17D7")
		this.addIcon("credit-card-edit-outline:F17D8")
		this.addIcon("credit-card-marker:F06A8")
		this.addIcon("credit-card-marker-outline:F0DBE")
		this.addIcon("credit-card-minus:F0FAC")
		this.addIcon("credit-card-minus-outline:F0FAD")
		this.addIcon("credit-card-multiple:F0FF0")
		this.addIcon("credit-card-multiple-outline:F019C")
		this.addIcon("credit-card-off:F0FF1")
		this.addIcon("credit-card-off-outline:F05E4")
		this.addIcon("credit-card-outline:F019B")
		this.addIcon("credit-card-plus:F0FF2")
		this.addIcon("credit-card-plus-outline:F0676")
		this.addIcon("credit-card-refresh:F1645")
		this.addIcon("credit-card-refresh-outline:F1646")
		this.addIcon("credit-card-refund:F0FF3")
		this.addIcon("credit-card-refund-outline:F0AA8")
		this.addIcon("credit-card-remove:F0FAE")
		this.addIcon("credit-card-remove-outline:F0FAF")
		this.addIcon("credit-card-scan:F0FF4")
		this.addIcon("credit-card-scan-outline:F019D")
		this.addIcon("credit-card-search:F1647")
		this.addIcon("credit-card-search-outline:F1648")
		this.addIcon("credit-card-settings:F0FF5")
		this.addIcon("credit-card-settings-outline:F08D7")
		this.addIcon("credit-card-sync:F1649")
		this.addIcon("credit-card-sync-outline:F164A")
		this.addIcon("credit-card-wireless:F0802")
		this.addIcon("credit-card-wireless-off:F057A")
		this.addIcon("credit-card-wireless-off-outline:F057B")
		this.addIcon("credit-card-wireless-outline:F0D6C")
		this.addIcon("cricket:F0D6D")
		this.addIcon("crop:F019E")
		this.addIcon("crop-free:F019F")
		this.addIcon("crop-landscape:F01A0")
		this.addIcon("crop-portrait:F01A1")
		this.addIcon("crop-rotate:F0696")
		this.addIcon("crop-square:F01A2")
		this.addIcon("cross:F0953")
		this.addIcon("cross-bolnisi:F0CED")
		this.addIcon("cross-celtic:F0CF5")
		this.addIcon("cross-outline:F0CF6")
		this.addIcon("crosshairs:F01A3")
		this.addIcon("crosshairs-gps:F01A4")
		this.addIcon("crosshairs-off:F0F45")
		this.addIcon("crosshairs-question:F1136")
		this.addIcon("crown:F01A5")
		this.addIcon("crown-circle:F17DC")
		this.addIcon("crown-circle-outline:F17DD")
		this.addIcon("crown-outline:F11D0")
		this.addIcon("cryengine:F0959")
		this.addIcon("crystal-ball:F0B2F")
		this.addIcon("cube:F01A6")
		this.addIcon("cube-off:F141C")
		this.addIcon("cube-off-outline:F141D")
		this.addIcon("cube-outline:F01A7")
		this.addIcon("cube-scan:F0B84")
		this.addIcon("cube-send:F01A8")
		this.addIcon("cube-unfolded:F01A9")
		this.addIcon("cup:F01AA")
		this.addIcon("cup-off:F05E5")
		this.addIcon("cup-off-outline:F137D")
		this.addIcon("cup-outline:F130F")
		this.addIcon("cup-water:F01AB")
		this.addIcon("cupboard:F0F46")
		this.addIcon("cupboard-outline:F0F47")
		this.addIcon("cupcake:F095A")
		this.addIcon("curling:F0863")
		this.addIcon("currency-bdt:F0864")
		this.addIcon("currency-brl:F0B85")
		this.addIcon("currency-btc:F01AC")
		this.addIcon("currency-cny:F07BA")
		this.addIcon("currency-eth:F07BB")
		this.addIcon("currency-eur:F01AD")
		this.addIcon("currency-eur-off:F1315")
		this.addIcon("currency-gbp:F01AE")
		this.addIcon("currency-ils:F0C61")
		this.addIcon("currency-inr:F01AF")
		this.addIcon("currency-jpy:F07BC")
		this.addIcon("currency-krw:F07BD")
		this.addIcon("currency-kzt:F0865")
		this.addIcon("currency-mnt:F1512")
		this.addIcon("currency-ngn:F01B0")
		this.addIcon("currency-php:F09E6")
		this.addIcon("currency-rial:F0E9C")
		this.addIcon("currency-rub:F01B1")
		this.addIcon("currency-sign:F07BE")
		this.addIcon("currency-try:F01B2")
		this.addIcon("currency-twd:F07BF")
		this.addIcon("currency-usd:F01C1")
		this.addIcon("currency-usd-off:F067A")
		this.addIcon("current-ac:F1480")
		this.addIcon("current-dc:F095C")
		this.addIcon("cursor-default:F01C0")
		this.addIcon("cursor-default-click:F0CFD")
		this.addIcon("cursor-default-click-outline:F0CFE")
		this.addIcon("cursor-default-gesture:F1127")
		this.addIcon("cursor-default-gesture-outline:F1128")
		this.addIcon("cursor-default-outline:F01BF")
		this.addIcon("cursor-move:F01BE")
		this.addIcon("cursor-pointer:F01BD")
		this.addIcon("cursor-text:F05E7")
		this.addIcon("dance-ballroom:F15FB")
		this.addIcon("dance-pole:F1578")
		this.addIcon("data-matrix:F153C")
		this.addIcon("data-matrix-edit:F153D")
		this.addIcon("data-matrix-minus:F153E")
		this.addIcon("data-matrix-plus:F153F")
		this.addIcon("data-matrix-remove:F1540")
		this.addIcon("data-matrix-scan:F1541")
		this.addIcon("database:F01BC")
		this.addIcon("database-alert:F163A")
		this.addIcon("database-alert-outline:F1624")
		this.addIcon("database-arrow-down:F163B")
		this.addIcon("database-arrow-down-outline:F1625")
		this.addIcon("database-arrow-left:F163C")
		this.addIcon("database-arrow-left-outline:F1626")
		this.addIcon("database-arrow-right:F163D")
		this.addIcon("database-arrow-right-outline:F1627")
		this.addIcon("database-arrow-up:F163E")
		this.addIcon("database-arrow-up-outline:F1628")
		this.addIcon("database-check:F0AA9")
		this.addIcon("database-check-outline:F1629")
		this.addIcon("database-clock:F163F")
		this.addIcon("database-clock-outline:F162A")
		this.addIcon("database-cog:F164B")
		this.addIcon("database-cog-outline:F164C")
		this.addIcon("database-edit:F0B86")
		this.addIcon("database-edit-outline:F162B")
		this.addIcon("database-export:F095E")
		this.addIcon("database-export-outline:F162C")
		this.addIcon("database-import:F095D")
		this.addIcon("database-import-outline:F162D")
		this.addIcon("database-lock:F0AAA")
		this.addIcon("database-lock-outline:F162E")
		this.addIcon("database-marker:F12F6")
		this.addIcon("database-marker-outline:F162F")
		this.addIcon("database-minus:F01BB")
		this.addIcon("database-minus-outline:F1630")
		this.addIcon("database-off:F1640")
		this.addIcon("database-off-outline:F1631")
		this.addIcon("database-outline:F1632")
		this.addIcon("database-plus:F01BA")
		this.addIcon("database-plus-outline:F1633")
		this.addIcon("database-refresh:F05C2")
		this.addIcon("database-refresh-outline:F1634")
		this.addIcon("database-remove:F0D00")
		this.addIcon("database-remove-outline:F1635")
		this.addIcon("database-search:F0866")
		this.addIcon("database-search-outline:F1636")
		this.addIcon("database-settings:F0D01")
		this.addIcon("database-settings-outline:F1637")
		this.addIcon("database-sync:F0CFF")
		this.addIcon("database-sync-outline:F1638")
		this.addIcon("death-star:F08D8")
		this.addIcon("death-star-variant:F08D9")
		this.addIcon("deathly-hallows:F0B87")
		this.addIcon("debian:F08DA")
		this.addIcon("debug-step-into:F01B9")
		this.addIcon("debug-step-out:F01B8")
		this.addIcon("debug-step-over:F01B7")
		this.addIcon("decagram:F076C")
		this.addIcon("decagram-outline:F076D")
		this.addIcon("decimal:F10A1")
		this.addIcon("decimal-comma:F10A2")
		this.addIcon("decimal-comma-decrease:F10A3")
		this.addIcon("decimal-comma-increase:F10A4")
		this.addIcon("decimal-decrease:F01B6")
		this.addIcon("decimal-increase:F01B5")
		this.addIcon("delete:F01B4")
		this.addIcon("delete-alert:F10A5")
		this.addIcon("delete-alert-outline:F10A6")
		this.addIcon("delete-circle:F0683")
		this.addIcon("delete-circle-outline:F0B88")
		this.addIcon("delete-clock:F1556")
		this.addIcon("delete-clock-outline:F1557")
		this.addIcon("delete-empty:F06CC")
		this.addIcon("delete-empty-outline:F0E9D")
		this.addIcon("delete-forever:F05E8")
		this.addIcon("delete-forever-outline:F0B89")
		this.addIcon("delete-off:F10A7")
		this.addIcon("delete-off-outline:F10A8")
		this.addIcon("delete-outline:F09E7")
		this.addIcon("delete-restore:F0819")
		this.addIcon("delete-sweep:F05E9")
		this.addIcon("delete-sweep-outline:F0C62")
		this.addIcon("delete-variant:F01B3")
		this.addIcon("delta:F01C2")
		this.addIcon("desk:F1239")
		this.addIcon("desk-lamp:F095F")
		this.addIcon("deskphone:F01C3")
		this.addIcon("desktop-classic:F07C0")
		this.addIcon("desktop-mac:F01C4")
		this.addIcon("desktop-mac-dashboard:F09E8")
		this.addIcon("desktop-tower:F01C5")
		this.addIcon("desktop-tower-monitor:F0AAB")
		this.addIcon("details:F01C6")
		this.addIcon("dev-to:F0D6E")
		this.addIcon("developer-board:F0697")
		this.addIcon("deviantart:F01C7")
		this.addIcon("devices:F0FB0")
		this.addIcon("dharmachakra:F094B")
		this.addIcon("diabetes:F1126")
		this.addIcon("dialpad:F061C")
		this.addIcon("diameter:F0C63")
		this.addIcon("diameter-outline:F0C64")
		this.addIcon("diameter-variant:F0C65")
		this.addIcon("diamond:F0B8A")
		this.addIcon("diamond-outline:F0B8B")
		this.addIcon("diamond-stone:F01C8")
		this.addIcon("dice-1:F01CA")
		this.addIcon("dice-1-outline:F114A")
		this.addIcon("dice-2:F01CB")
		this.addIcon("dice-2-outline:F114B")
		this.addIcon("dice-3:F01CC")
		this.addIcon("dice-3-outline:F114C")
		this.addIcon("dice-4:F01CD")
		this.addIcon("dice-4-outline:F114D")
		this.addIcon("dice-5:F01CE")
		this.addIcon("dice-5-outline:F114E")
		this.addIcon("dice-6:F01CF")
		this.addIcon("dice-6-outline:F114F")
		this.addIcon("dice-d10:F1153")
		this.addIcon("dice-d10-outline:F076F")
		this.addIcon("dice-d12:F1154")
		this.addIcon("dice-d12-outline:F0867")
		this.addIcon("dice-d20:F1155")
		this.addIcon("dice-d20-outline:F05EA")
		this.addIcon("dice-d4:F1150")
		this.addIcon("dice-d4-outline:F05EB")
		this.addIcon("dice-d6:F1151")
		this.addIcon("dice-d6-outline:F05ED")
		this.addIcon("dice-d8:F1152")
		this.addIcon("dice-d8-outline:F05EC")
		this.addIcon("dice-multiple:F076E")
		this.addIcon("dice-multiple-outline:F1156")
		this.addIcon("digital-ocean:F1237")
		this.addIcon("dip-switch:F07C1")
		this.addIcon("directions:F01D0")
		this.addIcon("directions-fork:F0641")
		this.addIcon("disc:F05EE")
		this.addIcon("disc-alert:F01D1")
		this.addIcon("disc-player:F0960")
		this.addIcon("discord:F066F")
		this.addIcon("dishwasher:F0AAC")
		this.addIcon("dishwasher-alert:F11B8")
		this.addIcon("dishwasher-off:F11B9")
		this.addIcon("disqus:F01D2")
		this.addIcon("distribute-horizontal-center:F11C9")
		this.addIcon("distribute-horizontal-left:F11C8")
		this.addIcon("distribute-horizontal-right:F11CA")
		this.addIcon("distribute-vertical-bottom:F11CB")
		this.addIcon("distribute-vertical-center:F11CC")
		this.addIcon("distribute-vertical-top:F11CD")
		this.addIcon("diving-flippers:F0DBF")
		this.addIcon("diving-helmet:F0DC0")
		this.addIcon("diving-scuba:F0DC1")
		this.addIcon("diving-scuba-flag:F0DC2")
		this.addIcon("diving-scuba-tank:F0DC3")
		this.addIcon("diving-scuba-tank-multiple:F0DC4")
		this.addIcon("diving-snorkel:F0DC5")
		this.addIcon("division:F01D4")
		this.addIcon("division-box:F01D5")
		this.addIcon("dlna:F0A41")
		this.addIcon("dna:F0684")
		this.addIcon("dns:F01D6")
		this.addIcon("dns-outline:F0B8C")
		this.addIcon("dock-bottom:F10A9")
		this.addIcon("dock-left:F10AA")
		this.addIcon("dock-right:F10AB")
		this.addIcon("dock-top:F1513")
		this.addIcon("dock-window:F10AC")
		this.addIcon("docker:F0868")
		this.addIcon("doctor:F0A42")
		this.addIcon("dog:F0A43")
		this.addIcon("dog-service:F0AAD")
		this.addIcon("dog-side:F0A44")
		this.addIcon("dog-side-off:F16EE")
		this.addIcon("dolby:F06B3")
		this.addIcon("dolly:F0E9E")
		this.addIcon("domain:F01D7")
		this.addIcon("domain-off:F0D6F")
		this.addIcon("domain-plus:F10AD")
		this.addIcon("domain-remove:F10AE")
		this.addIcon("dome-light:F141E")
		this.addIcon("domino-mask:F1023")
		this.addIcon("donkey:F07C2")
		this.addIcon("door:F081A")
		this.addIcon("door-closed:F081B")
		this.addIcon("door-closed-lock:F10AF")
		this.addIcon("door-open:F081C")
		this.addIcon("door-sliding:F181E")
		this.addIcon("door-sliding-lock:F181F")
		this.addIcon("door-sliding-open:F1820")
		this.addIcon("doorbell:F12E6")
		this.addIcon("doorbell-video:F0869")
		this.addIcon("dot-net:F0AAE")
		this.addIcon("dots-grid:F15FC")
		this.addIcon("dots-hexagon:F15FF")
		this.addIcon("dots-horizontal:F01D8")
		this.addIcon("dots-horizontal-circle:F07C3")
		this.addIcon("dots-horizontal-circle-outline:F0B8D")
		this.addIcon("dots-square:F15FD")
		this.addIcon("dots-triangle:F15FE")
		this.addIcon("dots-vertical:F01D9")
		this.addIcon("dots-vertical-circle:F07C4")
		this.addIcon("dots-vertical-circle-outline:F0B8E")
		this.addIcon("download:F01DA")
		this.addIcon("download-box:F1462")
		this.addIcon("download-box-outline:F1463")
		this.addIcon("download-circle:F1464")
		this.addIcon("download-circle-outline:F1465")
		this.addIcon("download-lock:F1320")
		this.addIcon("download-lock-outline:F1321")
		this.addIcon("download-multiple:F09E9")
		this.addIcon("download-network:F06F4")
		this.addIcon("download-network-outline:F0C66")
		this.addIcon("download-off:F10B0")
		this.addIcon("download-off-outline:F10B1")
		this.addIcon("download-outline:F0B8F")
		this.addIcon("drag:F01DB")
		this.addIcon("drag-horizontal:F01DC")
		this.addIcon("drag-horizontal-variant:F12F0")
		this.addIcon("drag-variant:F0B90")
		this.addIcon("drag-vertical:F01DD")
		this.addIcon("drag-vertical-variant:F12F1")
		this.addIcon("drama-masks:F0D02")
		this.addIcon("draw:F0F49")
		this.addIcon("drawing:F01DE")
		this.addIcon("drawing-box:F01DF")
		this.addIcon("dresser:F0F4A")
		this.addIcon("dresser-outline:F0F4B")
		this.addIcon("drone:F01E2")
		this.addIcon("dropbox:F01E3")
		this.addIcon("drupal:F01E4")
		this.addIcon("duck:F01E5")
		this.addIcon("dumbbell:F01E6")
		this.addIcon("dump-truck:F0C67")
		this.addIcon("ear-hearing:F07C5")
		this.addIcon("ear-hearing-off:F0A45")
		this.addIcon("earth:F01E7")
		this.addIcon("earth-arrow-right:F1311")
		this.addIcon("earth-box:F06CD")
		this.addIcon("earth-box-minus:F1407")
		this.addIcon("earth-box-off:F06CE")
		this.addIcon("earth-box-plus:F1406")
		this.addIcon("earth-box-remove:F1408")
		this.addIcon("earth-minus:F1404")
		this.addIcon("earth-off:F01E8")
		this.addIcon("earth-plus:F1403")
		this.addIcon("earth-remove:F1405")
		this.addIcon("egg:F0AAF")
		this.addIcon("egg-easter:F0AB0")
		this.addIcon("egg-off:F13F0")
		this.addIcon("egg-off-outline:F13F1")
		this.addIcon("egg-outline:F13F2")
		this.addIcon("eiffel-tower:F156B")
		this.addIcon("eight-track:F09EA")
		this.addIcon("eject:F01EA")
		this.addIcon("eject-outline:F0B91")
		this.addIcon("electric-switch:F0E9F")
		this.addIcon("electric-switch-closed:F10D9")
		this.addIcon("electron-framework:F1024")
		this.addIcon("elephant:F07C6")
		this.addIcon("elevation-decline:F01EB")
		this.addIcon("elevation-rise:F01EC")
		this.addIcon("elevator:F01ED")
		this.addIcon("elevator-down:F12C2")
		this.addIcon("elevator-passenger:F1381")
		this.addIcon("elevator-up:F12C1")
		this.addIcon("ellipse:F0EA0")
		this.addIcon("ellipse-outline:F0EA1")
		this.addIcon("email:F01EE")
		this.addIcon("email-alert:F06CF")
		this.addIcon("email-alert-outline:F0D42")
		this.addIcon("email-box:F0D03")
		this.addIcon("email-check:F0AB1")
		this.addIcon("email-check-outline:F0AB2")
		this.addIcon("email-edit:F0EE3")
		this.addIcon("email-edit-outline:F0EE4")
		this.addIcon("email-lock:F01F1")
		this.addIcon("email-mark-as-unread:F0B92")
		this.addIcon("email-minus:F0EE5")
		this.addIcon("email-minus-outline:F0EE6")
		this.addIcon("email-multiple:F0EE7")
		this.addIcon("email-multiple-outline:F0EE8")
		this.addIcon("email-newsletter:F0FB1")
		this.addIcon("email-off:F13E3")
		this.addIcon("email-off-outline:F13E4")
		this.addIcon("email-open:F01EF")
		this.addIcon("email-open-multiple:F0EE9")
		this.addIcon("email-open-multiple-outline:F0EEA")
		this.addIcon("email-open-outline:F05EF")
		this.addIcon("email-outline:F01F0")
		this.addIcon("email-plus:F09EB")
		this.addIcon("email-plus-outline:F09EC")
		this.addIcon("email-receive:F10DA")
		this.addIcon("email-receive-outline:F10DB")
		this.addIcon("email-remove:F1661")
		this.addIcon("email-remove-outline:F1662")
		this.addIcon("email-search:F0961")
		this.addIcon("email-search-outline:F0962")
		this.addIcon("email-send:F10DC")
		this.addIcon("email-send-outline:F10DD")
		this.addIcon("email-sync:F12C7")
		this.addIcon("email-sync-outline:F12C8")
		this.addIcon("email-variant:F05F0")
		this.addIcon("ember:F0B30")
		this.addIcon("emby:F06B4")
		this.addIcon("emoticon:F0C68")
		this.addIcon("emoticon-angry:F0C69")
		this.addIcon("emoticon-angry-outline:F0C6A")
		this.addIcon("emoticon-confused:F10DE")
		this.addIcon("emoticon-confused-outline:F10DF")
		this.addIcon("emoticon-cool:F0C6B")
		this.addIcon("emoticon-cool-outline:F01F3")
		this.addIcon("emoticon-cry:F0C6C")
		this.addIcon("emoticon-cry-outline:F0C6D")
		this.addIcon("emoticon-dead:F0C6E")
		this.addIcon("emoticon-dead-outline:F069B")
		this.addIcon("emoticon-devil:F0C6F")
		this.addIcon("emoticon-devil-outline:F01F4")
		this.addIcon("emoticon-excited:F0C70")
		this.addIcon("emoticon-excited-outline:F069C")
		this.addIcon("emoticon-frown:F0F4C")
		this.addIcon("emoticon-frown-outline:F0F4D")
		this.addIcon("emoticon-happy:F0C71")
		this.addIcon("emoticon-happy-outline:F01F5")
		this.addIcon("emoticon-kiss:F0C72")
		this.addIcon("emoticon-kiss-outline:F0C73")
		this.addIcon("emoticon-lol:F1214")
		this.addIcon("emoticon-lol-outline:F1215")
		this.addIcon("emoticon-neutral:F0C74")
		this.addIcon("emoticon-neutral-outline:F01F6")
		this.addIcon("emoticon-outline:F01F2")
		this.addIcon("emoticon-poop:F01F7")
		this.addIcon("emoticon-poop-outline:F0C75")
		this.addIcon("emoticon-sad:F0C76")
		this.addIcon("emoticon-sad-outline:F01F8")
		this.addIcon("emoticon-sick:F157C")
		this.addIcon("emoticon-sick-outline:F157D")
		this.addIcon("emoticon-tongue:F01F9")
		this.addIcon("emoticon-tongue-outline:F0C77")
		this.addIcon("emoticon-wink:F0C78")
		this.addIcon("emoticon-wink-outline:F0C79")
		this.addIcon("engine:F01FA")
		this.addIcon("engine-off:F0A46")
		this.addIcon("engine-off-outline:F0A47")
		this.addIcon("engine-outline:F01FB")
		this.addIcon("epsilon:F10E0")
		this.addIcon("equal:F01FC")
		this.addIcon("equal-box:F01FD")
		this.addIcon("equalizer:F0EA2")
		this.addIcon("equalizer-outline:F0EA3")
		this.addIcon("eraser:F01FE")
		this.addIcon("eraser-variant:F0642")
		this.addIcon("escalator:F01FF")
		this.addIcon("escalator-box:F1399")
		this.addIcon("escalator-down:F12C0")
		this.addIcon("escalator-up:F12BF")
		this.addIcon("eslint:F0C7A")
		this.addIcon("et:F0AB3")
		this.addIcon("ethereum:F086A")
		this.addIcon("ethernet:F0200")
		this.addIcon("ethernet-cable:F0201")
		this.addIcon("ethernet-cable-off:F0202")
		this.addIcon("ev-plug-ccs1:F1519")
		this.addIcon("ev-plug-ccs2:F151A")
		this.addIcon("ev-plug-chademo:F151B")
		this.addIcon("ev-plug-tesla:F151C")
		this.addIcon("ev-plug-type1:F151D")
		this.addIcon("ev-plug-type2:F151E")
		this.addIcon("ev-station:F05F1")
		this.addIcon("evernote:F0204")
		this.addIcon("excavator:F1025")
		this.addIcon("exclamation:F0205")
		this.addIcon("exclamation-thick:F1238")
		this.addIcon("exit-run:F0A48")
		this.addIcon("exit-to-app:F0206")
		this.addIcon("expand-all:F0AB4")
		this.addIcon("expand-all-outline:F0AB5")
		this.addIcon("expansion-card:F08AE")
		this.addIcon("expansion-card-variant:F0FB2")
		this.addIcon("exponent:F0963")
		this.addIcon("exponent-box:F0964")
		this.addIcon("export:F0207")
		this.addIcon("export-variant:F0B93")
		this.addIcon("eye:F0208")
		this.addIcon("eye-check:F0D04")
		this.addIcon("eye-check-outline:F0D05")
		this.addIcon("eye-circle:F0B94")
		this.addIcon("eye-circle-outline:F0B95")
		this.addIcon("eye-minus:F1026")
		this.addIcon("eye-minus-outline:F1027")
		this.addIcon("eye-off:F0209")
		this.addIcon("eye-off-outline:F06D1")
		this.addIcon("eye-outline:F06D0")
		this.addIcon("eye-plus:F086B")
		this.addIcon("eye-plus-outline:F086C")
		this.addIcon("eye-remove:F15E3")
		this.addIcon("eye-remove-outline:F15E4")
		this.addIcon("eye-settings:F086D")
		this.addIcon("eye-settings-outline:F086E")
		this.addIcon("eyedropper:F020A")
		this.addIcon("eyedropper-minus:F13DD")
		this.addIcon("eyedropper-off:F13DF")
		this.addIcon("eyedropper-plus:F13DC")
		this.addIcon("eyedropper-remove:F13DE")
		this.addIcon("eyedropper-variant:F020B")
		this.addIcon("face-agent:F0D70")
		this.addIcon("face-man:F0643")
		this.addIcon("face-man-outline:F0B96")
		this.addIcon("face-man-profile:F0644")
		this.addIcon("face-man-shimmer:F15CC")
		this.addIcon("face-man-shimmer-outline:F15CD")
		this.addIcon("face-mask:F1586")
		this.addIcon("face-mask-outline:F1587")
		this.addIcon("face-recognition:F0C7B")
		this.addIcon("face-woman:F1077")
		this.addIcon("face-woman-outline:F1078")
		this.addIcon("face-woman-profile:F1076")
		this.addIcon("face-woman-shimmer:F15CE")
		this.addIcon("face-woman-shimmer-outline:F15CF")
		this.addIcon("facebook:F020C")
		this.addIcon("facebook-gaming:F07DD")
		this.addIcon("facebook-messenger:F020E")
		this.addIcon("facebook-workplace:F0B31")
		this.addIcon("factory:F020F")
		this.addIcon("family-tree:F160E")
		this.addIcon("fan:F0210")
		this.addIcon("fan-alert:F146C")
		this.addIcon("fan-auto:F171D")
		this.addIcon("fan-chevron-down:F146D")
		this.addIcon("fan-chevron-up:F146E")
		this.addIcon("fan-minus:F1470")
		this.addIcon("fan-off:F081D")
		this.addIcon("fan-plus:F146F")
		this.addIcon("fan-remove:F1471")
		this.addIcon("fan-speed-1:F1472")
		this.addIcon("fan-speed-2:F1473")
		this.addIcon("fan-speed-3:F1474")
		this.addIcon("fast-forward:F0211")
		this.addIcon("fast-forward-10:F0D71")
		this.addIcon("fast-forward-30:F0D06")
		this.addIcon("fast-forward-5:F11F8")
		this.addIcon("fast-forward-60:F160B")
		this.addIcon("fast-forward-outline:F06D2")
		this.addIcon("fax:F0212")
		this.addIcon("feather:F06D3")
		this.addIcon("feature-search:F0A49")
		this.addIcon("feature-search-outline:F0A4A")
		this.addIcon("fedora:F08DB")
		this.addIcon("fence:F179A")
		this.addIcon("fence-electric:F17F6")
		this.addIcon("fencing:F14C1")
		this.addIcon("ferris-wheel:F0EA4")
		this.addIcon("ferry:F0213")
		this.addIcon("file:F0214")
		this.addIcon("file-account:F073B")
		this.addIcon("file-account-outline:F1028")
		this.addIcon("file-alert:F0A4B")
		this.addIcon("file-alert-outline:F0A4C")
		this.addIcon("file-cabinet:F0AB6")
		this.addIcon("file-cad:F0EEB")
		this.addIcon("file-cad-box:F0EEC")
		this.addIcon("file-cancel:F0DC6")
		this.addIcon("file-cancel-outline:F0DC7")
		this.addIcon("file-certificate:F1186")
		this.addIcon("file-certificate-outline:F1187")
		this.addIcon("file-chart:F0215")
		this.addIcon("file-chart-outline:F1029")
		this.addIcon("file-check:F0216")
		this.addIcon("file-check-outline:F0E29")
		this.addIcon("file-clock:F12E1")
		this.addIcon("file-clock-outline:F12E2")
		this.addIcon("file-cloud:F0217")
		this.addIcon("file-cloud-outline:F102A")
		this.addIcon("file-code:F022E")
		this.addIcon("file-code-outline:F102B")
		this.addIcon("file-cog:F107B")
		this.addIcon("file-cog-outline:F107C")
		this.addIcon("file-compare:F08AA")
		this.addIcon("file-delimited:F0218")
		this.addIcon("file-delimited-outline:F0EA5")
		this.addIcon("file-document:F0219")
		this.addIcon("file-document-edit:F0DC8")
		this.addIcon("file-document-edit-outline:F0DC9")
		this.addIcon("file-document-multiple:F1517")
		this.addIcon("file-document-multiple-outline:F1518")
		this.addIcon("file-document-outline:F09EE")
		this.addIcon("file-download:F0965")
		this.addIcon("file-download-outline:F0966")
		this.addIcon("file-edit:F11E7")
		this.addIcon("file-edit-outline:F11E8")
		this.addIcon("file-excel:F021B")
		this.addIcon("file-excel-box:F021C")
		this.addIcon("file-excel-box-outline:F102C")
		this.addIcon("file-excel-outline:F102D")
		this.addIcon("file-export:F021D")
		this.addIcon("file-export-outline:F102E")
		this.addIcon("file-eye:F0DCA")
		this.addIcon("file-eye-outline:F0DCB")
		this.addIcon("file-find:F021E")
		this.addIcon("file-find-outline:F0B97")
		this.addIcon("file-gif-box:F0D78")
		this.addIcon("file-hidden:F0613")
		this.addIcon("file-image:F021F")
		this.addIcon("file-image-marker:F1772")
		this.addIcon("file-image-marker-outline:F1773")
		this.addIcon("file-image-outline:F0EB0")
		this.addIcon("file-import:F0220")
		this.addIcon("file-import-outline:F102F")
		this.addIcon("file-jpg-box:F0225")
		this.addIcon("file-key:F1184")
		this.addIcon("file-key-outline:F1185")
		this.addIcon("file-link:F1177")
		this.addIcon("file-link-outline:F1178")
		this.addIcon("file-lock:F0221")
		this.addIcon("file-lock-outline:F1030")
		this.addIcon("file-marker:F1774")
		this.addIcon("file-marker-outline:F1775")
		this.addIcon("file-move:F0AB9")
		this.addIcon("file-move-outline:F1031")
		this.addIcon("file-multiple:F0222")
		this.addIcon("file-multiple-outline:F1032")
		this.addIcon("file-music:F0223")
		this.addIcon("file-music-outline:F0E2A")
		this.addIcon("file-outline:F0224")
		this.addIcon("file-pdf-box:F0226")
		this.addIcon("file-percent:F081E")
		this.addIcon("file-percent-outline:F1033")
		this.addIcon("file-phone:F1179")
		this.addIcon("file-phone-outline:F117A")
		this.addIcon("file-plus:F0752")
		this.addIcon("file-plus-outline:F0EED")
		this.addIcon("file-png-box:F0E2D")
		this.addIcon("file-powerpoint:F0227")
		this.addIcon("file-powerpoint-box:F0228")
		this.addIcon("file-powerpoint-box-outline:F1034")
		this.addIcon("file-powerpoint-outline:F1035")
		this.addIcon("file-presentation-box:F0229")
		this.addIcon("file-question:F086F")
		this.addIcon("file-question-outline:F1036")
		this.addIcon("file-refresh:F0918")
		this.addIcon("file-refresh-outline:F0541")
		this.addIcon("file-remove:F0B98")
		this.addIcon("file-remove-outline:F1037")
		this.addIcon("file-replace:F0B32")
		this.addIcon("file-replace-outline:F0B33")
		this.addIcon("file-restore:F0670")
		this.addIcon("file-restore-outline:F1038")
		this.addIcon("file-search:F0C7C")
		this.addIcon("file-search-outline:F0C7D")
		this.addIcon("file-send:F022A")
		this.addIcon("file-send-outline:F1039")
		this.addIcon("file-settings:F1079")
		this.addIcon("file-settings-outline:F107A")
		this.addIcon("file-star:F103A")
		this.addIcon("file-star-outline:F103B")
		this.addIcon("file-swap:F0FB4")
		this.addIcon("file-swap-outline:F0FB5")
		this.addIcon("file-sync:F1216")
		this.addIcon("file-sync-outline:F1217")
		this.addIcon("file-table:F0C7E")
		this.addIcon("file-table-box:F10E1")
		this.addIcon("file-table-box-multiple:F10E2")
		this.addIcon("file-table-box-multiple-outline:F10E3")
		this.addIcon("file-table-box-outline:F10E4")
		this.addIcon("file-table-outline:F0C7F")
		this.addIcon("file-tree:F0645")
		this.addIcon("file-tree-outline:F13D2")
		this.addIcon("file-undo:F08DC")
		this.addIcon("file-undo-outline:F103C")
		this.addIcon("file-upload:F0A4D")
		this.addIcon("file-upload-outline:F0A4E")
		this.addIcon("file-video:F022B")
		this.addIcon("file-video-outline:F0E2C")
		this.addIcon("file-word:F022C")
		this.addIcon("file-word-box:F022D")
		this.addIcon("file-word-box-outline:F103D")
		this.addIcon("file-word-outline:F103E")
		this.addIcon("film:F022F")
		this.addIcon("filmstrip:F0230")
		this.addIcon("filmstrip-box:F0332")
		this.addIcon("filmstrip-box-multiple:F0D18")
		this.addIcon("filmstrip-off:F0231")
		this.addIcon("filter:F0232")
		this.addIcon("filter-menu:F10E5")
		this.addIcon("filter-menu-outline:F10E6")
		this.addIcon("filter-minus:F0EEE")
		this.addIcon("filter-minus-outline:F0EEF")
		this.addIcon("filter-off:F14EF")
		this.addIcon("filter-off-outline:F14F0")
		this.addIcon("filter-outline:F0233")
		this.addIcon("filter-plus:F0EF0")
		this.addIcon("filter-plus-outline:F0EF1")
		this.addIcon("filter-remove:F0234")
		this.addIcon("filter-remove-outline:F0235")
		this.addIcon("filter-variant:F0236")
		this.addIcon("filter-variant-minus:F1112")
		this.addIcon("filter-variant-plus:F1113")
		this.addIcon("filter-variant-remove:F103F")
		this.addIcon("finance:F081F")
		this.addIcon("find-replace:F06D4")
		this.addIcon("fingerprint:F0237")
		this.addIcon("fingerprint-off:F0EB1")
		this.addIcon("fire:F0238")
		this.addIcon("fire-alert:F15D7")
		this.addIcon("fire-circle:F1807")
		this.addIcon("fire-extinguisher:F0EF2")
		this.addIcon("fire-hydrant:F1137")
		this.addIcon("fire-hydrant-alert:F1138")
		this.addIcon("fire-hydrant-off:F1139")
		this.addIcon("fire-off:F1722")
		this.addIcon("fire-truck:F08AB")
		this.addIcon("firebase:F0967")
		this.addIcon("firefox:F0239")
		this.addIcon("fireplace:F0E2E")
		this.addIcon("fireplace-off:F0E2F")
		this.addIcon("firewire:F05BE")
		this.addIcon("firework:F0E30")
		this.addIcon("firework-off:F1723")
		this.addIcon("fish:F023A")
		this.addIcon("fish-off:F13F3")
		this.addIcon("fishbowl:F0EF3")
		this.addIcon("fishbowl-outline:F0EF4")
		this.addIcon("fit-to-page:F0EF5")
		this.addIcon("fit-to-page-outline:F0EF6")
		this.addIcon("flag:F023B")
		this.addIcon("flag-checkered:F023C")
		this.addIcon("flag-minus:F0B99")
		this.addIcon("flag-minus-outline:F10B2")
		this.addIcon("flag-outline:F023D")
		this.addIcon("flag-plus:F0B9A")
		this.addIcon("flag-plus-outline:F10B3")
		this.addIcon("flag-remove:F0B9B")
		this.addIcon("flag-remove-outline:F10B4")
		this.addIcon("flag-triangle:F023F")
		this.addIcon("flag-variant:F0240")
		this.addIcon("flag-variant-outline:F023E")
		this.addIcon("flare:F0D72")
		this.addIcon("flash:F0241")
		this.addIcon("flash-alert:F0EF7")
		this.addIcon("flash-alert-outline:F0EF8")
		this.addIcon("flash-auto:F0242")
		this.addIcon("flash-off:F0243")
		this.addIcon("flash-outline:F06D5")
		this.addIcon("flash-red-eye:F067B")
		this.addIcon("flashlight:F0244")
		this.addIcon("flashlight-off:F0245")
		this.addIcon("flask:F0093")
		this.addIcon("flask-empty:F0094")
		this.addIcon("flask-empty-minus:F123A")
		this.addIcon("flask-empty-minus-outline:F123B")
		this.addIcon("flask-empty-off:F13F4")
		this.addIcon("flask-empty-off-outline:F13F5")
		this.addIcon("flask-empty-outline:F0095")
		this.addIcon("flask-empty-plus:F123C")
		this.addIcon("flask-empty-plus-outline:F123D")
		this.addIcon("flask-empty-remove:F123E")
		this.addIcon("flask-empty-remove-outline:F123F")
		this.addIcon("flask-minus:F1240")
		this.addIcon("flask-minus-outline:F1241")
		this.addIcon("flask-off:F13F6")
		this.addIcon("flask-off-outline:F13F7")
		this.addIcon("flask-outline:F0096")
		this.addIcon("flask-plus:F1242")
		this.addIcon("flask-plus-outline:F1243")
		this.addIcon("flask-remove:F1244")
		this.addIcon("flask-remove-outline:F1245")
		this.addIcon("flask-round-bottom:F124B")
		this.addIcon("flask-round-bottom-empty:F124C")
		this.addIcon("flask-round-bottom-empty-outline:F124D")
		this.addIcon("flask-round-bottom-outline:F124E")
		this.addIcon("fleur-de-lis:F1303")
		this.addIcon("flip-horizontal:F10E7")
		this.addIcon("flip-to-back:F0247")
		this.addIcon("flip-to-front:F0248")
		this.addIcon("flip-vertical:F10E8")
		this.addIcon("floor-lamp:F08DD")
		this.addIcon("floor-lamp-dual:F1040")
		this.addIcon("floor-lamp-dual-outline:F17CE")
		this.addIcon("floor-lamp-outline:F17C8")
		this.addIcon("floor-lamp-torchiere:F1747")
		this.addIcon("floor-lamp-torchiere-outline:F17D6")
		this.addIcon("floor-lamp-torchiere-variant:F1041")
		this.addIcon("floor-lamp-torchiere-variant-outline:F17CF")
		this.addIcon("floor-plan:F0821")
		this.addIcon("floppy:F0249")
		this.addIcon("floppy-variant:F09EF")
		this.addIcon("flower:F024A")
		this.addIcon("flower-outline:F09F0")
		this.addIcon("flower-poppy:F0D08")
		this.addIcon("flower-tulip:F09F1")
		this.addIcon("flower-tulip-outline:F09F2")
		this.addIcon("focus-auto:F0F4E")
		this.addIcon("focus-field:F0F4F")
		this.addIcon("focus-field-horizontal:F0F50")
		this.addIcon("focus-field-vertical:F0F51")
		this.addIcon("folder:F024B")
		this.addIcon("folder-account:F024C")
		this.addIcon("folder-account-outline:F0B9C")
		this.addIcon("folder-alert:F0DCC")
		this.addIcon("folder-alert-outline:F0DCD")
		this.addIcon("folder-clock:F0ABA")
		this.addIcon("folder-clock-outline:F0ABB")
		this.addIcon("folder-cog:F107F")
		this.addIcon("folder-cog-outline:F1080")
		this.addIcon("folder-download:F024D")
		this.addIcon("folder-download-outline:F10E9")
		this.addIcon("folder-edit:F08DE")
		this.addIcon("folder-edit-outline:F0DCE")
		this.addIcon("folder-eye:F178A")
		this.addIcon("folder-eye-outline:F178B")
		this.addIcon("folder-google-drive:F024E")
		this.addIcon("folder-heart:F10EA")
		this.addIcon("folder-heart-outline:F10EB")
		this.addIcon("folder-hidden:F179E")
		this.addIcon("folder-home:F10B5")
		this.addIcon("folder-home-outline:F10B6")
		this.addIcon("folder-image:F024F")
		this.addIcon("folder-information:F10B7")
		this.addIcon("folder-information-outline:F10B8")
		this.addIcon("folder-key:F08AC")
		this.addIcon("folder-key-network:F08AD")
		this.addIcon("folder-key-network-outline:F0C80")
		this.addIcon("folder-key-outline:F10EC")
		this.addIcon("folder-lock:F0250")
		this.addIcon("folder-lock-open:F0251")
		this.addIcon("folder-marker:F126D")
		this.addIcon("folder-marker-outline:F126E")
		this.addIcon("folder-move:F0252")
		this.addIcon("folder-move-outline:F1246")
		this.addIcon("folder-multiple:F0253")
		this.addIcon("folder-multiple-image:F0254")
		this.addIcon("folder-multiple-outline:F0255")
		this.addIcon("folder-multiple-plus:F147E")
		this.addIcon("folder-multiple-plus-outline:F147F")
		this.addIcon("folder-music:F1359")
		this.addIcon("folder-music-outline:F135A")
		this.addIcon("folder-network:F0870")
		this.addIcon("folder-network-outline:F0C81")
		this.addIcon("folder-open:F0770")
		this.addIcon("folder-open-outline:F0DCF")
		this.addIcon("folder-outline:F0256")
		this.addIcon("folder-plus:F0257")
		this.addIcon("folder-plus-outline:F0B9D")
		this.addIcon("folder-pound:F0D09")
		this.addIcon("folder-pound-outline:F0D0A")
		this.addIcon("folder-refresh:F0749")
		this.addIcon("folder-refresh-outline:F0542")
		this.addIcon("folder-remove:F0258")
		this.addIcon("folder-remove-outline:F0B9E")
		this.addIcon("folder-search:F0968")
		this.addIcon("folder-search-outline:F0969")
		this.addIcon("folder-settings:F107D")
		this.addIcon("folder-settings-outline:F107E")
		this.addIcon("folder-star:F069D")
		this.addIcon("folder-star-multiple:F13D3")
		this.addIcon("folder-star-multiple-outline:F13D4")
		this.addIcon("folder-star-outline:F0B9F")
		this.addIcon("folder-swap:F0FB6")
		this.addIcon("folder-swap-outline:F0FB7")
		this.addIcon("folder-sync:F0D0B")
		this.addIcon("folder-sync-outline:F0D0C")
		this.addIcon("folder-table:F12E3")
		this.addIcon("folder-table-outline:F12E4")
		this.addIcon("folder-text:F0C82")
		this.addIcon("folder-text-outline:F0C83")
		this.addIcon("folder-upload:F0259")
		this.addIcon("folder-upload-outline:F10ED")
		this.addIcon("folder-zip:F06EB")
		this.addIcon("folder-zip-outline:F07B9")
		this.addIcon("font-awesome:F003A")
		this.addIcon("food:F025A")
		this.addIcon("food-apple:F025B")
		this.addIcon("food-apple-outline:F0C84")
		this.addIcon("food-croissant:F07C8")
		this.addIcon("food-drumstick:F141F")
		this.addIcon("food-drumstick-off:F1468")
		this.addIcon("food-drumstick-off-outline:F1469")
		this.addIcon("food-drumstick-outline:F1420")
		this.addIcon("food-fork-drink:F05F2")
		this.addIcon("food-halal:F1572")
		this.addIcon("food-kosher:F1573")
		this.addIcon("food-off:F05F3")
		this.addIcon("food-steak:F146A")
		this.addIcon("food-steak-off:F146B")
		this.addIcon("food-turkey:F171C")
		this.addIcon("food-variant:F025C")
		this.addIcon("food-variant-off:F13E5")
		this.addIcon("foot-print:F0F52")
		this.addIcon("football:F025D")
		this.addIcon("football-australian:F025E")
		this.addIcon("football-helmet:F025F")
		this.addIcon("forklift:F07C9")
		this.addIcon("form-dropdown:F1400")
		this.addIcon("form-select:F1401")
		this.addIcon("form-textarea:F1095")
		this.addIcon("form-textbox:F060E")
		this.addIcon("form-textbox-lock:F135D")
		this.addIcon("form-textbox-password:F07F5")
		this.addIcon("format-align-bottom:F0753")
		this.addIcon("format-align-center:F0260")
		this.addIcon("format-align-justify:F0261")
		this.addIcon("format-align-left:F0262")
		this.addIcon("format-align-middle:F0754")
		this.addIcon("format-align-right:F0263")
		this.addIcon("format-align-top:F0755")
		this.addIcon("format-annotation-minus:F0ABC")
		this.addIcon("format-annotation-plus:F0646")
		this.addIcon("format-bold:F0264")
		this.addIcon("format-clear:F0265")
		this.addIcon("format-color-fill:F0266")
		this.addIcon("format-color-highlight:F0E31")
		this.addIcon("format-color-marker-cancel:F1313")
		this.addIcon("format-color-text:F069E")
		this.addIcon("format-columns:F08DF")
		this.addIcon("format-float-center:F0267")
		this.addIcon("format-float-left:F0268")
		this.addIcon("format-float-none:F0269")
		this.addIcon("format-float-right:F026A")
		this.addIcon("format-font:F06D6")
		this.addIcon("format-font-size-decrease:F09F3")
		this.addIcon("format-font-size-increase:F09F4")
		this.addIcon("format-header-1:F026B")
		this.addIcon("format-header-2:F026C")
		this.addIcon("format-header-3:F026D")
		this.addIcon("format-header-4:F026E")
		this.addIcon("format-header-5:F026F")
		this.addIcon("format-header-6:F0270")
		this.addIcon("format-header-decrease:F0271")
		this.addIcon("format-header-equal:F0272")
		this.addIcon("format-header-increase:F0273")
		this.addIcon("format-header-pound:F0274")
		this.addIcon("format-horizontal-align-center:F061E")
		this.addIcon("format-horizontal-align-left:F061F")
		this.addIcon("format-horizontal-align-right:F0620")
		this.addIcon("format-indent-decrease:F0275")
		this.addIcon("format-indent-increase:F0276")
		this.addIcon("format-italic:F0277")
		this.addIcon("format-letter-case:F0B34")
		this.addIcon("format-letter-case-lower:F0B35")
		this.addIcon("format-letter-case-upper:F0B36")
		this.addIcon("format-letter-ends-with:F0FB8")
		this.addIcon("format-letter-matches:F0FB9")
		this.addIcon("format-letter-starts-with:F0FBA")
		this.addIcon("format-line-spacing:F0278")
		this.addIcon("format-line-style:F05C8")
		this.addIcon("format-line-weight:F05C9")
		this.addIcon("format-list-bulleted:F0279")
		this.addIcon("format-list-bulleted-square:F0DD0")
		this.addIcon("format-list-bulleted-triangle:F0EB2")
		this.addIcon("format-list-bulleted-type:F027A")
		this.addIcon("format-list-checkbox:F096A")
		this.addIcon("format-list-checks:F0756")
		this.addIcon("format-list-numbered:F027B")
		this.addIcon("format-list-numbered-rtl:F0D0D")
		this.addIcon("format-list-text:F126F")
		this.addIcon("format-overline:F0EB3")
		this.addIcon("format-page-break:F06D7")
		this.addIcon("format-paint:F027C")
		this.addIcon("format-paragraph:F027D")
		this.addIcon("format-pilcrow:F06D8")
		this.addIcon("format-quote-close:F027E")
		this.addIcon("format-quote-close-outline:F11A8")
		this.addIcon("format-quote-open:F0757")
		this.addIcon("format-quote-open-outline:F11A7")
		this.addIcon("format-rotate-90:F06AA")
		this.addIcon("format-section:F069F")
		this.addIcon("format-size:F027F")
		this.addIcon("format-strikethrough:F0280")
		this.addIcon("format-strikethrough-variant:F0281")
		this.addIcon("format-subscript:F0282")
		this.addIcon("format-superscript:F0283")
		this.addIcon("format-text:F0284")
		this.addIcon("format-text-rotation-angle-down:F0FBB")
		this.addIcon("format-text-rotation-angle-up:F0FBC")
		this.addIcon("format-text-rotation-down:F0D73")
		this.addIcon("format-text-rotation-down-vertical:F0FBD")
		this.addIcon("format-text-rotation-none:F0D74")
		this.addIcon("format-text-rotation-up:F0FBE")
		this.addIcon("format-text-rotation-vertical:F0FBF")
		this.addIcon("format-text-variant:F0E32")
		this.addIcon("format-text-variant-outline:F150F")
		this.addIcon("format-text-wrapping-clip:F0D0E")
		this.addIcon("format-text-wrapping-overflow:F0D0F")
		this.addIcon("format-text-wrapping-wrap:F0D10")
		this.addIcon("format-textbox:F0D11")
		this.addIcon("format-textdirection-l-to-r:F0285")
		this.addIcon("format-textdirection-r-to-l:F0286")
		this.addIcon("format-title:F05F4")
		this.addIcon("format-underline:F0287")
		this.addIcon("format-vertical-align-bottom:F0621")
		this.addIcon("format-vertical-align-center:F0622")
		this.addIcon("format-vertical-align-top:F0623")
		this.addIcon("format-wrap-inline:F0288")
		this.addIcon("format-wrap-square:F0289")
		this.addIcon("format-wrap-tight:F028A")
		this.addIcon("format-wrap-top-bottom:F028B")
		this.addIcon("forum:F028C")
		this.addIcon("forum-outline:F0822")
		this.addIcon("forward:F028D")
		this.addIcon("forwardburger:F0D75")
		this.addIcon("fountain:F096B")
		this.addIcon("fountain-pen:F0D12")
		this.addIcon("fountain-pen-tip:F0D13")
		this.addIcon("freebsd:F08E0")
		this.addIcon("frequently-asked-questions:F0EB4")
		this.addIcon("fridge:F0290")
		this.addIcon("fridge-alert:F11B1")
		this.addIcon("fridge-alert-outline:F11B2")
		this.addIcon("fridge-bottom:F0292")
		this.addIcon("fridge-industrial:F15EE")
		this.addIcon("fridge-industrial-alert:F15EF")
		this.addIcon("fridge-industrial-alert-outline:F15F0")
		this.addIcon("fridge-industrial-off:F15F1")
		this.addIcon("fridge-industrial-off-outline:F15F2")
		this.addIcon("fridge-industrial-outline:F15F3")
		this.addIcon("fridge-off:F11AF")
		this.addIcon("fridge-off-outline:F11B0")
		this.addIcon("fridge-outline:F028F")
		this.addIcon("fridge-top:F0291")
		this.addIcon("fridge-variant:F15F4")
		this.addIcon("fridge-variant-alert:F15F5")
		this.addIcon("fridge-variant-alert-outline:F15F6")
		this.addIcon("fridge-variant-off:F15F7")
		this.addIcon("fridge-variant-off-outline:F15F8")
		this.addIcon("fridge-variant-outline:F15F9")
		this.addIcon("fruit-cherries:F1042")
		this.addIcon("fruit-cherries-off:F13F8")
		this.addIcon("fruit-citrus:F1043")
		this.addIcon("fruit-citrus-off:F13F9")
		this.addIcon("fruit-grapes:F1044")
		this.addIcon("fruit-grapes-outline:F1045")
		this.addIcon("fruit-pineapple:F1046")
		this.addIcon("fruit-watermelon:F1047")
		this.addIcon("fuel:F07CA")
		this.addIcon("fullscreen:F0293")
		this.addIcon("fullscreen-exit:F0294")
		this.addIcon("function:F0295")
		this.addIcon("function-variant:F0871")
		this.addIcon("furigana-horizontal:F1081")
		this.addIcon("furigana-vertical:F1082")
		this.addIcon("fuse:F0C85")
		this.addIcon("fuse-alert:F142D")
		this.addIcon("fuse-blade:F0C86")
		this.addIcon("fuse-off:F142C")
		this.addIcon("gamepad:F0296")
		this.addIcon("gamepad-circle:F0E33")
		this.addIcon("gamepad-circle-down:F0E34")
		this.addIcon("gamepad-circle-left:F0E35")
		this.addIcon("gamepad-circle-outline:F0E36")
		this.addIcon("gamepad-circle-right:F0E37")
		this.addIcon("gamepad-circle-up:F0E38")
		this.addIcon("gamepad-down:F0E39")
		this.addIcon("gamepad-left:F0E3A")
		this.addIcon("gamepad-right:F0E3B")
		this.addIcon("gamepad-round:F0E3C")
		this.addIcon("gamepad-round-down:F0E3D")
		this.addIcon("gamepad-round-left:F0E3E")
		this.addIcon("gamepad-round-outline:F0E3F")
		this.addIcon("gamepad-round-right:F0E40")
		this.addIcon("gamepad-round-up:F0E41")
		this.addIcon("gamepad-square:F0EB5")
		this.addIcon("gamepad-square-outline:F0EB6")
		this.addIcon("gamepad-up:F0E42")
		this.addIcon("gamepad-variant:F0297")
		this.addIcon("gamepad-variant-outline:F0EB7")
		this.addIcon("gamma:F10EE")
		this.addIcon("gantry-crane:F0DD1")
		this.addIcon("garage:F06D9")
		this.addIcon("garage-alert:F0872")
		this.addIcon("garage-alert-variant:F12D5")
		this.addIcon("garage-lock:F17FB")
		this.addIcon("garage-open:F06DA")
		this.addIcon("garage-open-variant:F12D4")
		this.addIcon("garage-variant:F12D3")
		this.addIcon("garage-variant-lock:F17FC")
		this.addIcon("gas-cylinder:F0647")
		this.addIcon("gas-station:F0298")
		this.addIcon("gas-station-off:F1409")
		this.addIcon("gas-station-off-outline:F140A")
		this.addIcon("gas-station-outline:F0EB8")
		this.addIcon("gate:F0299")
		this.addIcon("gate-alert:F17F8")
		this.addIcon("gate-and:F08E1")
		this.addIcon("gate-arrow-left:F17F7")
		this.addIcon("gate-arrow-right:F1169")
		this.addIcon("gate-nand:F08E2")
		this.addIcon("gate-nor:F08E3")
		this.addIcon("gate-not:F08E4")
		this.addIcon("gate-open:F116A")
		this.addIcon("gate-or:F08E5")
		this.addIcon("gate-xnor:F08E6")
		this.addIcon("gate-xor:F08E7")
		this.addIcon("gatsby:F0E43")
		this.addIcon("gauge:F029A")
		this.addIcon("gauge-empty:F0873")
		this.addIcon("gauge-full:F0874")
		this.addIcon("gauge-low:F0875")
		this.addIcon("gavel:F029B")
		this.addIcon("gender-female:F029C")
		this.addIcon("gender-male:F029D")
		this.addIcon("gender-male-female:F029E")
		this.addIcon("gender-male-female-variant:F113F")
		this.addIcon("gender-non-binary:F1140")
		this.addIcon("gender-transgender:F029F")
		this.addIcon("gentoo:F08E8")
		this.addIcon("gesture:F07CB")
		this.addIcon("gesture-double-tap:F073C")
		this.addIcon("gesture-pinch:F0ABD")
		this.addIcon("gesture-spread:F0ABE")
		this.addIcon("gesture-swipe:F0D76")
		this.addIcon("gesture-swipe-down:F073D")
		this.addIcon("gesture-swipe-horizontal:F0ABF")
		this.addIcon("gesture-swipe-left:F073E")
		this.addIcon("gesture-swipe-right:F073F")
		this.addIcon("gesture-swipe-up:F0740")
		this.addIcon("gesture-swipe-vertical:F0AC0")
		this.addIcon("gesture-tap:F0741")
		this.addIcon("gesture-tap-box:F12A9")
		this.addIcon("gesture-tap-button:F12A8")
		this.addIcon("gesture-tap-hold:F0D77")
		this.addIcon("gesture-two-double-tap:F0742")
		this.addIcon("gesture-two-tap:F0743")
		this.addIcon("ghost:F02A0")
		this.addIcon("ghost-off:F09F5")
		this.addIcon("ghost-off-outline:F165C")
		this.addIcon("ghost-outline:F165D")
		this.addIcon("gift:F0E44")
		this.addIcon("gift-off:F16EF")
		this.addIcon("gift-off-outline:F16F0")
		this.addIcon("gift-open:F16F1")
		this.addIcon("gift-open-outline:F16F2")
		this.addIcon("gift-outline:F02A1")
		this.addIcon("git:F02A2")
		this.addIcon("github:F02A4")
		this.addIcon("gitlab:F0BA0")
		this.addIcon("glass-cocktail:F0356")
		this.addIcon("glass-cocktail-off:F15E6")
		this.addIcon("glass-flute:F02A5")
		this.addIcon("glass-mug:F02A6")
		this.addIcon("glass-mug-off:F15E7")
		this.addIcon("glass-mug-variant:F1116")
		this.addIcon("glass-mug-variant-off:F15E8")
		this.addIcon("glass-pint-outline:F130D")
		this.addIcon("glass-stange:F02A7")
		this.addIcon("glass-tulip:F02A8")
		this.addIcon("glass-wine:F0876")
		this.addIcon("glasses:F02AA")
		this.addIcon("globe-light:F12D7")
		this.addIcon("globe-model:F08E9")
		this.addIcon("gmail:F02AB")
		this.addIcon("gnome:F02AC")
		this.addIcon("go-kart:F0D79")
		this.addIcon("go-kart-track:F0D7A")
		this.addIcon("gog:F0BA1")
		this.addIcon("gold:F124F")
		this.addIcon("golf:F0823")
		this.addIcon("golf-cart:F11A4")
		this.addIcon("golf-tee:F1083")
		this.addIcon("gondola:F0686")
		this.addIcon("goodreads:F0D7B")
		this.addIcon("google:F02AD")
		this.addIcon("google-ads:F0C87")
		this.addIcon("google-analytics:F07CC")
		this.addIcon("google-assistant:F07CD")
		this.addIcon("google-cardboard:F02AE")
		this.addIcon("google-chrome:F02AF")
		this.addIcon("google-circles:F02B0")
		this.addIcon("google-circles-communities:F02B1")
		this.addIcon("google-circles-extended:F02B2")
		this.addIcon("google-circles-group:F02B3")
		this.addIcon("google-classroom:F02C0")
		this.addIcon("google-cloud:F11F6")
		this.addIcon("google-controller:F02B4")
		this.addIcon("google-controller-off:F02B5")
		this.addIcon("google-downasaur:F1362")
		this.addIcon("google-drive:F02B6")
		this.addIcon("google-earth:F02B7")
		this.addIcon("google-fit:F096C")
		this.addIcon("google-glass:F02B8")
		this.addIcon("google-hangouts:F02C9")
		this.addIcon("google-home:F0824")
		this.addIcon("google-keep:F06DC")
		this.addIcon("google-lens:F09F6")
		this.addIcon("google-maps:F05F5")
		this.addIcon("google-my-business:F1048")
		this.addIcon("google-nearby:F02B9")
		this.addIcon("google-play:F02BC")
		this.addIcon("google-plus:F02BD")
		this.addIcon("google-podcast:F0EB9")
		this.addIcon("google-spreadsheet:F09F7")
		this.addIcon("google-street-view:F0C88")
		this.addIcon("google-translate:F02BF")
		this.addIcon("gradient-horizontal:F174A")
		this.addIcon("gradient-vertical:F06A0")
		this.addIcon("grain:F0D7C")
		this.addIcon("graph:F1049")
		this.addIcon("graph-outline:F104A")
		this.addIcon("graphql:F0877")
		this.addIcon("grass:F1510")
		this.addIcon("grave-stone:F0BA2")
		this.addIcon("grease-pencil:F0648")
		this.addIcon("greater-than:F096D")
		this.addIcon("greater-than-or-equal:F096E")
		this.addIcon("greenhouse:F002D")
		this.addIcon("grid:F02C1")
		this.addIcon("grid-large:F0758")
		this.addIcon("grid-off:F02C2")
		this.addIcon("grill:F0E45")
		this.addIcon("grill-outline:F118A")
		this.addIcon("group:F02C3")
		this.addIcon("guitar-acoustic:F0771")
		this.addIcon("guitar-electric:F02C4")
		this.addIcon("guitar-pick:F02C5")
		this.addIcon("guitar-pick-outline:F02C6")
		this.addIcon("guy-fawkes-mask:F0825")
		this.addIcon("hail:F0AC1")
		this.addIcon("hair-dryer:F10EF")
		this.addIcon("hair-dryer-outline:F10F0")
		this.addIcon("halloween:F0BA3")
		this.addIcon("hamburger:F0685")
		this.addIcon("hamburger-check:F1776")
		this.addIcon("hamburger-minus:F1777")
		this.addIcon("hamburger-off:F1778")
		this.addIcon("hamburger-plus:F1779")
		this.addIcon("hamburger-remove:F177A")
		this.addIcon("hammer:F08EA")
		this.addIcon("hammer-screwdriver:F1322")
		this.addIcon("hammer-wrench:F1323")
		this.addIcon("hand-back-left:F0E46")
		this.addIcon("hand-back-left-off:F1830")
		this.addIcon("hand-back-left-off-outline:F1832")
		this.addIcon("hand-back-left-outline:F182C")
		this.addIcon("hand-back-right:F0E47")
		this.addIcon("hand-back-right-off:F1831")
		this.addIcon("hand-back-right-off-outline:F1833")
		this.addIcon("hand-back-right-outline:F182D")
		this.addIcon("hand-front-left:F182B")
		this.addIcon("hand-front-left-outline:F182E")
		this.addIcon("hand-front-right:F0A4F")
		this.addIcon("hand-front-right-outline:F182F")
		this.addIcon("hand-heart:F10F1")
		this.addIcon("hand-heart-outline:F157E")
		this.addIcon("hand-okay:F0A50")
		this.addIcon("hand-peace:F0A51")
		this.addIcon("hand-peace-variant:F0A52")
		this.addIcon("hand-pointing-down:F0A53")
		this.addIcon("hand-pointing-left:F0A54")
		this.addIcon("hand-pointing-right:F02C7")
		this.addIcon("hand-pointing-up:F0A55")
		this.addIcon("hand-saw:F0E48")
		this.addIcon("hand-wash:F157F")
		this.addIcon("hand-wash-outline:F1580")
		this.addIcon("hand-water:F139F")
		this.addIcon("hand-wave:F1821")
		this.addIcon("hand-wave-outline:F1822")
		this.addIcon("handball:F0F53")
		this.addIcon("handcuffs:F113E")
		this.addIcon("hands-pray:F0579")
		this.addIcon("handshake:F1218")
		this.addIcon("handshake-outline:F15A1")
		this.addIcon("hanger:F02C8")
		this.addIcon("hard-hat:F096F")
		this.addIcon("harddisk:F02CA")
		this.addIcon("harddisk-plus:F104B")
		this.addIcon("harddisk-remove:F104C")
		this.addIcon("hat-fedora:F0BA4")
		this.addIcon("hazard-lights:F0C89")
		this.addIcon("hdr:F0D7D")
		this.addIcon("hdr-off:F0D7E")
		this.addIcon("head:F135E")
		this.addIcon("head-alert:F1338")
		this.addIcon("head-alert-outline:F1339")
		this.addIcon("head-check:F133A")
		this.addIcon("head-check-outline:F133B")
		this.addIcon("head-cog:F133C")
		this.addIcon("head-cog-outline:F133D")
		this.addIcon("head-dots-horizontal:F133E")
		this.addIcon("head-dots-horizontal-outline:F133F")
		this.addIcon("head-flash:F1340")
		this.addIcon("head-flash-outline:F1341")
		this.addIcon("head-heart:F1342")
		this.addIcon("head-heart-outline:F1343")
		this.addIcon("head-lightbulb:F1344")
		this.addIcon("head-lightbulb-outline:F1345")
		this.addIcon("head-minus:F1346")
		this.addIcon("head-minus-outline:F1347")
		this.addIcon("head-outline:F135F")
		this.addIcon("head-plus:F1348")
		this.addIcon("head-plus-outline:F1349")
		this.addIcon("head-question:F134A")
		this.addIcon("head-question-outline:F134B")
		this.addIcon("head-remove:F134C")
		this.addIcon("head-remove-outline:F134D")
		this.addIcon("head-snowflake:F134E")
		this.addIcon("head-snowflake-outline:F134F")
		this.addIcon("head-sync:F1350")
		this.addIcon("head-sync-outline:F1351")
		this.addIcon("headphones:F02CB")
		this.addIcon("headphones-bluetooth:F0970")
		this.addIcon("headphones-box:F02CC")
		this.addIcon("headphones-off:F07CE")
		this.addIcon("headphones-settings:F02CD")
		this.addIcon("headset:F02CE")
		this.addIcon("headset-dock:F02CF")
		this.addIcon("headset-off:F02D0")
		this.addIcon("heart:F02D1")
		this.addIcon("heart-box:F02D2")
		this.addIcon("heart-box-outline:F02D3")
		this.addIcon("heart-broken:F02D4")
		this.addIcon("heart-broken-outline:F0D14")
		this.addIcon("heart-circle:F0971")
		this.addIcon("heart-circle-outline:F0972")
		this.addIcon("heart-cog:F1663")
		this.addIcon("heart-cog-outline:F1664")
		this.addIcon("heart-flash:F0EF9")
		this.addIcon("heart-half:F06DF")
		this.addIcon("heart-half-full:F06DE")
		this.addIcon("heart-half-outline:F06E0")
		this.addIcon("heart-minus:F142F")
		this.addIcon("heart-minus-outline:F1432")
		this.addIcon("heart-multiple:F0A56")
		this.addIcon("heart-multiple-outline:F0A57")
		this.addIcon("heart-off:F0759")
		this.addIcon("heart-off-outline:F1434")
		this.addIcon("heart-outline:F02D5")
		this.addIcon("heart-plus:F142E")
		this.addIcon("heart-plus-outline:F1431")
		this.addIcon("heart-pulse:F05F6")
		this.addIcon("heart-remove:F1430")
		this.addIcon("heart-remove-outline:F1433")
		this.addIcon("heart-settings:F1665")
		this.addIcon("heart-settings-outline:F1666")
		this.addIcon("helicopter:F0AC2")
		this.addIcon("help:F02D6")
		this.addIcon("help-box:F078B")
		this.addIcon("help-circle:F02D7")
		this.addIcon("help-circle-outline:F0625")
		this.addIcon("help-network:F06F5")
		this.addIcon("help-network-outline:F0C8A")
		this.addIcon("help-rhombus:F0BA5")
		this.addIcon("help-rhombus-outline:F0BA6")
		this.addIcon("hexadecimal:F12A7")
		this.addIcon("hexagon:F02D8")
		this.addIcon("hexagon-multiple:F06E1")
		this.addIcon("hexagon-multiple-outline:F10F2")
		this.addIcon("hexagon-outline:F02D9")
		this.addIcon("hexagon-slice-1:F0AC3")
		this.addIcon("hexagon-slice-2:F0AC4")
		this.addIcon("hexagon-slice-3:F0AC5")
		this.addIcon("hexagon-slice-4:F0AC6")
		this.addIcon("hexagon-slice-5:F0AC7")
		this.addIcon("hexagon-slice-6:F0AC8")
		this.addIcon("hexagram:F0AC9")
		this.addIcon("hexagram-outline:F0ACA")
		this.addIcon("high-definition:F07CF")
		this.addIcon("high-definition-box:F0878")
		this.addIcon("highway:F05F7")
		this.addIcon("hiking:F0D7F")
		this.addIcon("history:F02DA")
		this.addIcon("hockey-puck:F0879")
		this.addIcon("hockey-sticks:F087A")
		this.addIcon("hololens:F02DB")
		this.addIcon("home:F02DC")
		this.addIcon("home-account:F0826")
		this.addIcon("home-alert:F087B")
		this.addIcon("home-alert-outline:F15D0")
		this.addIcon("home-analytics:F0EBA")
		this.addIcon("home-assistant:F07D0")
		this.addIcon("home-automation:F07D1")
		this.addIcon("home-circle:F07D2")
		this.addIcon("home-circle-outline:F104D")
		this.addIcon("home-city:F0D15")
		this.addIcon("home-city-outline:F0D16")
		this.addIcon("home-edit:F1159")
		this.addIcon("home-edit-outline:F115A")
		this.addIcon("home-export-outline:F0F9B")
		this.addIcon("home-flood:F0EFA")
		this.addIcon("home-floor-0:F0DD2")
		this.addIcon("home-floor-1:F0D80")
		this.addIcon("home-floor-2:F0D81")
		this.addIcon("home-floor-3:F0D82")
		this.addIcon("home-floor-a:F0D83")
		this.addIcon("home-floor-b:F0D84")
		this.addIcon("home-floor-g:F0D85")
		this.addIcon("home-floor-l:F0D86")
		this.addIcon("home-floor-negative-1:F0DD3")
		this.addIcon("home-group:F0DD4")
		this.addIcon("home-heart:F0827")
		this.addIcon("home-import-outline:F0F9C")
		this.addIcon("home-lightbulb:F1251")
		this.addIcon("home-lightbulb-outline:F1252")
		this.addIcon("home-lock:F08EB")
		this.addIcon("home-lock-open:F08EC")
		this.addIcon("home-map-marker:F05F8")
		this.addIcon("home-minus:F0974")
		this.addIcon("home-minus-outline:F13D5")
		this.addIcon("home-modern:F02DD")
		this.addIcon("home-outline:F06A1")
		this.addIcon("home-plus:F0975")
		this.addIcon("home-plus-outline:F13D6")
		this.addIcon("home-remove:F1247")
		this.addIcon("home-remove-outline:F13D7")
		this.addIcon("home-roof:F112B")
		this.addIcon("home-search:F13B0")
		this.addIcon("home-search-outline:F13B1")
		this.addIcon("home-switch:F1794")
		this.addIcon("home-switch-outline:F1795")
		this.addIcon("home-thermometer:F0F54")
		this.addIcon("home-thermometer-outline:F0F55")
		this.addIcon("home-variant:F02DE")
		this.addIcon("home-variant-outline:F0BA7")
		this.addIcon("hook:F06E2")
		this.addIcon("hook-off:F06E3")
		this.addIcon("hoop-house:F0E56")
		this.addIcon("hops:F02DF")
		this.addIcon("horizontal-rotate-clockwise:F10F3")
		this.addIcon("horizontal-rotate-counterclockwise:F10F4")
		this.addIcon("horse:F15BF")
		this.addIcon("horse-human:F15C0")
		this.addIcon("horse-variant:F15C1")
		this.addIcon("horseshoe:F0A58")
		this.addIcon("hospital:F0FF6")
		this.addIcon("hospital-box:F02E0")
		this.addIcon("hospital-box-outline:F0FF7")
		this.addIcon("hospital-building:F02E1")
		this.addIcon("hospital-marker:F02E2")
		this.addIcon("hot-tub:F0828")
		this.addIcon("hours-24:F1478")
		this.addIcon("hubspot:F0D17")
		this.addIcon("hulu:F0829")
		this.addIcon("human:F02E6")
		this.addIcon("human-baby-changing-table:F138B")
		this.addIcon("human-cane:F1581")
		this.addIcon("human-capacity-decrease:F159B")
		this.addIcon("human-capacity-increase:F159C")
		this.addIcon("human-child:F02E7")
		this.addIcon("human-edit:F14E8")
		this.addIcon("human-female:F0649")
		this.addIcon("human-female-boy:F0A59")
		this.addIcon("human-female-dance:F15C9")
		this.addIcon("human-female-female:F0A5A")
		this.addIcon("human-female-girl:F0A5B")
		this.addIcon("human-greeting:F17C4")
		this.addIcon("human-greeting-proximity:F159D")
		this.addIcon("human-greeting-variant:F064A")
		this.addIcon("human-handsdown:F064B")
		this.addIcon("human-handsup:F064C")
		this.addIcon("human-male:F064D")
		this.addIcon("human-male-board:F0890")
		this.addIcon("human-male-board-poll:F0846")
		this.addIcon("human-male-boy:F0A5C")
		this.addIcon("human-male-child:F138C")
		this.addIcon("human-male-female:F02E8")
		this.addIcon("human-male-female-child:F1823")
		this.addIcon("human-male-girl:F0A5D")
		this.addIcon("human-male-height:F0EFB")
		this.addIcon("human-male-height-variant:F0EFC")
		this.addIcon("human-male-male:F0A5E")
		this.addIcon("human-pregnant:F05CF")
		this.addIcon("human-queue:F1571")
		this.addIcon("human-scooter:F11E9")
		this.addIcon("human-wheelchair:F138D")
		this.addIcon("humble-bundle:F0744")
		this.addIcon("hvac:F1352")
		this.addIcon("hvac-off:F159E")
		this.addIcon("hydraulic-oil-level:F1324")
		this.addIcon("hydraulic-oil-temperature:F1325")
		this.addIcon("hydro-power:F12E5")
		this.addIcon("ice-cream:F082A")
		this.addIcon("ice-cream-off:F0E52")
		this.addIcon("ice-pop:F0EFD")
		this.addIcon("id-card:F0FC0")
		this.addIcon("identifier:F0EFE")
		this.addIcon("ideogram-cjk:F1331")
		this.addIcon("ideogram-cjk-variant:F1332")
		this.addIcon("image:F02E9")
		this.addIcon("image-album:F02EA")
		this.addIcon("image-area:F02EB")
		this.addIcon("image-area-close:F02EC")
		this.addIcon("image-auto-adjust:F0FC1")
		this.addIcon("image-broken:F02ED")
		this.addIcon("image-broken-variant:F02EE")
		this.addIcon("image-edit:F11E3")
		this.addIcon("image-edit-outline:F11E4")
		this.addIcon("image-filter-black-white:F02F0")
		this.addIcon("image-filter-center-focus:F02F1")
		this.addIcon("image-filter-center-focus-strong:F0EFF")
		this.addIcon("image-filter-center-focus-strong-outline:F0F00")
		this.addIcon("image-filter-center-focus-weak:F02F2")
		this.addIcon("image-filter-drama:F02F3")
		this.addIcon("image-filter-frames:F02F4")
		this.addIcon("image-filter-hdr:F02F5")
		this.addIcon("image-filter-none:F02F6")
		this.addIcon("image-filter-tilt-shift:F02F7")
		this.addIcon("image-filter-vintage:F02F8")
		this.addIcon("image-frame:F0E49")
		this.addIcon("image-marker:F177B")
		this.addIcon("image-marker-outline:F177C")
		this.addIcon("image-minus:F1419")
		this.addIcon("image-move:F09F8")
		this.addIcon("image-multiple:F02F9")
		this.addIcon("image-multiple-outline:F02EF")
		this.addIcon("image-off:F082B")
		this.addIcon("image-off-outline:F11D1")
		this.addIcon("image-outline:F0976")
		this.addIcon("image-plus:F087C")
		this.addIcon("image-remove:F1418")
		this.addIcon("image-search:F0977")
		this.addIcon("image-search-outline:F0978")
		this.addIcon("image-size-select-actual:F0C8D")
		this.addIcon("image-size-select-large:F0C8E")
		this.addIcon("image-size-select-small:F0C8F")
		this.addIcon("image-text:F160D")
		this.addIcon("import:F02FA")
		this.addIcon("inbox:F0687")
		this.addIcon("inbox-arrow-down:F02FB")
		this.addIcon("inbox-arrow-down-outline:F1270")
		this.addIcon("inbox-arrow-up:F03D1")
		this.addIcon("inbox-arrow-up-outline:F1271")
		this.addIcon("inbox-full:F1272")
		this.addIcon("inbox-full-outline:F1273")
		this.addIcon("inbox-multiple:F08B0")
		this.addIcon("inbox-multiple-outline:F0BA8")
		this.addIcon("inbox-outline:F1274")
		this.addIcon("inbox-remove:F159F")
		this.addIcon("inbox-remove-outline:F15A0")
		this.addIcon("incognito:F05F9")
		this.addIcon("incognito-circle:F1421")
		this.addIcon("incognito-circle-off:F1422")
		this.addIcon("incognito-off:F0075")
		this.addIcon("infinity:F06E4")
		this.addIcon("information:F02FC")
		this.addIcon("information-off:F178C")
		this.addIcon("information-off-outline:F178D")
		this.addIcon("information-outline:F02FD")
		this.addIcon("information-variant:F064E")
		this.addIcon("instagram:F02FE")
		this.addIcon("instrument-triangle:F104E")
		this.addIcon("invert-colors:F0301")
		this.addIcon("invert-colors-off:F0E4A")
		this.addIcon("iobroker:F12E8")
		this.addIcon("ip:F0A5F")
		this.addIcon("ip-network:F0A60")
		this.addIcon("ip-network-outline:F0C90")
		this.addIcon("ipod:F0C91")
		this.addIcon("iron:F1824")
		this.addIcon("iron-outline:F1825")
		this.addIcon("island:F104F")
		this.addIcon("iv-bag:F10B9")
		this.addIcon("jabber:F0DD5")
		this.addIcon("jeepney:F0302")
		this.addIcon("jellyfish:F0F01")
		this.addIcon("jellyfish-outline:F0F02")
		this.addIcon("jira:F0303")
		this.addIcon("jquery:F087D")
		this.addIcon("jsfiddle:F0304")
		this.addIcon("jump-rope:F12FF")
		this.addIcon("kabaddi:F0D87")
		this.addIcon("kangaroo:F1558")
		this.addIcon("karate:F082C")
		this.addIcon("kayaking:F08AF")
		this.addIcon("keg:F0305")
		this.addIcon("kettle:F05FA")
		this.addIcon("kettle-alert:F1317")
		this.addIcon("kettle-alert-outline:F1318")
		this.addIcon("kettle-off:F131B")
		this.addIcon("kettle-off-outline:F131C")
		this.addIcon("kettle-outline:F0F56")
		this.addIcon("kettle-pour-over:F173C")
		this.addIcon("kettle-steam:F1319")
		this.addIcon("kettle-steam-outline:F131A")
		this.addIcon("kettlebell:F1300")
		this.addIcon("key:F0306")
		this.addIcon("key-arrow-right:F1312")
		this.addIcon("key-chain:F1574")
		this.addIcon("key-chain-variant:F1575")
		this.addIcon("key-change:F0307")
		this.addIcon("key-link:F119F")
		this.addIcon("key-minus:F0308")
		this.addIcon("key-outline:F0DD6")
		this.addIcon("key-plus:F0309")
		this.addIcon("key-remove:F030A")
		this.addIcon("key-star:F119E")
		this.addIcon("key-variant:F030B")
		this.addIcon("key-wireless:F0FC2")
		this.addIcon("keyboard:F030C")
		this.addIcon("keyboard-backspace:F030D")
		this.addIcon("keyboard-caps:F030E")
		this.addIcon("keyboard-close:F030F")
		this.addIcon("keyboard-esc:F12B7")
		this.addIcon("keyboard-f1:F12AB")
		this.addIcon("keyboard-f10:F12B4")
		this.addIcon("keyboard-f11:F12B5")
		this.addIcon("keyboard-f12:F12B6")
		this.addIcon("keyboard-f2:F12AC")
		this.addIcon("keyboard-f3:F12AD")
		this.addIcon("keyboard-f4:F12AE")
		this.addIcon("keyboard-f5:F12AF")
		this.addIcon("keyboard-f6:F12B0")
		this.addIcon("keyboard-f7:F12B1")
		this.addIcon("keyboard-f8:F12B2")
		this.addIcon("keyboard-f9:F12B3")
		this.addIcon("keyboard-off:F0310")
		this.addIcon("keyboard-off-outline:F0E4B")
		this.addIcon("keyboard-outline:F097B")
		this.addIcon("keyboard-return:F0311")
		this.addIcon("keyboard-settings:F09F9")
		this.addIcon("keyboard-settings-outline:F09FA")
		this.addIcon("keyboard-space:F1050")
		this.addIcon("keyboard-tab:F0312")
		this.addIcon("keyboard-tab-reverse:F0325")
		this.addIcon("keyboard-variant:F0313")
		this.addIcon("khanda:F10FD")
		this.addIcon("kickstarter:F0745")
		this.addIcon("kitesurfing:F1744")
		this.addIcon("klingon:F135B")
		this.addIcon("knife:F09FB")
		this.addIcon("knife-military:F09FC")
		this.addIcon("koala:F173F")
		this.addIcon("kodi:F0314")
		this.addIcon("kubernetes:F10FE")
		this.addIcon("label:F0315")
		this.addIcon("label-multiple:F1375")
		this.addIcon("label-multiple-outline:F1376")
		this.addIcon("label-off:F0ACB")
		this.addIcon("label-off-outline:F0ACC")
		this.addIcon("label-outline:F0316")
		this.addIcon("label-percent:F12EA")
		this.addIcon("label-percent-outline:F12EB")
		this.addIcon("label-variant:F0ACD")
		this.addIcon("label-variant-outline:F0ACE")
		this.addIcon("ladder:F15A2")
		this.addIcon("ladybug:F082D")
		this.addIcon("lambda:F0627")
		this.addIcon("lamp:F06B5")
		this.addIcon("lamp-outline:F17D0")
		this.addIcon("lamps:F1576")
		this.addIcon("lamps-outline:F17D1")
		this.addIcon("lan:F0317")
		this.addIcon("lan-check:F12AA")
		this.addIcon("lan-connect:F0318")
		this.addIcon("lan-disconnect:F0319")
		this.addIcon("lan-pending:F031A")
		this.addIcon("language-c:F0671")
		this.addIcon("language-cpp:F0672")
		this.addIcon("language-csharp:F031B")
		this.addIcon("language-css3:F031C")
		this.addIcon("language-fortran:F121A")
		this.addIcon("language-go:F07D3")
		this.addIcon("language-haskell:F0C92")
		this.addIcon("language-html5:F031D")
		this.addIcon("language-java:F0B37")
		this.addIcon("language-javascript:F031E")
		this.addIcon("language-kotlin:F1219")
		this.addIcon("language-lua:F08B1")
		this.addIcon("language-markdown:F0354")
		this.addIcon("language-markdown-outline:F0F5B")
		this.addIcon("language-php:F031F")
		this.addIcon("language-python:F0320")
		this.addIcon("language-r:F07D4")
		this.addIcon("language-ruby:F0D2D")
		this.addIcon("language-ruby-on-rails:F0ACF")
		this.addIcon("language-rust:F1617")
		this.addIcon("language-swift:F06E5")
		this.addIcon("language-typescript:F06E6")
		this.addIcon("language-xaml:F0673")
		this.addIcon("laptop:F0322")
		this.addIcon("laptop-off:F06E7")
		this.addIcon("laravel:F0AD0")
		this.addIcon("laser-pointer:F1484")
		this.addIcon("lasso:F0F03")
		this.addIcon("lastpass:F0446")
		this.addIcon("latitude:F0F57")
		this.addIcon("launch:F0327")
		this.addIcon("lava-lamp:F07D5")
		this.addIcon("layers:F0328")
		this.addIcon("layers-minus:F0E4C")
		this.addIcon("layers-off:F0329")
		this.addIcon("layers-off-outline:F09FD")
		this.addIcon("layers-outline:F09FE")
		this.addIcon("layers-plus:F0E4D")
		this.addIcon("layers-remove:F0E4E")
		this.addIcon("layers-search:F1206")
		this.addIcon("layers-search-outline:F1207")
		this.addIcon("layers-triple:F0F58")
		this.addIcon("layers-triple-outline:F0F59")
		this.addIcon("lead-pencil:F064F")
		this.addIcon("leaf:F032A")
		this.addIcon("leaf-maple:F0C93")
		this.addIcon("leaf-maple-off:F12DA")
		this.addIcon("leaf-off:F12D9")
		this.addIcon("leak:F0DD7")
		this.addIcon("leak-off:F0DD8")
		this.addIcon("led-off:F032B")
		this.addIcon("led-on:F032C")
		this.addIcon("led-outline:F032D")
		this.addIcon("led-strip:F07D6")
		this.addIcon("led-strip-variant:F1051")
		this.addIcon("led-variant-off:F032E")
		this.addIcon("led-variant-on:F032F")
		this.addIcon("led-variant-outline:F0330")
		this.addIcon("leek:F117D")
		this.addIcon("less-than:F097C")
		this.addIcon("less-than-or-equal:F097D")
		this.addIcon("library:F0331")
		this.addIcon("library-shelves:F0BA9")
		this.addIcon("license:F0FC3")
		this.addIcon("lifebuoy:F087E")
		this.addIcon("light-recessed:F179B")
		this.addIcon("light-switch:F097E")
		this.addIcon("lightbulb:F0335")
		this.addIcon("lightbulb-auto:F1800")
		this.addIcon("lightbulb-auto-outline:F1801")
		this.addIcon("lightbulb-cfl:F1208")
		this.addIcon("lightbulb-cfl-off:F1209")
		this.addIcon("lightbulb-cfl-spiral:F1275")
		this.addIcon("lightbulb-cfl-spiral-off:F12C3")
		this.addIcon("lightbulb-fluorescent-tube:F1804")
		this.addIcon("lightbulb-fluorescent-tube-outline:F1805")
		this.addIcon("lightbulb-group:F1253")
		this.addIcon("lightbulb-group-off:F12CD")
		this.addIcon("lightbulb-group-off-outline:F12CE")
		this.addIcon("lightbulb-group-outline:F1254")
		this.addIcon("lightbulb-multiple:F1255")
		this.addIcon("lightbulb-multiple-off:F12CF")
		this.addIcon("lightbulb-multiple-off-outline:F12D0")
		this.addIcon("lightbulb-multiple-outline:F1256")
		this.addIcon("lightbulb-off:F0E4F")
		this.addIcon("lightbulb-off-outline:F0E50")
		this.addIcon("lightbulb-on:F06E8")
		this.addIcon("lightbulb-on-outline:F06E9")
		this.addIcon("lightbulb-outline:F0336")
		this.addIcon("lightbulb-spot:F17F4")
		this.addIcon("lightbulb-spot-off:F17F5")
		this.addIcon("lightbulb-variant:F1802")
		this.addIcon("lightbulb-variant-outline:F1803")
		this.addIcon("lighthouse:F09FF")
		this.addIcon("lighthouse-on:F0A00")
		this.addIcon("lightning-bolt:F140B")
		this.addIcon("lightning-bolt-circle:F0820")
		this.addIcon("lightning-bolt-outline:F140C")
		this.addIcon("line-scan:F0624")
		this.addIcon("lingerie:F1476")
		this.addIcon("link:F0337")
		this.addIcon("link-box:F0D1A")
		this.addIcon("link-box-outline:F0D1B")
		this.addIcon("link-box-variant:F0D1C")
		this.addIcon("link-box-variant-outline:F0D1D")
		this.addIcon("link-lock:F10BA")
		this.addIcon("link-off:F0338")
		this.addIcon("link-plus:F0C94")
		this.addIcon("link-variant:F0339")
		this.addIcon("link-variant-minus:F10FF")
		this.addIcon("link-variant-off:F033A")
		this.addIcon("link-variant-plus:F1100")
		this.addIcon("link-variant-remove:F1101")
		this.addIcon("linkedin:F033B")
		this.addIcon("linux:F033D")
		this.addIcon("linux-mint:F08ED")
		this.addIcon("lipstick:F13B5")
		this.addIcon("liquid-spot:F1826")
		this.addIcon("list-status:F15AB")
		this.addIcon("litecoin:F0A61")
		this.addIcon("loading:F0772")
		this.addIcon("location-enter:F0FC4")
		this.addIcon("location-exit:F0FC5")
		this.addIcon("lock:F033E")
		this.addIcon("lock-alert:F08EE")
		this.addIcon("lock-alert-outline:F15D1")
		this.addIcon("lock-check:F139A")
		this.addIcon("lock-check-outline:F16A8")
		this.addIcon("lock-clock:F097F")
		this.addIcon("lock-minus:F16A9")
		this.addIcon("lock-minus-outline:F16AA")
		this.addIcon("lock-off:F1671")
		this.addIcon("lock-off-outline:F1672")
		this.addIcon("lock-open:F033F")
		this.addIcon("lock-open-alert:F139B")
		this.addIcon("lock-open-alert-outline:F15D2")
		this.addIcon("lock-open-check:F139C")
		this.addIcon("lock-open-check-outline:F16AB")
		this.addIcon("lock-open-minus:F16AC")
		this.addIcon("lock-open-minus-outline:F16AD")
		this.addIcon("lock-open-outline:F0340")
		this.addIcon("lock-open-plus:F16AE")
		this.addIcon("lock-open-plus-outline:F16AF")
		this.addIcon("lock-open-remove:F16B0")
		this.addIcon("lock-open-remove-outline:F16B1")
		this.addIcon("lock-open-variant:F0FC6")
		this.addIcon("lock-open-variant-outline:F0FC7")
		this.addIcon("lock-outline:F0341")
		this.addIcon("lock-pattern:F06EA")
		this.addIcon("lock-plus:F05FB")
		this.addIcon("lock-plus-outline:F16B2")
		this.addIcon("lock-question:F08EF")
		this.addIcon("lock-remove:F16B3")
		this.addIcon("lock-remove-outline:F16B4")
		this.addIcon("lock-reset:F0773")
		this.addIcon("lock-smart:F08B2")
		this.addIcon("locker:F07D7")
		this.addIcon("locker-multiple:F07D8")
		this.addIcon("login:F0342")
		this.addIcon("login-variant:F05FC")
		this.addIcon("logout:F0343")
		this.addIcon("logout-variant:F05FD")
		this.addIcon("longitude:F0F5A")
		this.addIcon("looks:F0344")
		this.addIcon("lotion:F1582")
		this.addIcon("lotion-outline:F1583")
		this.addIcon("lotion-plus:F1584")
		this.addIcon("lotion-plus-outline:F1585")
		this.addIcon("loupe:F0345")
		this.addIcon("lumx:F0346")
		this.addIcon("lungs:F1084")
		this.addIcon("magazine-pistol:F0324")
		this.addIcon("magazine-rifle:F0323")
		this.addIcon("magnet:F0347")
		this.addIcon("magnet-on:F0348")
		this.addIcon("magnify:F0349")
		this.addIcon("magnify-close:F0980")
		this.addIcon("magnify-minus:F034A")
		this.addIcon("magnify-minus-cursor:F0A62")
		this.addIcon("magnify-minus-outline:F06EC")
		this.addIcon("magnify-plus:F034B")
		this.addIcon("magnify-plus-cursor:F0A63")
		this.addIcon("magnify-plus-outline:F06ED")
		this.addIcon("magnify-remove-cursor:F120C")
		this.addIcon("magnify-remove-outline:F120D")
		this.addIcon("magnify-scan:F1276")
		this.addIcon("mail:F0EBB")
		this.addIcon("mailbox:F06EE")
		this.addIcon("mailbox-open:F0D88")
		this.addIcon("mailbox-open-outline:F0D89")
		this.addIcon("mailbox-open-up:F0D8A")
		this.addIcon("mailbox-open-up-outline:F0D8B")
		this.addIcon("mailbox-outline:F0D8C")
		this.addIcon("mailbox-up:F0D8D")
		this.addIcon("mailbox-up-outline:F0D8E")
		this.addIcon("manjaro:F160A")
		this.addIcon("map:F034D")
		this.addIcon("map-check:F0EBC")
		this.addIcon("map-check-outline:F0EBD")
		this.addIcon("map-clock:F0D1E")
		this.addIcon("map-clock-outline:F0D1F")
		this.addIcon("map-legend:F0A01")
		this.addIcon("map-marker:F034E")
		this.addIcon("map-marker-alert:F0F05")
		this.addIcon("map-marker-alert-outline:F0F06")
		this.addIcon("map-marker-check:F0C95")
		this.addIcon("map-marker-check-outline:F12FB")
		this.addIcon("map-marker-circle:F034F")
		this.addIcon("map-marker-distance:F08F0")
		this.addIcon("map-marker-down:F1102")
		this.addIcon("map-marker-left:F12DB")
		this.addIcon("map-marker-left-outline:F12DD")
		this.addIcon("map-marker-minus:F0650")
		this.addIcon("map-marker-minus-outline:F12F9")
		this.addIcon("map-marker-multiple:F0350")
		this.addIcon("map-marker-multiple-outline:F1277")
		this.addIcon("map-marker-off:F0351")
		this.addIcon("map-marker-off-outline:F12FD")
		this.addIcon("map-marker-outline:F07D9")
		this.addIcon("map-marker-path:F0D20")
		this.addIcon("map-marker-plus:F0651")
		this.addIcon("map-marker-plus-outline:F12F8")
		this.addIcon("map-marker-question:F0F07")
		this.addIcon("map-marker-question-outline:F0F08")
		this.addIcon("map-marker-radius:F0352")
		this.addIcon("map-marker-radius-outline:F12FC")
		this.addIcon("map-marker-remove:F0F09")
		this.addIcon("map-marker-remove-outline:F12FA")
		this.addIcon("map-marker-remove-variant:F0F0A")
		this.addIcon("map-marker-right:F12DC")
		this.addIcon("map-marker-right-outline:F12DE")
		this.addIcon("map-marker-star:F1608")
		this.addIcon("map-marker-star-outline:F1609")
		this.addIcon("map-marker-up:F1103")
		this.addIcon("map-minus:F0981")
		this.addIcon("map-outline:F0982")
		this.addIcon("map-plus:F0983")
		this.addIcon("map-search:F0984")
		this.addIcon("map-search-outline:F0985")
		this.addIcon("mapbox:F0BAA")
		this.addIcon("margin:F0353")
		this.addIcon("marker:F0652")
		this.addIcon("marker-cancel:F0DD9")
		this.addIcon("marker-check:F0355")
		this.addIcon("mastodon:F0AD1")
		this.addIcon("material-design:F0986")
		this.addIcon("material-ui:F0357")
		this.addIcon("math-compass:F0358")
		this.addIcon("math-cos:F0C96")
		this.addIcon("math-integral:F0FC8")
		this.addIcon("math-integral-box:F0FC9")
		this.addIcon("math-log:F1085")
		this.addIcon("math-norm:F0FCA")
		this.addIcon("math-norm-box:F0FCB")
		this.addIcon("math-sin:F0C97")
		this.addIcon("math-tan:F0C98")
		this.addIcon("matrix:F0628")
		this.addIcon("medal:F0987")
		this.addIcon("medal-outline:F1326")
		this.addIcon("medical-bag:F06EF")
		this.addIcon("meditation:F117B")
		this.addIcon("memory:F035B")
		this.addIcon("menorah:F17D4")
		this.addIcon("menorah-fire:F17D5")
		this.addIcon("menu:F035C")
		this.addIcon("menu-down:F035D")
		this.addIcon("menu-down-outline:F06B6")
		this.addIcon("menu-left:F035E")
		this.addIcon("menu-left-outline:F0A02")
		this.addIcon("menu-open:F0BAB")
		this.addIcon("menu-right:F035F")
		this.addIcon("menu-right-outline:F0A03")
		this.addIcon("menu-swap:F0A64")
		this.addIcon("menu-swap-outline:F0A65")
		this.addIcon("menu-up:F0360")
		this.addIcon("menu-up-outline:F06B7")
		this.addIcon("merge:F0F5C")
		this.addIcon("message:F0361")
		this.addIcon("message-alert:F0362")
		this.addIcon("message-alert-outline:F0A04")
		this.addIcon("message-arrow-left:F12F2")
		this.addIcon("message-arrow-left-outline:F12F3")
		this.addIcon("message-arrow-right:F12F4")
		this.addIcon("message-arrow-right-outline:F12F5")
		this.addIcon("message-bookmark:F15AC")
		this.addIcon("message-bookmark-outline:F15AD")
		this.addIcon("message-bulleted:F06A2")
		this.addIcon("message-bulleted-off:F06A3")
		this.addIcon("message-cog:F06F1")
		this.addIcon("message-cog-outline:F1172")
		this.addIcon("message-draw:F0363")
		this.addIcon("message-flash:F15A9")
		this.addIcon("message-flash-outline:F15AA")
		this.addIcon("message-image:F0364")
		this.addIcon("message-image-outline:F116C")
		this.addIcon("message-lock:F0FCC")
		this.addIcon("message-lock-outline:F116D")
		this.addIcon("message-minus:F116E")
		this.addIcon("message-minus-outline:F116F")
		this.addIcon("message-off:F164D")
		this.addIcon("message-off-outline:F164E")
		this.addIcon("message-outline:F0365")
		this.addIcon("message-plus:F0653")
		this.addIcon("message-plus-outline:F10BB")
		this.addIcon("message-processing:F0366")
		this.addIcon("message-processing-outline:F1170")
		this.addIcon("message-question:F173A")
		this.addIcon("message-question-outline:F173B")
		this.addIcon("message-reply:F0367")
		this.addIcon("message-reply-outline:F173D")
		this.addIcon("message-reply-text:F0368")
		this.addIcon("message-reply-text-outline:F173E")
		this.addIcon("message-settings:F06F0")
		this.addIcon("message-settings-outline:F1171")
		this.addIcon("message-star:F069A")
		this.addIcon("message-star-outline:F1250")
		this.addIcon("message-text:F0369")
		this.addIcon("message-text-clock:F1173")
		this.addIcon("message-text-clock-outline:F1174")
		this.addIcon("message-text-lock:F0FCD")
		this.addIcon("message-text-lock-outline:F1175")
		this.addIcon("message-text-outline:F036A")
		this.addIcon("message-video:F036B")
		this.addIcon("meteor:F0629")
		this.addIcon("metronome:F07DA")
		this.addIcon("metronome-tick:F07DB")
		this.addIcon("micro-sd:F07DC")
		this.addIcon("microphone:F036C")
		this.addIcon("microphone-minus:F08B3")
		this.addIcon("microphone-off:F036D")
		this.addIcon("microphone-outline:F036E")
		this.addIcon("microphone-plus:F08B4")
		this.addIcon("microphone-settings:F036F")
		this.addIcon("microphone-variant:F0370")
		this.addIcon("microphone-variant-off:F0371")
		this.addIcon("microscope:F0654")
		this.addIcon("microsoft:F0372")
		this.addIcon("microsoft-access:F138E")
		this.addIcon("microsoft-azure:F0805")
		this.addIcon("microsoft-azure-devops:F0FD5")
		this.addIcon("microsoft-bing:F00A4")
		this.addIcon("microsoft-dynamics-365:F0988")
		this.addIcon("microsoft-edge:F01E9")
		this.addIcon("microsoft-excel:F138F")
		this.addIcon("microsoft-internet-explorer:F0300")
		this.addIcon("microsoft-office:F03C6")
		this.addIcon("microsoft-onedrive:F03CA")
		this.addIcon("microsoft-onenote:F0747")
		this.addIcon("microsoft-outlook:F0D22")
		this.addIcon("microsoft-powerpoint:F1390")
		this.addIcon("microsoft-sharepoint:F1391")
		this.addIcon("microsoft-teams:F02BB")
		this.addIcon("microsoft-visual-studio:F0610")
		this.addIcon("microsoft-visual-studio-code:F0A1E")
		this.addIcon("microsoft-windows:F05B3")
		this.addIcon("microsoft-windows-classic:F0A21")
		this.addIcon("microsoft-word:F1392")
		this.addIcon("microsoft-xbox:F05B9")
		this.addIcon("microsoft-xbox-controller:F05BA")
		this.addIcon("microsoft-xbox-controller-battery-alert:F074B")
		this.addIcon("microsoft-xbox-controller-battery-charging:F0A22")
		this.addIcon("microsoft-xbox-controller-battery-empty:F074C")
		this.addIcon("microsoft-xbox-controller-battery-full:F074D")
		this.addIcon("microsoft-xbox-controller-battery-low:F074E")
		this.addIcon("microsoft-xbox-controller-battery-medium:F074F")
		this.addIcon("microsoft-xbox-controller-battery-unknown:F0750")
		this.addIcon("microsoft-xbox-controller-menu:F0E6F")
		this.addIcon("microsoft-xbox-controller-off:F05BB")
		this.addIcon("microsoft-xbox-controller-view:F0E70")
		this.addIcon("microwave:F0C99")
		this.addIcon("microwave-off:F1423")
		this.addIcon("middleware:F0F5D")
		this.addIcon("middleware-outline:F0F5E")
		this.addIcon("midi:F08F1")
		this.addIcon("midi-port:F08F2")
		this.addIcon("mine:F0DDA")
		this.addIcon("minecraft:F0373")
		this.addIcon("mini-sd:F0A05")
		this.addIcon("minidisc:F0A06")
		this.addIcon("minus:F0374")
		this.addIcon("minus-box:F0375")
		this.addIcon("minus-box-multiple:F1141")
		this.addIcon("minus-box-multiple-outline:F1142")
		this.addIcon("minus-box-outline:F06F2")
		this.addIcon("minus-circle:F0376")
		this.addIcon("minus-circle-multiple:F035A")
		this.addIcon("minus-circle-multiple-outline:F0AD3")
		this.addIcon("minus-circle-off:F1459")
		this.addIcon("minus-circle-off-outline:F145A")
		this.addIcon("minus-circle-outline:F0377")
		this.addIcon("minus-network:F0378")
		this.addIcon("minus-network-outline:F0C9A")
		this.addIcon("minus-thick:F1639")
		this.addIcon("mirror:F11FD")
		this.addIcon("mirror-rectangle:F179F")
		this.addIcon("mirror-variant:F17A0")
		this.addIcon("mixed-martial-arts:F0D8F")
		this.addIcon("mixed-reality:F087F")
		this.addIcon("molecule:F0BAC")
		this.addIcon("molecule-co:F12FE")
		this.addIcon("molecule-co2:F07E4")
		this.addIcon("monitor:F0379")
		this.addIcon("monitor-cellphone:F0989")
		this.addIcon("monitor-cellphone-star:F098A")
		this.addIcon("monitor-dashboard:F0A07")
		this.addIcon("monitor-edit:F12C6")
		this.addIcon("monitor-eye:F13B4")
		this.addIcon("monitor-lock:F0DDB")
		this.addIcon("monitor-multiple:F037A")
		this.addIcon("monitor-off:F0D90")
		this.addIcon("monitor-screenshot:F0E51")
		this.addIcon("monitor-share:F1483")
		this.addIcon("monitor-shimmer:F1104")
		this.addIcon("monitor-speaker:F0F5F")
		this.addIcon("monitor-speaker-off:F0F60")
		this.addIcon("monitor-star:F0DDC")
		this.addIcon("moon-first-quarter:F0F61")
		this.addIcon("moon-full:F0F62")
		this.addIcon("moon-last-quarter:F0F63")
		this.addIcon("moon-new:F0F64")
		this.addIcon("moon-waning-crescent:F0F65")
		this.addIcon("moon-waning-gibbous:F0F66")
		this.addIcon("moon-waxing-crescent:F0F67")
		this.addIcon("moon-waxing-gibbous:F0F68")
		this.addIcon("moped:F1086")
		this.addIcon("moped-electric:F15B7")
		this.addIcon("moped-electric-outline:F15B8")
		this.addIcon("moped-outline:F15B9")
		this.addIcon("more:F037B")
		this.addIcon("mortar-pestle:F1748")
		this.addIcon("mortar-pestle-plus:F03F1")
		this.addIcon("mosque:F1827")
		this.addIcon("mother-heart:F1314")
		this.addIcon("mother-nurse:F0D21")
		this.addIcon("motion:F15B2")
		this.addIcon("motion-outline:F15B3")
		this.addIcon("motion-pause:F1590")
		this.addIcon("motion-pause-outline:F1592")
		this.addIcon("motion-play:F158F")
		this.addIcon("motion-play-outline:F1591")
		this.addIcon("motion-sensor:F0D91")
		this.addIcon("motion-sensor-off:F1435")
		this.addIcon("motorbike:F037C")
		this.addIcon("motorbike-electric:F15BA")
		this.addIcon("mouse:F037D")
		this.addIcon("mouse-bluetooth:F098B")
		this.addIcon("mouse-move-down:F1550")
		this.addIcon("mouse-move-up:F1551")
		this.addIcon("mouse-move-vertical:F1552")
		this.addIcon("mouse-off:F037E")
		this.addIcon("mouse-variant:F037F")
		this.addIcon("mouse-variant-off:F0380")
		this.addIcon("move-resize:F0655")
		this.addIcon("move-resize-variant:F0656")
		this.addIcon("movie:F0381")
		this.addIcon("movie-check:F16F3")
		this.addIcon("movie-check-outline:F16F4")
		this.addIcon("movie-cog:F16F5")
		this.addIcon("movie-cog-outline:F16F6")
		this.addIcon("movie-edit:F1122")
		this.addIcon("movie-edit-outline:F1123")
		this.addIcon("movie-filter:F1124")
		this.addIcon("movie-filter-outline:F1125")
		this.addIcon("movie-minus:F16F7")
		this.addIcon("movie-minus-outline:F16F8")
		this.addIcon("movie-off:F16F9")
		this.addIcon("movie-off-outline:F16FA")
		this.addIcon("movie-open:F0FCE")
		this.addIcon("movie-open-check:F16FB")
		this.addIcon("movie-open-check-outline:F16FC")
		this.addIcon("movie-open-cog:F16FD")
		this.addIcon("movie-open-cog-outline:F16FE")
		this.addIcon("movie-open-edit:F16FF")
		this.addIcon("movie-open-edit-outline:F1700")
		this.addIcon("movie-open-minus:F1701")
		this.addIcon("movie-open-minus-outline:F1702")
		this.addIcon("movie-open-off:F1703")
		this.addIcon("movie-open-off-outline:F1704")
		this.addIcon("movie-open-outline:F0FCF")
		this.addIcon("movie-open-play:F1705")
		this.addIcon("movie-open-play-outline:F1706")
		this.addIcon("movie-open-plus:F1707")
		this.addIcon("movie-open-plus-outline:F1708")
		this.addIcon("movie-open-remove:F1709")
		this.addIcon("movie-open-remove-outline:F170A")
		this.addIcon("movie-open-settings:F170B")
		this.addIcon("movie-open-settings-outline:F170C")
		this.addIcon("movie-open-star:F170D")
		this.addIcon("movie-open-star-outline:F170E")
		this.addIcon("movie-outline:F0DDD")
		this.addIcon("movie-play:F170F")
		this.addIcon("movie-play-outline:F1710")
		this.addIcon("movie-plus:F1711")
		this.addIcon("movie-plus-outline:F1712")
		this.addIcon("movie-remove:F1713")
		this.addIcon("movie-remove-outline:F1714")
		this.addIcon("movie-roll:F07DE")
		this.addIcon("movie-search:F11D2")
		this.addIcon("movie-search-outline:F11D3")
		this.addIcon("movie-settings:F1715")
		this.addIcon("movie-settings-outline:F1716")
		this.addIcon("movie-star:F1717")
		this.addIcon("movie-star-outline:F1718")
		this.addIcon("mower:F166F")
		this.addIcon("mower-bag:F1670")
		this.addIcon("muffin:F098C")
		this.addIcon("multiplication:F0382")
		this.addIcon("multiplication-box:F0383")
		this.addIcon("mushroom:F07DF")
		this.addIcon("mushroom-off:F13FA")
		this.addIcon("mushroom-off-outline:F13FB")
		this.addIcon("mushroom-outline:F07E0")
		this.addIcon("music:F075A")
		this.addIcon("music-accidental-double-flat:F0F69")
		this.addIcon("music-accidental-double-sharp:F0F6A")
		this.addIcon("music-accidental-flat:F0F6B")
		this.addIcon("music-accidental-natural:F0F6C")
		this.addIcon("music-accidental-sharp:F0F6D")
		this.addIcon("music-box:F0384")
		this.addIcon("music-box-multiple:F0333")
		this.addIcon("music-box-multiple-outline:F0F04")
		this.addIcon("music-box-outline:F0385")
		this.addIcon("music-circle:F0386")
		this.addIcon("music-circle-outline:F0AD4")
		this.addIcon("music-clef-alto:F0F6E")
		this.addIcon("music-clef-bass:F0F6F")
		this.addIcon("music-clef-treble:F0F70")
		this.addIcon("music-note:F0387")
		this.addIcon("music-note-bluetooth:F05FE")
		this.addIcon("music-note-bluetooth-off:F05FF")
		this.addIcon("music-note-eighth:F0388")
		this.addIcon("music-note-eighth-dotted:F0F71")
		this.addIcon("music-note-half:F0389")
		this.addIcon("music-note-half-dotted:F0F72")
		this.addIcon("music-note-off:F038A")
		this.addIcon("music-note-off-outline:F0F73")
		this.addIcon("music-note-outline:F0F74")
		this.addIcon("music-note-plus:F0DDE")
		this.addIcon("music-note-quarter:F038B")
		this.addIcon("music-note-quarter-dotted:F0F75")
		this.addIcon("music-note-sixteenth:F038C")
		this.addIcon("music-note-sixteenth-dotted:F0F76")
		this.addIcon("music-note-whole:F038D")
		this.addIcon("music-note-whole-dotted:F0F77")
		this.addIcon("music-off:F075B")
		this.addIcon("music-rest-eighth:F0F78")
		this.addIcon("music-rest-half:F0F79")
		this.addIcon("music-rest-quarter:F0F7A")
		this.addIcon("music-rest-sixteenth:F0F7B")
		this.addIcon("music-rest-whole:F0F7C")
		this.addIcon("mustache:F15DE")
		this.addIcon("nail:F0DDF")
		this.addIcon("nas:F08F3")
		this.addIcon("nativescript:F0880")
		this.addIcon("nature:F038E")
		this.addIcon("nature-people:F038F")
		this.addIcon("navigation:F0390")
		this.addIcon("navigation-outline:F1607")
		this.addIcon("near-me:F05CD")
		this.addIcon("necklace:F0F0B")
		this.addIcon("needle:F0391")
		this.addIcon("netflix:F0746")
		this.addIcon("network:F06F3")
		this.addIcon("network-off:F0C9B")
		this.addIcon("network-off-outline:F0C9C")
		this.addIcon("network-outline:F0C9D")
		this.addIcon("network-strength-1:F08F4")
		this.addIcon("network-strength-1-alert:F08F5")
		this.addIcon("network-strength-2:F08F6")
		this.addIcon("network-strength-2-alert:F08F7")
		this.addIcon("network-strength-3:F08F8")
		this.addIcon("network-strength-3-alert:F08F9")
		this.addIcon("network-strength-4:F08FA")
		this.addIcon("network-strength-4-alert:F08FB")
		this.addIcon("network-strength-off:F08FC")
		this.addIcon("network-strength-off-outline:F08FD")
		this.addIcon("network-strength-outline:F08FE")
		this.addIcon("new-box:F0394")
		this.addIcon("newspaper:F0395")
		this.addIcon("newspaper-minus:F0F0C")
		this.addIcon("newspaper-plus:F0F0D")
		this.addIcon("newspaper-variant:F1001")
		this.addIcon("newspaper-variant-multiple:F1002")
		this.addIcon("newspaper-variant-multiple-outline:F1003")
		this.addIcon("newspaper-variant-outline:F1004")
		this.addIcon("nfc:F0396")
		this.addIcon("nfc-search-variant:F0E53")
		this.addIcon("nfc-tap:F0397")
		this.addIcon("nfc-variant:F0398")
		this.addIcon("nfc-variant-off:F0E54")
		this.addIcon("ninja:F0774")
		this.addIcon("nintendo-game-boy:F1393")
		this.addIcon("nintendo-switch:F07E1")
		this.addIcon("nintendo-wii:F05AB")
		this.addIcon("nintendo-wiiu:F072D")
		this.addIcon("nix:F1105")
		this.addIcon("nodejs:F0399")
		this.addIcon("noodles:F117E")
		this.addIcon("not-equal:F098D")
		this.addIcon("not-equal-variant:F098E")
		this.addIcon("note:F039A")
		this.addIcon("note-alert:F177D")
		this.addIcon("note-alert-outline:F177E")
		this.addIcon("note-check:F177F")
		this.addIcon("note-check-outline:F1780")
		this.addIcon("note-edit:F1781")
		this.addIcon("note-edit-outline:F1782")
		this.addIcon("note-minus:F164F")
		this.addIcon("note-minus-outline:F1650")
		this.addIcon("note-multiple:F06B8")
		this.addIcon("note-multiple-outline:F06B9")
		this.addIcon("note-off:F1783")
		this.addIcon("note-off-outline:F1784")
		this.addIcon("note-outline:F039B")
		this.addIcon("note-plus:F039C")
		this.addIcon("note-plus-outline:F039D")
		this.addIcon("note-remove:F1651")
		this.addIcon("note-remove-outline:F1652")
		this.addIcon("note-search:F1653")
		this.addIcon("note-search-outline:F1654")
		this.addIcon("note-text:F039E")
		this.addIcon("note-text-outline:F11D7")
		this.addIcon("notebook:F082E")
		this.addIcon("notebook-check:F14F5")
		this.addIcon("notebook-check-outline:F14F6")
		this.addIcon("notebook-edit:F14E7")
		this.addIcon("notebook-edit-outline:F14E9")
		this.addIcon("notebook-minus:F1610")
		this.addIcon("notebook-minus-outline:F1611")
		this.addIcon("notebook-multiple:F0E55")
		this.addIcon("notebook-outline:F0EBF")
		this.addIcon("notebook-plus:F1612")
		this.addIcon("notebook-plus-outline:F1613")
		this.addIcon("notebook-remove:F1614")
		this.addIcon("notebook-remove-outline:F1615")
		this.addIcon("notification-clear-all:F039F")
		this.addIcon("npm:F06F7")
		this.addIcon("nuke:F06A4")
		this.addIcon("null:F07E2")
		this.addIcon("numeric:F03A0")
		this.addIcon("numeric-0:F0B39")
		this.addIcon("numeric-0-box:F03A1")
		this.addIcon("numeric-0-box-multiple:F0F0E")
		this.addIcon("numeric-0-box-multiple-outline:F03A2")
		this.addIcon("numeric-0-box-outline:F03A3")
		this.addIcon("numeric-0-circle:F0C9E")
		this.addIcon("numeric-0-circle-outline:F0C9F")
		this.addIcon("numeric-1:F0B3A")
		this.addIcon("numeric-1-box:F03A4")
		this.addIcon("numeric-1-box-multiple:F0F0F")
		this.addIcon("numeric-1-box-multiple-outline:F03A5")
		this.addIcon("numeric-1-box-outline:F03A6")
		this.addIcon("numeric-1-circle:F0CA0")
		this.addIcon("numeric-1-circle-outline:F0CA1")
		this.addIcon("numeric-10:F0FE9")
		this.addIcon("numeric-10-box:F0F7D")
		this.addIcon("numeric-10-box-multiple:F0FEA")
		this.addIcon("numeric-10-box-multiple-outline:F0FEB")
		this.addIcon("numeric-10-box-outline:F0F7E")
		this.addIcon("numeric-10-circle:F0FEC")
		this.addIcon("numeric-10-circle-outline:F0FED")
		this.addIcon("numeric-2:F0B3B")
		this.addIcon("numeric-2-box:F03A7")
		this.addIcon("numeric-2-box-multiple:F0F10")
		this.addIcon("numeric-2-box-multiple-outline:F03A8")
		this.addIcon("numeric-2-box-outline:F03A9")
		this.addIcon("numeric-2-circle:F0CA2")
		this.addIcon("numeric-2-circle-outline:F0CA3")
		this.addIcon("numeric-3:F0B3C")
		this.addIcon("numeric-3-box:F03AA")
		this.addIcon("numeric-3-box-multiple:F0F11")
		this.addIcon("numeric-3-box-multiple-outline:F03AB")
		this.addIcon("numeric-3-box-outline:F03AC")
		this.addIcon("numeric-3-circle:F0CA4")
		this.addIcon("numeric-3-circle-outline:F0CA5")
		this.addIcon("numeric-4:F0B3D")
		this.addIcon("numeric-4-box:F03AD")
		this.addIcon("numeric-4-box-multiple:F0F12")
		this.addIcon("numeric-4-box-multiple-outline:F03B2")
		this.addIcon("numeric-4-box-outline:F03AE")
		this.addIcon("numeric-4-circle:F0CA6")
		this.addIcon("numeric-4-circle-outline:F0CA7")
		this.addIcon("numeric-5:F0B3E")
		this.addIcon("numeric-5-box:F03B1")
		this.addIcon("numeric-5-box-multiple:F0F13")
		this.addIcon("numeric-5-box-multiple-outline:F03AF")
		this.addIcon("numeric-5-box-outline:F03B0")
		this.addIcon("numeric-5-circle:F0CA8")
		this.addIcon("numeric-5-circle-outline:F0CA9")
		this.addIcon("numeric-6:F0B3F")
		this.addIcon("numeric-6-box:F03B3")
		this.addIcon("numeric-6-box-multiple:F0F14")
		this.addIcon("numeric-6-box-multiple-outline:F03B4")
		this.addIcon("numeric-6-box-outline:F03B5")
		this.addIcon("numeric-6-circle:F0CAA")
		this.addIcon("numeric-6-circle-outline:F0CAB")
		this.addIcon("numeric-7:F0B40")
		this.addIcon("numeric-7-box:F03B6")
		this.addIcon("numeric-7-box-multiple:F0F15")
		this.addIcon("numeric-7-box-multiple-outline:F03B7")
		this.addIcon("numeric-7-box-outline:F03B8")
		this.addIcon("numeric-7-circle:F0CAC")
		this.addIcon("numeric-7-circle-outline:F0CAD")
		this.addIcon("numeric-8:F0B41")
		this.addIcon("numeric-8-box:F03B9")
		this.addIcon("numeric-8-box-multiple:F0F16")
		this.addIcon("numeric-8-box-multiple-outline:F03BA")
		this.addIcon("numeric-8-box-outline:F03BB")
		this.addIcon("numeric-8-circle:F0CAE")
		this.addIcon("numeric-8-circle-outline:F0CAF")
		this.addIcon("numeric-9:F0B42")
		this.addIcon("numeric-9-box:F03BC")
		this.addIcon("numeric-9-box-multiple:F0F17")
		this.addIcon("numeric-9-box-multiple-outline:F03BD")
		this.addIcon("numeric-9-box-outline:F03BE")
		this.addIcon("numeric-9-circle:F0CB0")
		this.addIcon("numeric-9-circle-outline:F0CB1")
		this.addIcon("numeric-9-plus:F0FEE")
		this.addIcon("numeric-9-plus-box:F03BF")
		this.addIcon("numeric-9-plus-box-multiple:F0F18")
		this.addIcon("numeric-9-plus-box-multiple-outline:F03C0")
		this.addIcon("numeric-9-plus-box-outline:F03C1")
		this.addIcon("numeric-9-plus-circle:F0CB2")
		this.addIcon("numeric-9-plus-circle-outline:F0CB3")
		this.addIcon("numeric-negative-1:F1052")
		this.addIcon("numeric-positive-1:F15CB")
		this.addIcon("nut:F06F8")
		this.addIcon("nutrition:F03C2")
		this.addIcon("nuxt:F1106")
		this.addIcon("oar:F067C")
		this.addIcon("ocarina:F0DE0")
		this.addIcon("oci:F12E9")
		this.addIcon("ocr:F113A")
		this.addIcon("octagon:F03C3")
		this.addIcon("octagon-outline:F03C4")
		this.addIcon("octagram:F06F9")
		this.addIcon("octagram-outline:F0775")
		this.addIcon("odnoklassniki:F03C5")
		this.addIcon("offer:F121B")
		this.addIcon("office-building:F0991")
		this.addIcon("office-building-marker:F1520")
		this.addIcon("office-building-marker-outline:F1521")
		this.addIcon("office-building-outline:F151F")
		this.addIcon("oil:F03C7")
		this.addIcon("oil-lamp:F0F19")
		this.addIcon("oil-level:F1053")
		this.addIcon("oil-temperature:F0FF8")
		this.addIcon("om:F0973")
		this.addIcon("omega:F03C9")
		this.addIcon("one-up:F0BAD")
		this.addIcon("onepassword:F0881")
		this.addIcon("opacity:F05CC")
		this.addIcon("open-in-app:F03CB")
		this.addIcon("open-in-new:F03CC")
		this.addIcon("open-source-initiative:F0BAE")
		this.addIcon("openid:F03CD")
		this.addIcon("opera:F03CE")
		this.addIcon("orbit:F0018")
		this.addIcon("orbit-variant:F15DB")
		this.addIcon("order-alphabetical-ascending:F020D")
		this.addIcon("order-alphabetical-descending:F0D07")
		this.addIcon("order-bool-ascending:F02BE")
		this.addIcon("order-bool-ascending-variant:F098F")
		this.addIcon("order-bool-descending:F1384")
		this.addIcon("order-bool-descending-variant:F0990")
		this.addIcon("order-numeric-ascending:F0545")
		this.addIcon("order-numeric-descending:F0546")
		this.addIcon("origin:F0B43")
		this.addIcon("ornament:F03CF")
		this.addIcon("ornament-variant:F03D0")
		this.addIcon("outdoor-lamp:F1054")
		this.addIcon("overscan:F1005")
		this.addIcon("owl:F03D2")
		this.addIcon("pac-man:F0BAF")
		this.addIcon("package:F03D3")
		this.addIcon("package-down:F03D4")
		this.addIcon("package-up:F03D5")
		this.addIcon("package-variant:F03D6")
		this.addIcon("package-variant-closed:F03D7")
		this.addIcon("page-first:F0600")
		this.addIcon("page-last:F0601")
		this.addIcon("page-layout-body:F06FA")
		this.addIcon("page-layout-footer:F06FB")
		this.addIcon("page-layout-header:F06FC")
		this.addIcon("page-layout-header-footer:F0F7F")
		this.addIcon("page-layout-sidebar-left:F06FD")
		this.addIcon("page-layout-sidebar-right:F06FE")
		this.addIcon("page-next:F0BB0")
		this.addIcon("page-next-outline:F0BB1")
		this.addIcon("page-previous:F0BB2")
		this.addIcon("page-previous-outline:F0BB3")
		this.addIcon("pail:F1417")
		this.addIcon("pail-minus:F1437")
		this.addIcon("pail-minus-outline:F143C")
		this.addIcon("pail-off:F1439")
		this.addIcon("pail-off-outline:F143E")
		this.addIcon("pail-outline:F143A")
		this.addIcon("pail-plus:F1436")
		this.addIcon("pail-plus-outline:F143B")
		this.addIcon("pail-remove:F1438")
		this.addIcon("pail-remove-outline:F143D")
		this.addIcon("palette:F03D8")
		this.addIcon("palette-advanced:F03D9")
		this.addIcon("palette-outline:F0E0C")
		this.addIcon("palette-swatch:F08B5")
		this.addIcon("palette-swatch-outline:F135C")
		this.addIcon("palm-tree:F1055")
		this.addIcon("pan:F0BB4")
		this.addIcon("pan-bottom-left:F0BB5")
		this.addIcon("pan-bottom-right:F0BB6")
		this.addIcon("pan-down:F0BB7")
		this.addIcon("pan-horizontal:F0BB8")
		this.addIcon("pan-left:F0BB9")
		this.addIcon("pan-right:F0BBA")
		this.addIcon("pan-top-left:F0BBB")
		this.addIcon("pan-top-right:F0BBC")
		this.addIcon("pan-up:F0BBD")
		this.addIcon("pan-vertical:F0BBE")
		this.addIcon("panda:F03DA")
		this.addIcon("pandora:F03DB")
		this.addIcon("panorama:F03DC")
		this.addIcon("panorama-fisheye:F03DD")
		this.addIcon("panorama-horizontal:F03DE")
		this.addIcon("panorama-vertical:F03DF")
		this.addIcon("panorama-wide-angle:F03E0")
		this.addIcon("paper-cut-vertical:F03E1")
		this.addIcon("paper-roll:F1157")
		this.addIcon("paper-roll-outline:F1158")
		this.addIcon("paperclip:F03E2")
		this.addIcon("parachute:F0CB4")
		this.addIcon("parachute-outline:F0CB5")
		this.addIcon("paragliding:F1745")
		this.addIcon("parking:F03E3")
		this.addIcon("party-popper:F1056")
		this.addIcon("passport:F07E3")
		this.addIcon("passport-biometric:F0DE1")
		this.addIcon("pasta:F1160")
		this.addIcon("patio-heater:F0F80")
		this.addIcon("patreon:F0882")
		this.addIcon("pause:F03E4")
		this.addIcon("pause-circle:F03E5")
		this.addIcon("pause-circle-outline:F03E6")
		this.addIcon("pause-octagon:F03E7")
		this.addIcon("pause-octagon-outline:F03E8")
		this.addIcon("paw:F03E9")
		this.addIcon("paw-off:F0657")
		this.addIcon("paw-off-outline:F1676")
		this.addIcon("paw-outline:F1675")
		this.addIcon("peace:F0884")
		this.addIcon("peanut:F0FFC")
		this.addIcon("peanut-off:F0FFD")
		this.addIcon("peanut-off-outline:F0FFF")
		this.addIcon("peanut-outline:F0FFE")
		this.addIcon("pen:F03EA")
		this.addIcon("pen-lock:F0DE2")
		this.addIcon("pen-minus:F0DE3")
		this.addIcon("pen-off:F0DE4")
		this.addIcon("pen-plus:F0DE5")
		this.addIcon("pen-remove:F0DE6")
		this.addIcon("pencil:F03EB")
		this.addIcon("pencil-box:F03EC")
		this.addIcon("pencil-box-multiple:F1144")
		this.addIcon("pencil-box-multiple-outline:F1145")
		this.addIcon("pencil-box-outline:F03ED")
		this.addIcon("pencil-circle:F06FF")
		this.addIcon("pencil-circle-outline:F0776")
		this.addIcon("pencil-lock:F03EE")
		this.addIcon("pencil-lock-outline:F0DE7")
		this.addIcon("pencil-minus:F0DE8")
		this.addIcon("pencil-minus-outline:F0DE9")
		this.addIcon("pencil-off:F03EF")
		this.addIcon("pencil-off-outline:F0DEA")
		this.addIcon("pencil-outline:F0CB6")
		this.addIcon("pencil-plus:F0DEB")
		this.addIcon("pencil-plus-outline:F0DEC")
		this.addIcon("pencil-remove:F0DED")
		this.addIcon("pencil-remove-outline:F0DEE")
		this.addIcon("pencil-ruler:F1353")
		this.addIcon("penguin:F0EC0")
		this.addIcon("pentagon:F0701")
		this.addIcon("pentagon-outline:F0700")
		this.addIcon("pentagram:F1667")
		this.addIcon("percent:F03F0")
		this.addIcon("percent-outline:F1278")
		this.addIcon("periodic-table:F08B6")
		this.addIcon("perspective-less:F0D23")
		this.addIcon("perspective-more:F0D24")
		this.addIcon("ph:F17C5")
		this.addIcon("phone:F03F2")
		this.addIcon("phone-alert:F0F1A")
		this.addIcon("phone-alert-outline:F118E")
		this.addIcon("phone-bluetooth:F03F3")
		this.addIcon("phone-bluetooth-outline:F118F")
		this.addIcon("phone-cancel:F10BC")
		this.addIcon("phone-cancel-outline:F1190")
		this.addIcon("phone-check:F11A9")
		this.addIcon("phone-check-outline:F11AA")
		this.addIcon("phone-classic:F0602")
		this.addIcon("phone-classic-off:F1279")
		this.addIcon("phone-dial:F1559")
		this.addIcon("phone-dial-outline:F155A")
		this.addIcon("phone-forward:F03F4")
		this.addIcon("phone-forward-outline:F1191")
		this.addIcon("phone-hangup:F03F5")
		this.addIcon("phone-hangup-outline:F1192")
		this.addIcon("phone-in-talk:F03F6")
		this.addIcon("phone-in-talk-outline:F1182")
		this.addIcon("phone-incoming:F03F7")
		this.addIcon("phone-incoming-outline:F1193")
		this.addIcon("phone-lock:F03F8")
		this.addIcon("phone-lock-outline:F1194")
		this.addIcon("phone-log:F03F9")
		this.addIcon("phone-log-outline:F1195")
		this.addIcon("phone-message:F1196")
		this.addIcon("phone-message-outline:F1197")
		this.addIcon("phone-minus:F0658")
		this.addIcon("phone-minus-outline:F1198")
		this.addIcon("phone-missed:F03FA")
		this.addIcon("phone-missed-outline:F11A5")
		this.addIcon("phone-off:F0DEF")
		this.addIcon("phone-off-outline:F11A6")
		this.addIcon("phone-outgoing:F03FB")
		this.addIcon("phone-outgoing-outline:F1199")
		this.addIcon("phone-outline:F0DF0")
		this.addIcon("phone-paused:F03FC")
		this.addIcon("phone-paused-outline:F119A")
		this.addIcon("phone-plus:F0659")
		this.addIcon("phone-plus-outline:F119B")
		this.addIcon("phone-remove:F152F")
		this.addIcon("phone-remove-outline:F1530")
		this.addIcon("phone-return:F082F")
		this.addIcon("phone-return-outline:F119C")
		this.addIcon("phone-ring:F11AB")
		this.addIcon("phone-ring-outline:F11AC")
		this.addIcon("phone-rotate-landscape:F0885")
		this.addIcon("phone-rotate-portrait:F0886")
		this.addIcon("phone-settings:F03FD")
		this.addIcon("phone-settings-outline:F119D")
		this.addIcon("phone-voip:F03FE")
		this.addIcon("pi:F03FF")
		this.addIcon("pi-box:F0400")
		this.addIcon("pi-hole:F0DF1")
		this.addIcon("piano:F067D")
		this.addIcon("piano-off:F0698")
		this.addIcon("pickaxe:F08B7")
		this.addIcon("picture-in-picture-bottom-right:F0E57")
		this.addIcon("picture-in-picture-bottom-right-outline:F0E58")
		this.addIcon("picture-in-picture-top-right:F0E59")
		this.addIcon("picture-in-picture-top-right-outline:F0E5A")
		this.addIcon("pier:F0887")
		this.addIcon("pier-crane:F0888")
		this.addIcon("pig:F0401")
		this.addIcon("pig-variant:F1006")
		this.addIcon("pig-variant-outline:F1678")
		this.addIcon("piggy-bank:F1007")
		this.addIcon("piggy-bank-outline:F1679")
		this.addIcon("pill:F0402")
		this.addIcon("pillar:F0702")
		this.addIcon("pin:F0403")
		this.addIcon("pin-off:F0404")
		this.addIcon("pin-off-outline:F0930")
		this.addIcon("pin-outline:F0931")
		this.addIcon("pine-tree:F0405")
		this.addIcon("pine-tree-box:F0406")
		this.addIcon("pine-tree-fire:F141A")
		this.addIcon("pinterest:F0407")
		this.addIcon("pinwheel:F0AD5")
		this.addIcon("pinwheel-outline:F0AD6")
		this.addIcon("pipe:F07E5")
		this.addIcon("pipe-disconnected:F07E6")
		this.addIcon("pipe-leak:F0889")
		this.addIcon("pipe-wrench:F1354")
		this.addIcon("pirate:F0A08")
		this.addIcon("pistol:F0703")
		this.addIcon("piston:F088A")
		this.addIcon("pitchfork:F1553")
		this.addIcon("pizza:F0409")
		this.addIcon("play:F040A")
		this.addIcon("play-box:F127A")
		this.addIcon("play-box-multiple:F0D19")
		this.addIcon("play-box-multiple-outline:F13E6")
		this.addIcon("play-box-outline:F040B")
		this.addIcon("play-circle:F040C")
		this.addIcon("play-circle-outline:F040D")
		this.addIcon("play-network:F088B")
		this.addIcon("play-network-outline:F0CB7")
		this.addIcon("play-outline:F0F1B")
		this.addIcon("play-pause:F040E")
		this.addIcon("play-protected-content:F040F")
		this.addIcon("play-speed:F08FF")
		this.addIcon("playlist-check:F05C7")
		this.addIcon("playlist-edit:F0900")
		this.addIcon("playlist-minus:F0410")
		this.addIcon("playlist-music:F0CB8")
		this.addIcon("playlist-music-outline:F0CB9")
		this.addIcon("playlist-play:F0411")
		this.addIcon("playlist-plus:F0412")
		this.addIcon("playlist-remove:F0413")
		this.addIcon("playlist-star:F0DF2")
		this.addIcon("plex:F06BA")
		this.addIcon("plus:F0415")
		this.addIcon("plus-box:F0416")
		this.addIcon("plus-box-multiple:F0334")
		this.addIcon("plus-box-multiple-outline:F1143")
		this.addIcon("plus-box-outline:F0704")
		this.addIcon("plus-circle:F0417")
		this.addIcon("plus-circle-multiple:F034C")
		this.addIcon("plus-circle-multiple-outline:F0418")
		this.addIcon("plus-circle-outline:F0419")
		this.addIcon("plus-minus:F0992")
		this.addIcon("plus-minus-box:F0993")
		this.addIcon("plus-minus-variant:F14C9")
		this.addIcon("plus-network:F041A")
		this.addIcon("plus-network-outline:F0CBA")
		this.addIcon("plus-outline:F0705")
		this.addIcon("plus-thick:F11EC")
		this.addIcon("podcast:F0994")
		this.addIcon("podium:F0D25")
		this.addIcon("podium-bronze:F0D26")
		this.addIcon("podium-gold:F0D27")
		this.addIcon("podium-silver:F0D28")
		this.addIcon("point-of-sale:F0D92")
		this.addIcon("pokeball:F041D")
		this.addIcon("pokemon-go:F0A09")
		this.addIcon("poker-chip:F0830")
		this.addIcon("polaroid:F041E")
		this.addIcon("police-badge:F1167")
		this.addIcon("police-badge-outline:F1168")
		this.addIcon("poll:F041F")
		this.addIcon("polo:F14C3")
		this.addIcon("polymer:F0421")
		this.addIcon("pool:F0606")
		this.addIcon("popcorn:F0422")
		this.addIcon("post:F1008")
		this.addIcon("post-outline:F1009")
		this.addIcon("postage-stamp:F0CBB")
		this.addIcon("pot:F02E5")
		this.addIcon("pot-mix:F065B")
		this.addIcon("pot-mix-outline:F0677")
		this.addIcon("pot-outline:F02FF")
		this.addIcon("pot-steam:F065A")
		this.addIcon("pot-steam-outline:F0326")
		this.addIcon("pound:F0423")
		this.addIcon("pound-box:F0424")
		this.addIcon("pound-box-outline:F117F")
		this.addIcon("power:F0425")
		this.addIcon("power-cycle:F0901")
		this.addIcon("power-off:F0902")
		this.addIcon("power-on:F0903")
		this.addIcon("power-plug:F06A5")
		this.addIcon("power-plug-off:F06A6")
		this.addIcon("power-plug-off-outline:F1424")
		this.addIcon("power-plug-outline:F1425")
		this.addIcon("power-settings:F0426")
		this.addIcon("power-sleep:F0904")
		this.addIcon("power-socket:F0427")
		this.addIcon("power-socket-au:F0905")
		this.addIcon("power-socket-ch:F0FB3")
		this.addIcon("power-socket-de:F1107")
		this.addIcon("power-socket-eu:F07E7")
		this.addIcon("power-socket-fr:F1108")
		this.addIcon("power-socket-it:F14FF")
		this.addIcon("power-socket-jp:F1109")
		this.addIcon("power-socket-uk:F07E8")
		this.addIcon("power-socket-us:F07E9")
		this.addIcon("power-standby:F0906")
		this.addIcon("powershell:F0A0A")
		this.addIcon("prescription:F0706")
		this.addIcon("presentation:F0428")
		this.addIcon("presentation-play:F0429")
		this.addIcon("pretzel:F1562")
		this.addIcon("printer:F042A")
		this.addIcon("printer-3d:F042B")
		this.addIcon("printer-3d-nozzle:F0E5B")
		this.addIcon("printer-3d-nozzle-alert:F11C0")
		this.addIcon("printer-3d-nozzle-alert-outline:F11C1")
		this.addIcon("printer-3d-nozzle-outline:F0E5C")
		this.addIcon("printer-alert:F042C")
		this.addIcon("printer-check:F1146")
		this.addIcon("printer-eye:F1458")
		this.addIcon("printer-off:F0E5D")
		this.addIcon("printer-off-outline:F1785")
		this.addIcon("printer-outline:F1786")
		this.addIcon("printer-pos:F1057")
		this.addIcon("printer-search:F1457")
		this.addIcon("printer-settings:F0707")
		this.addIcon("printer-wireless:F0A0B")
		this.addIcon("priority-high:F0603")
		this.addIcon("priority-low:F0604")
		this.addIcon("professional-hexagon:F042D")
		this.addIcon("progress-alert:F0CBC")
		this.addIcon("progress-check:F0995")
		this.addIcon("progress-clock:F0996")
		this.addIcon("progress-close:F110A")
		this.addIcon("progress-download:F0997")
		this.addIcon("progress-pencil:F1787")
		this.addIcon("progress-question:F1522")
		this.addIcon("progress-star:F1788")
		this.addIcon("progress-upload:F0998")
		this.addIcon("progress-wrench:F0CBD")
		this.addIcon("projector:F042E")
		this.addIcon("projector-screen:F042F")
		this.addIcon("projector-screen-off:F180D")
		this.addIcon("projector-screen-off-outline:F180E")
		this.addIcon("projector-screen-outline:F1724")
		this.addIcon("projector-screen-variant:F180F")
		this.addIcon("projector-screen-variant-off:F1810")
		this.addIcon("projector-screen-variant-off-outline:F1811")
		this.addIcon("projector-screen-variant-outline:F1812")
		this.addIcon("propane-tank:F1357")
		this.addIcon("propane-tank-outline:F1358")
		this.addIcon("protocol:F0FD8")
		this.addIcon("publish:F06A7")
		this.addIcon("pulse:F0430")
		this.addIcon("pump:F1402")
		this.addIcon("pumpkin:F0BBF")
		this.addIcon("purse:F0F1C")
		this.addIcon("purse-outline:F0F1D")
		this.addIcon("puzzle:F0431")
		this.addIcon("puzzle-check:F1426")
		this.addIcon("puzzle-check-outline:F1427")
		this.addIcon("puzzle-edit:F14D3")
		this.addIcon("puzzle-edit-outline:F14D9")
		this.addIcon("puzzle-heart:F14D4")
		this.addIcon("puzzle-heart-outline:F14DA")
		this.addIcon("puzzle-minus:F14D1")
		this.addIcon("puzzle-minus-outline:F14D7")
		this.addIcon("puzzle-outline:F0A66")
		this.addIcon("puzzle-plus:F14D0")
		this.addIcon("puzzle-plus-outline:F14D6")
		this.addIcon("puzzle-remove:F14D2")
		this.addIcon("puzzle-remove-outline:F14D8")
		this.addIcon("puzzle-star:F14D5")
		this.addIcon("puzzle-star-outline:F14DB")
		this.addIcon("qi:F0999")
		this.addIcon("qqchat:F0605")
		this.addIcon("qrcode:F0432")
		this.addIcon("qrcode-edit:F08B8")
		this.addIcon("qrcode-minus:F118C")
		this.addIcon("qrcode-plus:F118B")
		this.addIcon("qrcode-remove:F118D")
		this.addIcon("qrcode-scan:F0433")
		this.addIcon("quadcopter:F0434")
		this.addIcon("quality-high:F0435")
		this.addIcon("quality-low:F0A0C")
		this.addIcon("quality-medium:F0A0D")
		this.addIcon("quora:F0D29")
		this.addIcon("rabbit:F0907")
		this.addIcon("racing-helmet:F0D93")
		this.addIcon("racquetball:F0D94")
		this.addIcon("radar:F0437")
		this.addIcon("radiator:F0438")
		this.addIcon("radiator-disabled:F0AD7")
		this.addIcon("radiator-off:F0AD8")
		this.addIcon("radio:F0439")
		this.addIcon("radio-am:F0CBE")
		this.addIcon("radio-fm:F0CBF")
		this.addIcon("radio-handheld:F043A")
		this.addIcon("radio-off:F121C")
		this.addIcon("radio-tower:F043B")
		this.addIcon("radioactive:F043C")
		this.addIcon("radioactive-off:F0EC1")
		this.addIcon("radiobox-blank:F043D")
		this.addIcon("radiobox-marked:F043E")
		this.addIcon("radiology-box:F14C5")
		this.addIcon("radiology-box-outline:F14C6")
		this.addIcon("radius:F0CC0")
		this.addIcon("radius-outline:F0CC1")
		this.addIcon("railroad-light:F0F1E")
		this.addIcon("rake:F1544")
		this.addIcon("raspberry-pi:F043F")
		this.addIcon("ray-end:F0440")
		this.addIcon("ray-end-arrow:F0441")
		this.addIcon("ray-start:F0442")
		this.addIcon("ray-start-arrow:F0443")
		this.addIcon("ray-start-end:F0444")
		this.addIcon("ray-start-vertex-end:F15D8")
		this.addIcon("ray-vertex:F0445")
		this.addIcon("react:F0708")
		this.addIcon("read:F0447")
		this.addIcon("receipt:F0449")
		this.addIcon("record:F044A")
		this.addIcon("record-circle:F0EC2")
		this.addIcon("record-circle-outline:F0EC3")
		this.addIcon("record-player:F099A")
		this.addIcon("record-rec:F044B")
		this.addIcon("rectangle:F0E5E")
		this.addIcon("rectangle-outline:F0E5F")
		this.addIcon("recycle:F044C")
		this.addIcon("recycle-variant:F139D")
		this.addIcon("reddit:F044D")
		this.addIcon("redhat:F111B")
		this.addIcon("redo:F044E")
		this.addIcon("redo-variant:F044F")
		this.addIcon("reflect-horizontal:F0A0E")
		this.addIcon("reflect-vertical:F0A0F")
		this.addIcon("refresh:F0450")
		this.addIcon("refresh-circle:F1377")
		this.addIcon("regex:F0451")
		this.addIcon("registered-trademark:F0A67")
		this.addIcon("reiterate:F1588")
		this.addIcon("relation-many-to-many:F1496")
		this.addIcon("relation-many-to-one:F1497")
		this.addIcon("relation-many-to-one-or-many:F1498")
		this.addIcon("relation-many-to-only-one:F1499")
		this.addIcon("relation-many-to-zero-or-many:F149A")
		this.addIcon("relation-many-to-zero-or-one:F149B")
		this.addIcon("relation-one-or-many-to-many:F149C")
		this.addIcon("relation-one-or-many-to-one:F149D")
		this.addIcon("relation-one-or-many-to-one-or-many:F149E")
		this.addIcon("relation-one-or-many-to-only-one:F149F")
		this.addIcon("relation-one-or-many-to-zero-or-many:F14A0")
		this.addIcon("relation-one-or-many-to-zero-or-one:F14A1")
		this.addIcon("relation-one-to-many:F14A2")
		this.addIcon("relation-one-to-one:F14A3")
		this.addIcon("relation-one-to-one-or-many:F14A4")
		this.addIcon("relation-one-to-only-one:F14A5")
		this.addIcon("relation-one-to-zero-or-many:F14A6")
		this.addIcon("relation-one-to-zero-or-one:F14A7")
		this.addIcon("relation-only-one-to-many:F14A8")
		this.addIcon("relation-only-one-to-one:F14A9")
		this.addIcon("relation-only-one-to-one-or-many:F14AA")
		this.addIcon("relation-only-one-to-only-one:F14AB")
		this.addIcon("relation-only-one-to-zero-or-many:F14AC")
		this.addIcon("relation-only-one-to-zero-or-one:F14AD")
		this.addIcon("relation-zero-or-many-to-many:F14AE")
		this.addIcon("relation-zero-or-many-to-one:F14AF")
		this.addIcon("relation-zero-or-many-to-one-or-many:F14B0")
		this.addIcon("relation-zero-or-many-to-only-one:F14B1")
		this.addIcon("relation-zero-or-many-to-zero-or-many:F14B2")
		this.addIcon("relation-zero-or-many-to-zero-or-one:F14B3")
		this.addIcon("relation-zero-or-one-to-many:F14B4")
		this.addIcon("relation-zero-or-one-to-one:F14B5")
		this.addIcon("relation-zero-or-one-to-one-or-many:F14B6")
		this.addIcon("relation-zero-or-one-to-only-one:F14B7")
		this.addIcon("relation-zero-or-one-to-zero-or-many:F14B8")
		this.addIcon("relation-zero-or-one-to-zero-or-one:F14B9")
		this.addIcon("relative-scale:F0452")
		this.addIcon("reload:F0453")
		this.addIcon("reload-alert:F110B")
		this.addIcon("reminder:F088C")
		this.addIcon("remote:F0454")
		this.addIcon("remote-desktop:F08B9")
		this.addIcon("remote-off:F0EC4")
		this.addIcon("remote-tv:F0EC5")
		this.addIcon("remote-tv-off:F0EC6")
		this.addIcon("rename-box:F0455")
		this.addIcon("reorder-horizontal:F0688")
		this.addIcon("reorder-vertical:F0689")
		this.addIcon("repeat:F0456")
		this.addIcon("repeat-off:F0457")
		this.addIcon("repeat-once:F0458")
		this.addIcon("repeat-variant:F0547")
		this.addIcon("replay:F0459")
		this.addIcon("reply:F045A")
		this.addIcon("reply-all:F045B")
		this.addIcon("reply-all-outline:F0F1F")
		this.addIcon("reply-circle:F11AE")
		this.addIcon("reply-outline:F0F20")
		this.addIcon("reproduction:F045C")
		this.addIcon("resistor:F0B44")
		this.addIcon("resistor-nodes:F0B45")
		this.addIcon("resize:F0A68")
		this.addIcon("resize-bottom-right:F045D")
		this.addIcon("responsive:F045E")
		this.addIcon("restart:F0709")
		this.addIcon("restart-alert:F110C")
		this.addIcon("restart-off:F0D95")
		this.addIcon("restore:F099B")
		this.addIcon("restore-alert:F110D")
		this.addIcon("rewind:F045F")
		this.addIcon("rewind-10:F0D2A")
		this.addIcon("rewind-30:F0D96")
		this.addIcon("rewind-5:F11F9")
		this.addIcon("rewind-60:F160C")
		this.addIcon("rewind-outline:F070A")
		this.addIcon("rhombus:F070B")
		this.addIcon("rhombus-medium:F0A10")
		this.addIcon("rhombus-medium-outline:F14DC")
		this.addIcon("rhombus-outline:F070C")
		this.addIcon("rhombus-split:F0A11")
		this.addIcon("rhombus-split-outline:F14DD")
		this.addIcon("ribbon:F0460")
		this.addIcon("rice:F07EA")
		this.addIcon("rickshaw:F15BB")
		this.addIcon("rickshaw-electric:F15BC")
		this.addIcon("ring:F07EB")
		this.addIcon("rivet:F0E60")
		this.addIcon("road:F0461")
		this.addIcon("road-variant:F0462")
		this.addIcon("robber:F1058")
		this.addIcon("robot:F06A9")
		this.addIcon("robot-angry:F169D")
		this.addIcon("robot-angry-outline:F169E")
		this.addIcon("robot-confused:F169F")
		this.addIcon("robot-confused-outline:F16A0")
		this.addIcon("robot-dead:F16A1")
		this.addIcon("robot-dead-outline:F16A2")
		this.addIcon("robot-excited:F16A3")
		this.addIcon("robot-excited-outline:F16A4")
		this.addIcon("robot-happy:F1719")
		this.addIcon("robot-happy-outline:F171A")
		this.addIcon("robot-industrial:F0B46")
		this.addIcon("robot-love:F16A5")
		this.addIcon("robot-love-outline:F16A6")
		this.addIcon("robot-mower:F11F7")
		this.addIcon("robot-mower-outline:F11F3")
		this.addIcon("robot-off:F16A7")
		this.addIcon("robot-off-outline:F167B")
		this.addIcon("robot-outline:F167A")
		this.addIcon("robot-vacuum:F070D")
		this.addIcon("robot-vacuum-variant:F0908")
		this.addIcon("rocket:F0463")
		this.addIcon("rocket-launch:F14DE")
		this.addIcon("rocket-launch-outline:F14DF")
		this.addIcon("rocket-outline:F13AF")
		this.addIcon("rodent:F1327")
		this.addIcon("roller-skate:F0D2B")
		this.addIcon("roller-skate-off:F0145")
		this.addIcon("rollerblade:F0D2C")
		this.addIcon("rollerblade-off:F002E")
		this.addIcon("rollupjs:F0BC0")
		this.addIcon("roman-numeral-1:F1088")
		this.addIcon("roman-numeral-10:F1091")
		this.addIcon("roman-numeral-2:F1089")
		this.addIcon("roman-numeral-3:F108A")
		this.addIcon("roman-numeral-4:F108B")
		this.addIcon("roman-numeral-5:F108C")
		this.addIcon("roman-numeral-6:F108D")
		this.addIcon("roman-numeral-7:F108E")
		this.addIcon("roman-numeral-8:F108F")
		this.addIcon("roman-numeral-9:F1090")
		this.addIcon("room-service:F088D")
		this.addIcon("room-service-outline:F0D97")
		this.addIcon("rotate-3d:F0EC7")
		this.addIcon("rotate-3d-variant:F0464")
		this.addIcon("rotate-left:F0465")
		this.addIcon("rotate-left-variant:F0466")
		this.addIcon("rotate-orbit:F0D98")
		this.addIcon("rotate-right:F0467")
		this.addIcon("rotate-right-variant:F0468")
		this.addIcon("rounded-corner:F0607")
		this.addIcon("router:F11E2")
		this.addIcon("router-network:F1087")
		this.addIcon("router-wireless:F0469")
		this.addIcon("router-wireless-off:F15A3")
		this.addIcon("router-wireless-settings:F0A69")
		this.addIcon("routes:F046A")
		this.addIcon("routes-clock:F1059")
		this.addIcon("rowing:F0608")
		this.addIcon("rss:F046B")
		this.addIcon("rss-box:F046C")
		this.addIcon("rss-off:F0F21")
		this.addIcon("rug:F1475")
		this.addIcon("rugby:F0D99")
		this.addIcon("ruler:F046D")
		this.addIcon("ruler-square:F0CC2")
		this.addIcon("ruler-square-compass:F0EBE")
		this.addIcon("run:F070E")
		this.addIcon("run-fast:F046E")
		this.addIcon("rv-truck:F11D4")
		this.addIcon("sack:F0D2E")
		this.addIcon("sack-percent:F0D2F")
		this.addIcon("safe:F0A6A")
		this.addIcon("safe-square:F127C")
		this.addIcon("safe-square-outline:F127D")
		this.addIcon("safety-goggles:F0D30")
		this.addIcon("sail-boat:F0EC8")
		this.addIcon("sale:F046F")
		this.addIcon("salesforce:F088E")
		this.addIcon("sass:F07EC")
		this.addIcon("satellite:F0470")
		this.addIcon("satellite-uplink:F0909")
		this.addIcon("satellite-variant:F0471")
		this.addIcon("sausage:F08BA")
		this.addIcon("sausage-off:F1789")
		this.addIcon("saw-blade:F0E61")
		this.addIcon("sawtooth-wave:F147A")
		this.addIcon("saxophone:F0609")
		this.addIcon("scale:F0472")
		this.addIcon("scale-balance:F05D1")
		this.addIcon("scale-bathroom:F0473")
		this.addIcon("scale-off:F105A")
		this.addIcon("scan-helper:F13D8")
		this.addIcon("scanner:F06AB")
		this.addIcon("scanner-off:F090A")
		this.addIcon("scatter-plot:F0EC9")
		this.addIcon("scatter-plot-outline:F0ECA")
		this.addIcon("school:F0474")
		this.addIcon("school-outline:F1180")
		this.addIcon("scissors-cutting:F0A6B")
		this.addIcon("scooter:F15BD")
		this.addIcon("scooter-electric:F15BE")
		this.addIcon("scoreboard:F127E")
		this.addIcon("scoreboard-outline:F127F")
		this.addIcon("screen-rotation:F0475")
		this.addIcon("screen-rotation-lock:F0478")
		this.addIcon("screw-flat-top:F0DF3")
		this.addIcon("screw-lag:F0DF4")
		this.addIcon("screw-machine-flat-top:F0DF5")
		this.addIcon("screw-machine-round-top:F0DF6")
		this.addIcon("screw-round-top:F0DF7")
		this.addIcon("screwdriver:F0476")
		this.addIcon("script:F0BC1")
		this.addIcon("script-outline:F0477")
		this.addIcon("script-text:F0BC2")
		this.addIcon("script-text-key:F1725")
		this.addIcon("script-text-key-outline:F1726")
		this.addIcon("script-text-outline:F0BC3")
		this.addIcon("script-text-play:F1727")
		this.addIcon("script-text-play-outline:F1728")
		this.addIcon("sd:F0479")
		this.addIcon("seal:F047A")
		this.addIcon("seal-variant:F0FD9")
		this.addIcon("search-web:F070F")
		this.addIcon("seat:F0CC3")
		this.addIcon("seat-flat:F047B")
		this.addIcon("seat-flat-angled:F047C")
		this.addIcon("seat-individual-suite:F047D")
		this.addIcon("seat-legroom-extra:F047E")
		this.addIcon("seat-legroom-normal:F047F")
		this.addIcon("seat-legroom-reduced:F0480")
		this.addIcon("seat-outline:F0CC4")
		this.addIcon("seat-passenger:F1249")
		this.addIcon("seat-recline-extra:F0481")
		this.addIcon("seat-recline-normal:F0482")
		this.addIcon("seatbelt:F0CC5")
		this.addIcon("security:F0483")
		this.addIcon("security-network:F0484")
		this.addIcon("seed:F0E62")
		this.addIcon("seed-off:F13FD")
		this.addIcon("seed-off-outline:F13FE")
		this.addIcon("seed-outline:F0E63")
		this.addIcon("seesaw:F15A4")
		this.addIcon("segment:F0ECB")
		this.addIcon("select:F0485")
		this.addIcon("select-all:F0486")
		this.addIcon("select-color:F0D31")
		this.addIcon("select-compare:F0AD9")
		this.addIcon("select-drag:F0A6C")
		this.addIcon("select-group:F0F82")
		this.addIcon("select-inverse:F0487")
		this.addIcon("select-marker:F1280")
		this.addIcon("select-multiple:F1281")
		this.addIcon("select-multiple-marker:F1282")
		this.addIcon("select-off:F0488")
		this.addIcon("select-place:F0FDA")
		this.addIcon("select-remove:F17C1")
		this.addIcon("select-search:F1204")
		this.addIcon("selection:F0489")
		this.addIcon("selection-drag:F0A6D")
		this.addIcon("selection-ellipse:F0D32")
		this.addIcon("selection-ellipse-arrow-inside:F0F22")
		this.addIcon("selection-ellipse-remove:F17C2")
		this.addIcon("selection-marker:F1283")
		this.addIcon("selection-multiple:F1285")
		this.addIcon("selection-multiple-marker:F1284")
		this.addIcon("selection-off:F0777")
		this.addIcon("selection-remove:F17C3")
		this.addIcon("selection-search:F1205")
		this.addIcon("semantic-web:F1316")
		this.addIcon("send:F048A")
		this.addIcon("send-check:F1161")
		this.addIcon("send-check-outline:F1162")
		this.addIcon("send-circle:F0DF8")
		this.addIcon("send-circle-outline:F0DF9")
		this.addIcon("send-clock:F1163")
		this.addIcon("send-clock-outline:F1164")
		this.addIcon("send-lock:F07ED")
		this.addIcon("send-lock-outline:F1166")
		this.addIcon("send-outline:F1165")
		this.addIcon("serial-port:F065C")
		this.addIcon("server:F048B")
		this.addIcon("server-minus:F048C")
		this.addIcon("server-network:F048D")
		this.addIcon("server-network-off:F048E")
		this.addIcon("server-off:F048F")
		this.addIcon("server-plus:F0490")
		this.addIcon("server-remove:F0491")
		this.addIcon("server-security:F0492")
		this.addIcon("set-all:F0778")
		this.addIcon("set-center:F0779")
		this.addIcon("set-center-right:F077A")
		this.addIcon("set-left:F077B")
		this.addIcon("set-left-center:F077C")
		this.addIcon("set-left-right:F077D")
		this.addIcon("set-merge:F14E0")
		this.addIcon("set-none:F077E")
		this.addIcon("set-right:F077F")
		this.addIcon("set-split:F14E1")
		this.addIcon("set-square:F145D")
		this.addIcon("set-top-box:F099F")
		this.addIcon("settings-helper:F0A6E")
		this.addIcon("shaker:F110E")
		this.addIcon("shaker-outline:F110F")
		this.addIcon("shape:F0831")
		this.addIcon("shape-circle-plus:F065D")
		this.addIcon("shape-outline:F0832")
		this.addIcon("shape-oval-plus:F11FA")
		this.addIcon("shape-plus:F0495")
		this.addIcon("shape-polygon-plus:F065E")
		this.addIcon("shape-rectangle-plus:F065F")
		this.addIcon("shape-square-plus:F0660")
		this.addIcon("shape-square-rounded-plus:F14FA")
		this.addIcon("share:F0496")
		this.addIcon("share-all:F11F4")
		this.addIcon("share-all-outline:F11F5")
		this.addIcon("share-circle:F11AD")
		this.addIcon("share-off:F0F23")
		this.addIcon("share-off-outline:F0F24")
		this.addIcon("share-outline:F0932")
		this.addIcon("share-variant:F0497")
		this.addIcon("share-variant-outline:F1514")
		this.addIcon("shark-fin:F1673")
		this.addIcon("shark-fin-outline:F1674")
		this.addIcon("sheep:F0CC6")
		this.addIcon("shield:F0498")
		this.addIcon("shield-account:F088F")
		this.addIcon("shield-account-outline:F0A12")
		this.addIcon("shield-account-variant:F15A7")
		this.addIcon("shield-account-variant-outline:F15A8")
		this.addIcon("shield-airplane:F06BB")
		this.addIcon("shield-airplane-outline:F0CC7")
		this.addIcon("shield-alert:F0ECC")
		this.addIcon("shield-alert-outline:F0ECD")
		this.addIcon("shield-bug:F13DA")
		this.addIcon("shield-bug-outline:F13DB")
		this.addIcon("shield-car:F0F83")
		this.addIcon("shield-check:F0565")
		this.addIcon("shield-check-outline:F0CC8")
		this.addIcon("shield-cross:F0CC9")
		this.addIcon("shield-cross-outline:F0CCA")
		this.addIcon("shield-edit:F11A0")
		this.addIcon("shield-edit-outline:F11A1")
		this.addIcon("shield-half:F1360")
		this.addIcon("shield-half-full:F0780")
		this.addIcon("shield-home:F068A")
		this.addIcon("shield-home-outline:F0CCB")
		this.addIcon("shield-key:F0BC4")
		this.addIcon("shield-key-outline:F0BC5")
		this.addIcon("shield-link-variant:F0D33")
		this.addIcon("shield-link-variant-outline:F0D34")
		this.addIcon("shield-lock:F099D")
		this.addIcon("shield-lock-outline:F0CCC")
		this.addIcon("shield-moon:F1828")
		this.addIcon("shield-moon-outline:F1829")
		this.addIcon("shield-off:F099E")
		this.addIcon("shield-off-outline:F099C")
		this.addIcon("shield-outline:F0499")
		this.addIcon("shield-plus:F0ADA")
		this.addIcon("shield-plus-outline:F0ADB")
		this.addIcon("shield-refresh:F00AA")
		this.addIcon("shield-refresh-outline:F01E0")
		this.addIcon("shield-remove:F0ADC")
		this.addIcon("shield-remove-outline:F0ADD")
		this.addIcon("shield-search:F0D9A")
		this.addIcon("shield-star:F113B")
		this.addIcon("shield-star-outline:F113C")
		this.addIcon("shield-sun:F105D")
		this.addIcon("shield-sun-outline:F105E")
		this.addIcon("shield-sync:F11A2")
		this.addIcon("shield-sync-outline:F11A3")
		this.addIcon("shimmer:F1545")
		this.addIcon("ship-wheel:F0833")
		this.addIcon("shoe-ballet:F15CA")
		this.addIcon("shoe-cleat:F15C7")
		this.addIcon("shoe-formal:F0B47")
		this.addIcon("shoe-heel:F0B48")
		this.addIcon("shoe-print:F0DFA")
		this.addIcon("shoe-sneaker:F15C8")
		this.addIcon("shopping:F049A")
		this.addIcon("shopping-music:F049B")
		this.addIcon("shopping-outline:F11D5")
		this.addIcon("shopping-search:F0F84")
		this.addIcon("shore:F14F9")
		this.addIcon("shovel:F0710")
		this.addIcon("shovel-off:F0711")
		this.addIcon("shower:F09A0")
		this.addIcon("shower-head:F09A1")
		this.addIcon("shredder:F049C")
		this.addIcon("shuffle:F049D")
		this.addIcon("shuffle-disabled:F049E")
		this.addIcon("shuffle-variant:F049F")
		this.addIcon("shuriken:F137F")
		this.addIcon("sigma:F04A0")
		this.addIcon("sigma-lower:F062B")
		this.addIcon("sign-caution:F04A1")
		this.addIcon("sign-direction:F0781")
		this.addIcon("sign-direction-minus:F1000")
		this.addIcon("sign-direction-plus:F0FDC")
		this.addIcon("sign-direction-remove:F0FDD")
		this.addIcon("sign-pole:F14F8")
		this.addIcon("sign-real-estate:F1118")
		this.addIcon("sign-text:F0782")
		this.addIcon("signal:F04A2")
		this.addIcon("signal-2g:F0712")
		this.addIcon("signal-3g:F0713")
		this.addIcon("signal-4g:F0714")
		this.addIcon("signal-5g:F0A6F")
		this.addIcon("signal-cellular-1:F08BC")
		this.addIcon("signal-cellular-2:F08BD")
		this.addIcon("signal-cellular-3:F08BE")
		this.addIcon("signal-cellular-outline:F08BF")
		this.addIcon("signal-distance-variant:F0E64")
		this.addIcon("signal-hspa:F0715")
		this.addIcon("signal-hspa-plus:F0716")
		this.addIcon("signal-off:F0783")
		this.addIcon("signal-variant:F060A")
		this.addIcon("signature:F0DFB")
		this.addIcon("signature-freehand:F0DFC")
		this.addIcon("signature-image:F0DFD")
		this.addIcon("signature-text:F0DFE")
		this.addIcon("silo:F0B49")
		this.addIcon("silverware:F04A3")
		this.addIcon("silverware-clean:F0FDE")
		this.addIcon("silverware-fork:F04A4")
		this.addIcon("silverware-fork-knife:F0A70")
		this.addIcon("silverware-spoon:F04A5")
		this.addIcon("silverware-variant:F04A6")
		this.addIcon("sim:F04A7")
		this.addIcon("sim-alert:F04A8")
		this.addIcon("sim-alert-outline:F15D3")
		this.addIcon("sim-off:F04A9")
		this.addIcon("sim-off-outline:F15D4")
		this.addIcon("sim-outline:F15D5")
		this.addIcon("simple-icons:F131D")
		this.addIcon("sina-weibo:F0ADF")
		this.addIcon("sine-wave:F095B")
		this.addIcon("sitemap:F04AA")
		this.addIcon("size-l:F13A6")
		this.addIcon("size-m:F13A5")
		this.addIcon("size-s:F13A4")
		this.addIcon("size-xl:F13A7")
		this.addIcon("size-xs:F13A3")
		this.addIcon("size-xxl:F13A8")
		this.addIcon("size-xxs:F13A2")
		this.addIcon("size-xxxl:F13A9")
		this.addIcon("skate:F0D35")
		this.addIcon("skate-off:F0699")
		this.addIcon("skateboard:F14C2")
		this.addIcon("skateboarding:F0501")
		this.addIcon("skew-less:F0D36")
		this.addIcon("skew-more:F0D37")
		this.addIcon("ski:F1304")
		this.addIcon("ski-cross-country:F1305")
		this.addIcon("ski-water:F1306")
		this.addIcon("skip-backward:F04AB")
		this.addIcon("skip-backward-outline:F0F25")
		this.addIcon("skip-forward:F04AC")
		this.addIcon("skip-forward-outline:F0F26")
		this.addIcon("skip-next:F04AD")
		this.addIcon("skip-next-circle:F0661")
		this.addIcon("skip-next-circle-outline:F0662")
		this.addIcon("skip-next-outline:F0F27")
		this.addIcon("skip-previous:F04AE")
		this.addIcon("skip-previous-circle:F0663")
		this.addIcon("skip-previous-circle-outline:F0664")
		this.addIcon("skip-previous-outline:F0F28")
		this.addIcon("skull:F068C")
		this.addIcon("skull-crossbones:F0BC6")
		this.addIcon("skull-crossbones-outline:F0BC7")
		this.addIcon("skull-outline:F0BC8")
		this.addIcon("skull-scan:F14C7")
		this.addIcon("skull-scan-outline:F14C8")
		this.addIcon("skype:F04AF")
		this.addIcon("skype-business:F04B0")
		this.addIcon("slack:F04B1")
		this.addIcon("slash-forward:F0FDF")
		this.addIcon("slash-forward-box:F0FE0")
		this.addIcon("sledding:F041B")
		this.addIcon("sleep:F04B2")
		this.addIcon("sleep-off:F04B3")
		this.addIcon("slide:F15A5")
		this.addIcon("slope-downhill:F0DFF")
		this.addIcon("slope-uphill:F0E00")
		this.addIcon("slot-machine:F1114")
		this.addIcon("slot-machine-outline:F1115")
		this.addIcon("smart-card:F10BD")
		this.addIcon("smart-card-outline:F10BE")
		this.addIcon("smart-card-reader:F10BF")
		this.addIcon("smart-card-reader-outline:F10C0")
		this.addIcon("smog:F0A71")
		this.addIcon("smoke:F1799")
		this.addIcon("smoke-detector:F0392")
		this.addIcon("smoke-detector-off:F1809")
		this.addIcon("smoke-detector-off-outline:F180A")
		this.addIcon("smoke-detector-outline:F1808")
		this.addIcon("smoke-detector-variant:F180B")
		this.addIcon("smoke-detector-variant-off:F180C")
		this.addIcon("smoking:F04B4")
		this.addIcon("smoking-off:F04B5")
		this.addIcon("smoking-pipe:F140D")
		this.addIcon("smoking-pipe-off:F1428")
		this.addIcon("snail:F1677")
		this.addIcon("snake:F150E")
		this.addIcon("snapchat:F04B6")
		this.addIcon("snowboard:F1307")
		this.addIcon("snowflake:F0717")
		this.addIcon("snowflake-alert:F0F29")
		this.addIcon("snowflake-melt:F12CB")
		this.addIcon("snowflake-off:F14E3")
		this.addIcon("snowflake-variant:F0F2A")
		this.addIcon("snowman:F04B7")
		this.addIcon("snowmobile:F06DD")
		this.addIcon("soccer:F04B8")
		this.addIcon("soccer-field:F0834")
		this.addIcon("social-distance-2-meters:F1579")
		this.addIcon("social-distance-6-feet:F157A")
		this.addIcon("sofa:F04B9")
		this.addIcon("sofa-outline:F156D")
		this.addIcon("sofa-single:F156E")
		this.addIcon("sofa-single-outline:F156F")
		this.addIcon("solar-panel:F0D9B")
		this.addIcon("solar-panel-large:F0D9C")
		this.addIcon("solar-power:F0A72")
		this.addIcon("soldering-iron:F1092")
		this.addIcon("solid:F068D")
		this.addIcon("sony-playstation:F0414")
		this.addIcon("sort:F04BA")
		this.addIcon("sort-alphabetical-ascending:F05BD")
		this.addIcon("sort-alphabetical-ascending-variant:F1148")
		this.addIcon("sort-alphabetical-descending:F05BF")
		this.addIcon("sort-alphabetical-descending-variant:F1149")
		this.addIcon("sort-alphabetical-variant:F04BB")
		this.addIcon("sort-ascending:F04BC")
		this.addIcon("sort-bool-ascending:F1385")
		this.addIcon("sort-bool-ascending-variant:F1386")
		this.addIcon("sort-bool-descending:F1387")
		this.addIcon("sort-bool-descending-variant:F1388")
		this.addIcon("sort-calendar-ascending:F1547")
		this.addIcon("sort-calendar-descending:F1548")
		this.addIcon("sort-clock-ascending:F1549")
		this.addIcon("sort-clock-ascending-outline:F154A")
		this.addIcon("sort-clock-descending:F154B")
		this.addIcon("sort-clock-descending-outline:F154C")
		this.addIcon("sort-descending:F04BD")
		this.addIcon("sort-numeric-ascending:F1389")
		this.addIcon("sort-numeric-ascending-variant:F090D")
		this.addIcon("sort-numeric-descending:F138A")
		this.addIcon("sort-numeric-descending-variant:F0AD2")
		this.addIcon("sort-numeric-variant:F04BE")
		this.addIcon("sort-reverse-variant:F033C")
		this.addIcon("sort-variant:F04BF")
		this.addIcon("sort-variant-lock:F0CCD")
		this.addIcon("sort-variant-lock-open:F0CCE")
		this.addIcon("sort-variant-remove:F1147")
		this.addIcon("soundbar:F17DB")
		this.addIcon("soundcloud:F04C0")
		this.addIcon("source-branch:F062C")
		this.addIcon("source-branch-check:F14CF")
		this.addIcon("source-branch-minus:F14CB")
		this.addIcon("source-branch-plus:F14CA")
		this.addIcon("source-branch-refresh:F14CD")
		this.addIcon("source-branch-remove:F14CC")
		this.addIcon("source-branch-sync:F14CE")
		this.addIcon("source-commit:F0718")
		this.addIcon("source-commit-end:F0719")
		this.addIcon("source-commit-end-local:F071A")
		this.addIcon("source-commit-local:F071B")
		this.addIcon("source-commit-next-local:F071C")
		this.addIcon("source-commit-start:F071D")
		this.addIcon("source-commit-start-next-local:F071E")
		this.addIcon("source-fork:F04C1")
		this.addIcon("source-merge:F062D")
		this.addIcon("source-pull:F04C2")
		this.addIcon("source-repository:F0CCF")
		this.addIcon("source-repository-multiple:F0CD0")
		this.addIcon("soy-sauce:F07EE")
		this.addIcon("soy-sauce-off:F13FC")
		this.addIcon("spa:F0CD1")
		this.addIcon("spa-outline:F0CD2")
		this.addIcon("space-invaders:F0BC9")
		this.addIcon("space-station:F1383")
		this.addIcon("spade:F0E65")
		this.addIcon("speaker:F04C3")
		this.addIcon("speaker-bluetooth:F09A2")
		this.addIcon("speaker-multiple:F0D38")
		this.addIcon("speaker-off:F04C4")
		this.addIcon("speaker-wireless:F071F")
		this.addIcon("speedometer:F04C5")
		this.addIcon("speedometer-medium:F0F85")
		this.addIcon("speedometer-slow:F0F86")
		this.addIcon("spellcheck:F04C6")
		this.addIcon("spider:F11EA")
		this.addIcon("spider-thread:F11EB")
		this.addIcon("spider-web:F0BCA")
		this.addIcon("spirit-level:F14F1")
		this.addIcon("spoon-sugar:F1429")
		this.addIcon("spotify:F04C7")
		this.addIcon("spotlight:F04C8")
		this.addIcon("spotlight-beam:F04C9")
		this.addIcon("spray:F0665")
		this.addIcon("spray-bottle:F0AE0")
		this.addIcon("sprinkler:F105F")
		this.addIcon("sprinkler-variant:F1060")
		this.addIcon("sprout:F0E66")
		this.addIcon("sprout-outline:F0E67")
		this.addIcon("square:F0764")
		this.addIcon("square-circle:F1500")
		this.addIcon("square-edit-outline:F090C")
		this.addIcon("square-medium:F0A13")
		this.addIcon("square-medium-outline:F0A14")
		this.addIcon("square-off:F12EE")
		this.addIcon("square-off-outline:F12EF")
		this.addIcon("square-outline:F0763")
		this.addIcon("square-root:F0784")
		this.addIcon("square-root-box:F09A3")
		this.addIcon("square-rounded:F14FB")
		this.addIcon("square-rounded-outline:F14FC")
		this.addIcon("square-small:F0A15")
		this.addIcon("square-wave:F147B")
		this.addIcon("squeegee:F0AE1")
		this.addIcon("ssh:F08C0")
		this.addIcon("stack-exchange:F060B")
		this.addIcon("stack-overflow:F04CC")
		this.addIcon("stackpath:F0359")
		this.addIcon("stadium:F0FF9")
		this.addIcon("stadium-variant:F0720")
		this.addIcon("stairs:F04CD")
		this.addIcon("stairs-box:F139E")
		this.addIcon("stairs-down:F12BE")
		this.addIcon("stairs-up:F12BD")
		this.addIcon("stamper:F0D39")
		this.addIcon("standard-definition:F07EF")
		this.addIcon("star:F04CE")
		this.addIcon("star-box:F0A73")
		this.addIcon("star-box-multiple:F1286")
		this.addIcon("star-box-multiple-outline:F1287")
		this.addIcon("star-box-outline:F0A74")
		this.addIcon("star-check:F1566")
		this.addIcon("star-check-outline:F156A")
		this.addIcon("star-circle:F04CF")
		this.addIcon("star-circle-outline:F09A4")
		this.addIcon("star-cog:F1668")
		this.addIcon("star-cog-outline:F1669")
		this.addIcon("star-crescent:F0979")
		this.addIcon("star-david:F097A")
		this.addIcon("star-face:F09A5")
		this.addIcon("star-four-points:F0AE2")
		this.addIcon("star-four-points-outline:F0AE3")
		this.addIcon("star-half:F0246")
		this.addIcon("star-half-full:F04D0")
		this.addIcon("star-minus:F1564")
		this.addIcon("star-minus-outline:F1568")
		this.addIcon("star-off:F04D1")
		this.addIcon("star-off-outline:F155B")
		this.addIcon("star-outline:F04D2")
		this.addIcon("star-plus:F1563")
		this.addIcon("star-plus-outline:F1567")
		this.addIcon("star-remove:F1565")
		this.addIcon("star-remove-outline:F1569")
		this.addIcon("star-settings:F166A")
		this.addIcon("star-settings-outline:F166B")
		this.addIcon("star-shooting:F1741")
		this.addIcon("star-shooting-outline:F1742")
		this.addIcon("star-three-points:F0AE4")
		this.addIcon("star-three-points-outline:F0AE5")
		this.addIcon("state-machine:F11EF")
		this.addIcon("steam:F04D3")
		this.addIcon("steering:F04D4")
		this.addIcon("steering-off:F090E")
		this.addIcon("step-backward:F04D5")
		this.addIcon("step-backward-2:F04D6")
		this.addIcon("step-forward:F04D7")
		this.addIcon("step-forward-2:F04D8")
		this.addIcon("stethoscope:F04D9")
		this.addIcon("sticker:F1364")
		this.addIcon("sticker-alert:F1365")
		this.addIcon("sticker-alert-outline:F1366")
		this.addIcon("sticker-check:F1367")
		this.addIcon("sticker-check-outline:F1368")
		this.addIcon("sticker-circle-outline:F05D0")
		this.addIcon("sticker-emoji:F0785")
		this.addIcon("sticker-minus:F1369")
		this.addIcon("sticker-minus-outline:F136A")
		this.addIcon("sticker-outline:F136B")
		this.addIcon("sticker-plus:F136C")
		this.addIcon("sticker-plus-outline:F136D")
		this.addIcon("sticker-remove:F136E")
		this.addIcon("sticker-remove-outline:F136F")
		this.addIcon("sticker-text:F178E")
		this.addIcon("sticker-text-outline:F178F")
		this.addIcon("stocking:F04DA")
		this.addIcon("stomach:F1093")
		this.addIcon("stop:F04DB")
		this.addIcon("stop-circle:F0666")
		this.addIcon("stop-circle-outline:F0667")
		this.addIcon("store:F04DC")
		this.addIcon("store-24-hour:F04DD")
		this.addIcon("store-minus:F165E")
		this.addIcon("store-outline:F1361")
		this.addIcon("store-plus:F165F")
		this.addIcon("store-remove:F1660")
		this.addIcon("storefront:F07C7")
		this.addIcon("storefront-outline:F10C1")
		this.addIcon("stove:F04DE")
		this.addIcon("strategy:F11D6")
		this.addIcon("stretch-to-page:F0F2B")
		this.addIcon("stretch-to-page-outline:F0F2C")
		this.addIcon("string-lights:F12BA")
		this.addIcon("string-lights-off:F12BB")
		this.addIcon("subdirectory-arrow-left:F060C")
		this.addIcon("subdirectory-arrow-right:F060D")
		this.addIcon("submarine:F156C")
		this.addIcon("subtitles:F0A16")
		this.addIcon("subtitles-outline:F0A17")
		this.addIcon("subway:F06AC")
		this.addIcon("subway-alert-variant:F0D9D")
		this.addIcon("subway-variant:F04DF")
		this.addIcon("summit:F0786")
		this.addIcon("sun-snowflake:F1796")
		this.addIcon("sun-wireless:F17FE")
		this.addIcon("sun-wireless-outline:F17FF")
		this.addIcon("sunglasses:F04E0")
		this.addIcon("surfing:F1746")
		this.addIcon("surround-sound:F05C5")
		this.addIcon("surround-sound-2-0:F07F0")
		this.addIcon("surround-sound-2-1:F1729")
		this.addIcon("surround-sound-3-1:F07F1")
		this.addIcon("surround-sound-5-1:F07F2")
		this.addIcon("surround-sound-5-1-2:F172A")
		this.addIcon("surround-sound-7-1:F07F3")
		this.addIcon("svg:F0721")
		this.addIcon("swap-horizontal:F04E1")
		this.addIcon("swap-horizontal-bold:F0BCD")
		this.addIcon("swap-horizontal-circle:F0FE1")
		this.addIcon("swap-horizontal-circle-outline:F0FE2")
		this.addIcon("swap-horizontal-variant:F08C1")
		this.addIcon("swap-vertical:F04E2")
		this.addIcon("swap-vertical-bold:F0BCE")
		this.addIcon("swap-vertical-circle:F0FE3")
		this.addIcon("swap-vertical-circle-outline:F0FE4")
		this.addIcon("swap-vertical-variant:F08C2")
		this.addIcon("swim:F04E3")
		this.addIcon("switch:F04E4")
		this.addIcon("sword:F04E5")
		this.addIcon("sword-cross:F0787")
		this.addIcon("syllabary-hangul:F1333")
		this.addIcon("syllabary-hiragana:F1334")
		this.addIcon("syllabary-katakana:F1335")
		this.addIcon("syllabary-katakana-halfwidth:F1336")
		this.addIcon("symbol:F1501")
		this.addIcon("symfony:F0AE6")
		this.addIcon("sync:F04E6")
		this.addIcon("sync-alert:F04E7")
		this.addIcon("sync-circle:F1378")
		this.addIcon("sync-off:F04E8")
		this.addIcon("tab:F04E9")
		this.addIcon("tab-minus:F0B4B")
		this.addIcon("tab-plus:F075C")
		this.addIcon("tab-remove:F0B4C")
		this.addIcon("tab-unselected:F04EA")
		this.addIcon("table:F04EB")
		this.addIcon("table-account:F13B9")
		this.addIcon("table-alert:F13BA")
		this.addIcon("table-arrow-down:F13BB")
		this.addIcon("table-arrow-left:F13BC")
		this.addIcon("table-arrow-right:F13BD")
		this.addIcon("table-arrow-up:F13BE")
		this.addIcon("table-border:F0A18")
		this.addIcon("table-cancel:F13BF")
		this.addIcon("table-chair:F1061")
		this.addIcon("table-check:F13C0")
		this.addIcon("table-clock:F13C1")
		this.addIcon("table-cog:F13C2")
		this.addIcon("table-column:F0835")
		this.addIcon("table-column-plus-after:F04EC")
		this.addIcon("table-column-plus-before:F04ED")
		this.addIcon("table-column-remove:F04EE")
		this.addIcon("table-column-width:F04EF")
		this.addIcon("table-edit:F04F0")
		this.addIcon("table-eye:F1094")
		this.addIcon("table-eye-off:F13C3")
		this.addIcon("table-furniture:F05BC")
		this.addIcon("table-headers-eye:F121D")
		this.addIcon("table-headers-eye-off:F121E")
		this.addIcon("table-heart:F13C4")
		this.addIcon("table-key:F13C5")
		this.addIcon("table-large:F04F1")
		this.addIcon("table-large-plus:F0F87")
		this.addIcon("table-large-remove:F0F88")
		this.addIcon("table-lock:F13C6")
		this.addIcon("table-merge-cells:F09A6")
		this.addIcon("table-minus:F13C7")
		this.addIcon("table-multiple:F13C8")
		this.addIcon("table-network:F13C9")
		this.addIcon("table-of-contents:F0836")
		this.addIcon("table-off:F13CA")
		this.addIcon("table-picnic:F1743")
		this.addIcon("table-plus:F0A75")
		this.addIcon("table-refresh:F13A0")
		this.addIcon("table-remove:F0A76")
		this.addIcon("table-row:F0837")
		this.addIcon("table-row-height:F04F2")
		this.addIcon("table-row-plus-after:F04F3")
		this.addIcon("table-row-plus-before:F04F4")
		this.addIcon("table-row-remove:F04F5")
		this.addIcon("table-search:F090F")
		this.addIcon("table-settings:F0838")
		this.addIcon("table-split-cell:F142A")
		this.addIcon("table-star:F13CB")
		this.addIcon("table-sync:F13A1")
		this.addIcon("table-tennis:F0E68")
		this.addIcon("tablet:F04F6")
		this.addIcon("tablet-android:F04F7")
		this.addIcon("tablet-cellphone:F09A7")
		this.addIcon("tablet-dashboard:F0ECE")
		this.addIcon("taco:F0762")
		this.addIcon("tag:F04F9")
		this.addIcon("tag-arrow-down:F172B")
		this.addIcon("tag-arrow-down-outline:F172C")
		this.addIcon("tag-arrow-left:F172D")
		this.addIcon("tag-arrow-left-outline:F172E")
		this.addIcon("tag-arrow-right:F172F")
		this.addIcon("tag-arrow-right-outline:F1730")
		this.addIcon("tag-arrow-up:F1731")
		this.addIcon("tag-arrow-up-outline:F1732")
		this.addIcon("tag-faces:F04FA")
		this.addIcon("tag-heart:F068B")
		this.addIcon("tag-heart-outline:F0BCF")
		this.addIcon("tag-minus:F0910")
		this.addIcon("tag-minus-outline:F121F")
		this.addIcon("tag-multiple:F04FB")
		this.addIcon("tag-multiple-outline:F12F7")
		this.addIcon("tag-off:F1220")
		this.addIcon("tag-off-outline:F1221")
		this.addIcon("tag-outline:F04FC")
		this.addIcon("tag-plus:F0722")
		this.addIcon("tag-plus-outline:F1222")
		this.addIcon("tag-remove:F0723")
		this.addIcon("tag-remove-outline:F1223")
		this.addIcon("tag-text:F1224")
		this.addIcon("tag-text-outline:F04FD")
		this.addIcon("tailwind:F13FF")
		this.addIcon("tangram:F04F8")
		this.addIcon("tank:F0D3A")
		this.addIcon("tanker-truck:F0FE5")
		this.addIcon("tape-drive:F16DF")
		this.addIcon("tape-measure:F0B4D")
		this.addIcon("target:F04FE")
		this.addIcon("target-account:F0BD0")
		this.addIcon("target-variant:F0A77")
		this.addIcon("taxi:F04FF")
		this.addIcon("tea:F0D9E")
		this.addIcon("tea-outline:F0D9F")
		this.addIcon("teamviewer:F0500")
		this.addIcon("telescope:F0B4E")
		this.addIcon("television:F0502")
		this.addIcon("television-ambient-light:F1356")
		this.addIcon("television-box:F0839")
		this.addIcon("television-classic:F07F4")
		this.addIcon("television-classic-off:F083A")
		this.addIcon("television-guide:F0503")
		this.addIcon("television-off:F083B")
		this.addIcon("television-pause:F0F89")
		this.addIcon("television-play:F0ECF")
		this.addIcon("television-shimmer:F1110")
		this.addIcon("television-stop:F0F8A")
		this.addIcon("temperature-celsius:F0504")
		this.addIcon("temperature-fahrenheit:F0505")
		this.addIcon("temperature-kelvin:F0506")
		this.addIcon("tennis:F0DA0")
		this.addIcon("tennis-ball:F0507")
		this.addIcon("tent:F0508")
		this.addIcon("terraform:F1062")
		this.addIcon("terrain:F0509")
		this.addIcon("test-tube:F0668")
		this.addIcon("test-tube-empty:F0911")
		this.addIcon("test-tube-off:F0912")
		this.addIcon("text:F09A8")
		this.addIcon("text-account:F1570")
		this.addIcon("text-box:F021A")
		this.addIcon("text-box-check:F0EA6")
		this.addIcon("text-box-check-outline:F0EA7")
		this.addIcon("text-box-minus:F0EA8")
		this.addIcon("text-box-minus-outline:F0EA9")
		this.addIcon("text-box-multiple:F0AB7")
		this.addIcon("text-box-multiple-outline:F0AB8")
		this.addIcon("text-box-outline:F09ED")
		this.addIcon("text-box-plus:F0EAA")
		this.addIcon("text-box-plus-outline:F0EAB")
		this.addIcon("text-box-remove:F0EAC")
		this.addIcon("text-box-remove-outline:F0EAD")
		this.addIcon("text-box-search:F0EAE")
		this.addIcon("text-box-search-outline:F0EAF")
		this.addIcon("text-long:F09AA")
		this.addIcon("text-recognition:F113D")
		this.addIcon("text-search:F13B8")
		this.addIcon("text-shadow:F0669")
		this.addIcon("text-short:F09A9")
		this.addIcon("text-to-speech:F050A")
		this.addIcon("text-to-speech-off:F050B")
		this.addIcon("texture:F050C")
		this.addIcon("texture-box:F0FE6")
		this.addIcon("theater:F050D")
		this.addIcon("theme-light-dark:F050E")
		this.addIcon("thermometer:F050F")
		this.addIcon("thermometer-alert:F0E01")
		this.addIcon("thermometer-chevron-down:F0E02")
		this.addIcon("thermometer-chevron-up:F0E03")
		this.addIcon("thermometer-high:F10C2")
		this.addIcon("thermometer-lines:F0510")
		this.addIcon("thermometer-low:F10C3")
		this.addIcon("thermometer-minus:F0E04")
		this.addIcon("thermometer-off:F1531")
		this.addIcon("thermometer-plus:F0E05")
		this.addIcon("thermostat:F0393")
		this.addIcon("thermostat-box:F0891")
		this.addIcon("thought-bubble:F07F6")
		this.addIcon("thought-bubble-outline:F07F7")
		this.addIcon("thumb-down:F0511")
		this.addIcon("thumb-down-outline:F0512")
		this.addIcon("thumb-up:F0513")
		this.addIcon("thumb-up-outline:F0514")
		this.addIcon("thumbs-up-down:F0515")
		this.addIcon("ticket:F0516")
		this.addIcon("ticket-account:F0517")
		this.addIcon("ticket-confirmation:F0518")
		this.addIcon("ticket-confirmation-outline:F13AA")
		this.addIcon("ticket-outline:F0913")
		this.addIcon("ticket-percent:F0724")
		this.addIcon("ticket-percent-outline:F142B")
		this.addIcon("tie:F0519")
		this.addIcon("tilde:F0725")
		this.addIcon("timelapse:F051A")
		this.addIcon("timeline:F0BD1")
		this.addIcon("timeline-alert:F0F95")
		this.addIcon("timeline-alert-outline:F0F98")
		this.addIcon("timeline-check:F1532")
		this.addIcon("timeline-check-outline:F1533")
		this.addIcon("timeline-clock:F11FB")
		this.addIcon("timeline-clock-outline:F11FC")
		this.addIcon("timeline-help:F0F99")
		this.addIcon("timeline-help-outline:F0F9A")
		this.addIcon("timeline-minus:F1534")
		this.addIcon("timeline-minus-outline:F1535")
		this.addIcon("timeline-outline:F0BD2")
		this.addIcon("timeline-plus:F0F96")
		this.addIcon("timeline-plus-outline:F0F97")
		this.addIcon("timeline-remove:F1536")
		this.addIcon("timeline-remove-outline:F1537")
		this.addIcon("timeline-text:F0BD3")
		this.addIcon("timeline-text-outline:F0BD4")
		this.addIcon("timer:F13AB")
		this.addIcon("timer-10:F051C")
		this.addIcon("timer-3:F051D")
		this.addIcon("timer-off:F13AC")
		this.addIcon("timer-off-outline:F051E")
		this.addIcon("timer-outline:F051B")
		this.addIcon("timer-sand:F051F")
		this.addIcon("timer-sand-empty:F06AD")
		this.addIcon("timer-sand-full:F078C")
		this.addIcon("timetable:F0520")
		this.addIcon("toaster:F1063")
		this.addIcon("toaster-off:F11B7")
		this.addIcon("toaster-oven:F0CD3")
		this.addIcon("toggle-switch:F0521")
		this.addIcon("toggle-switch-off:F0522")
		this.addIcon("toggle-switch-off-outline:F0A19")
		this.addIcon("toggle-switch-outline:F0A1A")
		this.addIcon("toilet:F09AB")
		this.addIcon("toolbox:F09AC")
		this.addIcon("toolbox-outline:F09AD")
		this.addIcon("tools:F1064")
		this.addIcon("tooltip:F0523")
		this.addIcon("tooltip-account:F000C")
		this.addIcon("tooltip-check:F155C")
		this.addIcon("tooltip-check-outline:F155D")
		this.addIcon("tooltip-edit:F0524")
		this.addIcon("tooltip-edit-outline:F12C5")
		this.addIcon("tooltip-image:F0525")
		this.addIcon("tooltip-image-outline:F0BD5")
		this.addIcon("tooltip-minus:F155E")
		this.addIcon("tooltip-minus-outline:F155F")
		this.addIcon("tooltip-outline:F0526")
		this.addIcon("tooltip-plus:F0BD6")
		this.addIcon("tooltip-plus-outline:F0527")
		this.addIcon("tooltip-remove:F1560")
		this.addIcon("tooltip-remove-outline:F1561")
		this.addIcon("tooltip-text:F0528")
		this.addIcon("tooltip-text-outline:F0BD7")
		this.addIcon("tooth:F08C3")
		this.addIcon("tooth-outline:F0529")
		this.addIcon("toothbrush:F1129")
		this.addIcon("toothbrush-electric:F112C")
		this.addIcon("toothbrush-paste:F112A")
		this.addIcon("torch:F1606")
		this.addIcon("tortoise:F0D3B")
		this.addIcon("toslink:F12B8")
		this.addIcon("tournament:F09AE")
		this.addIcon("tow-truck:F083C")
		this.addIcon("tower-beach:F0681")
		this.addIcon("tower-fire:F0682")
		this.addIcon("toy-brick:F1288")
		this.addIcon("toy-brick-marker:F1289")
		this.addIcon("toy-brick-marker-outline:F128A")
		this.addIcon("toy-brick-minus:F128B")
		this.addIcon("toy-brick-minus-outline:F128C")
		this.addIcon("toy-brick-outline:F128D")
		this.addIcon("toy-brick-plus:F128E")
		this.addIcon("toy-brick-plus-outline:F128F")
		this.addIcon("toy-brick-remove:F1290")
		this.addIcon("toy-brick-remove-outline:F1291")
		this.addIcon("toy-brick-search:F1292")
		this.addIcon("toy-brick-search-outline:F1293")
		this.addIcon("track-light:F0914")
		this.addIcon("trackpad:F07F8")
		this.addIcon("trackpad-lock:F0933")
		this.addIcon("tractor:F0892")
		this.addIcon("tractor-variant:F14C4")
		this.addIcon("trademark:F0A78")
		this.addIcon("traffic-cone:F137C")
		this.addIcon("traffic-light:F052B")
		this.addIcon("traffic-light-outline:F182A")
		this.addIcon("train:F052C")
		this.addIcon("train-car:F0BD8")
		this.addIcon("train-car-passenger:F1733")
		this.addIcon("train-car-passenger-door:F1734")
		this.addIcon("train-car-passenger-door-open:F1735")
		this.addIcon("train-car-passenger-variant:F1736")
		this.addIcon("train-variant:F08C4")
		this.addIcon("tram:F052D")
		this.addIcon("tram-side:F0FE7")
		this.addIcon("transcribe:F052E")
		this.addIcon("transcribe-close:F052F")
		this.addIcon("transfer:F1065")
		this.addIcon("transfer-down:F0DA1")
		this.addIcon("transfer-left:F0DA2")
		this.addIcon("transfer-right:F0530")
		this.addIcon("transfer-up:F0DA3")
		this.addIcon("transit-connection:F0D3C")
		this.addIcon("transit-connection-horizontal:F1546")
		this.addIcon("transit-connection-variant:F0D3D")
		this.addIcon("transit-detour:F0F8B")
		this.addIcon("transit-skip:F1515")
		this.addIcon("transit-transfer:F06AE")
		this.addIcon("transition:F0915")
		this.addIcon("transition-masked:F0916")
		this.addIcon("translate:F05CA")
		this.addIcon("translate-off:F0E06")
		this.addIcon("transmission-tower:F0D3E")
		this.addIcon("trash-can:F0A79")
		this.addIcon("trash-can-outline:F0A7A")
		this.addIcon("tray:F1294")
		this.addIcon("tray-alert:F1295")
		this.addIcon("tray-arrow-down:F0120")
		this.addIcon("tray-arrow-up:F011D")
		this.addIcon("tray-full:F1296")
		this.addIcon("tray-minus:F1297")
		this.addIcon("tray-plus:F1298")
		this.addIcon("tray-remove:F1299")
		this.addIcon("treasure-chest:F0726")
		this.addIcon("tree:F0531")
		this.addIcon("tree-outline:F0E69")
		this.addIcon("trello:F0532")
		this.addIcon("trending-down:F0533")
		this.addIcon("trending-neutral:F0534")
		this.addIcon("trending-up:F0535")
		this.addIcon("triangle:F0536")
		this.addIcon("triangle-outline:F0537")
		this.addIcon("triangle-wave:F147C")
		this.addIcon("triforce:F0BD9")
		this.addIcon("trophy:F0538")
		this.addIcon("trophy-award:F0539")
		this.addIcon("trophy-broken:F0DA4")
		this.addIcon("trophy-outline:F053A")
		this.addIcon("trophy-variant:F053B")
		this.addIcon("trophy-variant-outline:F053C")
		this.addIcon("truck:F053D")
		this.addIcon("truck-check:F0CD4")
		this.addIcon("truck-check-outline:F129A")
		this.addIcon("truck-delivery:F053E")
		this.addIcon("truck-delivery-outline:F129B")
		this.addIcon("truck-fast:F0788")
		this.addIcon("truck-fast-outline:F129C")
		this.addIcon("truck-outline:F129D")
		this.addIcon("truck-trailer:F0727")
		this.addIcon("trumpet:F1096")
		this.addIcon("tshirt-crew:F0A7B")
		this.addIcon("tshirt-crew-outline:F053F")
		this.addIcon("tshirt-v:F0A7C")
		this.addIcon("tshirt-v-outline:F0540")
		this.addIcon("tumble-dryer:F0917")
		this.addIcon("tumble-dryer-alert:F11BA")
		this.addIcon("tumble-dryer-off:F11BB")
		this.addIcon("tune:F062E")
		this.addIcon("tune-variant:F1542")
		this.addIcon("tune-vertical:F066A")
		this.addIcon("tune-vertical-variant:F1543")
		this.addIcon("turkey:F171B")
		this.addIcon("turnstile:F0CD5")
		this.addIcon("turnstile-outline:F0CD6")
		this.addIcon("turtle:F0CD7")
		this.addIcon("twitch:F0543")
		this.addIcon("twitter:F0544")
		this.addIcon("two-factor-authentication:F09AF")
		this.addIcon("typewriter:F0F2D")
		this.addIcon("ubisoft:F0BDA")
		this.addIcon("ubuntu:F0548")
		this.addIcon("ufo:F10C4")
		this.addIcon("ufo-outline:F10C5")
		this.addIcon("ultra-high-definition:F07F9")
		this.addIcon("umbraco:F0549")
		this.addIcon("umbrella:F054A")
		this.addIcon("umbrella-closed:F09B0")
		this.addIcon("umbrella-closed-outline:F13E2")
		this.addIcon("umbrella-closed-variant:F13E1")
		this.addIcon("umbrella-outline:F054B")
		this.addIcon("undo:F054C")
		this.addIcon("undo-variant:F054D")
		this.addIcon("unfold-less-horizontal:F054E")
		this.addIcon("unfold-less-vertical:F0760")
		this.addIcon("unfold-more-horizontal:F054F")
		this.addIcon("unfold-more-vertical:F0761")
		this.addIcon("ungroup:F0550")
		this.addIcon("unicode:F0ED0")
		this.addIcon("unicorn:F15C2")
		this.addIcon("unicorn-variant:F15C3")
		this.addIcon("unicycle:F15E5")
		this.addIcon("unity:F06AF")
		this.addIcon("unreal:F09B1")
		this.addIcon("update:F06B0")
		this.addIcon("upload:F0552")
		this.addIcon("upload-lock:F1373")
		this.addIcon("upload-lock-outline:F1374")
		this.addIcon("upload-multiple:F083D")
		this.addIcon("upload-network:F06F6")
		this.addIcon("upload-network-outline:F0CD8")
		this.addIcon("upload-off:F10C6")
		this.addIcon("upload-off-outline:F10C7")
		this.addIcon("upload-outline:F0E07")
		this.addIcon("usb:F0553")
		this.addIcon("usb-flash-drive:F129E")
		this.addIcon("usb-flash-drive-outline:F129F")
		this.addIcon("usb-port:F11F0")
		this.addIcon("valve:F1066")
		this.addIcon("valve-closed:F1067")
		this.addIcon("valve-open:F1068")
		this.addIcon("van-passenger:F07FA")
		this.addIcon("van-utility:F07FB")
		this.addIcon("vanish:F07FC")
		this.addIcon("vanish-quarter:F1554")
		this.addIcon("vanity-light:F11E1")
		this.addIcon("variable:F0AE7")
		this.addIcon("variable-box:F1111")
		this.addIcon("vector-arrange-above:F0554")
		this.addIcon("vector-arrange-below:F0555")
		this.addIcon("vector-bezier:F0AE8")
		this.addIcon("vector-circle:F0556")
		this.addIcon("vector-circle-variant:F0557")
		this.addIcon("vector-combine:F0558")
		this.addIcon("vector-curve:F0559")
		this.addIcon("vector-difference:F055A")
		this.addIcon("vector-difference-ab:F055B")
		this.addIcon("vector-difference-ba:F055C")
		this.addIcon("vector-ellipse:F0893")
		this.addIcon("vector-intersection:F055D")
		this.addIcon("vector-line:F055E")
		this.addIcon("vector-link:F0FE8")
		this.addIcon("vector-point:F055F")
		this.addIcon("vector-polygon:F0560")
		this.addIcon("vector-polyline:F0561")
		this.addIcon("vector-polyline-edit:F1225")
		this.addIcon("vector-polyline-minus:F1226")
		this.addIcon("vector-polyline-plus:F1227")
		this.addIcon("vector-polyline-remove:F1228")
		this.addIcon("vector-radius:F074A")
		this.addIcon("vector-rectangle:F05C6")
		this.addIcon("vector-selection:F0562")
		this.addIcon("vector-square:F0001")
		this.addIcon("vector-triangle:F0563")
		this.addIcon("vector-union:F0564")
		this.addIcon("vhs:F0A1B")
		this.addIcon("vibrate:F0566")
		this.addIcon("vibrate-off:F0CD9")
		this.addIcon("video:F0567")
		this.addIcon("video-3d:F07FD")
		this.addIcon("video-3d-off:F13D9")
		this.addIcon("video-3d-variant:F0ED1")
		this.addIcon("video-4k-box:F083E")
		this.addIcon("video-account:F0919")
		this.addIcon("video-box:F00FD")
		this.addIcon("video-box-off:F00FE")
		this.addIcon("video-check:F1069")
		this.addIcon("video-check-outline:F106A")
		this.addIcon("video-high-definition:F152E")
		this.addIcon("video-image:F091A")
		this.addIcon("video-input-antenna:F083F")
		this.addIcon("video-input-component:F0840")
		this.addIcon("video-input-hdmi:F0841")
		this.addIcon("video-input-scart:F0F8C")
		this.addIcon("video-input-svideo:F0842")
		this.addIcon("video-minus:F09B2")
		this.addIcon("video-minus-outline:F02BA")
		this.addIcon("video-off:F0568")
		this.addIcon("video-off-outline:F0BDB")
		this.addIcon("video-outline:F0BDC")
		this.addIcon("video-plus:F09B3")
		this.addIcon("video-plus-outline:F01D3")
		this.addIcon("video-stabilization:F091B")
		this.addIcon("video-switch:F0569")
		this.addIcon("video-switch-outline:F0790")
		this.addIcon("video-vintage:F0A1C")
		this.addIcon("video-wireless:F0ED2")
		this.addIcon("video-wireless-outline:F0ED3")
		this.addIcon("view-agenda:F056A")
		this.addIcon("view-agenda-outline:F11D8")
		this.addIcon("view-array:F056B")
		this.addIcon("view-array-outline:F1485")
		this.addIcon("view-carousel:F056C")
		this.addIcon("view-carousel-outline:F1486")
		this.addIcon("view-column:F056D")
		this.addIcon("view-column-outline:F1487")
		this.addIcon("view-comfy:F0E6A")
		this.addIcon("view-comfy-outline:F1488")
		this.addIcon("view-compact:F0E6B")
		this.addIcon("view-compact-outline:F0E6C")
		this.addIcon("view-dashboard:F056E")
		this.addIcon("view-dashboard-outline:F0A1D")
		this.addIcon("view-dashboard-variant:F0843")
		this.addIcon("view-dashboard-variant-outline:F1489")
		this.addIcon("view-day:F056F")
		this.addIcon("view-day-outline:F148A")
		this.addIcon("view-grid:F0570")
		this.addIcon("view-grid-outline:F11D9")
		this.addIcon("view-grid-plus:F0F8D")
		this.addIcon("view-grid-plus-outline:F11DA")
		this.addIcon("view-headline:F0571")
		this.addIcon("view-list:F0572")
		this.addIcon("view-list-outline:F148B")
		this.addIcon("view-module:F0573")
		this.addIcon("view-module-outline:F148C")
		this.addIcon("view-parallel:F0728")
		this.addIcon("view-parallel-outline:F148D")
		this.addIcon("view-quilt:F0574")
		this.addIcon("view-quilt-outline:F148E")
		this.addIcon("view-sequential:F0729")
		this.addIcon("view-sequential-outline:F148F")
		this.addIcon("view-split-horizontal:F0BCB")
		this.addIcon("view-split-vertical:F0BCC")
		this.addIcon("view-stream:F0575")
		this.addIcon("view-stream-outline:F1490")
		this.addIcon("view-week:F0576")
		this.addIcon("view-week-outline:F1491")
		this.addIcon("vimeo:F0577")
		this.addIcon("violin:F060F")
		this.addIcon("virtual-reality:F0894")
		this.addIcon("virus:F13B6")
		this.addIcon("virus-outline:F13B7")
		this.addIcon("vlc:F057C")
		this.addIcon("voicemail:F057D")
		this.addIcon("volleyball:F09B4")
		this.addIcon("volume-high:F057E")
		this.addIcon("volume-low:F057F")
		this.addIcon("volume-medium:F0580")
		this.addIcon("volume-minus:F075E")
		this.addIcon("volume-mute:F075F")
		this.addIcon("volume-off:F0581")
		this.addIcon("volume-plus:F075D")
		this.addIcon("volume-source:F1120")
		this.addIcon("volume-variant-off:F0E08")
		this.addIcon("volume-vibrate:F1121")
		this.addIcon("vote:F0A1F")
		this.addIcon("vote-outline:F0A20")
		this.addIcon("vpn:F0582")
		this.addIcon("vuejs:F0844")
		this.addIcon("vuetify:F0E6D")
		this.addIcon("walk:F0583")
		this.addIcon("wall:F07FE")
		this.addIcon("wall-sconce:F091C")
		this.addIcon("wall-sconce-flat:F091D")
		this.addIcon("wall-sconce-flat-outline:F17C9")
		this.addIcon("wall-sconce-flat-variant:F041C")
		this.addIcon("wall-sconce-flat-variant-outline:F17CA")
		this.addIcon("wall-sconce-outline:F17CB")
		this.addIcon("wall-sconce-round:F0748")
		this.addIcon("wall-sconce-round-outline:F17CC")
		this.addIcon("wall-sconce-round-variant:F091E")
		this.addIcon("wall-sconce-round-variant-outline:F17CD")
		this.addIcon("wallet:F0584")
		this.addIcon("wallet-giftcard:F0585")
		this.addIcon("wallet-membership:F0586")
		this.addIcon("wallet-outline:F0BDD")
		this.addIcon("wallet-plus:F0F8E")
		this.addIcon("wallet-plus-outline:F0F8F")
		this.addIcon("wallet-travel:F0587")
		this.addIcon("wallpaper:F0E09")
		this.addIcon("wan:F0588")
		this.addIcon("wardrobe:F0F90")
		this.addIcon("wardrobe-outline:F0F91")
		this.addIcon("warehouse:F0F81")
		this.addIcon("washing-machine:F072A")
		this.addIcon("washing-machine-alert:F11BC")
		this.addIcon("washing-machine-off:F11BD")
		this.addIcon("watch:F0589")
		this.addIcon("watch-export:F058A")
		this.addIcon("watch-export-variant:F0895")
		this.addIcon("watch-import:F058B")
		this.addIcon("watch-import-variant:F0896")
		this.addIcon("watch-variant:F0897")
		this.addIcon("watch-vibrate:F06B1")
		this.addIcon("watch-vibrate-off:F0CDA")
		this.addIcon("water:F058C")
		this.addIcon("water-alert:F1502")
		this.addIcon("water-alert-outline:F1503")
		this.addIcon("water-boiler:F0F92")
		this.addIcon("water-boiler-alert:F11B3")
		this.addIcon("water-boiler-off:F11B4")
		this.addIcon("water-check:F1504")
		this.addIcon("water-check-outline:F1505")
		this.addIcon("water-circle:F1806")
		this.addIcon("water-minus:F1506")
		this.addIcon("water-minus-outline:F1507")
		this.addIcon("water-off:F058D")
		this.addIcon("water-off-outline:F1508")
		this.addIcon("water-outline:F0E0A")
		this.addIcon("water-percent:F058E")
		this.addIcon("water-percent-alert:F1509")
		this.addIcon("water-plus:F150A")
		this.addIcon("water-plus-outline:F150B")
		this.addIcon("water-polo:F12A0")
		this.addIcon("water-pump:F058F")
		this.addIcon("water-pump-off:F0F93")
		this.addIcon("water-remove:F150C")
		this.addIcon("water-remove-outline:F150D")
		this.addIcon("water-sync:F17C6")
		this.addIcon("water-well:F106B")
		this.addIcon("water-well-outline:F106C")
		this.addIcon("watering-can:F1481")
		this.addIcon("watering-can-outline:F1482")
		this.addIcon("watermark:F0612")
		this.addIcon("wave:F0F2E")
		this.addIcon("waveform:F147D")
		this.addIcon("waves:F078D")
		this.addIcon("waze:F0BDE")
		this.addIcon("weather-cloudy:F0590")
		this.addIcon("weather-cloudy-alert:F0F2F")
		this.addIcon("weather-cloudy-arrow-right:F0E6E")
		this.addIcon("weather-fog:F0591")
		this.addIcon("weather-hail:F0592")
		this.addIcon("weather-hazy:F0F30")
		this.addIcon("weather-hurricane:F0898")
		this.addIcon("weather-lightning:F0593")
		this.addIcon("weather-lightning-rainy:F067E")
		this.addIcon("weather-night:F0594")
		this.addIcon("weather-night-partly-cloudy:F0F31")
		this.addIcon("weather-partly-cloudy:F0595")
		this.addIcon("weather-partly-lightning:F0F32")
		this.addIcon("weather-partly-rainy:F0F33")
		this.addIcon("weather-partly-snowy:F0F34")
		this.addIcon("weather-partly-snowy-rainy:F0F35")
		this.addIcon("weather-pouring:F0596")
		this.addIcon("weather-rainy:F0597")
		this.addIcon("weather-snowy:F0598")
		this.addIcon("weather-snowy-heavy:F0F36")
		this.addIcon("weather-snowy-rainy:F067F")
		this.addIcon("weather-sunny:F0599")
		this.addIcon("weather-sunny-alert:F0F37")
		this.addIcon("weather-sunny-off:F14E4")
		this.addIcon("weather-sunset:F059A")
		this.addIcon("weather-sunset-down:F059B")
		this.addIcon("weather-sunset-up:F059C")
		this.addIcon("weather-tornado:F0F38")
		this.addIcon("weather-windy:F059D")
		this.addIcon("weather-windy-variant:F059E")
		this.addIcon("web:F059F")
		this.addIcon("web-box:F0F94")
		this.addIcon("web-cancel:F1790")
		this.addIcon("web-check:F0789")
		this.addIcon("web-clock:F124A")
		this.addIcon("web-minus:F10A0")
		this.addIcon("web-off:F0A8E")
		this.addIcon("web-plus:F0033")
		this.addIcon("web-refresh:F1791")
		this.addIcon("web-remove:F0551")
		this.addIcon("web-sync:F1792")
		this.addIcon("webcam:F05A0")
		this.addIcon("webcam-off:F1737")
		this.addIcon("webhook:F062F")
		this.addIcon("webpack:F072B")
		this.addIcon("webrtc:F1248")
		this.addIcon("wechat:F0611")
		this.addIcon("weight:F05A1")
		this.addIcon("weight-gram:F0D3F")
		this.addIcon("weight-kilogram:F05A2")
		this.addIcon("weight-lifter:F115D")
		this.addIcon("weight-pound:F09B5")
		this.addIcon("whatsapp:F05A3")
		this.addIcon("wheel-barrow:F14F2")
		this.addIcon("wheelchair-accessibility:F05A4")
		this.addIcon("whistle:F09B6")
		this.addIcon("whistle-outline:F12BC")
		this.addIcon("white-balance-auto:F05A5")
		this.addIcon("white-balance-incandescent:F05A6")
		this.addIcon("white-balance-iridescent:F05A7")
		this.addIcon("white-balance-sunny:F05A8")
		this.addIcon("widgets:F072C")
		this.addIcon("widgets-outline:F1355")
		this.addIcon("wifi:F05A9")
		this.addIcon("wifi-alert:F16B5")
		this.addIcon("wifi-arrow-down:F16B6")
		this.addIcon("wifi-arrow-left:F16B7")
		this.addIcon("wifi-arrow-left-right:F16B8")
		this.addIcon("wifi-arrow-right:F16B9")
		this.addIcon("wifi-arrow-up:F16BA")
		this.addIcon("wifi-arrow-up-down:F16BB")
		this.addIcon("wifi-cancel:F16BC")
		this.addIcon("wifi-check:F16BD")
		this.addIcon("wifi-cog:F16BE")
		this.addIcon("wifi-lock:F16BF")
		this.addIcon("wifi-lock-open:F16C0")
		this.addIcon("wifi-marker:F16C1")
		this.addIcon("wifi-minus:F16C2")
		this.addIcon("wifi-off:F05AA")
		this.addIcon("wifi-plus:F16C3")
		this.addIcon("wifi-refresh:F16C4")
		this.addIcon("wifi-remove:F16C5")
		this.addIcon("wifi-settings:F16C6")
		this.addIcon("wifi-star:F0E0B")
		this.addIcon("wifi-strength-1:F091F")
		this.addIcon("wifi-strength-1-alert:F0920")
		this.addIcon("wifi-strength-1-lock:F0921")
		this.addIcon("wifi-strength-1-lock-open:F16CB")
		this.addIcon("wifi-strength-2:F0922")
		this.addIcon("wifi-strength-2-alert:F0923")
		this.addIcon("wifi-strength-2-lock:F0924")
		this.addIcon("wifi-strength-2-lock-open:F16CC")
		this.addIcon("wifi-strength-3:F0925")
		this.addIcon("wifi-strength-3-alert:F0926")
		this.addIcon("wifi-strength-3-lock:F0927")
		this.addIcon("wifi-strength-3-lock-open:F16CD")
		this.addIcon("wifi-strength-4:F0928")
		this.addIcon("wifi-strength-4-alert:F0929")
		this.addIcon("wifi-strength-4-lock:F092A")
		this.addIcon("wifi-strength-4-lock-open:F16CE")
		this.addIcon("wifi-strength-alert-outline:F092B")
		this.addIcon("wifi-strength-lock-open-outline:F16CF")
		this.addIcon("wifi-strength-lock-outline:F092C")
		this.addIcon("wifi-strength-off:F092D")
		this.addIcon("wifi-strength-off-outline:F092E")
		this.addIcon("wifi-strength-outline:F092F")
		this.addIcon("wifi-sync:F16C7")
		this.addIcon("wikipedia:F05AC")
		this.addIcon("wind-turbine:F0DA5")
		this.addIcon("window-close:F05AD")
		this.addIcon("window-closed:F05AE")
		this.addIcon("window-closed-variant:F11DB")
		this.addIcon("window-maximize:F05AF")
		this.addIcon("window-minimize:F05B0")
		this.addIcon("window-open:F05B1")
		this.addIcon("window-open-variant:F11DC")
		this.addIcon("window-restore:F05B2")
		this.addIcon("window-shutter:F111C")
		this.addIcon("window-shutter-alert:F111D")
		this.addIcon("window-shutter-open:F111E")
		this.addIcon("windsock:F15FA")
		this.addIcon("wiper:F0AE9")
		this.addIcon("wiper-wash:F0DA6")
		this.addIcon("wizard-hat:F1477")
		this.addIcon("wordpress:F05B4")
		this.addIcon("wrap:F05B6")
		this.addIcon("wrap-disabled:F0BDF")
		this.addIcon("wrench:F05B7")
		this.addIcon("wrench-outline:F0BE0")
		this.addIcon("xamarin:F0845")
		this.addIcon("xml:F05C0")
		this.addIcon("xmpp:F07FF")
		this.addIcon("yahoo:F0B4F")
		this.addIcon("yeast:F05C1")
		this.addIcon("yin-yang:F0680")
		this.addIcon("yoga:F117C")
		this.addIcon("youtube:F05C3")
		this.addIcon("youtube-gaming:F0848")
		this.addIcon("youtube-studio:F0847")
		this.addIcon("youtube-subscription:F0D40")
		this.addIcon("youtube-tv:F0448")
		this.addIcon("yurt:F1516")
		this.addIcon("z-wave:F0AEA")
		this.addIcon("zend:F0AEB")
		this.addIcon("zigbee:F0D41")
		this.addIcon("zip-box:F05C4")
		this.addIcon("zip-box-outline:F0FFA")
		this.addIcon("zip-disk:F0A23")
		this.addIcon("zodiac-aquarius:F0A7D")
		this.addIcon("zodiac-aries:F0A7E")
		this.addIcon("zodiac-cancer:F0A7F")
		this.addIcon("zodiac-capricorn:F0A80")
		this.addIcon("zodiac-gemini:F0A81")
		this.addIcon("zodiac-leo:F0A82")
		this.addIcon("zodiac-libra:F0A83")
		this.addIcon("zodiac-pisces:F0A84")
		this.addIcon("zodiac-sagittarius:F0A85")
		this.addIcon("zodiac-scorpio:F0A86")
		this.addIcon("zodiac-taurus:F0A87")
		this.addIcon("zodiac-virgo:F0A88")		
    }

}