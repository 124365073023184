import {Injectable} from '@angular/core';

import { OpeningHours } from '@libraries/data-commons/opening-hours/openinghours.class'

@Injectable()
export class DomainOpeningHoursService {

    private _openingHours: any[] = [] 

    constructor() {
    }


    openingHours(id: string) {

    }

    addOpeningHoursRec(rec: any) {
        this._openingHours.push(rec)
    }

    private idExists(id: string): boolean {
        let exists: boolean = false
        for(let i=0;i<this._openingHours.length;i++) {
            if(this._openingHours[i]["id"] == id) {
                exists = true
            }
        }
        return exists
    }

    private getIndex(id: string): number {
        let idx: number = -1
        for(let i=0;i<this._openingHours.length;i++) {
            if(this._openingHours[i]["id"] == id) {
                idx = i
            }
        }
        return idx
    }

    private getRecord(id: string): any {
        let rec: any = {}
        for(let i=0;i<this._openingHours.length;i++) {
            if(this._openingHours[i]["id"] == id) {
                rec = this._openingHours[i]
            }
        }
        return rec
    }    

    
}


