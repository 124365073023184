import {Injectable} from '@angular/core'
import { HttpClient } from  '@angular/common/http'
import { Observable, Subject, ReplaySubject, from, of, range, timer, interval  } from 'rxjs'
import {takeWhile, take } from "rxjs/operators"
import { map, filter, switchMap } from 'rxjs/operators'


import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { UILayout } from '@coreui/services//ui.layout.service'
import { TypographyModel } from '@coreui/services/typography.service';

import { NavigationBarSettings } from '@coreui/classes/navigation/navigation.bar.class'
import { NavigationRailSettings } from '@coreui/classes/navigation/navigation.rail.class'
import { NavigationDrawerSettings } from '@coreui/classes/navigation/navigation.drawer.class'
import { NavigationDrawerMenu } from '@coreui/classes/navigation/navigation.drawer.menu.class'

import { SkyAISessionService } from '@libraries/skyai-api/api/skyai.session.class'
import { SkyAIDOMService } from '@libraries/skyai-api/api/skyai.dom.class'
                          

@Injectable()
export class ApplicationService  {

  private uiNavigation: UINavigation
  private uiColorModel: UIColorModel
  private uiLayout: UILayout
	private typographyModel: TypographyModel
	private httpClient: HttpClient


  private _mainNavigationBar: NavigationBarSettings = new NavigationBarSettings()
  private _mainNavigationRail: NavigationRailSettings = new NavigationRailSettings()
  private _mainNavigationDrawer: NavigationDrawerSettings = new NavigationDrawerSettings()
  private _mainNavigationDrawerMenu: NavigationDrawerMenu = new NavigationDrawerMenu()

  private skyAISessionService: SkyAISessionService
  private skyAIDOMService: SkyAIDOMService

  constructor(_http: HttpClient,
    private _uiNavigation: UINavigation, 
    private _uiColorModel: UIColorModel,
    private _uiLayout: UILayout,
    private _typographyModel: TypographyModel) {

    this.httpClient = _http
    this.uiNavigation = _uiNavigation
    this.uiColorModel = _uiColorModel
    this.uiLayout = _uiLayout
    this.typographyModel = _typographyModel

    this.settings()

    this.skyAISessionService = new SkyAISessionService(this.httpClient)
    this.skyAIDOMService = new SkyAIDOMService(this.httpClient)
   
    this.skyAISessionService.init("https://pg8.in/pm/v1", "skyai_root.FVsaDg1rQEIAcRpqQEukPkqxjeOmPb8NeJgn9Sef")
    this.skyAIDOMService.init("https://pg8.in/pm/v1", "skyai_root.FVsaDg1rQEIAcRpqQEukPkqxjeOmPb8NeJgn9Sef")
  }

  darkMode() {
    this.uiColorModel.darkScheme()
  }
  lightMode() {
    this.uiColorModel.lightScheme()
  }


  public navigationRail(): NavigationRailSettings {
    return this._mainNavigationRail
  }
 
  public navigationBar(): NavigationBarSettings {
    return this._mainNavigationBar
  }

  public navigationDrawer(): NavigationDrawerSettings {
    return this._mainNavigationDrawer
  }

  public navigationDrawerMenu(): NavigationDrawerMenu {
    return this._mainNavigationDrawerMenu
  }

  public get session(): SkyAISessionService {
    return this.skyAISessionService
  }
  public set session(value: SkyAISessionService) {
     this.skyAISessionService = value
  }
  public get dom(): SkyAIDOMService {
    return this.skyAIDOMService
  }
  public set dom(value: SkyAIDOMService) {
     this.skyAIDOMService = value
  }

  private settings() {

    this.typographyModel.uiFontFamily = "Manrope"

    this.uiColorModel.generateM3ColorScheme("F17E27", "6CBDB8", "fee715" , "605D62", "605D66", "b3261e")
//    this.uiColorModel.generateColorScheme("BB2649", "0CF0BB", "fee715" , "605D62", "605D66", "b3261e")
    this.uiColorModel.lightScheme()

    this.uiLayout.applicationContainerSettings().compactNavigationComponent = "top"
    this.uiLayout.applicationContainerSettings().mediumNavigationComponent = "rail"
    this.uiLayout.applicationContainerSettings().expandedNavigationComponent = "rail"
		this.uiLayout.showInnerHeader = false


    this._mainNavigationRail = this.defineNavigationRail()

    this.defineNavigationDrawerMenu()
    
/*    this._mainNavigationBar.addDestination("home", "Home", "home")
    this._mainNavigationBar.addDestination("style", "Style", "palette")
    this._mainNavigationBar.addDestination("apps", "Components", "apps")
    this._mainNavigationBar.addDestination("charts", "Charts", "chart-box-outline")
    this._mainNavigationBar.addDestination("travel", "Travel", "airplane-takeoff")
*/
    

    this._mainNavigationDrawer.addDestination("home", "Home", "home", "home-outline")
    this._mainNavigationDrawer.addDestination("templates.article.m3", "Article Page", "book-account", "book-account-outline")
    this._mainNavigationDrawer.addDestination("templates.article.m3", "Article Page", "book-account", "book-account-outline")
    this._mainNavigationDrawer.addDestination("templates.technicaldoc.01", "Favourites", "heart",  "heart-outline")
    this._mainNavigationDrawer.addDestination("patterns", "Trash", "delete", "delete-outline")
    this._mainNavigationDrawer.addDestination("icons", "Icons", "apps", "apps")
    
  }

  private defineNavigationDrawerMenu() {
    this._mainNavigationDrawerMenu.addDestination("home", "Home", "home", "home-outline")
    this._mainNavigationDrawerMenu.addDestination("article", "Article", "book-account", "book-account-outline")
    this._mainNavigationDrawerMenu.addDestination("article.m3", "Article Page", "book-account", "book-account-outline")
    this._mainNavigationDrawerMenu.addDestination("templates.technicaldoc.01", "Favourites", "heart",  "heart-outline")
    this._mainNavigationDrawerMenu.addDestination("patterns", "Trash", "delete", "delete-outline")
    this._mainNavigationDrawerMenu.addDestination("icons", "Icons", "apps", "apps")
  }


  private defineNavigationRail(): NavigationRailSettings {
    let navrail: NavigationRailSettings = new NavigationRailSettings()
    navrail.showMenu = true
    navrail.addDestination("home", "Home", "home", "home-outline")
    navrail.addDestination("getstarted", "Get Started", "rocket-launch", "rocket-launch-outline")
    navrail.addDestination("layout", "Layout", "page-layout-body", "page-layout-body")

    navrail.addDestination("styles", "Style", "palette", "palette-outline")
    navrail.addDestination("styles.color", "Color", "", "")
    navrail.addDestination("styles.typography", "Typograpphy", "", "")
    navrail.addDestination("styles.icons", "Icons", "", "")
    navrail.addDestination("styles.maps", "Maps", "", "")

    navrail.addDestination("templates", "Templates", "view-dashboard", "view-dashboard-outline")
    navrail.addDestination("templates.landing", "Landing Pages", "", "")
    navrail.addDestination("templates.landing.landing01", "Landing 01", "", "")
    navrail.addDestination("templates.article", "Article", "", "")
    navrail.addDestination("templates.article.blog01", "Blog Article Page", "", "")
    navrail.addDestination("templates.article.m3", "M3 Style", "", "")
    navrail.addDestination("templates.technicaldoc", "Technical", "", "")
    navrail.addDestination("templates.technicaldoc.01", "Technical Document 01", "", "")

    navrail.addDestination("templates.saas", "SaaS Layouts", "", "")
    navrail.addDestination("templates.listdetail", "List-Detail Layouts", "", "")
    navrail.addDestination("templates.listdetail.wireframe", "List Detail Wireframe", "", "")
    navrail.addDestination("templates.listdetail.colors", "Example - Colors", "", "")
    navrail.addDestination("templates.feed", "Feed Layouts", "", "")


    navrail.addDestination("collections", "Collections", "view-grid", "view-grid-outline")
    navrail.addDestination("collections.pictures", "Images", "", "")
    navrail.addDestination("collections.pictures.grid", "Image Grid", "", "")
    navrail.addDestination("collections.pictures.manager", "Images Manager", "", "")
    navrail.addDestination("collections.pictures.carousel", "Carousel", "", "")
    navrail.addDestination("collections.pictures.upload", "Image Upload", "", "")
    navrail.addDestination("collections.colors", "Colors", "", "")
    navrail.addDestination("collections.gradients", "Gradients", "", "")

    return navrail
  }


  private defineAdminNavigationRail(): NavigationRailSettings {
    let navrail: NavigationRailSettings = new NavigationRailSettings()
    navrail.showMenu = true
    navrail.addDestination("home", "Home", "home", "home-outline")
    navrail.addDestination("auth", "Authentication", "account-box-multiple", "account-box-multiple-outline")
    navrail.addDestination("auth.sessions", "Sessions", "", "")
    navrail.addDestination("auth.usermanagement", "User Management", "", "")
    navrail.addDestination("image-management", "Image Management", "image-multiple", "image-multiple-outline")
    navrail.addDestination("settings", "Settings", "cog", "cog-outline")
    navrail.addDestination("collections", "Collections", "view-grid", "view-grid-outline")
    navrail.addDestination("collections.pictures", "Images", "", "")
    navrail.addDestination("collections.pictures.grid", "Image Grid", "", "")
    navrail.addDestination("collections.pictures.manager", "Images Manager", "", "")
    navrail.addDestination("collections.pictures.carousel", "Carousel", "", "")
    navrail.addDestination("collections.pictures.upload", "Image Upload", "", "")
    navrail.addDestination("collections.colors", "Colors", "", "")
    navrail.addDestination("collections.gradients", "Gradients", "", "")
    return navrail
  }


  public dashboardMode() {
    this._mainNavigationRail = this.defineAdminNavigationRail()
  }



  visitLog() {
		this.skyAISessionService.visitlog("test").subscribe(
      value => {
				console.log(JSON.stringify(value, null, 4))
      },
      err => {
        console.log(err)
        console.log("Error contacting server")
    })

  }
}



