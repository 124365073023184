import { Airport } from '@libraries/data-commons/places/place.airport.class'

export class AirportsData {

    private _data: Airport[] = []

    constructor() {
    }

    public get data(): any[] {
        return this._data
    }
    public set data(value: any[]) {
        this._data = value
    }

    addAirport(id: string, name: string, iata: string, icao: string, city: string, country: string, 
        ccode: string, continent: string, contcode: string, lat: number, lon: number,
        tz: string, tzoffset: number) {
        let airport = new Airport(id, iata, name)
        airport.icao = icao
        airport.location.city = city
        airport.location.country = country
        airport.location.countryCode2 = ccode
        airport.location.countryCode2 = ccode
        airport.location.continent = continent
        airport.location.continentCode = contcode
        airport.location.latitude = lat
        airport.location.longitude = lon
        airport.location.timezone = tz
        airport.location.timezoneUTCOffset = tzoffset
        this.data.push(airport)
    }

    addAirportLocation(id: string,city: string, country: string, 
        ccode: string, continent: string, contcode: string, lat: number, lon: number) {
        let airport = this.getAirport(id)
        airport.location.city = city
        airport.location.country = country
        airport.location.countryCode2 = ccode
        airport.location.countryCode2 = ccode
        airport.location.continent = continent
        airport.location.continentCode = contcode
        airport.location.latitude = lat
        airport.location.longitude = lon
        this.updateAirport(airport)
    }

    addAirportTimezone(id: string, tz: string, tzoffset: number) {
        let airport = this.getAirport(id)
        airport.location.timezone = tz
        airport.location.timezoneUTCOffset = tzoffset
        this.updateAirport(airport)
    }

    public getAirport(iata: string): any {
        let airport: any = {}
        for(let i=0;i<this._data.length;i++) {
           if(this._data[i]["iata"] == iata) {
             airport = this._data[i]
           }
        } 
        return airport
    }

    public removeAirport(id: string) {
        let idx = this.recordIndex(id)
        if(idx != -1) {
            this._data.splice(idx, 1)
        }
    }

    public updateAirport(newAirport: Airport) {
        let idx = this.recordIndex(newAirport.id)
        if(idx != -1) {
            this._data[idx] = newAirport
        }
    }

    public airportExists(id: string): boolean {
        let exists: boolean = false
        for(let i=0;i<this._data.length;i++) {
            if(this._data[i]["id"] == id) {
                exists = true
            }
        } 
        return exists
    }

    private recordIndex(id: string): number {
        let idx: number = -1
        for(let i=0;i<this._data.length;i++) {
            if(this._data[i]["id"] == id) {
            idx = i
            }
        } 
        return idx
    }
  
}
   