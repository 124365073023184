import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  HostListener,
  ChangeDetectionStrategy,
} from '@angular/core';

import { EventsService } from '../../../services/event.service';
import { UIColorModel } from '../../../services/ui.color.model.service';
import { NavigationDrawerSettings } from '../../../classes/navigation/navigation.drawer.class';

@Component({
  selector: 'navigation-drawer',
  inputs: ['data'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `

    <div
      class="_navigation-drawer" surfaceContainerLow>
      <div class="headline" font-family onSurface>Heading</div>
      <ul class="menu-container">
        <menu-item-w-icon *ngFor="let dat of this._data.destinations" 
          [id]=dat.id
          [icon]=dat.icon
          [label]=dat.label
          [active]=dat.isActive
          (clicked)="itemClicked(dat.id)">
        </menu-item-w-icon>
      </ul>

    </div>
  `,
})
export class NavigationDrawerComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set data(value: NavigationDrawerSettings) {
    this.setData(value);
  }

  private changeDetectorRef: ChangeDetectorRef;
  private eventsService: EventsService;
  uiColorModel: UIColorModel;
  _surfaceColor: string = '';
  _cssShowHide: string = 'hide';
  _cssShadowClass: string = '';
  _cssAnimationClass: string = '';
  _borderColor: string = 'none';
  _data: NavigationDrawerSettings = new NavigationDrawerSettings();

  subMenu: any = []
  activeParentId: string = ""
  subMenuItemHighlightColor: string = ""

  constructor(
    public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
    private _uiColorModel: UIColorModel
  ) {
    this.changeDetectorRef = ref;
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
  }

  setData(value: NavigationDrawerSettings) {
    this._data = value;
    this.changeDetectorRef.detectChanges();
  }

  itemClicked(id: string) {
    let rec = {
      parent: null,
      id: id
    }
    this.clicked.emit(rec);
  }
  

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
