import { Place } from './place.class'

export abstract class TravelServicePoint extends Place {


    private _serviceType: string = ""

    constructor(id: string, type: string) {
      super(id)
      this._serviceType = type
    }
  
    public get serviceType(): string {
        return this._serviceType
    }
    public set serviceType(value: string) {
        this._serviceType = value
    }

  }
  