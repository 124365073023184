import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";

import { EventsService } from "@coreui//services/event.service";
import { UIColorModel } from "@coreui/services/ui.color.model.service";

@Component({
  selector: "card",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  inputs: ['height', 'noradius'],
  template: `

<div class="_card">
    <div class="_card-media" elevated [elevation]=1>
          <div class="media" [style.height.px]="_cardHeight">
          </div>
          <div class="_card-content" secondaryContainer>
          </div>
          <div class="_card-actions" tertiaryContainer>
            <ng-content select="[actions]"></ng-content>
          </div>
    </div>    
</div>


  `,
})
 
export class CardContainer {
  @Input()
  set height(value: number) {
    this.setHeight(value);
  }
  set noradius(value: boolean) {
    this.setNoRadius(value);
  }

  private eventsService: EventsService;
  private changeDetectorRef: ChangeDetectorRef;
  uiColorModel: UIColorModel;

  _cardHeight: number = 1
  _noRadiusCSS: string = ""


  constructor(
    public ref: ChangeDetectorRef,
    _eventsService: EventsService,
    private _uiColorModel: UIColorModel
  ) {
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.changeDetectorRef = ref;

    let self = this;
    this.eventsService.on("onRefresh", function (args: any) {
    });
  }
  setHeight(value: number) {
    this._cardHeight = value
  }

  setNoRadius(value: boolean) {
    if(value) {
      this._noRadiusCSS = "no-radius"
    } else {
      this._noRadiusCSS = ""
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {}
}

/*
    <div class="_card {{_noRadiusCSS}}" surfaceContainerLow 
        elevated [elevation]=1>
        <ng-content select="[media]"></ng-content>
				<ng-content select="[content]"></ng-content>
				<ng-content select="[actions]"></ng-content>
    </div>


*/

