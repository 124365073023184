import { DynamicCloudsBackground } from './dynamicclouds.class';


export class DynamicCloudsBackgroundControl {

  private items:              any[] = []
  private activeItemIndex:    number = -1

  constructor() {
    this.presets()
  }

  public add(_id: string, _backgroundcolor: string, _color1: string, _color2: string, _interval: number,
              _numclouds: number, _speed: number, _opacity: number): void {
    let background = new DynamicCloudsBackground(_id, _backgroundcolor, _color1, _color2, _interval, _numclouds, _speed, _opacity)
    let record = background.getRecord()
    this.items.push(record)
  }

  public presets(): void {
    this.add("alien_sky","3D5AFE", "64FFDA", "FF4081", 15000, 15, 5000, 1)
    this.add("galaxy_aurora","000044", "64FFDA", "FF4081", 15000, 15, 2000, 0.1)
    this.add("grey_skies","E3F2FD", "E0E0E0", "FFFFF", 15000, 15, 2000, 1)
    this.add("byss_by_night","121212", "1A237E", "607D8B", 5000, 3, 2000, 0.5)

    this.add("ocean_abyss","000000", "0c7cdf", "003C5F", 30000, 1, 30000, 1)

  }


  public getActiveBackground(): any {
    let background = null
    let rec = this.items[this.activeItemIndex]
    return rec
  }

  public setActiveBackground(_id: string): void {
    this.activeItemIndex = this.getItemIndex(_id)
  }


  public getPreset(_id: string): any {
    let preset = null
    let i = 0
    for(i=0;i<this.items.length;i++) {
      if(this.items[i]["id"] == _id) {
        preset = this.items[i]
      }
    }
    return preset
  }


  public presetExists(_id: string): boolean {
    let exists = false
    let i = 0
    for(i=0;i<this.items.length;i++) {
      if(this.items[i]["id"] == _id) {
        exists = true
      }
    }
    return exists
  }


  public getPresetRecord(_id: string): any {
    let preset = null
    let i = 0
    for(i=0;i<this.items.length;i++) {
      if(this.items[i]["id"] == _id) {
        preset = this.items[i].getRecrod()
      }
    }
    return preset
  }


  public getItemIndex(_id: string): number {
    let idx = -1
    let i = 0
    for(i=0;i<this.items.length;i++) {
      if(this.items[i]["id"] == _id) {
        idx = i
      }
    }
    return idx
  }
}
