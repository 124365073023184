import { Injectable } from '@angular/core';

import { UISpacingUtils } from "../layout/grids/ui.spacig.utils.class"
import { ApplicationContainerSettings } from '../classes/control/application.container.settings'

@Injectable()
export class UILayout {


    private uiLayoutUtils = new UISpacingUtils()
    private _applicationContainerSettings = new ApplicationContainerSettings()
    private windowClasses: string[] = ["compact", "medium", "expanded"]
    private _windowClass: string = "expanded";
    private _windowMargin: number = 24;

    private _ignoreWindowMargins: boolean = false

    private _showRightSliderSheet: boolean = false;
    private _showInnerHeader: boolean = false;


    
    constructor() {
    }


    public detectDeviceWindowClass(deviceScreenWidth: number) {
      this._windowClass = this.uiLayoutUtils.detectWindowClass(deviceScreenWidth)
    }

    public detectDeviceWindowMargins() {
      if(this._ignoreWindowMargins == false)
        this.windowMargin = this.uiLayoutUtils.selectWindowMargin(this._windowClass)
    }

    public ignoreWindowMargins(value: boolean) {
      this._ignoreWindowMargins = value
      if(value == false) {
        this.detectDeviceWindowMargins()
      } else {
        this.windowMargin = 0
      }
    }


    public detectInnerWindowClass(width: number): string {
      let utils = new UISpacingUtils()
      return utils.detectWindowClass(width)
    }


    public applicationContainerSettings(): ApplicationContainerSettings {
      return this._applicationContainerSettings
    }

    public get windowClass(): string {
      return this._windowClass;
    }
    public set windowClass(value: string) {
      this._windowClass = value;
    }
    public get windowMargin(): number {
      return this._windowMargin;
    }
    public set windowMargin(value: number) {
      this._windowMargin = value;
    }
    public get showRightSliderSheet(): boolean {
      return this._showRightSliderSheet;
    }
    public set showRightSliderSheet(value: boolean) {
      this._showRightSliderSheet = value;
    }
    public get showInnerHeader(): boolean {
      return this._showInnerHeader;
    }
    public set showInnerHeader(value: boolean) {
      this._showInnerHeader = value;
    }
     
}
