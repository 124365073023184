import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	ChangeDetectionStrategy
} from '@angular/core';


@Component({
	selector: 		'background-sunrise',
	inputs: ['state'],
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
					<div class="_background-sunrise" [style.opacity]=1>
						<div class="lower-sky" [style.background]=sky2Color [style.top.px]=sky2Top
						[style.height.px]=sky2height [style.opacity]=sky2Opacity>
						</div>
						<div class="upper-sky"
							[style.width.px]=skyWidth [style.height.px]=skyHeight [style.opacity]=showSky
							[style.top.px]=skyTop [style.right.px]=skyRight [style.background]=skyColor>
						</div>
						<div class="solar-corona"
							[style.width.px]=coronaWidth [style.height.px]=coronaHeight [style.opacity]=showCorona
							[style.top.px]=coronaTop [style.right.px]=coronaRight [style.background]=coronaColor>
						</div>

						<div class="sun"
							[style.width.px]=sunWidth [style.height.px]=sunHeight [style.opacity]=showSun
							[style.top.px]=sunTop [style.right.px]=sunRight [style.background]=sunColor>
						</div>
					</div>

								`,
})



export class BackgroundSunriseComponent {

	@Input()
	set state(value: string) { this.setState(value); }

	private changeDetectorRef: ChangeDetectorRef;

	sunWidth:			number = 100
	sunHeight:		number = 100
	sunTop:				number = 350
	sunRight:			number = 350
	sunColor:			string = "#FFFFFF"

	coronaWidth:			number = 100
	coronaHeight:		  number = 100
	coronaTop:				number = 0
	coronaRight:			number = 0
	coronaColor:			string = "#FFB300"

	skyWidth:			number = 100
	skyHeight:		number = 100
	skyTop:				number = 350
	skyRight:			number = 350
	skyColor:			string = "#E91E63"

	sky2Color:			string = "#64FFDA"
	sky2Top:				number = 350
	sky2height:			number = 64
	sky2Opacity:		number = 0.3


	showSun:			number = 0
	showCorona:		number = 0
	showSky:			number = 0

	containerHeight: number = 100

	constructor(public ref: ChangeDetectorRef) {
		this.changeDetectorRef = ref;
	}


	setState(state: string): void {
		if(state=="reduce") {
			this.reduce()
		} else {
			this.expand()
		}
	}


	tequilaSunrise() {
		this.sunWidth = window.innerWidth
		this.sunHeight = window.innerWidth
		this.sunTop = window.innerWidth/2 * -1
		this.sunRight = window.innerWidth/2 * -1
		this.sunColor = "#FFFFFF"

		let coronaRadius = window.innerWidth * 1.5
		this.coronaWidth = coronaRadius
		this.coronaHeight = coronaRadius + 50
		this.coronaTop = coronaRadius / 1.8 * -1
		this.coronaRight = (coronaRadius * -1) / 3
		this.coronaColor = "#FFB300"

		this.skyWidth = this.sunWidth * 4
		this.skyHeight = this.sunWidth * 1.6
		this.skyTop = this.sunWidth / 2 * -1
		this.skyRight = this.skyWidth / 2  * -1
		this.skyColor = "#E91E63"

		this.sky2Top = window.innerHeight - 96
		this.sky2height = 64
		this.sky2Opacity = 0.5
		this.sky2Color = "#64FFDA"

	}


	entrance() {
		this.reduce()
		setTimeout(() => {
			this.showSky = 0.7
			this.showSun = 1
			this.showCorona = 1
		}, 250);


		setTimeout(() => {
			this.expand()
		}, 2000);

	}



	sunElement(status: string) {
		if(status=="expand") {
			this.sunWidth = window.innerWidth
			this.sunHeight = window.innerWidth
			this.sunTop = window.innerWidth/2 * -1
			this.sunRight = window.innerWidth/2 * -1
			this.sunColor = "#FFFFFF"
		} else {
			this.sunWidth = window.innerWidth
			this.sunHeight = window.innerWidth
			this.sunTop = window.innerWidth/2 * -1 - 80
			this.sunRight = window.innerWidth/2 * -1
			this.sunColor = "#FFFFFF"
		}
	}

	solarCoronaElement(status: string) {
		let coronaRadius = window.innerWidth * 1.5
		if(status=="expand") {
			this.coronaWidth = coronaRadius
			this.coronaHeight = coronaRadius + 50
			this.coronaTop = coronaRadius / 1.8 * -1
			this.coronaRight = (coronaRadius * -1) / 3
			this.coronaColor = "#FFB300"
			this.showCorona = 1
		} else {
//			this.coronaHeight = this.containerHeight * 0.5
//			this.coronaTop = 0
			this.showCorona = 0.85
		}
	}

	upperSkyElement(status: string) {
		let coronaRadius = window.innerWidth * 1.5
		if(status=="expand") {
			this.skyWidth = this.sunWidth * 4
			this.skyHeight = this.sunWidth * 1.6
			this.skyTop = this.sunWidth / 2 * -1
			this.skyRight = this.skyWidth / 2  * -1
			this.skyColor = "#EC407A"
			this.showSky = 1
		} else {
			this.showSky = 0.8
		}
	}


	lowerSkyElement(status: string) {
		if(status=="expand") {
			this.sky2Top = window.innerHeight - 96
			this.sky2height = 64
			this.sky2Opacity = 0.5
		} else {
	//		this.sky2Top = window.innerHeight - 144
	//		this.sky2height = 144
			this.sky2Opacity = 0.7
		}
	}



	reduce() {
		this.sunElement("reduce")
		this.solarCoronaElement("reduce")
		this.upperSkyElement("reduce")
		this.lowerSkyElement("reduce")
	}

	expand() {
		this.sunElement("expand")
		this.solarCoronaElement("expand")
		this.upperSkyElement("expand")
		this.lowerSkyElement("expand")
	}


	ngOnInit() {
		this.containerHeight = window.innerHeight
		this.tequilaSunrise()
		this.entrance()
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}


}
