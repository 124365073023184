import { Injectable } from '@angular/core';


@Injectable()
export class FileUploadService {


	private _files: any[] = [];
    

    constructor() {
    }


    public fileBrowseHandler($event: any): void {
		const element = $event.currentTarget as HTMLInputElement;
    	let fileList: FileList | null = element.files;
    	if (fileList) {
			const arr = Array.from(fileList);
			arr.forEach(file => {
				let rec = {
					file:				file,
					filename:			file.name,
					size:				file.size,
					progress:			0,
					type:				file.type,
					isUploading:		false
				}
				this._files.push(rec)
			});

		//	this.uploadAllFiles()
    	}
		console.log(JSON.stringify(this._files, null, 4))
    }


    public getTotalUploadingStats() {

    }




	public formatBytes(bytes: number, decimals = 2): string {
	    if (bytes === 0) return '0';
	    const k = 1024;
	    const dm = decimals < 0 ? 0 : decimals;
	    const sizes = [' B', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZP', ' YB'];
	    const i = Math.floor(Math.log(bytes) / Math.log(k));
	    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}



    public get files(): any[] {
        return this._files;
    }
    public set files(value: any[]) {
        this._files = value;
    }

}
