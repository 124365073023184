import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'badge',
  inputs: ['value', 'small'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div class="_badge {{_size}}" errorColor onError
    [style.paddingLeft.px]=_padding [style.paddingRight.px]=_padding>{{this._label}}</div>
  `,
})
export class BadgeComponent {

  @Input()
  set value(value: number) {
    this.setValue(value);
  }
  set small(value: boolean) {
    this.isSmall(value);
  }


  _size: string = ""
  _label: string = ""
  _value: number = 0
  _padding: number = 0
  changeDetectorRef: ChangeDetectorRef;

  constructor(public ref: ChangeDetectorRef) {
    this.changeDetectorRef = ref;
  }


  setValue(value: number) {
    this._value = value;

    if(value < 10) {
      this._padding = 0
    } else {
      this._padding = 4
    }

    if(value > 1000) {
      this._label = "999+"
    } else {
      this._label = this._value.toString()
    }

    if(this._size == "small") {
      this._label = ""
    }
  }

  isSmall(value: boolean) {
    if(value == true) {
      this._size = "small"
      this._label = ""
    } else {
      this._size = ""
    }
  }


  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
