import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter
} from '@angular/core';


@Component({
	selector: 		'flag-icon',
	inputs: ['countrycode'],

	template: 	`
		<img [src]=this.imgSrc/>
								`,

})



export class FlagIconComponent {

	@Input()
	set countrycode(value: string) { this.setCountryCode(value); }

	_countryCode: string = "au"
	imgSrc: string = ""

	constructor() {
	}

	setCountryCode(value: string) {
		this._countryCode = value;
		this.setImgSrc(this._countryCode)
	}


	setImgSrc(ccode: string) {
		this.imgSrc = "./assets/flagicons/" + ccode + ".svg"
	}

	ngOnInit() {
		//this.setImgSrc("us")
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
