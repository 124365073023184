export class UIBrandColor {

    private _colorModelName: string = "";
    private _primaryColor: string = "";
    private _secondaryColor: string = "";
    private _tertiaryColor: string = "";
    private _quaternaryColor: string = "";
    private _neutralColor: string = "";
    private _neutralVariantColor: string = "";
    private _errorColor: string = "";
    private _successColor: string = "";
    private _warningColor: string = "";
    private _isDark: boolean = false;
    

    constructor() {
        this.defaultColorScheme()
    }

    defaultColorScheme() {
        this._colorModelName = "Default Purple";
        this._primaryColor = "6750A4";
        this._secondaryColor = "958DA5";
        this._tertiaryColor = "B58392";
        this._quaternaryColor = "B58392";
        this._neutralColor = "939094";
        this._neutralVariantColor = "615d66";
        this._errorColor = "ba1a1a";
        this._successColor = "4BB543";
        this._warningColor = "ffbf00";
    }


    toJSON() {
        let rec = {
            name: this._colorModelName,
            primary: this._primaryColor,
            secondary: this._secondaryColor,
            tertiary: this._tertiaryColor,
            quaternary: this._quaternaryColor,
            neutral: this._neutralColor,
            neutralVariant: this._neutralVariantColor,
            error: this._errorColor,
            success: this._successColor,
            warning: this._warningColor,
            isDark: this._isDark
        }
    }

    fromJSON(json: any) {
        this._colorModelName = json["name"];
        this._primaryColor = json["primary"];
        this._secondaryColor = json["secondary"];
        this._tertiaryColor = json["tertiary"];
        this._quaternaryColor = json["quaternary"];
        this._neutralColor = json["neutral"];
        this._neutralVariantColor = json["neutralVariant"];
        this._errorColor = json["error"];
        this._successColor = json["success"];
        this._warningColor = json["warning"];
        this._isDark = json["isDark"]
    }




    public get colorModelName(): string {
        return this._colorModelName;
    }
    public set colorModelName(value: string) {
        this._colorModelName = value;
    }
    public get primaryColor(): string {
        return this._primaryColor;
    }
    public set primaryColor(value: string) {
        this._primaryColor = value;
    }
    public get secondaryColor(): string {
        return this._secondaryColor;
    }
    public set secondaryColor(value: string) {
        this._secondaryColor = value;
    }
    public get tertiaryColor(): string {
        return this._tertiaryColor;
    }
    public set tertiaryColor(value: string) {
        this._tertiaryColor = value;
    }
    public get quaternaryColor(): string {
        return this._quaternaryColor;
    }
    public set quaternaryColor(value: string) {
        this._quaternaryColor = value;
    }
    public get neutralColor(): string {
        return this._neutralColor;
    }
    public set neutralColor(value: string) {
        this._neutralColor = value;
    }
    public get neutralVariantColor(): string {
        return this._neutralVariantColor;
    }
    public set neutralVariantColor(value: string) {
        this._neutralVariantColor = value;
    }
    public get errorColor(): string {
        return this._errorColor;
    }
    public set errorColor(value: string) {
        this._errorColor = value;
    }
    public get successColor(): string {
        return this._successColor;
    }
    public set successColor(value: string) {
        this._successColor = value;
    }
    public get warningColor(): string {
        return this._warningColor;
    }
    public set warningColor(value: string) {
        this._warningColor = value;
    }
    public get isDark(): boolean {
        return this._isDark;
    }
    public set isDark(value: boolean) {
        this._isDark = value;
    }
        
}
    