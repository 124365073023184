import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  ChangeDetectorRef,
  NgZone,
  Inject,
  Renderer2,
} from '@angular/core';

import { EventsService } from '../../services/event.service';
import { UILayout } from '../../services/ui.layout.service'

@Component({
  selector: 'modal',
  encapsulation: ViewEncapsulation.None,
  inputs: ['width', 'height', 'percentage', 
          'differentcompact', 'compactwidth', 'compactheight',
        'compactpercentage'],

  template: `
    <scrim-layer (clicked)=clickEvent($event) [close]=_closeScrim>
      <div class="_modal-container {{_cssClass}}"
        [style.marginTop.px]=_marginTopPx
        [style.marginLeft.px]=_marginLeftPx
        [style.width.px]=_modalWidthPx
        [style.height.px]=_modalHeightPx
        >
        <card [height]=_modalHeightPx [noradius]=_noRadius>
          <ng-content></ng-content>
        </card>
      </div>
    </scrim-layer>
  `,
})
export class ModalContainer {
  @Input()
  set width(value: number) {
    this.setWidth(value);
  }
  set height(value: number) {
    this.setHeight(value);
  }
  set compactwidth(value: number) {
    this.setCompactWidth(value);
  }
  set compactheight(value: number) {
    this.setCompactHeight(value);
  }
  set percentage(value: boolean) {
    this.setUsePercentage(value);
  }
  set differentcompact(value: boolean) {
    this.setDifferentCompact(value);
  }
  set compactpercentage(value: boolean) {
    this.setCompactPercentage(value);
  }

  closeIcon: string = 'dots-vertical';

  screenHeight: number = 100
  screenWidth: number = 200

  _modalWidthPx: number = 200
  _modalHeightPx: number = 200
  _marginTopPx: number = 0
  _marginLeftPx: number = 0

  _originalModalWidth: number = 200
  _originalModalHeight: number = 200

  _compactWidthPx: number = 98
  _compactHeightPx: number = 98
  _originalCompactWidth: number = 98
  _originalCompactHeight: number = 98

  _closeScrim: boolean = false
  _cssClass: string = ""
  _noRadius: boolean = false
  _usePercentage: boolean = false
  _useDifferentCompact: boolean = false
  _compactPercentage: boolean = false

  eventsService: EventsService;
	uiLayout: UILayout

  constructor(
    private _eventsService: EventsService,
    private _uiLayout: UILayout,
    public ref: ChangeDetectorRef
  ) {
    this.eventsService = _eventsService;
    this.uiLayout = _uiLayout

    let self = this;
    this.eventsService.on('onResize', function (args: any) {
      self.calculatePosition();
    });
  }

  setWidth(value: number) {
    this._modalWidthPx = value
    this._originalModalWidth = value
  }

  setHeight(value: number) {
    this._modalHeightPx = value
    this._originalModalHeight = value
  }

  setCompactWidth(value: number) {
    this._compactWidthPx = value
    this._originalCompactWidth = value
  }
  setCompactHeight(value: number) {
    console.log("Setting.... " + value)
    this._compactHeightPx = value
    this._originalCompactHeight = value
  }


  setUsePercentage(value: boolean) {
    this._usePercentage = value
  }

  setDifferentCompact(value: boolean) {
    this._useDifferentCompact = value
  }
  setCompactPercentage(value: boolean) {
    this._compactPercentage = value
  }


  closeSequence() {
    this._closeScrim = true
    setTimeout(() => {
      this.eventsService.broadcast('onHideModalOverlay', null);
      this.ref.detectChanges();
    }, 350);
  }

  clickEvent($event: any) {
    if (
      this.isOutOfBounds($event.x,$event.y)) {
      this.closeSequence();
    }
  }


  //detect bounds
  isOutOfBounds(x: number, y: number) {
    let isOut = false;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    //test the x axis
    if(x < this._marginLeftPx || (x > this._marginLeftPx + this._modalWidthPx)) {
      isOut = true
    }
    if(y < this._marginTopPx || (y > this._marginTopPx + this._modalHeightPx)) {
      isOut = true
    }
    return isOut;
  }

 
  calculateWidth(width: number) {
    let widthPx: number = 0
    if(this._usePercentage) {
        widthPx = window.innerWidth * (this._originalModalWidth/100)
    } else {
      widthPx = width
    }
    return widthPx
  }
  calculateWidthCompactMode(width: number) {
    let widthPx: number = 0
    if(this._compactPercentage) {
        widthPx = window.innerWidth * (this._originalCompactWidth/100)
    } else {
      widthPx = width
    }
    return widthPx
  }


  calculateHeight(height: number) {
    let heightPx: number = 0
    if(this._usePercentage) {
        heightPx = window.innerHeight * (this._originalModalHeight/100)
    } else {
      heightPx = height
    }
    return heightPx
  }
  calculateHeightCompactMode(height: number) {
    let heightPx: number = 0
    if(this._compactPercentage) {
        heightPx = window.innerHeight * (this._originalCompactHeight/100)
    } else {
      heightPx = height
    }
    return heightPx
  }


  calculatePosition() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    if(this._useDifferentCompact) {
      if(this.uiLayout.windowClass == "compact") {
        this.calculatePositionCompact()
      } else {
        this.calculatePositionRegular()
      }
    } else {
      this.calculatePositionRegular()
    }
  }

  calculatePositionRegular() {
    this._modalWidthPx = this.calculateWidth(this._originalModalWidth)
    this._modalHeightPx = this.calculateHeight(this._originalModalHeight)
    this._marginTopPx = (this.screenHeight - this._modalHeightPx) / 2
    this._marginLeftPx = (this.screenWidth - this._modalWidthPx) / 2
    this._cssClass = ""
    this._noRadius = false
    this._cssClass = ""
    this._noRadius = false
    this.ref.detectChanges();
  }


  calculatePositionCompact() {
    this._modalWidthPx = this.calculateWidthCompactMode(this._originalCompactWidth)
    this._modalHeightPx = this.calculateHeightCompactMode(this._originalCompactHeight)
    this._marginTopPx = (this.screenHeight - this._modalHeightPx) / 2
    this._marginLeftPx = (this.screenWidth - this._modalWidthPx) / 2

    if(this._modalWidthPx >= window.innerWidth) {
        this.fullscreenSettings()
    } else {
      this._cssClass = ""
      this._noRadius = false
      this._cssClass = ""
      this._noRadius = false
    }
    this.ref.detectChanges();
  }



  fullscreenSettings() {
    this._cssClass = "fullscreen"
    this._modalWidthPx = this.screenWidth      
    this._modalHeightPx = this.screenHeight
    this._marginTopPx = 0
    this._marginLeftPx = 0
    this._noRadius = true
  }


  ngOnInit() {
    this._closeScrim = false
    this.calculatePosition();
    setTimeout(() => {
      this.ref.detectChanges();
    }, 50);
  }

  ngOnDestroy() {
	}

}
