import { Injectable } from '@angular/core';

import { NavigationRailSettings } from "../classes/navigation/navigation.rail.class"
import { NavigationBarSettings } from "../classes/navigation/navigation.bar.class"


@Injectable()
export class UINavigation {

  private _navigationBarSettings: NavigationBarSettings = new NavigationBarSettings()
  private _navigationRailSettings: NavigationRailSettings = new NavigationRailSettings()

    activeNavigationRail: string = ""
    private _activeView: string = "";
    private _activeRightSlider: string = "";
    private _activeBottomNav: string = "";

    constructor() {
    }


    navigationBar() {
      return this._navigationBarSettings
    }
    navigationRail() {
      return this._navigationRailSettings
    }

    openRightSlider(id: string) {
      this._activeRightSlider = id
    }

    closeRightSlider() {
      this._activeRightSlider = ""
    }


    public get activeView(): string {
      return this._activeView;
    }
    public set activeView(value: string) {
      this._activeView = value;
    }
    public get activeRightSlider(): string {
      return this._activeRightSlider;
    }
    public set activeRightSlider(value: string) {
      this._activeRightSlider = value;
    }
    public get activeBottomNav(): string {
      return this._activeBottomNav;
    }
    public set activeBottomNav(value: string) {
      this._activeBottomNav = value;
    }
  
}
