///////////////////////////////////////////////////////////////////////
// Angular modules
///////////////////////////////////////////////////////////////////////
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

///////////////////////////////////////////////////////////////////////
// 3rd Party Modules - Imports
///////////////////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module';
import { CoreUIExtensionsModule } from '@libraries/coreui-extensions/coreui.extensions.module';
import { DynamicCloudsBackgroundModule } from '@libraries/dynamic-clouds/dynamicclouds.module'
import { TilesCollectionsModule } from '@libraries/tile-collections/tile.collection.module';
import { CardExamplesModule } from '@libraries/card-examples/card.examples.module';
import { CalendarUIModule } from '@libraries/calendar-ui/calendar-ui.module';
import { TimezonesModule } from '@libraries/timezones/timezones.module';

import { PeopleUIModule } from '@libraries/people-ui/peopleui.module';

import { DomainProtocolsModule } from '@libraries/domainprotocols/domainprotocols.module';

///////////////////////////////////////////////////////////////////////
// SERVICES
///////////////////////////////////////////////////////////////////////
import { ApplicationService } from './services/application.service';



///////////////////////////////////////////////////////////////////////
// Container
///////////////////////////////////////////////////////////////////////
import { TopLevelRoutes } from './main/toplevel.routes';

import { MainContainer } from './main/main';
import { MainRoutes } from './main/main.routes';
import { Modals } from './main/modals';
import { RightSliders } from './main/sliders';
import { Backgrounds } from './main/backgrounds';
import { LeftNavigation } from './main/left.navigation.routes';
import { BottomNavigation } from './main/bottom.navigation.routes';

import { AppbarRoutes } from './components/appbar/appbar.routes';
/* Appbar - medium size */
import { AppbarMediumComponent } from './components/appbar/appbar.medium.component';
/* Appbar - compact size */
import { AppbarCompactComponent } from './components/appbar/appbar.compact.component';

/* Sidenav Drawer */
import { SidenavDrawerComponent } from './components/sidenav/sidenav.component';
import { SidenavMenuItemComponent } from './components/sidenav/sidenav.menu.item.component';


///////////////////////////////////////////////////////////////////////
// MODULES
///////////////////////////////////////////////////////////////////////
/** Auth **/
import { AppAuthService } from './modules/auth/app.auth.service';
import { SignUpScreen } from './modules/auth/signup.screen';
import { SignUpMethodScreen } from './modules/auth/signup.method.screen';
import { SignInFieldsScreen } from './modules/auth/signin.fields.screen';
import { SignInScreen } from './modules/auth/signin.screen';

import { BackgroundSunriseComponent } from './creative/sunrise/background.sunrise.component';

///////////////////////////////////////////////////////////////////////
// CONTENT
///////////////////////////////////////////////////////////////////////

import { HomeScreen } from './content/home/home.screen';


import { TravelScreen } from './content/travel/travel.screen';
import { Travel01Screen } from './content/travel/travel01.screen';

import { Dining01Screen } from './content/dining/dining01.screen';


import { IconsCollectionScreen } from './content/icons/icons.screen';
import { DiscoverScreen } from './content/discover/discover.screen';


@NgModule({
  declarations: [
    TopLevelRoutes,
    MainContainer,
    MainRoutes,
    Modals,
    RightSliders,
    Backgrounds,
    LeftNavigation,
    BottomNavigation,
    AppbarRoutes,
    AppbarMediumComponent,
    AppbarCompactComponent,
    SidenavDrawerComponent,
    SidenavMenuItemComponent,

    BackgroundSunriseComponent,

    // Auth
    SignUpScreen,
    SignUpMethodScreen,
    SignInFieldsScreen,
    SignInScreen,

    HomeScreen,
    TravelScreen,
    Travel01Screen,

    Dining01Screen,
    IconsCollectionScreen,
    DiscoverScreen

  ],

  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    CoreUIModule,
    CoreUIExtensionsModule,
    DynamicCloudsBackgroundModule,
    TilesCollectionsModule,
    CardExamplesModule,
    CalendarUIModule,
    TimezonesModule,
    PeopleUIModule,
    DomainProtocolsModule
  ],


  providers: [
    ApplicationService,
    AppAuthService
  ],

  exports: [
    TopLevelRoutes,
    MainContainer,
    MainRoutes,
    Modals,
    RightSliders,
    Backgrounds,
    LeftNavigation,
    BottomNavigation,
    AppbarRoutes,
    AppbarMediumComponent,
    AppbarCompactComponent,
    SidenavDrawerComponent,
    SidenavMenuItemComponent,

    BackgroundSunriseComponent,

    // Auth
    SignUpScreen,
    SignUpMethodScreen,
    SignInFieldsScreen,
    SignInScreen,
    
    HomeScreen,
    TravelScreen,
    Travel01Screen,

    Dining01Screen,
    IconsCollectionScreen,
    DiscoverScreen

  ]
})
export class PeachMarketingModule { }
