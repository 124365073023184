import {
	Component,
	Input,
	ChangeDetectorRef,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';


@Component({
	selector: 		'star-review',
	changeDetection: ChangeDetectionStrategy.Default,
	inputs: ['score', 'activecolor', 'small', 'color'],
	template: 	`

		<ul class="_stars-review {{smallCSS}}">
				<li class="item" *ngFor="let dat of this._stars">
					<i class="mdi mdi-{{dat.icon}}"
						[style.color]=dat.color>
					</i>
				</li>
		</ul>


		`,
})


export class StarReviewWComponent {

	@Input()
	set score(value: number) {
	  this.setScore(value);
	}
	set activecolor(value: string) {
		this.setActiveColor(value);
	}
	set small(value: boolean) {
		this.setSmall(value);
	}
	
	private changeDetectorRef: ChangeDetectorRef
    private eventsService: EventsService
	uiColorModel: UIColorModel

	_score: number = 0
	_activeColor: string = "#FFD740"


	_activeIcon: string = "star"  
	_inactiveIcon: string = "star"  
	_activeIconHalf: string = "star-half-full"  

	_stars: any = []
	_isSmall: boolean = false
	smallCSS: string = ""

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiColorModel: UIColorModel) {
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.changeDetectorRef = ref

		this.addStar(1)
		this.addStar(2)
		this.addStar(3)
		this.addStar(4)
		this.addStar(5)

		let self = this;
		this.eventsService.on('onRefresh', function (args: any) {
			self.updateColors()
		});

	}

	setScore(value: number) {
		this._score = value
		this.renderScore(this._score)
	}

	setActiveColor(value: string) {
		if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')) {
			this._activeColor = value
		} else {
			this._activeColor = '#' + value
		}
		this.updateColors()
	}


	addStar(id: number) {
		let rec = {
			id: id,
			color: this._activeColor,
			icon: this._inactiveIcon,
			isactive: true
		}
		this._stars.push(rec)
	}

	renderScore(value: number) {
		//strip the decimal
		let newValue = Math.trunc(value)
		let i = 0
		for(i=0;i<this._stars.length;i++) {
			if(this._stars[i]["id"] < (newValue + 1)) {
				this._stars[i]["icon"] = this._activeIcon
				this._stars[i]["isactive"] = true
			} else {
				this._stars[i]["icon"] = this._inactiveIcon
				this._stars[i]["isactive"] = false
			}
		}

		//check if decimal - for the half star
		let idx = value.toString().indexOf(".")
		if(idx != -1) {
			let position = newValue
			this._stars[position]["icon"] = this._activeIconHalf
			this._stars[position]["isactive"] = true
		}
	}


	updateColors() {
	//	this._activeColor = "#" + this.uiColorModel.primaryKeyColor
		let i = 0
		for(i=0;i<this._stars.length;i++) {
			this._stars[i]["color"] = this._activeColor
		}

	}

	setSmall(value: boolean) {
		this._isSmall = value
		if(value == true) {
			this.smallCSS = "small"
		} else {
			this.smallCSS = ""
		}
	}

	ngOnInit() {
		this.updateColors()
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}