import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { UINavigation } from '@coreui/services/ui.navigation.service';


@Component({
	selector: 		'sp-appbar-medium',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
		<div class="_sp-navbar-medium {{_cssShadowClass}} {{_cssAnimationClass}}"
			[style.background]=this._surfaceColor>
			<div class="logo" (click)=logoClick()>
				<img [src]=getLogo()/>
			</div>
			<div class="fill-remaining-space"></div>
			<div class="text-items" onSurface>
				<div class="item" subtitle onSurface font-family (click)=whoWeAre()>
					About Us				
				</div>
				<div class="item" subtitle onSurface font-family>
					What We Do				
				</div>
				<div class="item" subtitle onSurface font-family>
					Where We Serve
				</div>
				<div class="item" subtitle onSurface font-family>
					Members
				</div>
			</div>
		</div> 

		`,
})


export class AppbarMediumComponent {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiNavigation: UINavigation
	uiColorModel: UIColorModel
	_surfaceColor: string = ""
	_cssShadowClass: string = ""
	_cssAnimationClass: string = ""

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _uiNavigation: UINavigation) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.uiNavigation = _uiNavigation

		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self._surfaceColor = self.uiColorModel.surface
		});
		this.eventsService.on('onScrollStart', function (args: any) {
			self._surfaceColor = self.uiColorModel.surfaceContainer
			self._cssShadowClass = "shadow-2 scale-2"
			self.changeDetectorRef.detectChanges()
		});
		this.eventsService.on('onScrollEnd', function (args: any) {
			self._surfaceColor = self.uiColorModel.surface
			self._cssShadowClass = "scale-1"
			self.changeDetectorRef.detectChanges() 
		});
	  
	}


	whoWeAre() {
		this.uiNavigation.activeView = "who-we-are"
	}

	public getLogo(): string {
		return "./assets/spiritans/logos/Spiritan-Logo-Full.png"
	}
	public logoClick(): void {
		this.uiNavigation.activeView = "home"
	}


	public getAvatarPic(): string {
		return "./assets/avatars/happy-avatar1.jpg"
	}

	ngOnInit() {
		this._surfaceColor = this.uiColorModel.surface
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
