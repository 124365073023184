import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	HostListener,
	ChangeDetectorRef,
	Input
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { UILayout } from '../../services/ui.layout.service'


@Component({
	selector: 		'column',
	inputs: ['cssclass'],
	encapsulation: ViewEncapsulation.None,
	template:	`
		<div class="col {{_cssString}} no-padding margin-motion-animation">
			<div class="_column-inner-container">
				<ng-content></ng-content>
			</div>
		</div>
	`,

})


export class ColComponent {

	@Input()
	set cssclass(value: string) {
	  this.setCssSlass(value);
	}
  

	private changeDetectorRef: ChangeDetectorRef
    private eventsService: EventsService
	uiLayout: UILayout
	_cssString: string = "s0"

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, private _uiLayout: UILayout
		) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiLayout = _uiLayout
	}

	setCssSlass(value: string) {
		this._cssString = value
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
	}


}
