import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import {
  Observable,
} from "rxjs";

import { EventsService } from '@coreui/services/event.service'
import { ApplicationService } from "../../services/application.service";

@Injectable()
export class FileUploadService {
  http: HttpClient;
  applicationService: ApplicationService;
  private eventsService: EventsService
  private _filesToUpload: any[] = [];

  constructor(
    private _http: HttpClient,
    private _applicationService: ApplicationService,
	private _eventsService: EventsService
  ) {
    this.http = _http
    this.applicationService = _applicationService
	this.eventsService = _eventsService
  }

  public fileBrowseHandler($event: any): void {
    const element = $event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      const arr = Array.from(fileList);
      arr.forEach((file) => {
        let rec = {
          file: file,
          original_filename: file.name,
          size: file.size,
          progress: 0,
          type: file.type,
          isUploading: false,
          isDone: false,
          url: "",
          objectid: "",
          filename: "",
        };
        this._filesToUpload.push(rec);
      });

      this.uploadAllFiles();
    }
  }

  private uploadAllFiles() {
    for (let dat of this._filesToUpload) {
		console.log("UPLOADING " + this._filesToUpload.length + " files")
      if (dat.isUploading == false) {
        if (dat.isUploading == false) {
          this.readFile(dat.file).then((fileContents) => {
            console.log(fileContents);
            // Put this string in a request body to upload it to an API.
            this.applicationService.dom
              .presignedurlPut(dat.file.name, "artcloud")
              .subscribe(
                (value) => {
                  console.log(JSON.stringify(value, null, 4));
                  let putURL = value.body.url
				  let objectid = value.body.objectid
          console.log(">>>>>>>>>>>> " + objectid)
			//	  let newFilename = objectid + "." + dat.file.name.split(".").pop()
			//	  console.log(newFilename)
                  this.upload_file_to_s3(
                    putURL,
                    fileContents,
                    objectid
                  ).subscribe(
                    (value) => {
                      console.log(JSON.stringify(value, null, 4));					  
					//  this.eventsService.broadcast("onHoverMenuItem", {id: dat.objectid})
					  if(value.type == 1) {
						console.log("File uploaded: " + objectid)	
						let sid = this.applicationService.session.sessionid
						let objecttype = this.detectFileType(dat.file.name)
						let size = dat.file.size
						let ext =  dat.file.name.split(".").pop() || ""
						this.applicationService.dom.addObject(sid, objectid, objecttype, ext, size).subscribe(
							(value) => {
								console.log(JSON.stringify(value, null, 4));
							},	
							(err) => {
								console.log(err);
								console.log("Error contacting server");
							}
							);
						}
  					  
                      },
                      (err) => {
                        console.log(err);
                        console.log("Error contacting server");
                      }
                    );
                },
                (err) => {
                  console.log(err);
                  console.log("Error contacting server");
                }
              );
          });
        }
      }
    }
  }



  ////////////////////////////////////////
  // Manage the file uploads
  ////////////////////////////////////////
  setAsUploading(id: string) {
	let i=0
	for(i=0;i<this._filesToUpload.length;i++) {
		if(this._filesToUpload[i].objectid == id) {
			this._filesToUpload[i].isUploading = true
		}
	}
  }
  updateProgress(id: string, progress: number) {
	let i=0
	for(i=0;i<this._filesToUpload.length;i++) {
		if(this._filesToUpload[i].objectid == id) {
			this._filesToUpload[i].progress = progress
		}
	}
  }


  getUploadingFileRecord(id: string): any {
	let result: any = {}
	let i=0
	for(i=0;i<this._filesToUpload.length;i++) {
		if(this._filesToUpload[i].objectid == id) {
			result = this._filesToUpload[i]
		}
	}
	return result
  }




  processFileUpload(id: string, uploaded: number, total: number) {
    let perc = Math.round((100 * uploaded) / total);
    console.log(id + "  " + perc + "%   " + uploaded);
    return perc;
  }

  public getTotalUploadingStats() {}

  public formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return "0";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      " B",
      " KB",
      " MB",
      " GB",
      " TB",
      " PB",
      " EB",
      " ZP",
      " YB",
    ];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  detectFileType(filename: string): string {
    let extn = filename.split(".").pop();
    let contentType = "application/octet-stream";
    if (extn == "html") contentType = "text/html";
    if (extn == "css") contentType = "text/css";
    if (extn == "js") contentType = "application/javascript";
    if (extn == "pdf") contentType = "application/pdf";
    if (extn == "png" || extn == "jpg" || extn == "gif" || extn == "jpeg")
      contentType = "image/" + extn;
    return contentType;
  }

  upload_file_to_s3(
    put_url: string,
    file: string | ArrayBuffer,
    filename: string
  ): Observable<any> {
    let fileType = this.detectFileType(filename);

    return this.http.put(put_url, file, {
      reportProgress: true,
      observe: "events",
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Content-Type": fileType,
      }),
    });
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        return resolve((e.target as FileReader).result || "");
      };

      reader.onerror = (e) => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      if (!file) {
        console.error("No file to read.");
        return reject(null);
      }

      reader.readAsArrayBuffer(file);
    });
  }

  public get filesToUpload(): any[] {
    return this._filesToUpload;
  }
  public set filesToUpload(value: any[]) {
    this._filesToUpload = value;
  }
}
