import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { ColorUtils } from "../../style/color/utils/color.utils.class"
import { UIColorModel } from '../../services/ui.color.model.service';

@Component({
  selector: 'filter-chip',
  inputs: ['label'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
        <chip [label]=_label [leadingicon]=_leadingIcon (clicked)=onClick($event)></chip>
  `,
})
export class FilterChipComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
  private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel

  _id: string = ""
  _label: string = ""
  _leadingIcon: string = ""
  _outlineString: string = "1px solid #000000"
	_highlightColor: string = "rgba(0,0,0,0)"
	_iconColor: string = "rgba(0,0,0,0)"
  _isActive: boolean = false
  _elevation: number = 0
  _isOutlined: boolean = false

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

	}


  onClick($event: any) {
    if(this._isActive) {
      this._leadingIcon = ""
      this._isActive = false
    } else {
      this._leadingIcon = "check"
      this._isActive = true
    }
    this.clicked.emit(this._id)
  }

  setLabel(value: string) {
    this._label = value
  }
  setLeadingIcon(value: string) {
    this._leadingIcon = value
  }


  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
