import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';

import { PersonSampleDataInfo } from '@libraries/people-ui/classes/person.sample.class'

@Component({
	selector: 		'people-picker-slider',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<slider-sheet [direction]="'bottom'" [size]=_containerHeight>
      <div bottom>
		<div class="_people-vertical-search-list" surfaceContainer>
			<div class="_header" onSurface font-family>
				<div class="label">Add Contacts</div>
				<div class="control" (click)=close()>Done</div>
			</div>
			<div class="_search-section">
				<search-bar (value)=searchValue($event)></search-bar>
			</div>
			<div class="_content" [style.height.px]=_searchContainerHeight>
				<ul class="list-tiles-container">
					<li class="single-item-list-tile-icon-text" *ngFor="let dat of this._searchArray"
						(click)=addPerson(dat.id)
						[style.marginBottom.px]=_marginBottom>
						<div class="circle-wrapper">
						<div class="circle">
							<img [src]=dat.imgsrc/>
						</div>
						</div>
						<div class="content">
							<div class="primary-text" onSurface font-family>{{dat.fullname}}</div>
						</div>
						<div *ngIf="'this._icon.length>0'" class="secondary-action" onSurface>
							<tile-secondary-action-56 [icon]=dat.icon></tile-secondary-action-56>
						</div>
					</li>


				<!--
					<tile-single-text-avatar *ngFor="let dat of this._searchArray"
						[imgsrc]=dat.imgsrc
						[label]=dat.fullname
						[secondaryicon]=dat.icon
						(clicked)=addPerson(dat.id)>
					</tile-single-text-avatar>
-->
				</ul>
			</div>
		</div>
      </div>
    </slider-sheet>		

		`,
})


export class PeoplePickerBottomSliderComponent {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	_surfaceColor: string = ""

	_containerHeight: number = 100
	_searchContainerHeight: number = 100
	_sample: PersonSampleDataInfo = new PersonSampleDataInfo()
	_samplePeople: any[] = []
	_marginBottom: number = 8

	_prevScroll: number = 0

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
		});
	/*	this.eventsService.on('onScrollStart', function (args: any) {
			let diff = Math.abs(args.y - self._prevScroll) 
			if(diff > 50) {
				self._marginBottom = 12;
				console.log("scroll start " + args.y)
				self._prevScroll = args.y
			}

			self.changeDetectorRef.detectChanges()
		});
		this.eventsService.on('onScrollEnd', function (args: any) {
			self._marginBottom = 8;
			console.log("scroll end " + args.y)
			self.changeDetectorRef.detectChanges()
		});
*/
	}

	processNames() {
		let i = 0
		for(i=0;i<this._samplePeople.length;i++) {
			this._samplePeople[i]["added"] = false
			this._samplePeople[i]["icon"] = ""
			this._samplePeople[i]["fullname"] = this._samplePeople[i]["firstname"] + " " 
			+ this._samplePeople[i]["lastname"] 
		}
		this._samplePeople.sort((a, b) => (a.fullname > b.fullname) ? 1 : -1)
	}

	ngOnInit() {
		this._surfaceColor = this.uiColorModel.surface
		this._containerHeight = window.innerHeight * 0.95
		this._searchContainerHeight = this._containerHeight - 132
		this._samplePeople = this._sample.data
		this.processNames()

	  
		
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

	close() {
		this.eventsService.broadcast("onHideSliderSheet", {})
	}

	_searchArray: any[] = []
	searchValue($event: any) {
		//this.entranceCSS = "hidden"
		let searchTerm = $event.toLowerCase()
		this.clearSearch()
		let i = 0
		for(i=0;i<this._samplePeople.length;i++) {
			let firstname: string = this._samplePeople[i]["firstname"]
			firstname = firstname.toLowerCase()
			let lastname: string = this._samplePeople[i]["lastname"]
			lastname = lastname.toLowerCase()
			if(firstname.startsWith(searchTerm) || lastname.startsWith(searchTerm)) {
			  this._searchArray.push(this._samplePeople[i])
			}
		}
	}

	addPerson(id: string) {
		let i = 0
		for(i=0;i<this._samplePeople.length;i++) {
			if(this._samplePeople[i]["id"] == id) {
				if(this._samplePeople[i]["added"] == false) {
					this._samplePeople[i]["added"] = true
					this._samplePeople[i]["icon"] = "check-circle"	
				} else {
					this._samplePeople[i]["added"] = false
					this._samplePeople[i]["icon"] = ""		
				}
			//	this.clearSearch()
			}
		}
	}

	clearSearch() {
		let newArray: any[] = []
		let i = 0
		for(i=0;i<this._samplePeople.length;i++) {
			//if(this._searchArray[i]["added"] == true) {
				newArray.push(this._samplePeople[i])				
		//	}
		}
		this._searchArray = []
	}
}
