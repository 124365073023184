import {
	Component,
	Input
} from '@angular/core';


@Component({
	selector: 		'travel-icon-progress',
	inputs: ['icon', 'isdark', 'perc', 'noline'],

	template: 	`
		<div class="_travel-icon-progress">
			<div *ngIf="'_noLine==false'" class="progress-line"></div>
			<i class="mdi mdi-{{this._icon}}" [style.left.%]=_perc onSurface></i>
		</div>

	`,

})



export class TravelIconProgressComponent {

	@Input()
	set icon(value: string) { this.setIcon(value); }
	set perc(value: number) { this.setPerc(value); }
	set isdark(value: boolean) { this.setIsDark(value); }
	set noline(value: boolean) { this.setNoLine(value); }

	_icon: string = "airplane rotate90"
	_perc: number = 50
	_isDark:	boolean = false
	_noLine: boolean = false

	constructor() {
	}

	setIcon(value: string) {
		this._icon = value
	}

	setPerc(value: number) {
		this._perc = value
	}

	setIsDark(value: boolean) {
		this._isDark = value
	}

	setNoLine(value: boolean) {
		this._noLine = value
	}

	ngOnInit() {
	/*	this._perc = 1
		setTimeout(() => {
			this._perc = 100
		}, 1000); */
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
