import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef
} from '@angular/core';

import { ApplicationService } from '../services/application.service';
import { EventsService } from '@coreui//services/event.service'
import { TypographyModel } from '@coreui/services/typography.service';
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UILayout } from '@coreui//services/ui.layout.service';


@Component({
	selector: 		'uicore-guide-main',
	encapsulation: ViewEncapsulation.None,
	template:	`

		<application-container>

			<div top>
				<appbar-top></appbar-top>

			</div>

			<div left-navigation>
				<navigation-rail 
						[data]=this.applicationService.navigationRail()
						(hoverenter)=this.handleNavRailOnHover($event)
						(clicked)=handleNavRailClicked($event)>
				</navigation-rail>
			</div>

			<div inner-header>
				<web-appbar-w-search></web-appbar-w-search>
			</div>

			<div body>
				<main-routes [route]=this.uiNavigation.activeView></main-routes>
			</div>

			<div right-slider-sheet>
				<right-sliders></right-sliders>
			</div>

			<div bottom>
				<navigation-bar 
					[data]=this.applicationService.navigationBar()
					(clicked)=handleNavBarClicked($event)>
				</navigation-bar>
			</div>

		</application-container>

		<!-- Modals -->
		<modal-contaimer></modal-contaimer>
	`,

})


export class Main {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	applicationService: ApplicationService
	uiNavigation: UINavigation
	uiLayout: UILayout
	private typographyModel: TypographyModel

	_route: string = "home"

	constructor(public ref: ChangeDetectorRef,
			private _applicationService: ApplicationService, 
			private _eventsService: EventsService,
			private _uiNavigation: UINavigation,
			private _uiLayout: UILayout,
			private _typographyModel: TypographyModel) {
		this.changeDetectorRef = ref		
		this.applicationService = _applicationService
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.uiLayout = _uiLayout
		this.typographyModel = _typographyModel

		this.uiNavigation.activeView = "templates.article.m3"

		let self = this
		self.eventsService.on('onSignIn', function (args: any) {
			self.applicationService.session = args.data
			self.uiLayout.showInnerHeader = true
			self.applicationService.dashboardMode()
		});

	}

	ngOnInit() {
		this.applicationService.visitLog()
		setTimeout(() => {
			this.autoSignIn()
		}, 100);

		
	/*	setTimeout(() => {
			this.applicationService.navigationRail().unHoverAll()
			this.applicationService.navigationRail().makeHover("nr-styles", true)
			this.eventsService.broadcast("onHoverMenuItem", {id: "nr-styles"})
		}, 3000);
		setTimeout(() => {
			this.applicationService.navigationRail().makeHover("nr-styles", false)
			this.eventsService.broadcast("onHoverExitMenuItem", {})
		}, 7000);



		setTimeout(() => {
			this.applicationService.navigationRail().unPressAll()
			this.applicationService.navigationRail().makePressed("nr-styles.icons", true)
			this.uiNavigation.activeView = this.applicationService.navigationRail().getDestination("nr-styles.icons")
		}, 20000);
		setTimeout(() => {
			this.applicationService.navigationRail().unPressAll()
			this.applicationService.navigationRail().setPressed("nr-patterns", false)
		}, 50000);
		*/

		
	}


	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

	
	handleNavRailClicked(id: string) {
		if(id == "navigation-rail-menu") {
			this.eventsService.broadcast("onShowModalOverlay", {id: "sample-overlay"})
		} else {
			this.applicationService.navigationRail().unPressAll()
			if(this.applicationService.navigationRail().hasSubmenu(id) == false) {
				this.applicationService.navigationRail().makePressed(id, true)
				this.uiNavigation.activeView = this.applicationService.navigationRail().getDestination(id)
			}	
		}
	}

	handleNavRailOnHover(id: string) {
		this.applicationService.navigationRail().unHoverAll()
		this.applicationService.navigationRail().makeHover(id, true)
	}


	handleNavBarClicked(rec: any) {
		this.applicationService.navigationBar().setActive(rec.id, true)
		this.uiNavigation.activeView = rec.id
	}

	handleNavDrawerClicked(rec: any) {
		this.applicationService.navigationDrawer().setActive(rec.id, true)
		this.uiNavigation.activeView = rec.id
	}


	gotoDestinationAnimated(id: string) {
	}


	private recurseHovers(id: string) {
		let items: string[] = []
		items = id.split(".")
		let str: string = ""
		let i = 0
		for(i=0;i<items.length;i++) {
			if(i==0) {
				str = items[i]
			} else {
				str = str + "." + items[i]
			}
			let hasChildren = this.applicationService.navigationRail().hasChildren(str)
			if(hasChildren) {
			//	this.applicationService.navigationRail().setHover(str, true)
				this.eventsService.broadcast("onHoverMenuItem", {id: str})
			}
			console.log(str + " has children = " + hasChildren)
		}
	}


	autoSignIn() {
		let username = "root"
		let password = "skyai_password"
		this.applicationService.session.signin(username, password).subscribe(
			value => {
				console.log(JSON.stringify(value.body.data, null, 4))
				if(value.body.data.status == true) {
					this.applicationService.session.sessionRegisterSuccessful(value.body.data.session)
					this.eventsService.broadcast("onSignIn", {data: this.applicationService.session})
				} else {
					console.log(JSON.stringify(value.body.data, null, 4))
				}
		
			}, 
			err => {
				console.log(err)
				console.log("Error contacting server")
		})		
	}


}

